import React,{useState} from "react";
import Slider from "react-slick";
import imageUpload from "../assets/img/upload.png";
import CountDown from "../Components/CountDown";
import AutoHeightTextarea from "../Components/AutoHeightTextarea";
function SlickNextArrow(props) {
  const { className, onClick } = props;
  return (
      <button type='button' className={className} onClick={onClick}>{">"}</button>
  );
}
function SlickPrevArrow(props) {
  const { className, onClick } = props;
  return (
      <button type='button' className={className} onClick={onClick}>{"<"}</button>
  );
}
function hexToRgb(hex) {
  hex = hex.replace(/^#/, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
}
const NewHomepage = (props) => {
    const {mode,windowSize,texts,banerSlides,images,cards, ...rest} = props;
    const ButtonDetails = (props) => {
      const [isOpen, setIsOpen] = useState(mode||rest.slider ? false : true);
      const [position, setPosition] = useState({ x: props.left, y: props.top });
      const [isDragging, setIsDragging] = useState(false);
      const handleMouseDown = (e) => {
        if (!isDragging) {
          setIsOpen(true);
          setIsDragging(true);
        }
      };
      const handleMouseMove = (e) => {
        if (isDragging) {
          const parent = e.currentTarget.parentElement;
          const parentRect = parent.getBoundingClientRect();
          const offsetX = (e.clientX - parentRect.left) / parentRect.width;
          const offsetY = (e.clientY - parentRect.top) / parentRect.height;
          const clampedX = Math.min(1, Math.max(0, offsetX));
          const clampedY = Math.min(1, Math.max(0, offsetY));
          setPosition({ x: clampedX * 100, y: clampedY * 100 });
        }
      };
      const handleMouseUp = () => {
        setIsDragging(false);
        props.objet.link = position.x;
        props.objet.redirection = position.y;
        rest.handleEdit(props.objet);
        setIsOpen(false);
      };
      return(
          <div 
            className="details-card" 
            onMouseDown={mode ? handleMouseDown : () => {}}
            onMouseMove={mode ? handleMouseMove : () => {}}
            onMouseUp={mode ? handleMouseUp : () => {}}
            style={{left:`${position.x}%`,top:`${position.y}%`}}
           >
              <button type="button" onClick={() =>setIsOpen(!isOpen)}>{mode||rest.slider ? <i className={!isOpen ? "fa fa-plus" : "fa fa-minus"}></i>:<span className="fa fa-plus"></span>}</button>
              {isOpen&&(<div className="card">
                {props.content}
              </div>)}
              {!mode&&!rest.slider&&(<div className="card">
                {props.content}
              </div>)}
          </div>
      )
    }
    var settings = {
      dots: true,
      fade:true,
      swipe:mode ? false : true,
      speed: 1200,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:false
    };
    var settings2 = {
      autoplay: false,
      dots:false,
      centerMode:false,
      infinite:false,
      slidesToShow:  windowSize != "mobile-view" ? 4.5 : 1.2,
      slidesToScroll: 1,
      arrow:true,
      nextArrow: <SlickNextArrow />,
      prevArrow: <SlickPrevArrow />,
      responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          arrow:false,
        }
      }]
    };
    var settings3 = {
      dots: false,
      swipe:true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrow:true,
      nextArrow: <SlickNextArrow />,
      prevArrow: <SlickPrevArrow />,
    };
    const renderText = (id,click) => {
      const ElementType = texts[id].textType;
      if(ElementType)
        return(
          mode && ElementType!=="p"? 
          <AutoHeightTextarea onClick={click} classvalue={texts[id].class} id={texts[id].id} value={texts[id].text} change={rest.changeTitle} />
          :
          ElementType=="p" ?
          <ElementType 
          className={mode ? "label-edit "+texts[id].class : texts[id].class}
          dangerouslySetInnerHTML={{ __html: texts[id].text }}
          onClick={mode ? click : () => {}}
          />
          :
          <ElementType 
          className={texts[id].class}
          >
          {texts[id].text}
          </ElementType>
        )
    }
    const renderElements = (prefix,id) => {
      var type = id!=null ? prefix+'-'+id : prefix;
      return texts.filter((text) =>text.type == type).map((item, index) => {
        const ElementType = item.textType;
        if(item.elementType==="texte")
          if(item.textType!=="p")
            return (
              mode ?
              <AutoHeightTextarea key={index} classvalue={item.class} id={item.id} value={item.text} change={rest.changeTitle} />
              :
              <ElementType 
                key={index}
                className={item.class}
              >
              {item.text}
              </ElementType>
            );
          else   
            return(
              <p 
                key={index}
                className={item.class}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            );
          else if (item.elementType==="bouton")
            return (
              <a href={item.redirection ? item.redirection :"#"} className={"btn "+item.class} key={index}>{item.text}</a>
            );
          else if (item.elementType==="image")
            return (
                <img src={item.redirection ? item.redirection : images[item.imgId].imagePreviewUrl} className={item.class ? item.class : null} key={index} alt=""/>
          );
          else if (item.elementType==="compteur")
            if(mode)
              return (
                <CountDown 
                  targetDate={item.date}
                  key={index}
                />
              );
            else 
            return(
              <div className="countdown" data-date={item.date} key={index}>
                <div class="countdown-item">
                  <span class="countdown-number" id="days">00</span>
                  <span class="countdown-label">jours</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-number" id="hours">00</span>
                  <span class="countdown-label">heures</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-number" id="minutes">00</span>
                  <span class="countdown-label">minutes</span>
                </div>
                <div class="countdown-item">
                  <span class="countdown-number" id="seconds">00</span>
                  <span class="countdown-label">secondes</span>
                </div>
              </div>
            )
      });
    };
    return (
        <div className='front newhomepage'>
            <style>
              {`
                .front.newhomepage b{
                  font-weight:900
                }
                .front.newhomepage section{
                  max-width:100%;
                  margin-bottom:0;
                }
                .front.newhomepage button{
                  margin-top:unset;
                }
                .front.newhomepage button:focus,.front.newhomepage a:focus{
                  outline:0
                }
                .front.newhomepage .baner-slide .slide{
                  height:${texts[0].size+''+texts[0].unity};
                  display:flex!important;
                  min-height:400px;
                  background-size:cover;
                  background-repeat:no-repeat;
                  background-position:center;
                }
                .front.newhomepage .baner-slide .container{
                  position:relative;
                  z-index:2
                }
                .front.newhomepage .baner-slide .slick-dots{
                  bottom:16px;
                }
                .front.newhomepage .baner-slide .slick-dots li button:before{
                  width:15px;
                  height:15px;
                  border-radius:8px;
                  border:2px solid #fff;
                  font-family:unset;
                  font-size:0!important;
                  line-height:unset;
                  background-color:transparent;
                  opacity:1
                }
                .front.newhomepage .baner-slide .slick-dots li.slick-active button:before{
                  background-color:#fff;
                  top:0!important
                }
                ${banerSlides.filter((slide) =>slide.type === 'slide-baner').map((slide) => {
                  return `.front.newhomepage .baner-slide .slide.slide-${slide.id}{
                    background-image:url(${images[slide.imageId].imagePreviewUrl});
                    text-align:${texts[slide.textId].align};
                    align-items:${texts[slide.textId].verticalAlign};
                    padding:${texts[slide.textId].mt+'px '+texts[slide.textId].mr+'px '+texts[slide.textId].mb+'px '+texts[slide.textId].ml+'px'};
                    ${images[slide.imageId].imagePreviewUrl==imageUpload?
                      'background-size:auto': ''
                    }
                  }
                  ${texts.filter((element) => element.type === 'baner-slide-content-'+slide.id).map((element) => {
                    if(element.elementType==="texte")
                      return `.front.newhomepage .baner-slide .slide.slide-${slide.id} ${element.class ? "."+element.class : element.textType}{
                        font-size:${element.size+'px'};
                        color:${element.color};
                        font-weight:${element.weight};
                        font-family:${element.font+",sans-serif"};
                        margin:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'}
                      }`
                    if(element.elementType==="bouton")
                      return `.front.newhomepage .baner-slide .slide.slide-${slide.id} ${element.class ? ".btn."+element.class : ".btn"}{
                        font-size:${element.size+'px'};
                        color:${element.color};
                        font-weight:${element.weight};
                        font-family:${element.font+",sans-serif"};
                        background-color:${element.background};
                        text-transform:none;
                        padding:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                        border-radius:${element.btl+"px "+element.btr+"px "+element.bbr+"px "+element.bbl+"px"};
                      }`
                    if(element.elementType==="image")
                      return `.front.newhomepage .baner-slide .slide.slide-${slide.id} ${element.class ? '.'+element.class : "img"}{
                        width:100%;
                        max-width: ${element.size+''+element.unity};
                        margin:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                        height:auto
                      }`
                    if(element.elementType==="compteur")
                      return `.front.newhomepage .baner-slide .slide.slide-${slide.id} .countdown{
                        display:flex;
                        justify-content:space-between;
                        margin:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                        max-width:300px
                      }
                      .front.newhomepage .baner-slide .slide.slide-${slide.id} .countdown .countdown-number{
                        font-size:${element.size+'px'};
                        color:${element.color};
                        font-weight:${element.weight};
                        font-family:${element.font+",sans-serif"};
                        display:block;
                        text-align:center;
                        line-height:36px
                      }
                      .front.newhomepage .baner-slide .slide.slide-${slide.id} .countdown .countdown-label{
                        font-size:9px;
                        color:${element.color};
                        text-transform:uppercase;
                        display:block;
                        text-align:center;
                      }`
                    }).join('')}`
                  }).join('')}
                .front.newhomepage .btn.black{
                  border-radius:25px;
                  padding:4px 24px;
                  background-color:#080808;
                  color:#fff;
                  letter-spacing:1px;
                  font-family:LFT Etica, sans-serif
                }
                .front.newhomepage .decouvrir .title-slide .slick-arrow{
                  font-size:30px;
                  font-weight:500;
                  font-family:Poppins, sans-serif;
                  color:#000;
                  z-index:1
                }
                .front.newhomepage .decouvrir .title-slide .slick-arrow::before{
                  display:none
                }
                .front.newhomepage .decouvrir .title-slide .slick-prev{
                  left:0;
                }
                .front.newhomepage .decouvrir .title-slide .slick-next{
                  right:0;
                }
                .front.newhomepage .decouvrir .title-slide{
                  text-align:${texts[3].align};
                  padding:${texts[3].mt+'px '+texts[3].mr+'px '+texts[3].mb+'px '+texts[3].ml+'px'};
                }
                .front.newhomepage .decouvrir .title-slide .ttr, .front.newhomepage .decouvrir .title-slide .ttr a{
                  font-size:${texts[3].size+'px'};
                  font-family:${texts[3].font+",sans-serif"};
                  font-weight:${texts[3].weight};
                  color:${texts[3].color};
                  text-decoration:none;
                  margin:0;
                  letter-spacing:2px
                }
                .front.newhomepage .decouvrir .title-slide .ttr a:hover{
                  border-bottom:1px solid ${texts[3].color};
                }
                .front.newhomepage .decouvrir-card{
                  position:relative;
                  display:block;
                  text-decoration:none
                }
                .front.newhomepage .decouvrir-card .details{
                  position:absolute;
                  top:0;
                  left:0;
                  width:100%;
                  height:100%;
                  display:flex;
                  align-items:${texts[4].verticalAlign};
                  justify-content:${texts[4].align =="left" ? "flex-start" : texts[4].align =="right" ? "flex-end" : "center"};
                }
                .front.newhomepage .decouvrir-card .details .sttr{
                  font-size:${texts[4].size+'px'};
                  font-family:${texts[4].font+",sans-serif"};
                  font-weight:${texts[4].weight};
                  color:${texts[4].color};
                  text-align:${texts[4].align};
                }
                .front.newhomepage .decouvrir-card:hover .details{
                  background-color:rgba(0,0,0,.25)
                }
                .front.newhomepage .decouvrir-card:hover .details .sttr{
                  text-decoration:underline;
                  text-decoration-thickness: 1px;
                  text-underline-offset: 10px;
                }
                .front.newhomepage .bestseller{
                  overflow:hidden;
                  padding-left:15px
                }
                .front.newhomepage .bestseller .bestseller-slider .slick-list{
                  overflow:unset;
                }
                .front.newhomepage .bestseller .bestseller-slider .slide{
                  text-decoration:none
                }
                .front.newhomepage .bestseller .bestseller-slider .slide .card-bs{
                  margin-right:10px;
                }
                .front.newhomepage .bestseller .bestseller-slider .slick-arrow{
                  z-index:10;
                }
                .front.newhomepage .bestseller .bestseller-slider .slick-prev{
                  left:25px;
                }
                .front.newhomepage .bestseller .bestseller-slider .slick-next{
                  right:25px;
                }
                .front.newhomepage .bestseller ${texts[5].class ? "."+texts[5].class : texts[5].textType}{
                  position:relative;
                  font-size:${texts[5].size+'px'};
                  color:${texts[5].color};
                  font-weight:${texts[5].weight};
                  font-family:${texts[5].font+",sans-serif"};
                  margin:${texts[5].mt+'px '+texts[5].mr+'px '+texts[5].mb+'px '+texts[5].ml+'px'};
                  text-align:${texts[5].align};
                }
                .front.newhomepage .bestseller ${texts[5].class ? "."+texts[5].class : texts[5].textType}::before{
                  content:"";
                  height:2px;
                  width:70px;
                  background-color:${texts[5].color};
                  position:absolute;
                  left:auto;
                  bottom:-1px
                }
                .front.newhomepage .bestseller .card-bs-title{
                  font-size:${texts[6].size+'px'};
                  font-family:${texts[6].font+",sans-serif"};
                  font-weight:${texts[6].weight};
                  color:${texts[6].color};
                  text-align:${texts[6].align};
                  margin:${texts[6].mt+'px '+texts[6].mr+'px '+texts[6].mb+'px '+texts[6].ml+'px'};
                }
                .front.newhomepage .preview{
                  padding: 16px 0 64px;
                }
                .front.newhomepage .preview .img-preview-content{
                  max-width:80%;
                  position:relative;
                }
                .front.newhomepage .preview .col-md-6:first-child .img-preview-content{
                  margin-left:auto;
                }
                .front.newhomepage .preview .col-md-6 .img-preview-content .details-card{
                  position:absolute;
                }
                .front.newhomepage .preview .col-md-6 .img-preview-content .details-card button{
                  width:25px;
                  height:25px;
                  border:none;
                  background-color:${texts[7].background};
                  border-radius:50%;
                  display:flex;
                  padding:0;
                  justify-content:center;
                  align-items:center;
                  position:relative;
                  font-size:16px;
                  color:#232323;
                  z-index:2
                }
                .front.newhomepage .preview .col-md-6 .img-preview-content .details-card .card{
                  width:${texts[7].sizeMobile+'px'};
                  padding:${texts[7].mt+'px '+texts[7].mr+'px '+texts[7].mb+'px '+texts[7].ml+'px'};
                  border-radius:${texts[7].btl+"px "+texts[7].btr+"px "+texts[7].bbr+"px "+texts[7].bbl+"px"};
                  border:1px solid #dedede;
                  opacity:${texts[7].size};
                  text-align:${texts[7].align};
                  background-color:rgba(${hexToRgb(texts[7].background)},${texts[7].size});
                  box-shadow:1px 1px 5px rgba(0,0,0,.2);
                  position:absolute;
                  bottom:12px;
                  right:12px;
                  ${!mode&&!rest.slider&&('display:none')}
                }
                ${!mode&&!rest.slider&&('.front.newhomepage .preview .col-md-6 .img-preview-content .details-card.show .card{display:block}')}
                .front.newhomepage .preview .col-md-6 .img-preview-content .details-card .card .ttr{
                  font-size:${texts[8].size+'px'};
                  color:${texts[8].color};
                  font-weight:${texts[8].weight};
                  font-family:${texts[8].font+",sans-serif"};
                  margin:${texts[8].mt+'px '+texts[8].mr+'px '+texts[8].mb+'px '+texts[8].ml+'px'};
                }
                .front.newhomepage .preview .col-md-6 .img-preview-content .details-card .card .sttr{
                  font-size:${texts[9].size+'px'};
                  color:${texts[9].color};
                  font-weight:${texts[9].weight};
                  font-family:${texts[9].font+",sans-serif"};
                  margin:${texts[9].mt+'px '+texts[9].mr+'px '+texts[9].mb+'px '+texts[9].ml+'px'};
                }
                .front.newhomepage .preview .col-md-6 .img-preview-content .details-card .card .price{
                  font-size:${texts[10].size+'px'};
                  color:${texts[10].color};
                  font-weight:${texts[10].weight};
                  font-family:${texts[10].font+",sans-serif"};
                  margin:${texts[10].mt+'px '+texts[10].mr+'px '+texts[10].mb+'px '+texts[10].ml+'px'};
                }
                .front.newhomepage .preview .col-md-6 .img-preview-content .details-card .card .btn{
                  font-size:${texts[11].size+'px'};
                  color:${texts[11].color};
                  font-weight:${texts[11].weight};
                  font-family:${texts[11].font+",sans-serif"};
                  background-color:${texts[11].background};
                  padding:${texts[11].mt+'px '+texts[11].mr+'px '+texts[11].mb+'px '+texts[11].ml+'px'};
                  border-radius:${texts[11].btl+"px "+texts[11].btr+"px "+texts[11].bbr+"px "+texts[11].bbl+"px"};
                }
                .front.newhomepage .bandeau{
                  padding:${texts[12].mt+'px '+texts[12].mr+'px '+texts[12].mb+'px '+texts[12].ml+'px'};
                  background-color:${texts[12].background};
                }
                .front.newhomepage .bandeau ${texts[13].class ? "."+texts[13].class : texts[13].textType}{
                  font-size:${texts[13].size+'px'};
                  color:${texts[13].color};
                  font-weight:${texts[13].weight};
                  font-family:${texts[13].font+",sans-serif"};
                  margin:${texts[13].mt+'px '+texts[13].mr+'px '+texts[13].mb+'px '+texts[13].ml+'px'};
                  text-align:${texts[13].align};
                }
                .front.newhomepage .bandeau a{
                  text-decoration:underline;
                }
                .front.newhomepage .news{
                  margin-bottom:64px;
                }
                .front.newhomepage .news ${texts[14].class ? "."+texts[14].class : texts[14].textType}{
                  position:relative;
                  font-size:${texts[14].size+'px'};
                  color:${texts[14].color};
                  font-weight:${texts[14].weight};
                  font-family:${texts[14].font+",sans-serif"};
                  margin:${texts[14].mt+'px '+texts[14].mr+'px '+texts[14].mb+'px '+texts[14].ml+'px'};
                  text-align:${texts[14].align};
                }
                
                .front.newhomepage .news ${texts[14].class ? "."+texts[14].class : texts[14].textType}::before{
                  content:"";
                  height:2px;
                  width:70px;
                  background-color:${texts[14].color};
                  position:absolute;
                  left:auto;
                  bottom:-1px
                }
                .front.newhomepage .preview ${texts[18].class ? "."+texts[18].class : texts[18].textType}{
                  position:relative;
                  font-size:${texts[18].size+'px'};
                  color:${texts[18].color};
                  font-weight:${texts[18].weight};
                  font-family:${texts[18].font+",sans-serif"};
                  margin:${texts[18].mt+'px '+texts[18].mr+'px '+texts[18].mb+'px '+texts[18].ml+'px'};
                  text-align:${texts[18].align};
                }
                
                .front.newhomepage .preview ${texts[18].class ? "."+texts[18].class : texts[18].textType}::before{
                  content:"";
                  height:2px;
                  width:70px;
                  background-color:${texts[18].color};
                  position:absolute;
                  left:auto;
                  bottom:-1px
                }
                .front.newhomepage .news .card-news{
                  text-align:${texts[15].align}
                }
                .front.newhomepage .news .card-news .news-title{
                  font-size:${texts[15].size+'px'};
                  color:${texts[15].color};
                  font-weight:${texts[15].weight};
                  font-family:${texts[15].font+",sans-serif"};
                  margin:${texts[15].mt+'px '+texts[15].mr+'px '+texts[15].mb+'px '+texts[15].ml+'px'};
                }
                .front.newhomepage .news .card-news .news-text{
                  font-size:${texts[16].size+'px'};
                  color:${texts[16].color};
                  font-weight:${texts[16].weight};
                  font-family:${texts[16].font+",sans-serif"};
                  margin:${texts[16].mt+'px '+texts[16].mr+'px '+texts[16].mb+'px '+texts[16].ml+'px'};
                }
                .front.newhomepage .news .card-news .btn{
                  font-size:${texts[17].size+'px'};
                  color:${texts[17].color};
                  font-weight:${texts[17].weight};
                  font-family:${texts[17].font+",sans-serif"};
                  padding:${texts[17].mt+'px '+texts[17].mr+'px '+texts[17].mb+'px '+texts[17].ml+'px'};
                  background-color:${texts[17].background};
                  border-radius:${texts[17].btl+"px "+texts[17].btr+"px "+texts[17].bbr+"px "+texts[17].bbl+"px"};
                }
                @media (max-width: 992px){
                  ${banerSlides.filter((slide) =>slide.type === 'slide-baner').map((slide) => {
                    return `.front.newhomepage .baner-slide .slide.slide-${slide.id}{
                      background-image:url(${images[slide.imageId].imagePreviewUrlMobile ? images[slide.imageId].imagePreviewUrlMobile : images[slide.imageId].imagePreviewUrl});
                    }
                    ${texts.filter((element) => element.type === 'baner-slide-content-'+slide.id).map((element) => {
                      if(element.elementType==="texte")
                        return `.front.newhomepage .baner-slide .slide.slide-${slide.id} ${element.class ? "."+element.class : element.textType}{
                          font-size:${element.sizeMobile+'px'};
                        }`
                      if(element.elementType==="bouton")
                        return `.front.newhomepage .baner-slide .slide.slide-${slide.id} ${element.class ? ".btn."+element.class : ".btn"}{
                          font-size:${element.sizeMobile+'px'};
                        }`
                      if(element.elementType==="compteur")
                        return `
                        .front.newhomepage .baner-slide .slide.slide-${slide.id} .countdown .countdown-number{
                          font-size:${element.sizeMobile+'px'};
                        }`
                      }).join('')}`
                    }).join('')}
                  .front.newhomepage .baner-slide .slide{
                    height:${texts[0].sizeMobile+''+texts[0].unity};
                  }
                  .front.newhomepage .decouvrir-card .details .sttr{
                    font-size:24px
                  }
                  .front.newhomepage .bestseller .card-bs-title{
                    font-size:18px
                  }
                }
                @media (max-width: 767px){
                  .front.newhomepage .baner-slide .slide{
                    padding-bottom:100px
                  }
                  .front.newhomepage .baner-slide .slide .ttr{
                    font-size:36px;
                    margin-bottom:24px;
                  }
                  .front.newhomepage .decouvrir-card{
                    margin-bottom:16px
                  }
                  .front.newhomepage .preview .img-preview-content{
                    max-width:100%;
                    margin-bottom:16px
                  }
                  .front.newhomepage .news .card-news{
                    margin-bottom:24px
                  }
                }
              `}
              {mode&&
              `.front{container-type:${windowSize=="desktop-view" ? "inline-size" : "size"}}
              ${banerSlides.filter((slide) =>slide.type === 'slide-baner').map((slide) => {
                return `
                ${texts.filter((element) => element.type === 'baner-slide-content-'+slide.id).map((element) => {
                  if(element.elementType==="texte")
                    return `.front.newhomepage .baner-slide .slide.slide-${slide.id} ${element.class ? "."+element.class : element.textType}{
                      text-align:${texts[slide.textId].align};
                    }`
                  }).join('')}`
                }).join('')}
                @container (max-width: 992px){
                  ${banerSlides.filter((slide) =>slide.type === 'slide-baner').map((slide) => {
                    return `
                    ${`.front.newhomepage .baner-slide .slide.slide-${slide.id}{
                      background-image:url(${images[slide.imageId].imagePreviewUrlMobile ? images[slide.imageId].imagePreviewUrlMobile : images[slide.imageId].imagePreviewUrl});
                    }`}
                    ${texts.filter((element) => element.type === 'baner-slide-content-'+slide.id).map((element) => {
                      if(element.elementType==="texte")
                        return `.front.newhomepage .baner-slide .slide.slide-${slide.id} ${element.class ? "."+element.class : element.textType}{
                          font-size:${element.sizeMobile+'px'};
                        }`
                      if(element.elementType==="bouton")
                        return `.front.newhomepage .baner-slide .slide.slide-${slide.id} ${element.class ? ".btn."+element.class : ".btn"}{
                          font-size:${element.sizeMobile+'px'};
                        }`
                      if(element.elementType==="compteur")
                        return `
                        .front.newhomepage .baner-slide .slide.slide-${slide.id} .countdown .countdown-number{
                          font-size:${element.sizeMobile+'px'};
                        }`
                      }).join('')}`
                    }).join('')}
                  .front{container-type:size;}
                  .front.newhomepage .baner-slide .slide{
                    height:${texts[0].sizeMobile}${texts[0].unity=="vh"?"cqh":texts[0].unity};
                  }
                  .front.newhomepage .decouvrir-card .details .sttr{
                    font-size:24px
                  }
                  .front.newhomepage .bestseller .card-bs-title{
                    font-size:18px
                  }
                }
                @container (max-width: 767px){
                  .row > * {flex-shrink:0;width:100%;max-width:100%}
                  .front.newhomepage .baner-slide .slide{
                    padding-bottom:100px
                  }
                  .front.newhomepage .baner-slide .slide .ttr{
                    font-size:36px;
                    margin-bottom:24px;
                  }
                  .front.newhomepage .decouvrir-card{
                    margin-bottom:16px
                  }
                  .front.newhomepage .preview .img-preview-content{
                    max-width:100%;
                    margin-bottom:16px
                  }
                  .front.newhomepage .news .card-news{
                    margin-bottom:24px
                  }
                }
              `}
            </style>
            {mode || rest.slider ? 
            <Slider className="baner-slide" {...settings} id={mode ? "baner" : null}>
              {banerSlides.filter((slide) =>slide.type === 'slide-baner').map((slide,index) => (
              <div className={"slide slide-"+slide.id} key={index}>
                <div className="container">
                  {renderElements("baner-slide-content",slide.id)}
                </div>
              </div>
              ))}
            </Slider>
            :
            <section className="baner-slide">
              {banerSlides.filter((slide) =>slide.type === 'slide-baner').map((slide,index) => (
              <div className={"slide slide-"+slide.id} key={index}>
                <div className="container">
                  {renderElements("baner-slide-content",slide.id)}
                </div>
              </div>
              ))}
            </section>
            }
            <section className="decouvrir" id={mode ? "decouvrir" : null}>
              <div className="container">
                {mode || rest.slider ? 
                <Slider className="title-slide" {...settings3}>
                  {banerSlides.filter(objet=> objet.type == "baner-slide").map(objet =>
                    <div className="slide" key={objet.id}>
                      {mode ? <p className="ttr"><span className="label-edit" onClick={() => {rest.togglePopup("edit-baner-slide",objet.id,objet.type);rest.focusAccordion(41,`#bandeau-${objet.id}`);}}>{objet.text} <a>{objet.link}</a></span></p> : <p className="ttr">{objet.text} <a href={objet.redirection}>{objet.link}</a></p>}
                    </div>
                  )}
                </Slider>
                :
                <div className="title-slide">
                  {banerSlides.filter(objet=> objet.type == "baner-slide").map(objet =>
                    <div className="slide" key={objet.id}>
                      {mode ? <p className="ttr"><span className="label-edit" onClick={() => {rest.togglePopup("edit-baner-slide",objet.id,objet.type);rest.focusAccordion(41,`#bandeau-${objet.id}`);}}>{objet.text} <a>{objet.link}</a></span></p> : <p className="ttr">{objet.text} <a href={objet.redirection}>{objet.link}</a></p>}
                    </div>
                  )}
                </div>
                }
                <div className="row">
                  {cards.filter(card => card.type == "card-slide").map(card =>
                  <div className="col-md-3" key={card.id}>
                    {mode ? 
                    <div className="decouvrir-card label-edit" onClick={() => {rest.togglePopup("edit-card",card.id,card.type);rest.focusAccordion(41,`#card-${card.id}`);}}>
                      <img src={card.imagePreviewUrl} alt="" className="img-fluid"/>
                      <div className="details">
                        <h3 className="sttr">{card.text}</h3>
                      </div>
                    </div>
                    :
                    <a href={card.redirection ? card.redirection : "#"} className="decouvrir-card">
                      <img src={card.imagePreviewUrl} alt="" className="img-fluid"/>
                      <div className="details">
                        <h3 className="sttr">{card.text}</h3>
                      </div>
                    </a>
                    }
                  </div>
                  )}
                </div>
              </div>
            </section>
            <section className="bestseller" id={mode ? "bestseller" : null}>
              <div className="container">
                {renderText(5,() => rest.focusAccordion(42,`#${CSS.escape(texts[5].elementType!=="p" ? '5' : 'content-editable-5')}`))}
              </div>
              {mode || rest.slider ? 
              <Slider className="bestseller-slider" {...settings2}>
                {cards.filter(card => card.type == "card-carousel").map(card =>
                  rest.slider ?
                  <a href={card.redirection ? card.redirection : "#"} key={card.id} className="slide">
                    <div className="card-bs">
                      <img src={card.imagePreviewUrl} alt="" className="img-fluid"/>
                      <h5 className="card-bs-title">{card.text}</h5>
                    </div>
                  </a>
                  :
                  <div className="slide" key={card.id}>
                    <div className="card-bs">
                      <img src={card.imagePreviewUrl} alt="" className={`img-fluid ${mode ? 'label-edit' : ''}`}  onClick={mode ? () => {rest.togglePopup("edit-card",card.id,card.type);rest.focusAccordion(42,`#card-carousel-${card.id}`);}:() => {}}/>
                      <h5 className={`card-bs-title ${mode ? 'label-edit' : ''}`}  onClick={mode ? () => {rest.togglePopup("edit-card",card.id,card.type);rest.focusAccordion(42,`#card-carousel-${card.id}`);}:() => {}}>{card.text}</h5>
                    </div>
                  </div>
                )}
              </Slider>
              :
              <div className="bestseller-slider">
                {cards.filter(card => card.type == "card-carousel").map(card =>
                <a href={card.redirection ? card.redirection : "#"} key={card.id} className="slide">
                  <div className="card-bs">
                    <img src={card.imagePreviewUrl} alt="" className="img-fluid"/>
                    <h5 className="card-bs-title">{card.text}</h5>
                  </div>
                </a>
                )}
              </div>
              }
            </section>
            <section className="preview" id={mode ? "preview" : null}>
              <div className="container">
                {renderText(18,() => rest.focusAccordion(43,`#${CSS.escape(texts[18].elementType!=="p" ? '18' : 'content-editable-18')}`))}
                <div className="row">
                  <div className="col-md-6">
                    <div className="img-preview-content">
                      {mode ? 
                      <label htmlFor="photo-upload-2">
                        <img src={images[2].imagePreviewUrl} alt="" className="img-fluid label-edit" onClick={() => rest.focusAccordion(43,`#photo-upload-2`)}/>
                      </label>
                      :
                      <img src={images[2].imagePreviewUrl} alt="" className={"img-fluid"}/>
                      }
                      {banerSlides.filter(objet=> objet.type == "puce-first").map(objet =>
                      <ButtonDetails
                        key={objet.id}
                        objet={objet}
                        left={objet.link?objet.link:0}
                        top={objet.redirection?objet.redirection:0}
                        content={
                          <>
                            {objet.text.match(/\*titre:(.*?)\*/)&&(
                              <h5 className='ttr'>{objet.text.match(/\*titre:(.*?)\*/)[1]}</h5>
                            )}
                            {objet.text.match(/\*sous-titre:(.*?)\*/)&&(
                              <h6 className='sttr'>{objet.text.match(/\*sous-titre:(.*?)\*/)[1]}</h6>
                            )}
                            {objet.text.match(/\*prix:(.*?)\*/)&&(
                              <p className="price">{objet.text.match(/\*prix:(.*?)\*/)[1]}</p>
                            )}
                            <a href={objet.text.match(/\*bouton:(.*?)\*/)[1].split("\\")[1]} className="btn">{objet.text.match(/\*bouton:(.*?)\*/)[1].split("\\")[0]}</a>
                          </>
                        }
                      />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="img-preview-content">
                      {mode ? 
                      <label htmlFor="photo-upload-3">
                        <img src={images[3].imagePreviewUrl} alt="" className="img-fluid label-edit" onClick={() => rest.focusAccordion(43,`#photo-upload-3`)}/>
                      </label>
                      :
                      <img src={images[3].imagePreviewUrl} alt="" className={"img-fluid"}/>
                      }
                      {banerSlides.filter(objet=> objet.type == "puce-second").map(objet =>
                      <ButtonDetails
                        key={objet.id}
                        objet={objet}
                        left={objet.link?objet.link:0}
                        top={objet.redirection?objet.redirection:0}
                        content={
                          <>
                            {objet.text.match(/\*titre:(.*?)\*/)&&(
                              <h5 className='ttr'>{objet.text.match(/\*titre:(.*?)\*/)[1]}</h5>
                            )}
                            {objet.text.match(/\*sous-titre:(.*?)\*/)&&(
                              <h6 className='sttr'>{objet.text.match(/\*sous-titre:(.*?)\*/)[1]}</h6>
                            )}
                            {objet.text.match(/\*prix:(.*?)\*/)&&(
                              <p className="price">{objet.text.match(/\*prix:(.*?)\*/)[1]}</p>
                            )}
                            <a href={objet.text.match(/\*bouton:(.*?)\*/)[1].split("\\")[1]} className="btn">{objet.text.match(/\*bouton:(.*?)\*/)[1].split("\\")[0]}</a>
                          </>
                        }
                      />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="bandeau" id={mode ? "bandeau" : null}>
              <div className="container">
                {renderText(13,() => rest.focusAccordion(44,`#${CSS.escape(texts[13].elementType!=="p" ? '13' : 'content-editable-13')}`))}
              </div>
            </section>
            <section className="news" id={mode ? "news" : null}>
              <div className="container">
                {renderText(14,() => rest.focusAccordion(45,`#${CSS.escape(texts[14].elementType!=="p" ? '14' : 'content-editable-14')}`))}
                <div className="row">
                  {cards.filter(card => card.type == "card-news").map(card =>
                  <div className="col-md-4" key={card.id}>
                    <div className={mode ? "card-news label-edit" : "card-news"} onClick={mode ? () => {rest.togglePopup("edit-card",card.id,card.type);rest.focusAccordion(45,`#bloc-${card.id}`);}:() => {}}>
                      <img src={card.imagePreviewUrl} alt="" className="img-fluid"/>
                      <h3 className="news-title">{card.link}</h3>
                      <p className="news-text">{card.text}</p>
                      <a href={card.redirection.split("\\")[1]} className="btn">{card.redirection.split("\\")[0]}</a>
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </section>
            {!mode&&!rest.slider&&(<script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.js"/>)}
        </div>
    );
  }
export default NewHomepage;