import React from "react";
import '../assets/styles/Recrutement.scss';
import "../assets/img/recrutement/icon-arrow-right.svg";
import HubspotForm from 'react-hubspot-form';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import AutoHeightTextarea from "../Components/AutoHeightTextarea";
import iframeIcon from "../assets/img/recrutement/iframe.png"
import AutoWidthInput from "../Components/AutoWidthInput";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Recrutement = (props) => {
    const{mode,logo,logoBlanc,fondBaner,cards,banerSlides,banerTitleStyle,banerSubtitleStyle,formStyle,formSubtitleStyle,formParaStyle,formMentionStyle,formMentionLinkStyle,conseillereTitleStyle,conseillereSubtitleStyle,conseillereSubtitlePinkStyle,conseillereIframe,conseillereSecondColumnTitle,reseauxStyle,decouvrirTitleStyle,decouvrirSubtitleStyle,decouvrirSecondColumnTitle,decouvrirSecondColumnPara,imagineTitleStyle,imagineSubtitleStyle,bgColor,savoiPlusTitleStyle,savoiPlusSubtitleStyle,savoiPlusSubtitlePinkStyle,savoiPlusCardTitleStyle,savoiPlusCardTextStyle,presseStyle,generalBtnStyle,footerStyle,footerTextStyle, ...rest} = props;
    const carouselCards = cards.filter((objet) => objet.type === 'carousel');
    const IframePlaceholder = ({click}) => {
        return (
        <div className="iframe-placeholder label-edit" onClick={()=>click()}>
            <img src={iframeIcon}/>
            <h4>Votre vidéo</h4>
            <p>Pour économiser des ressources, l'aperçu vidéo n'est pas disponible en mode édition, passer en mode aperçu pour voir un aperçu de votre video</p>
        </div>
        )
    }
    return (
        <div className="front engagement">
            <style>{`
            .front.engagement .bloc-hero:before{background-image:url(${fondBaner})}
            .front.engagement .bloc-hero h1{
                font-size:${banerTitleStyle.size+'px'};
                font-family:${banerTitleStyle.font+',sans-serif'};
                color:${banerTitleStyle.color};
                font-weight:${banerTitleStyle.weight};
                text-align:${banerTitleStyle.align};
            }
            .front.engagement .bloc-hero h1 span{
                font-size:${banerSubtitleStyle.size+'px'};
                font-family:${banerSubtitleStyle.font+',sans-serif'};
                color:${banerSubtitleStyle.color};
                font-weight:${banerSubtitleStyle.weight};
                background:no-repeat 0 100%;
                display:inline;
                background-image:linear-gradient(${banerSubtitleStyle.background}, ${banerSubtitleStyle.background});
                background-size:100% 70%;
            }
            .front.engagement .formulaire{
                background-color:${formStyle.background};
                padding:${formStyle.mt+"px "+formStyle.mr+"px "+formStyle.mb+"px "+formStyle.ml+"px"}
            }
            .front.engagement .formulaire h2{
                font-size:${formStyle.size+'px'};
                font-family:${formStyle.font+',sans-serif'};
                color:${formStyle.color};
                font-weight:${formStyle.weight};
                text-align:${formStyle.align};
            }
            .front.engagement .formulaire h2 span{
                display:inline-block;
                padding:0 10px;
                font-size:${formSubtitleStyle.size+'px'};
                font-family:${formSubtitleStyle.font+',sans-serif'};
                color:${formSubtitleStyle.color};
                font-weight:${formSubtitleStyle.weight};
                background:no-repeat 0 100%;
                background-image:linear-gradient(${formSubtitleStyle.background}, ${formSubtitleStyle.background});
                background-size:100% 70%;
            }
            .front.engagement .formulaire p{
                font-size:${formParaStyle.size+'px'};
                font-family:${formParaStyle.font+',sans-serif'};
                color:${formParaStyle.color};
                font-weight:${formParaStyle.weight};
                text-align:${formParaStyle.align};
            }
            .front.engagement .formulaire p.hs-richtext{
                font-size:${formMentionStyle.size+'px'};
                font-family:${formMentionStyle.font+',sans-serif'};
                color:${formMentionStyle.color};
                font-weight:${formMentionStyle.weight};
                text-align:${formMentionStyle.align};
            }
            .front.engagement .formulaire p.hs-richtext a{
                font-size:${formMentionLinkStyle.size+'px'};
                font-family:${formMentionLinkStyle.font+',sans-serif'};
                color:${formMentionLinkStyle.color};
                font-weight:${formMentionLinkStyle.weight};
            } 
            .front.engagement .formulaire .hs-input:not([type=file],.hs-fieldtype-intl-phone){
                background-color:${formParaStyle.background};
                padding:${formParaStyle.mt+'px '+formParaStyle.mr+'px '+formParaStyle.mb+'px '+formParaStyle.ml+'px'}
            }
            .front.engagement .bloc-video h2{
                font-size:${conseillereTitleStyle.size+'px'};
                font-family:${conseillereTitleStyle.font+',sans-serif'};
                color:${conseillereTitleStyle.color};
                font-weight:${conseillereTitleStyle.weight};
                text-align:${conseillereTitleStyle.align};
            }
            .front.engagement .bloc-video h2 .txtweight-300{
                font-size:${conseillereSubtitleStyle.size+'px'};
                font-family:${conseillereSubtitleStyle.font+',sans-serif'};
                color:${conseillereSubtitleStyle.color};
                font-weight:${conseillereSubtitleStyle.weight};
            }
            .front.engagement .bloc-video h2 .txtweight-300 div{
                font-size:${conseillereSubtitlePinkStyle.size+'px'};
                font-family:${conseillereSubtitlePinkStyle.font+',sans-serif'};
                color:${conseillereSubtitlePinkStyle.color};
                font-weight:${conseillereSubtitlePinkStyle.weight};
                display:inline;
            }
            .front.engagement .bloc-video .col-md-5 h3{
                font-size:${conseillereSecondColumnTitle.size+'px'};
                font-family:${conseillereSecondColumnTitle.font+',sans-serif'};
                color:${conseillereSecondColumnTitle.color};
                font-weight:${conseillereSecondColumnTitle.weight};
            }
            .front.engagement .bloc-logos{
                background-color:${reseauxStyle.background};
                padding:${reseauxStyle.mt+'px '+reseauxStyle.mr+'px '+reseauxStyle.mb+'px '+reseauxStyle.ml+'px'}
            }
            .front.engagement .bloc-logos h2{
                font-size:${reseauxStyle.size+'px'};
                font-family:${reseauxStyle.font+',sans-serif'};
                color:${reseauxStyle.color};
                font-weight:${reseauxStyle.weight};
                text-align:${reseauxStyle.align};
            }
            .front.engagement .bloc-videoslider h2{
                font-size:${decouvrirTitleStyle.size+'px'};
                font-family:${decouvrirTitleStyle.font+',sans-serif'};
                color:${decouvrirTitleStyle.color};
                font-weight:${decouvrirTitleStyle.weight};
                text-align:${decouvrirTitleStyle.align};
            }
            .front.engagement .bloc-videoslider h2 span{
                font-size:${decouvrirSubtitleStyle.size+'px'};
                font-family:${decouvrirSubtitleStyle.font+',sans-serif'};
                color:${decouvrirSubtitleStyle.color};
                font-weight:${decouvrirSubtitleStyle.weight};
            }
            .front.engagement .bloc-videoslider .col-md-5 h4{
                font-size:${decouvrirSecondColumnTitle.size+'px'};
                font-family:${decouvrirSecondColumnTitle.font+',sans-serif'};
                color:${decouvrirSecondColumnTitle.color};
                font-weight:${decouvrirSecondColumnTitle.weight};
                text-align:${decouvrirSecondColumnTitle.align};
            }
            .front.engagement .bloc-videoslider .col-md-5 p,.front.engagement .bloc-videoslider .col-md-5 ul li{
                font-size:${decouvrirSecondColumnPara.size+'px'};
                font-family:${decouvrirSecondColumnPara.font+',sans-serif'};
                color:${decouvrirSecondColumnPara.color};
                font-weight:${decouvrirSecondColumnPara.weight};
            }
            .front.engagement .bloc-carrousel:before,.front.engagement .bloc-illus{
                background-color:${bgColor.background}
            }
            .front.engagement .bloc-illus h2{
                font-size:${imagineTitleStyle.size+'px'};
                font-family:${imagineTitleStyle.font+',sans-serif'};
                color:${imagineTitleStyle.color};
                font-weight:${imagineTitleStyle.weight};
                text-align:${imagineTitleStyle.align};
            }
            .front.engagement .bloc-illus h3{
                text-align:${imagineSubtitleStyle.align};
            }
            .front.engagement .bloc-illus h3 span{
                font-size:${imagineSubtitleStyle.size+'px'};
                font-family:${imagineSubtitleStyle.font+',sans-serif'};
                color:${imagineSubtitleStyle.color};
                font-weight:${imagineSubtitleStyle.weight};
            }
            .front.engagement .bloc-illus .col-8 h4{
                font-size:${bgColor.size+'px'};
                font-family:${bgColor.font+',sans-serif'};
                color:${bgColor.color};
                font-weight:${bgColor.weight};
            }
            .front.engagement .bloc-2column .container h2{
                text-align:${savoiPlusTitleStyle.align}
            }
            .front.engagement .bloc-2column .container h2 .txtweight-300{
                font-size:${savoiPlusTitleStyle.size+'px'};
                font-family:${savoiPlusTitleStyle.font+',sans-serif'};
                color:${savoiPlusTitleStyle.color};
                font-weight:${savoiPlusTitleStyle.weight};
                padding:${savoiPlusTitleStyle.mt+'% '+savoiPlusTitleStyle.mr+'% '+savoiPlusTitleStyle.mb+'% '+savoiPlusTitleStyle.ml+'% '};
            }
            .front.engagement .bloc-2column .container h2 div:nth-of-type(2){
                font-size:${savoiPlusSubtitleStyle.size+'px'};
                font-family:${savoiPlusSubtitleStyle.font+',sans-serif'};
                color:${savoiPlusSubtitleStyle.color};
                font-weight:${savoiPlusSubtitleStyle.weight};
                padding:${savoiPlusSubtitleStyle.mt+'% '+savoiPlusSubtitleStyle.mr+'% '+savoiPlusSubtitleStyle.mb+'% '+savoiPlusSubtitleStyle.ml+'% '};
            }
            .front.engagement .bloc-2column .container h2 div:nth-of-type(2) span{
                font-size:${savoiPlusSubtitlePinkStyle.size+'px'};
                font-family:${savoiPlusSubtitlePinkStyle.font+',sans-serif'};
                color:${savoiPlusSubtitlePinkStyle.color};
                font-weight:${savoiPlusSubtitlePinkStyle.weight};
                display:inline;
            }
            .front.engagement .bloc-2column .wrap h2{
                font-size:${savoiPlusCardTitleStyle.size+'px'};
                font-family:${savoiPlusCardTitleStyle.font+',sans-serif'};
                color:${savoiPlusCardTitleStyle.color};
                font-weight:${savoiPlusCardTitleStyle.weight};
            }
            .front.engagement .bloc-2column .wrap p,.front.engagement .bloc-2column .wrap ul li{
                font-size:${savoiPlusCardTextStyle.size+'px'};
                font-family:${savoiPlusCardTextStyle.font+',sans-serif'};
                color:${savoiPlusCardTextStyle.color};
                font-weight:${savoiPlusCardTextStyle.weight};
            }
            .front.engagement .bloc-2column .row .txt .item{
                background-color:${savoiPlusCardTitleStyle.background};
                padding:${savoiPlusCardTitleStyle.mt+'px '+savoiPlusCardTitleStyle.mr+'px '+savoiPlusCardTitleStyle.mb+'px '+savoiPlusCardTitleStyle.ml+'px'};
            }
            .front.engagement .bloc-presse{
                background-color:${presseStyle.background};
                padding:${presseStyle.mt+'px '+presseStyle.mr+'px '+presseStyle.mb+'px '+presseStyle.ml+'px'};
            }
            .front.engagement .btn.mobile-hidden,.front.engagement .btn-bottom .btn{
                background-color:${generalBtnStyle.background};
                padding:${generalBtnStyle.mt+'px '+generalBtnStyle.mr+'px '+generalBtnStyle.mb+'px '+generalBtnStyle.ml+'px'};
                font-size:${generalBtnStyle.size+"px"};
                font-weight:${generalBtnStyle.weight};
                color:${generalBtnStyle.color};
                font-family:${generalBtnStyle.font+',sans-serif'};
            }
            .front.engagement .btn.mobile-hidden:hover{
                background-color:#BF458B
            }
            .front.engagement .bloc-presse h2{
                font-size:${presseStyle.size+'px'};
                font-family:${presseStyle.font+',sans-serif'};
                color:${presseStyle.color};
                font-weight:${presseStyle.weight};
                text-align:${presseStyle.align};
            }
            .front.engagement footer{
                background-color:${footerStyle.background};
                padding:${footerStyle.mt+'px '+footerStyle.mr+'px '+footerStyle.mb+'px '+footerStyle.ml+'px'};
            }
            .front.engagement footer .title{
                font-size:${footerStyle.size+'px'};
                font-family:${footerStyle.font+',sans-serif'};
                color:${footerStyle.color};
                font-weight:${footerStyle.weight};
                text-align:${footerStyle.align};
            }
            .front.engagement footer .text{
                font-size:${footerTextStyle.size+'px'};
                font-family:${footerTextStyle.font+',sans-serif'};
                color:${footerTextStyle.color};
                font-weight:${footerTextStyle.weight};
                text-align:${footerTextStyle.align};
            }
            @media(max-width: 767px) {
                .front.engagement .bloc-hero h1{
                    font-size:${banerTitleStyle.sizeMobile+'px'}
                }
                .front.engagement .bloc-hero h1 span{
                    font-size:${banerSubtitleStyle.sizeMobile+'px'}
                }
                .front.engagement .formulaire h2{
                    font-size:${formStyle.sizeMobile+'px'};
                }
                .front.engagement .formulaire h2 span{
                    font-size:${formSubtitleStyle.sizeMobile+'px'};
                }
                .front.engagement .formulaire p{
                    font-size:${formParaStyle.sizeMobile+'px'}
                }
                .front.engagement .bloc-video h2{
                    font-size:${conseillereTitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-video h2 .txtweight-300{
                    font-size:${conseillereSubtitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-video h2 .txtweight-300 div{
                    font-size:${conseillereSubtitlePinkStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-logos h2{
                    font-size:${reseauxStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-videoslider h2{
                    font-size:${decouvrirTitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-videoslider h2 span{
                    font-size:${decouvrirSubtitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-videoslider .col-md-5 h4{
                    font-size:${decouvrirSecondColumnTitle.sizeMobile+'px'};
                }
                .front.engagement .bloc-videoslider .col-md-5 p,.front.engagement .bloc-videoslider .col-md-5 ul li{
                    font-size:${decouvrirSecondColumnPara.sizeMobile+'px'};
                }
                .front.engagement .bloc-illus h2{
                    font-size:${imagineTitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-illus h3 span{
                    font-size:${imagineSubtitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-2column .container h2 .txtweight-300{
                    font-size:${savoiPlusTitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-2column .container h2 div:nth-of-type(2){
                    font-size:${savoiPlusSubtitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-2column .container h2 div:nth-of-type(2) span{
                    font-size:${savoiPlusSubtitlePinkStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-2column .row .txt .item{
                    padding:${savoiPlusCardTextStyle.mt+'px '+savoiPlusCardTextStyle.mr+'px '+savoiPlusCardTextStyle.mb+'px '+savoiPlusCardTextStyle.ml+'px'};
                }
                .front.engagement .bloc-2column .wrap p,.front.engagement .bloc-2column .wrap ul li{
                    font-size:${savoiPlusCardTextStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-2column .wrap h2{
                    font-size:${savoiPlusCardTitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-presse h2{
                    font-size:${presseStyle.sizeMobile+'px'};
                }
                .front.engagement .btn.mobile-hidden,.front.engagement .btn-bottom .btn{
                    font-size:${generalBtnStyle.sizeMobile+'px'};
                }
                .front.engagement footer .text,.front.engagement footer .title{
                    text-align:center;
                }
                .front.engagement .bloc-hero:before{
                    background-position-y:30%;
                    background-position-x:center;
                    background-size:cover;
                }
            }
            `}
            {/* afficher les styles responsives selon les breakpoints mais en utilisant container */}
            {mode ? 
            `.front.engagement{container-type:inline-size;}
            .front.engagement .textarea-edit,.front.engagement .input-edit{
                font-style:italic;
            }
            .front.engagement .bloc-hero h1 .textarea-edit{
                font-size:${banerTitleStyle.size+'px'};
                font-family:${banerTitleStyle.font+',sans-serif'};
                color:${banerTitleStyle.color};
                font-weight:${banerTitleStyle.weight};
                text-align:${banerTitleStyle.align};
            }
            .front.engagement .bloc-hero h1 span .textarea-edit{
                font-size:${banerSubtitleStyle.size+'px'};
                font-family:${banerSubtitleStyle.font+',sans-serif'};
                color:transparent;
                font-weight:${banerSubtitleStyle.weight};
                text-align:${banerTitleStyle.align};
                width:fit-content;
            }
            .front.engagement .formulaire h2 .input-edit{
                font-size:${formStyle.size+'px'};
                font-family:${formStyle.font+',sans-serif'};
                color:${formStyle.color};
                font-weight:${formStyle.weight};
                text-align:${formStyle.align};
            }
            .front.engagement .formulaire h2 span,.front.engagement .formulaire p.hs-richtext,.front.engagement .bloc-hero h1 span{
                position:relative;
                white-space:break-spaces;
            }
            .front.engagement .formulaire h2 span .input-edit{
                font-size:${formSubtitleStyle.size+'px'};
                font-family:${formSubtitleStyle.font+',sans-serif'};
                color:transparent;
                font-weight:${formSubtitleStyle.weight};
                padding:0 10px;
            }
            .front.engagement .formulaire p .textarea-edit{
                font-size:${formParaStyle.size+'px'};
                font-family:${formParaStyle.font+',sans-serif'};
                color:${formParaStyle.color};
                font-weight:${formParaStyle.weight};
                text-align:${formParaStyle.align};
                font-style:unset;
            }
            .front.engagement .formulaire p.hs-richtext .textarea-edit{
                font-size:${formMentionStyle.size+'px'};
                font-family:${formMentionStyle.font+',sans-serif'};
                color:transparent;
                font-weight:${formMentionStyle.weight};
                text-align:${formMentionStyle.align};
                height:100%
            }
            .front.engagement .formulaire p.hs-richtext .input-edit{
                position:relative;
                z-index:1;
                font-size:${formMentionLinkStyle.size+'px'};
                font-family:${formMentionLinkStyle.font+',sans-serif'};
                color:${formMentionLinkStyle.color};
                font-weight:${formMentionLinkStyle.weight};
                text-align:${formMentionStyle.align};
            }
            .front.engagement .bloc-video h2 .input-edit{
                font-size:${conseillereTitleStyle.size+'px'};
                font-family:${conseillereTitleStyle.font+',sans-serif'};
                color:${conseillereTitleStyle.color};
                font-weight:${conseillereTitleStyle.weight};
                text-align:${conseillereTitleStyle.align};
            }
            .front.engagement .bloc-video h2 .txtweight-300 .input-edit{
                font-size:${conseillereSubtitleStyle.size+'px'};
                font-family:${conseillereSubtitleStyle.font+',sans-serif'};
                color:${conseillereSubtitleStyle.color};
                font-weight:${conseillereSubtitleStyle.weight};
            }
            .front.engagement .bloc-video h2 .txtweight-300 div .input-edit{
                font-size:${conseillereSubtitlePinkStyle.size+'px'};
                font-family:${conseillereSubtitlePinkStyle.font+',sans-serif'};
                color:${conseillereSubtitlePinkStyle.color};
                font-weight:${conseillereSubtitlePinkStyle.weight};
            }
            .front.engagement .bloc-video .col-md-5 h3 .textarea-edit{
                font-size:${conseillereSecondColumnTitle.size+'px'};
                font-family:${conseillereSecondColumnTitle.font+',sans-serif'};
                color:${conseillereSecondColumnTitle.color};
                font-weight:${conseillereSecondColumnTitle.weight};
            }
            .front.engagement .bloc-logos h2 .input-edit{
                font-size:${reseauxStyle.size+'px'};
                font-family:${reseauxStyle.font+',sans-serif'};
                color:${reseauxStyle.color};
                font-weight:${reseauxStyle.weight};
                text-align:${reseauxStyle.align};
                display:inline-block
            }
            .front.engagement .bloc-videoslider h2 .input-edit{
                font-size:${decouvrirTitleStyle.size+'px'};
                font-family:${decouvrirTitleStyle.font+',sans-serif'};
                color:${decouvrirTitleStyle.color};
                font-weight:${decouvrirTitleStyle.weight};
                text-align:${decouvrirTitleStyle.align};
                display:inline-block
            }
            .front.engagement .bloc-videoslider h2 span .input-edit{
                font-size:${decouvrirSubtitleStyle.size+'px'};
                font-family:${decouvrirSubtitleStyle.font+',sans-serif'};
                color:${decouvrirSubtitleStyle.color};
                font-weight:${decouvrirSubtitleStyle.weight};
                display:inline-block
            }
            .front.engagement .bloc-videoslider .col-md-5 h4 .textarea-edit{
                font-size:${decouvrirSecondColumnTitle.size+'px'};
                font-family:${decouvrirSecondColumnTitle.font+',sans-serif'};
                color:${decouvrirSecondColumnTitle.color};
                font-weight:${decouvrirSecondColumnTitle.weight};
                text-align:${decouvrirSecondColumnTitle.align};
            }
            .front.engagement .bloc-illus h2 .input-edit{
                font-size:${imagineTitleStyle.size+'px'};
                font-family:${imagineTitleStyle.font+',sans-serif'};
                color:${imagineTitleStyle.color};
                font-weight:${imagineTitleStyle.weight};
                text-align:${imagineTitleStyle.align};
            }
            .front.engagement .bloc-illus h3 span{
                position:relative;
                display:block;
                white-space:break-spaces;
            }
            .front.engagement .bloc-illus h3 span .textarea-edit{
                font-size:${imagineSubtitleStyle.size+'px'};
                font-family:${imagineSubtitleStyle.font+',sans-serif'};
                color:transparent;
                font-weight:${imagineSubtitleStyle.weight};
                text-align:${imagineSubtitleStyle.align};
            }
            .front.engagement .bloc-2column .container h2 .txtweight-300 .input-edit{
                font-size:${savoiPlusTitleStyle.size+'px'};
                font-family:${savoiPlusTitleStyle.font+',sans-serif'};
                color:${savoiPlusTitleStyle.color};
                font-weight:${savoiPlusTitleStyle.weight};
            }
            .front.engagement .bloc-2column .container h2 div:nth-of-type(2) .input-edit{
                font-size:${savoiPlusSubtitleStyle.size+'px'};
                font-family:${savoiPlusSubtitleStyle.font+',sans-serif'};
                color:${savoiPlusSubtitleStyle.color};
                font-weight:${savoiPlusSubtitleStyle.weight};
                display:inline-block
            }
            .front.engagement .bloc-2column .container h2 div:nth-of-type(2) span .input-edit{
                font-size:${savoiPlusSubtitlePinkStyle.size+'px'};
                font-family:${savoiPlusSubtitlePinkStyle.font+',sans-serif'};
                color:${savoiPlusSubtitlePinkStyle.color};
                font-weight:${savoiPlusSubtitlePinkStyle.weight};
                display:inline-block
            }
            .front.engagement .bloc-presse h2 .input-edit{
                font-size:${presseStyle.size+'px'};
                font-family:${presseStyle.font+',sans-serif'};
                color:${presseStyle.color};
                font-weight:${presseStyle.weight};
                text-align:${presseStyle.align};
            }
            .front.engagement .btn.mobile-hidden .input-edit,.front.engagement .btn-bottom .btn .input-edit{
                background-color:${generalBtnStyle.background};
                font-size:${generalBtnStyle.size+"px"};
                font-weight:${generalBtnStyle.weight};
                color:${generalBtnStyle.color};
                font-family:${generalBtnStyle.font+',sans-serif'};
                text-align:center;
                text-decoration:none
            }
            .front.engagement .btn-bottom{
                position:sticky;
                z-index:5;
                bottom:0;
                top:unset;
            }
            .front.engagement footer .title .input-edit{
                font-size:${footerStyle.size+'px'};
                font-family:${footerStyle.font+',sans-serif'};
                color:${footerStyle.color};
                font-weight:${footerStyle.weight};
                text-align:${footerStyle.align};
                font-style:normal;
            }
            .front.engagement footer .text .textarea-edit{
                font-size:${footerTextStyle.size+'px'};
                font-family:${footerTextStyle.font+',sans-serif'};
                color:${footerTextStyle.color};
                font-weight:${footerTextStyle.weight};
                text-align:${footerTextStyle.align};
                font-style:normal;
            }
            @container(max-width:991px){
                .front.engagement .hs-firstname, .front.engagement .hs-lastname,.front.engagement .hs_phone, .front.engagement .hs_zip{
                    width:100%;
                    margin-left:0;
                }
            }
            @container(max-width: 767px) {
                .front.engagement .bloc-hero{
                    padding-top:15em;
                    padding-bottom:0;
                }
                .front.engagement .bloc-hero h1,.front.engagement .bloc-hero h1 .textarea-edit{
                    font-size:${banerTitleStyle.sizeMobile+'px'}
                }
                .front.engagement .bloc-hero h1 span,.front.engagement .bloc-hero h1 span .textarea-edit{
                    font-size:${banerSubtitleStyle.sizeMobile+'px'}
                }
                .front.engagement .formulaire p .textarea-edit{
                    font-size:${formParaStyle.sizeMobile+'px'}
                }
                .front.engagement .formulaire h2 .input-edit{
                    font-size:${formStyle.sizeMobile+'px'};
                }
                .front.engagement .formulaire h2 span,.front.engagement .formulaire h2 span .input-edit{
                    font-size:${formSubtitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-video h2 .textarea-edit{
                    font-size:${conseillereTitleStyle.sizeMobile+'px'};
                    font-family:${conseillereTitleStyle.font+',sans-serif'};
                    color:${conseillereTitleStyle.color};
                    font-weight:${conseillereTitleStyle.weight};
                    text-align:${conseillereTitleStyle.align};
                }
                .front.engagement .bloc-video h2 .txtweight-300 .textarea-edit{
                    font-size:${conseillereSubtitleStyle.sizeMobile+'px'};
                    font-family:${conseillereSubtitleStyle.font+',sans-serif'};
                    color:${conseillereSubtitleStyle.color};
                    font-weight:${conseillereSubtitleStyle.weight};
                }
                .front.engagement .bloc-video h2 .txtweight-300 div .input-edit{
                    font-size:${conseillereSubtitlePinkStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-logos h2 .textarea-edit{
                    font-size:${reseauxStyle.sizeMobile+'px'};
                    font-family:${reseauxStyle.font+',sans-serif'};
                    color:${reseauxStyle.color};
                    font-weight:${reseauxStyle.weight};
                    text-align:${reseauxStyle.align};
                }
                .front.engagement .bloc-videoslider h2 .textarea-edit{
                    font-size:${decouvrirTitleStyle.sizeMobile+'px'};
                    font-family:${decouvrirTitleStyle.font+',sans-serif'};
                    color:${decouvrirTitleStyle.color};
                    font-weight:${decouvrirTitleStyle.weight};
                    text-align:${decouvrirTitleStyle.align};
                }
                .front.engagement .bloc-videoslider h2 span .textarea-edit{
                    font-size:${decouvrirSubtitleStyle.sizeMobile+'px'};
                    font-family:${decouvrirSubtitleStyle.font+',sans-serif'};
                    color:${decouvrirSubtitleStyle.color};
                    font-weight:${decouvrirSubtitleStyle.weight};
                }
                .front.engagement .bloc-videoslider .col-md-5 h4 .textarea-edit{
                    font-size:${decouvrirSecondColumnTitle.sizeMobile+'px'};
                }
                .front.engagement .bloc-videoslider .col-md-5 p,.front.engagement .bloc-videoslider .col-md-5 ul li{
                    font-size:${decouvrirSecondColumnPara.sizeMobile+'px'};
                }
                .front.engagement .bloc-illus h2 .textarea-edit{
                    font-size:${imagineTitleStyle.sizeMobile+'px'};
                    font-family:${imagineTitleStyle.font+',sans-serif'};
                    color:${imagineTitleStyle.color};
                    font-weight:${imagineTitleStyle.weight};
                    text-align:${imagineTitleStyle.align};
                }
                .front.engagement .bloc-illus h3 span,.front.engagement .bloc-illus h3 span .textarea-edit{
                    font-size:${imagineSubtitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-2column .container h2 .txtweight-300 .textarea-edit{
                    font-size:${savoiPlusTitleStyle.sizeMobile+'px'};
                    font-family:${savoiPlusTitleStyle.font+',sans-serif'};
                    color:${savoiPlusTitleStyle.color};
                    font-weight:${savoiPlusTitleStyle.weight};
                    text-align:${savoiPlusTitleStyle.align}
                }
                .front.engagement .bloc-2column .container h2 div:nth-of-type(2) .input-edit{
                    font-size:${savoiPlusSubtitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-2column .row .txt .item{
                    padding:${savoiPlusCardTextStyle.mt+'px '+savoiPlusCardTextStyle.mr+'px '+savoiPlusCardTextStyle.mb+'px '+savoiPlusCardTextStyle.ml+'px'};
                }
                .front.engagement .bloc-2column .wrap p,.front.engagement .bloc-2column .wrap ul li{
                    font-size:${savoiPlusCardTextStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-2column .wrap h2{
                    font-size:${savoiPlusCardTitleStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-presse h2 .input-edit{
                    font-size:${presseStyle.sizeMobile+'px'};
                }
                .front.engagement .bloc-2column{
                    padding-bottom:28px;
                }
                .front.engagement .bloc-2column .wrap .col-md-6.order-first{
                    order:-1!important
                }
                .front.engagement .bloc-2column .wrap .col-md-6 img{
                    transform:none;
                }
                .front.engagement .bloc-2column .row .txt{
                    margin-left:unset
                }
                .front.engagement .bloc-2column .container > h2 div:nth-of-type(2) span .input-edit{
                    font-size:${savoiPlusSubtitlePinkStyle.sizeMobile+'px'};
                }
                .front.engagement .btn.mobile-hidden .input-edit,.front.engagement .btn-bottom .btn .input-edit{
                    font-size:${generalBtnStyle.sizeMobile+'px'};
                }
                .front.engagement footer .title .textarea-edit{
                    font-size:${footerStyle.size+'px'};
                    font-family:${footerStyle.font+',sans-serif'};
                    color:${footerStyle.color};
                    font-weight:${footerStyle.weight};
                    text-align:center;
                    font-style:normal;
                }
                .front.engagement footer .text .textarea-edit{
                    text-align:center;
                }
                .front.engagement .bloc-hero .col-md-7{
                    padding-bottom:1rem
                }
                .front.engagement .bloc-video .col-md-5{
                    padding-left:12px
                }
                .front.engagement .mobile-hidden{
                    display:none!important
                }
                .front.engagement .desktop-hidden{
                    display:block!important
                }
                .front.engagement p,.front.engagement label,.front.engagement .formulaire p{
                    font-size:14px;
                }
                .front.engagement .formulaire {
                    margin-bottom:0;
                }
                .front.engagement #Carousel-savoirplus .carousel-inner .carousel-item > div, .front.engagement #Carousel-rs .carousel-inner .carousel-item > div {
                  display: none;
                }
                .front.engagement #Carousel-savoirplus .carousel-inner .carousel-item > div:first-child,.front.engagement #Carousel-rs .carousel-inner .carousel-item > div:first-child {
                  display: block;
                }
                .row > *:not(.col-4,.col-8){
                    width:100%;
                    flex-shrink:0
                }
                .front.engagement #Carousel-savoirplus .carousel-inner .carousel-item .col-md-4{
                    width:100%
                }
                .text-center.text-md-start{
                    text-align:center!important
                }
                .front.engagement .bloc-hero:before{
                    max-height:550px;
                    height:100%;
                    background-position-y:30%;
                    background-position-x:center;
                    background-size:cover;
                }
                .front.engagement #Carousel-savoirplus .carousel-control-next{
                    right:35%
                }
                .front.engagement #Carousel-savoirplus .carousel-control-prev{
                    left:35%
                }
                .front.engagement .bloc-videoslider .col-md-5{
                    padding-top:0;
                    padding-left:12px
                }
                .front.engagement #Carousel-savoirplus{
                    min-height:auto;
                    max-height:unset;
                    padding-left:0;
                    padding-right:0
                }
                #Carousel-savoirplus .carousel-inner .carousel-item > div{
                    display:none
                }
                .front.engagement #Carousel-savoirplus .carousel-inner{
                    transform:none
                }
                .front.engagement #Carousel-savoirplus .carousel-inner .carousel-item .col-md-4{
                    padding:0 12px;
                }
                .front.engagement #Carousel-savoirplus .carousel-inner .carousel-item.active .col-md-4 .pc-visu{
                    transform:unset;
                    transition:unset;
                }
                .front.engagement .bloc-presse .w-md-75{
                    width:100%
                }
                .front.engagement .bloc-presse .bb-1:after {
                    display: block;
                }
            }
            @container (max-width: 376px) {
                .front.engagement h1 {
                  padding-top:0;
                }
            }
            @container (max-width: 400px), (min-device-width: 320px) and (max-device-width: 480px) {
                .front.engagement .email-correction form .form-columns-2 .hs-form-field, .front.engagement .email-correction form .form-columns-3 .hs-form-field, .front.engagement .email-validation form .form-columns-2 .hs-form-field, .front.engagement .email-validation form .form-columns-3 .hs-form-field {
                  float: none;
                  width: 100%;
                }
                .front.engagement .email-correction form .form-columns-2 .hs-form-field .hs-input, .front.engagement .email-correction form .form-columns-3 .hs-form-field .hs-input, .front.engagement .email-validation form .form-columns-2 .hs-form-field .hs-input, .front.engagement .email-validation form .form-columns-3 .hs-form-field .hs-input {
                  width: 90%;
                }
                .front.engagement .email-correction form .form-columns-2 .hs-form-field input[type=checkbox], .front.engagement .email-correction form .form-columns-2 .hs-form-field input[type=radio], .front.engagement .email-correction form .form-columns-3 .hs-form-field input[type=checkbox], .front.engagement .email-correction form .form-columns-3 .hs-form-field input[type=radio], .front.engagement .email-validation form .form-columns-2 .hs-form-field input[type=checkbox], .front.engagement .email-validation form .form-columns-2 .hs-form-field input[type=radio], .front.engagement .email-validation form .form-columns-3 .hs-form-field input[type=checkbox], .front.engagement .email-validation form .form-columns-3 .hs-form-field input[type=radio] {
                  width: 24px;
                }
                .front.engagement .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 > .hs-phone > .input > .hs-fieldtype-intl-phone.hs-input > input.hs-input {
                    width: 68% !important;
                }
                .front.engagement .hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051 > .hs-phone > .input > .hs-fieldtype-intl-phone.hs-input > select.hs-input {
                    width: 30% !important;
                }
                .front.engagement .hs-custom-style .hs-input:not([type=checkbox]):not([type=radio]), .front.engagement .hs-custom-style fieldset {
                    margin-right: 0 !important;
                    width: 100% !important;
                }
                .front.engagement form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-2 .hs-form-field, .front.engagement form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-3 .hs-form-field {
                    float: none;
                    width: 100%;
                }
                .front.engagement form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-2 .hs-form-field .hs-input, .front.engagement form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-3 .hs-form-field .hs-input {
                    width: 95%;
                }
                .front.engagement form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-2 .hs-form-field input[type=checkbox], .front.engagement form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-2 .hs-form-field input[type=radio], .front.engagement form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-3 .hs-form-field input[type=checkbox], .front.engagement form.hs-form-ea7d0ab2-2840-4377-a3f2-f3ae16ebd928_a268bdde-2e47-42c2-97d8-8e51c2d65051:not(.hs-video-form) .form-columns-3 .hs-form-field input[type=radio] {
                    width: auto;
                }
            }
            `:null}</style>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            {mode ? 
                            <label htmlFor='photo-upload-0' className="label-edit" onClick={() => rest.focusAccordion(22,`label[for="photo-upload-0"]`)}>
                                <LazyLoadImage src={logo} alt="" width="117"/>
                            </label>
                            :
                            <a href="https://fr.captaintortue.com/" target="_blank">
                                <img alt="" src={logo} width="117" />
                            </a>
                            }
                        </div>
                    </div>
                </div>
            </header>
            <section className="bloc-hero" id="top">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            {mode ?
                            <h1>
                                <AutoHeightTextarea id={banerTitleStyle.id} change={rest.changeTitle} value={banerTitleStyle.text} onFocus={() => rest.focusAccordion(22,`#${CSS.escape('0')}`)} view={props.windowSize}/>
                                <span>{banerSubtitleStyle.text} <AutoHeightTextarea id={banerSubtitleStyle.id} change={rest.changeTitle} absolute="true" value={banerSubtitleStyle.text} onFocus={() => rest.focusAccordion(22,`#${CSS.escape('1')}`)} view={props.windowSize}/></span>
                            </h1>
                            :
                            <h1>{banerTitleStyle.text}<br/>
                                <span dangerouslySetInnerHTML={{ __html: banerSubtitleStyle.text.replace(/\n/g, '<br/>')}}></span>
                            </h1>
                            }  
                        </div>
                        <div className="col-md-5 formulaire" id="formulaire">
                            <div>
                                <h2>
                                    {mode ? <AutoWidthInput id={formStyle.id} className="input-edit" value={formStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(22,`#${CSS.escape('2')}`)}/> : formStyle.text}
                                    <span>{formSubtitleStyle.text}{mode&&(<input type="text"className="input-edit absolute-edit" id={formSubtitleStyle.id} value={formSubtitleStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(22,`#${CSS.escape('3')}`)}/>)}</span>
                                </h2>
                                <p>{mode ? <AutoHeightTextarea id={formParaStyle.id} change={rest.changeTitle} value={formParaStyle.text} onFocus={() => rest.focusAccordion(22,`#${CSS.escape('4')}`)}/> : formParaStyle.text}</p>
                                <HubspotForm
                                    portalId="26694758"
                                    formId="ea7d0ab2-2840-4377-a3f2-f3ae16ebd928"
                                />
                                {mode ? 
                                <>
                                    <p className="hs-richtext">
                                        {formMentionStyle.text}
                                        <AutoHeightTextarea id={formMentionStyle.id} change={rest.changeTitle} absolute="true" value={formMentionStyle.text} onFocus={() => rest.focusAccordion(22,`#${CSS.escape('5')}`)}/>
                                        <input type="text" id={formMentionLinkStyle.id} className="input-edit" value={formMentionLinkStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(22,`#${CSS.escape('6')}`)}/>
                                    </p>
                                </>
                                :
                                <p className="hs-richtext">{formMentionStyle.text} <a href={formMentionLinkStyle.redirection} target="_blank">{formMentionLinkStyle.text}</a></p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bloc-video" id={mode ? "bloc-video" : null}>
                <div className="container">
                    {mode ?
                    <h2>
                        {props.windowSize == "desktop-view" || props.windowSize == "tablet-view" ?
                        <input type="text" id={conseillereTitleStyle.id} className="input-edit" value={conseillereTitleStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(23,`#${CSS.escape('7')}`)}/>
                        :
                        <AutoHeightTextarea id={conseillereTitleStyle.id} change={rest.changeTitle} value={conseillereTitleStyle.text} onFocus={() => rest.focusAccordion(23,`#${CSS.escape('7')}`)}/>
                        }
                        <br />
                        <div className="txtweight-300">
                            {props.windowSize == "desktop-view" || props.windowSize == "tablet-view" ?
                            <AutoWidthInput id={conseillereSubtitleStyle.id} value={conseillereSubtitleStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(23,`#${CSS.escape('8')}`)}/>
                            :
                            <AutoHeightTextarea id={conseillereSubtitleStyle.id} value={conseillereSubtitleStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(23,`#${CSS.escape('8')}`)}/>
                            }
                            <div>
                                <AutoWidthInput id={conseillereSubtitlePinkStyle.id} value={conseillereSubtitlePinkStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(23,`#${CSS.escape('9')}`)}/>
                            </div>
                        </div>
                    </h2>
                    :
                    <h2>{conseillereTitleStyle.text} <br />
                        <div className="txtweight-300">{conseillereSubtitleStyle.text}
                            <div>{conseillereSubtitlePinkStyle.text}</div>
                        </div>
                    </h2>}
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="video-container">
                                {mode ?
                                <IframePlaceholder
                                    click={() => rest.focusAccordion(23,`#${CSS.escape('10')}`)}
                                />:
                                <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" frameBorder="0" height="315" src={conseillereIframe.text} title="YouTube video player" width="560"></iframe>
                                }
                            </div>
                        </div>
                        <div className="col-md-5">
                            <h3>{mode ? <AutoHeightTextarea id={conseillereSecondColumnTitle.id} change={rest.changeTitle} value={conseillereSecondColumnTitle.text} onFocus={() => rest.focusAccordion(23,`#${CSS.escape('11')}`)}/> : conseillereSecondColumnTitle.text}</h3>
                            {banerSlides.filter((objet) => objet.type === 'fun').map((objet) => 
                            mode ?
                            <p key={objet.id} className="label-edit" onClick={() => {rest.togglePopup("edit-baner-slide",objet.id,objet.type);rest.focusAccordion(23,`#fun-${objet.id}`)}}><strong>{objet.text}</strong> {objet.redirection}</p>
                            :
                            <p key={objet.id}><strong>{objet.text}</strong> {objet.redirection}</p>
                            )}
                            <a className="btn mobile-hidden" href={mode ? null : generalBtnStyle.redirection ? generalBtnStyle.redirection : "#"}>{mode ? <AutoWidthInput id={generalBtnStyle.id} value={generalBtnStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(30,`#${CSS.escape('20')}`)}/> : generalBtnStyle.text}</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bloc-logos" id={mode ? "bloc-logos" : null}>
                <div className="container">
                    <h2>
                        {mode ?
                        props.windowSize == "desktop-view" || props.windowSize == "tablet-view" ?
                            <AutoWidthInput id={reseauxStyle.id} value={reseauxStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(24,`#${CSS.escape('13')}`)}/>
                        :
                            <AutoHeightTextarea id={reseauxStyle.id} value={reseauxStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(24,`#${CSS.escape('13')}`)}/>
                        :
                        reseauxStyle.text
                        }
                        <img src="https://rejoignez.fr.captaintortue.com/hubfs/logo-instagram.png" style={{marginLeft:5,marginRight:5}} width="22" />
                        <img src="https://rejoignez.fr.captaintortue.com/hubfs/logo-facebook.png" style={{marginLeft:5,marginRight:5}} width="22" />
                        <img src="https://rejoignez.fr.captaintortue.com/hubfs/logo-youtube.png" width="22" />
                        <img src="https://rejoignez.fr.captaintortue.com/hubfs/logo-pinterest.png" style={{marginLeft:5,marginRight:5}} width="20" />
                    </h2>
                    <div className="row">
                        {cards.filter((objet) => objet.type === 'reseaux').map((objet) => 
                        mode ?
                        <div className="col-md-3 my-2" key={objet.id} onClick={() => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(24,`#card-${objet.id}`)}}>
                            <img alt="" src={objet.imagePreviewUrl} className="label-edit" width="100%" />
                        </div>
                        :
                        <div className="col-md-3 my-2" key={objet.id}>
                            <img alt="" src={objet.imagePreviewUrl} width="100%" />
                        </div>
                        )}
                    </div>
                </div>
            </section>
            <section className="bloc-videoslider" id={mode ? "bloc-videoSlider" : null}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <h2>
                                {mode ? 
                                props.windowSize == "desktop-view" || props.windowSize == "tablet-view" ?
                                <AutoWidthInput id={decouvrirTitleStyle.id} value={decouvrirTitleStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(25,`#${CSS.escape('14')}`)}/> 
                                :
                                <AutoHeightTextarea id={decouvrirTitleStyle.id} value={decouvrirTitleStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(25,`#${CSS.escape('14')}`)}/> 
                                :
                                decouvrirTitleStyle.text} 
                                <br />
                                <span>{mode ? 
                                props.windowSize == "desktop-view" || props.windowSize == "tablet-view" ?
                                <AutoWidthInput id={decouvrirSubtitleStyle.id} value={decouvrirSubtitleStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(25,`#${CSS.escape('15')}`)}/> 
                                :
                                <AutoHeightTextarea id={decouvrirSubtitleStyle.id} value={decouvrirSubtitleStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(25,`#${CSS.escape('15')}`)}/> 
                                : decouvrirSubtitleStyle.text}
                                </span>
                            </h2>
                            <div className="video-slider">
                                <div className="carousel slide" data-bs-ride="carousel" id="carouselVideoslider">
                                    <div className="carousel-indicators">
                                        {banerSlides.filter((objet) => objet.type === 'iframe-slide').map((objet,index) => (
                                        <button key={index} aria-current={index === 0 ? true : false} aria-label={`Slide ${index + 1}`} className={index === 0 ? 'active' : ''} data-bs-slide-to={index} data-bs-target="#carouselVideoslider" type="button">{objet.text}</button>
                                        ))}
                                    </div>
                                    <div className="carousel-inner">
                                        {banerSlides.filter((objet) => objet.type === 'iframe-slide').map((objet,index) => (
                                        <div className={`carousel-item${index === 0 ? ' active' : ''}`} key={objet.id}>
                                            <div className="video-container">
                                                {mode ?
                                                <IframePlaceholder
                                                    click={() => {rest.togglePopup("edit-baner-slide",objet.id,objet.type);rest.focusAccordion(25,`#bandeau-${objet.id}`)}}
                                                />
                                                :
                                                <iframe allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" frameBorder="0" height="auto" src={objet.redirection} title="YouTube video player" width="100%"></iframe>
                                                }
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <h4>{mode ? <AutoHeightTextarea id={decouvrirSecondColumnTitle.id} change={rest.changeTitle} value={decouvrirSecondColumnTitle.text} onFocus={() => rest.focusAccordion(25,`#${CSS.escape('16')}`)}/>:decouvrirSecondColumnTitle.text}</h4>
                            {decouvrirSecondColumnPara.text.split(/^(->.*)$/gm).filter(Boolean).map((item, index) => {
                                if(!item.startsWith("->")&&item!==" ")
                                    return <p key={index}>{item}</p>;
                            })}
                            <ul className={mode ? "label-edit" : ""} onClick={() => rest.focusAccordion(25,`#${CSS.escape('17')}`)}>
                            {decouvrirSecondColumnPara.text.split(/^(->.*)$/gm).filter(Boolean).map((item, index) => {
                                if(item.startsWith("->"))
                                    return <li key={index}>{item.slice(2)}</li>;
                            })}
                            </ul>
                            <a className="btn mobile-hidden" href={mode ? null : generalBtnStyle.redirection ? generalBtnStyle.redirection : "#"}>{mode ? <AutoWidthInput id={generalBtnStyle.id} value={generalBtnStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(30,`#${CSS.escape('20')}`)}/> : generalBtnStyle.text}</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bloc-carrousel" id={mode ? "bloc-carrousel" : null}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="carousel slide" data-bs-ride="carousel" id="Carousel-savoirplus">
                            <div className="carousel-inner" role="listbox">
                                {mode||rest.slider ? 
                                carouselCards.map((objet,index) => (
                                <div key={index} className={`carousel-item${index === 0 ? ' active' : ''}`}>
                                    {[0, 1, 2].map((i) => {
                                      const imageIndex = (index + i) % carouselCards.length;
                                      return (
                                        <div key={i} className="col-md-4">
                                          <figure className="soon">
                                            <div className={mode ? "pc-visu label-edit" : "pc-visu"} onClick={mode ? () => {rest.togglePopup("edit-card",carouselCards[imageIndex].id,objet.type);rest.focusAccordion(26,`#card-${carouselCards[imageIndex].id}`)} : ()=>{}}>
                                              <img alt="" src={carouselCards[imageIndex].imagePreviewUrl} width="100%" />
                                            </div>
                                          </figure>
                                        </div>
                                      );
                                    })}
                                </div>
                                ))
                                :
                                carouselCards.filter((objet) => objet.type === 'carousel').map((objet,index) => (
                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={objet.id}>
                                        <div className="col-md-4">
                                            <figure className="soon">
                                                <div className="pc-visu">
                                                    <img alt="" src={objet.imagePreviewUrl} width="100%" />
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                            <a className="carousel-control-prev bg-transparent w-aut" data-bs-target="#Carousel-savoirplus" data-bs-slide="prev" href="#Carousel-savoirplus" role="button">
                                <span aria-hidden="true" className="carousel-control-prev-icon"></span>
                            </a>
                            <a className="carousel-control-next bg-transparent w-aut" data-bs-target="#Carousel-savoirplus" data-bs-slide="next" href="#Carousel-savoirplus" role="button">
                                <span aria-hidden="true" className="carousel-control-next-icon"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bloc-illus" id={mode ? "bloc-illus" : null}>
                <div className="container">
                    <h2>
                        {mode ? 
                        props.windowSize == "desktop-view" || props.windowSize == "tablet-view" ?
                        <AutoWidthInput id={imagineTitleStyle.id} value={imagineTitleStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(27,`#${CSS.escape('18')}`)}/>
                        :
                        <AutoHeightTextarea id={imagineTitleStyle.id} value={imagineTitleStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(27,`#${CSS.escape('18')}`)}/>
                        : imagineTitleStyle.text}
                    </h2>
                    <h3>
                        {mode
                        ?
                        <span>{imagineSubtitleStyle.text}<AutoHeightTextarea id={imagineSubtitleStyle.id} absolute="true" change={rest.changeTitle} value={imagineSubtitleStyle.text} onFocus={() => rest.focusAccordion(27,`#${CSS.escape('19')}`)}/></span>
                        :
                        <span dangerouslySetInnerHTML={{ __html: imagineSubtitleStyle.text.replace(/\n/g, '<br/>')}}></span>
                        }
                    </h3>
                    <div className="row">
                        {cards.filter((objet) => objet.type === 'card-recrutement').map((objet) => (
                        <div className="col-md-6" key={objet.id}>
                            <div className="row align-items-center">
                                <div className="col-4">
                                    <img alt="" src={objet.imagePreviewUrl} width="100%" className={mode ?"label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(27,`#card-${objet.id}`)} : ()=> {} }/>
                                </div>
                                <div className="col-8">
                                    <h4 className={mode ?"label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(27,`#card-${objet.id}`)} : ()=> {} }>{objet.link}</h4>
                                    <p className={mode ?"label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(27,`#card-${objet.id}`)} : ()=> {} }>{objet.text}</p>
                                </div>
                            </div>
                        </div>
                        ))}
                        <div className="col-12 text-center mobile-hidden">
                            <a className="btn mobile-hidden" href={mode ? null : generalBtnStyle.redirection ? generalBtnStyle.redirection : "#"}>{mode ? <AutoWidthInput id={generalBtnStyle.id} value={generalBtnStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(30,`#${CSS.escape('20')}`)}/> : generalBtnStyle.text}</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bloc-2column" id={mode ? "bloc-2column" : null}>
                <div className="container">
                    {mode ?
                    <h2>
                        <div className="txtweight-300">
                            {props.windowSize == "desktop-view" || props.windowSize == "tablet-view" ? 
                            <AutoWidthInput id={savoiPlusTitleStyle.id} value={savoiPlusTitleStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(28,`#${CSS.escape('21')}`)}/>
                            :
                            <AutoHeightTextarea id={savoiPlusTitleStyle.id} value={savoiPlusTitleStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(28,`#${CSS.escape('21')}`)}/>
                            }
                        </div>
                        <div><AutoWidthInput id={savoiPlusSubtitleStyle.id} value={savoiPlusSubtitleStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(28,`#${CSS.escape('22')}`)}/> <span><AutoWidthInput id={savoiPlusSubtitlePinkStyle.id} value={savoiPlusSubtitlePinkStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(28,`#${CSS.escape('23')}`)}/></span></div>
                    </h2>
                    :
                    <h2>
                        <div className="txtweight-300">{savoiPlusTitleStyle.text}</div>
                        <div>{savoiPlusSubtitleStyle.text} <span>{savoiPlusSubtitlePinkStyle.text}</span></div>
                    </h2>
                    }
                    {cards.filter((objet) => objet.type === 'savoir-plus').map((objet,index) => (
                    index % 2 === 0 ? 
                    <div className="wrap" key={"savoir-plus-"+objet.id}>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img alt="" src={objet.imagePreviewUrl} width="100%" className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(28,`#card-${objet.id}`)} : ()=> {} }/>
                            </div>
                            <div className="col-md-6 txt">
                                <div className="item"><h2 className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(28,`#card-${objet.id}`)} : ()=> {} }>{objet.link}</h2>
                                    {objet.text.split(/^(->.*)$/gm).filter(Boolean).map((item, index) => {
                                        if(!item.startsWith("->")&&item!==" ")
                                            return <p key={index}>{item}</p>;
                                    })}
                                    <ul className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(28,`#card-${objet.id}`)} : ()=> {} }>
                                    {objet.text.split(/^(->.*)$/gm).filter(Boolean).map((item, index) => {
                                        if(item.startsWith("->"))
                                            return <li key={index}>{item.slice(2)}</li>;
                                    })}
                                    </ul>
                                    <a className="btn mobile-hidden" href={mode ? null : generalBtnStyle.redirection ? generalBtnStyle.redirection : "#"}>{mode ? <AutoWidthInput id={generalBtnStyle.id} value={generalBtnStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(30,`#${CSS.escape('20')}`)}/> : generalBtnStyle.text}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="wrap" key={"savoir-plus-"+objet.id}>
                        <div className="row align-items-center">
                            <div className="col-md-6 txt">
                                <div className="item">
                                    <h2 className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(28,`#card-${objet.id}`)} : ()=> {} }>{objet.link}</h2>
                                    <ul className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(28,`#card-${objet.id}`)} : ()=> {} }>
                                    {objet.text.split(/^(->.*)$/gm).filter(Boolean).map((item, index) => {
                                        if(item.startsWith("->"))
                                            return <li key={index}>{item.slice(2)}</li>;
                                    })}
                                    </ul>
                                    <a className="btn mobile-hidden" href={mode ? null : generalBtnStyle.redirection ? generalBtnStyle.redirection : "#"}>{mode ? <AutoWidthInput id={generalBtnStyle.id} value={generalBtnStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(30,`#${CSS.escape('20')}`)}/> : generalBtnStyle.text}</a>
                                </div>
                            </div>
                            <div className="col-md-6 order-first order-md-last">
                                <img alt="" src={objet.imagePreviewUrl} width="100%" className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(28,`#card-${objet.id}`)} : ()=> {} }/>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </section>
            <section className="bloc-presse" id={mode ? "bloc-presse" : null}>
                <div className="container">
                    <h2>{mode ? <AutoWidthInput id={presseStyle.id} value={presseStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(29,`#${CSS.escape('26')}`)}/> :presseStyle.text}</h2>
                    <div className="row">
                        {cards.filter((objet) => objet.type === 'card-slide').length > 0&&(
                        <div className="col-md-12 py-4 py-md-3 bb-1 w-md-75 mx-auto">
                            <div className="row">
                                <div className="col-4 text-center">
                                    <img alt="" src={cards.filter((objet) => objet.type === 'card-slide')[0].imagePreviewUrl} style={{maxWidth:150,height:"auto",marginLeft:"auto",marginRight:"auto"}} width="100" className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",cards.filter((objet) => objet.type === 'card-slide')[0].id,cards.filter((objet) => objet.type === 'card-slide')[0].type);rest.focusAccordion(29,`#card-${cards.filter((objet) => objet.type === 'card-slide')[0].id}`)} : ()=> {} }/>
                                </div>
                                <div className="col-8">
                                    <p className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",cards.filter((objet) => objet.type === 'card-slide')[0].id,cards.filter((objet) => objet.type === 'card-slide')[0].type);rest.focusAccordion(29,`#card-${cards.filter((objet) => objet.type === 'card-slide')[0].id}`)} : ()=> {} }>{cards.filter((objet) => objet.type === 'card-slide')[0].text}</p>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="row">
                        {cards.filter((objet) => objet.type === 'card-slide').slice(1).map((objet) => (
                        <div className="col-md-4 py-4 py-md-3 bb-1" key={"card-slide-"+objet.id}>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <img alt="" src={objet.imagePreviewUrl} style={{maxWidth:150,height:"auto",marginLeft:"auto",marginRight:"auto"}} width="100" className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(29,`#card-${objet.id}`)} : ()=> {} }/>
                                </div>
                                <div className="col-12 pt-3 text-center">
                                    <p className={mode ? "label-edit" : null} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(29,`#card-${objet.id}`)} : ()=> {} }>{objet.text}</p>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
            <footer id={mode ? "footer" : null}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-2 text-center my-3">
                            {mode ? 
                            <label htmlFor='photo-upload-2' className="label-edit" onClick={() => rest.focusAccordion(31,`label[for="photo-upload-1"]`)}><LazyLoadImage src={logoBlanc} alt="" width="97"/></label>
                            :
                            <img alt="" src={logoBlanc} width="97" />
                            }
                        </div>
                        <div className="col-md-10">
                            <p className="title">
                            {mode ? 
                            props.windowSize == "desktop-view" || props.windowSize == "tablet-view" ? 
                            <AutoWidthInput id={footerStyle.id} value={footerStyle.text} change={rest.changeTitle} onFocus={() => rest.focusAccordion(31,`#${CSS.escape('27')}`)}/> 
                            :
                            <AutoHeightTextarea id={footerStyle.id} value={footerStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(31,`#${CSS.escape('27')}`)}/> 
                            : footerStyle.text}</p>
                            { mode ? 
                            <p className="text">
                                <AutoHeightTextarea id={footerTextStyle.id} value={footerTextStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(31,`#${CSS.escape('28')}`)}/>
                            </p>
                            :
                            <p className='text' dangerouslySetInnerHTML={{ __html: footerTextStyle.text.replace(/\n/g, '<br/>')}}></p>
                            }
                        </div>
                    </div>
                </div>
            </footer>
            <div className="btn-bottom desktop-hidden">
                <a className="btn" href={mode ? null : generalBtnStyle.redirection ? generalBtnStyle.redirection : "#"}>{mode ? <input type="text" className="input-edit" id={generalBtnStyle.id} value={generalBtnStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(30,`#${CSS.escape('20')}`)}/> : generalBtnStyle.text}</a>
            </div>
        </div>
    );
}
export default Recrutement;