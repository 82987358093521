import React from "react";
import Slider from "react-slick";
import '../assets/styles/Home.scss';
import CountDown from "./CountDown";

function SlickNextArrow(props) {
    const { className, onClick } = props;
    return (
        <button type='button' className={className} onClick={onClick}>{">"}</button>
    );
  }
  
  function SlickPrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button type='button' className={className} onClick={onClick}>{"<"}</button>
    );
}
function hexToRgb(hex) {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `${r}, ${g}, ${b}`;
}
const Poppin = (props) => {
    const {menuStyle,menuTextStyle,banerSlides, fondBaner, titleBaner, subtitleBaner, banerBtnStyle, cardTextStyle, pinkStyle, pinkTitleStyle, pinkSubtitleStyle, mode, windowSize,titleBloc1Style,texts,images,page,extract, ...rest} = props;
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow:true,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />
    };
    const renderElements = () => {
        return texts.map((item, index) => {
            const ElementType = item.textType;
            if (item.type == "poppins-content")
                if(item.elementType==="texte")
                    if(item.textType!=="p")
                        return (
                            <ElementType 
                                key={index}
                                className={item.class}
                            >
                            {item.text}
                            </ElementType>
                        );
                    else   
                        return(
                            <p 
                                key={index}
                                className={item.class}
                                dangerouslySetInnerHTML={{ __html: item.text }}
                            />
                        )
                else if (item.elementType==="bouton")
                    return (
                        item.redirection&&page!=="popup" ?
                        <a href={item.redirection} className={"btn "+item.class} key={index}>{item.text}</a>
                        :
                        <button className={"btn "+item.class} id="b0abc783-c1ce-4439-b077-d0440dc67ba5" key={index} type="button">{item.text}</button>
                    );
                else if (item.elementType==="image")
                    return (
                        <img src={item.redirection ? item.redirection : images[item.imgId].imagePreviewUrl} className={item.class ? item.class : null} key={index} alt=""/>
                );
                else if (item.elementType==="compteur")
                    return (
                        <CountDown 
                            targetDate={item.date}
                            key={index}
                        />
                    );
                else if (item.elementType==="champ")
                    return (
                        <div key={index}>
                            <small id="error-popup" className="text-danger d-block"></small>
                            <input 
                                type='email'
                                id="email-popup"
                                value=''
                                name="NL_input_0"
                                placeholder={item.text}
                                className={item.class}
                            />
                        </div>
                    );
            else
                return;
        });
      };
    return (
        <div className='front'>
            {!extract&&(
            <style>{`
            .front{background-color:${subtitleBaner.background}}.front .content{padding-left:${subtitleBaner.ml+'px '};padding-right:${subtitleBaner.mr+'px'}}.front .section .jumbotron{background-image:url(${fondBaner});padding-top:${menuTextStyle.mt+'px'};padding-bottom:${menuTextStyle.mb+'px'}}
            .front .section .jumbotron .content{
                text-align:${menuTextStyle.align}
            }
            ${`.front .section .jumbotron .ttr{
                font-size:${titleBaner.size+'px'};
                font-weight:${titleBaner.weight};
                font-family:${titleBaner.font+',sans-serif'};
                color:${titleBaner.color}
            }
            .front .section .jumbotron .ttr span{
                font-size:${subtitleBaner.size+'px'};
                font-weight:${subtitleBaner.weight};
                font-family:${subtitleBaner.font+',sans-serif'};
                color:${subtitleBaner.color}
            }
            .front .section .jumbotron .btn{
                background-color:${banerBtnStyle.background};
                font-size:${banerBtnStyle.size+'px'};
                color:${banerBtnStyle.color};
                font-weight:${banerBtnStyle.weight};
                font-family:${banerBtnStyle.font+',sans-serif'};
                padding:${banerBtnStyle.mt+'px '+banerBtnStyle.mr+'px '+banerBtnStyle.mb+'px '+banerBtnStyle.ml+'px'}
            }`}
            .front .news-slider{
                background: ${menuStyle.background};
                padding:${menuStyle.mt+'px '+menuStyle.mr+'px '+menuStyle.mb+'px '+menuStyle.ml+'px'}
            }
            .front .news-slider .news-text{
                text-align:${menuStyle.align};
                color:${menuStyle.color};
                font-size:${menuStyle.size+'px'};
                font-weight:${menuStyle.weight};
                font-family:${menuStyle.font+",sans-serif"}
            }
            .front .news-slider .news-text a,.front .news-slider .slick-arrow{
                color:${menuStyle.color}
            }
            .front .section .popup-promo{
                background-color: rgba(${hexToRgb(texts[0].background)},${texts[0].size});
                box-shadow: 2px 0px 5px rgba(0,0,0,.2);
                width:100%;
                max-width: ${texts[1].size}${texts[1].unity ? texts[1].unity : 'px'};
                padding:${texts[0].mt+"px "+texts[0].mr+"px "+texts[0].mb+"px "+texts[0].ml+"px"};
                border-radius:${texts[1].btl+"px "+texts[1].btr+"px "+texts[1].bbr+"px "+texts[1].bbl+"px"};
                text-align:${texts[0].align};
                overflow:hidden;
            }
            .front .section .popup-promo b{
                font-weight:bold
            }
            ${page=="poppins" &&(
                texts.filter((element) => element.type === 'poppins-content').map((element) => {
                    if(element.elementType==="texte")
                        return `.front .section .popup-promo ${element.class ? "."+element.class : element.textType}{
                            font-size:${element.size+'px'};
                            color:${element.color};
                            font-weight:${element.weight};
                            font-family:${element.font+",sans-serif"};
                            margin:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'}
                        }`
                    if(element.elementType==="bouton")
                        return `.front .section .popup-promo ${element.class ? ".btn."+element.class : ".btn"}{
                            font-size:${element.size+'px'};
                            color:${element.color};
                            font-weight:${element.weight};
                            font-family:${element.font+",sans-serif"};
                            background-color:${element.background};
                            text-transform:none;
                            padding:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                            border-radius:${element.btl+"px "+element.btr+"px "+element.bbr+"px "+element.bbl+"px"};
                        }`
                    if(element.elementType==="image")
                        return `.front .section .popup-promo ${element.class ? '.'+element.class : "img"}{
                            width:100%;
                            max-width: ${element.size+'px'};
                            margin:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                            height:auto
                        }`
                    if(element.elementType==="compteur")
                        return `.front .section .popup-promo .countdown{
                                display:flex;
                                justify-content:center;
                                gap:16px;
                                margin:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                            }
                            .front .section .popup-promo .countdown .countdown-number{
                                font-size:${element.size+'px'};
                                color:${element.color};
                                font-weight:${element.weight};
                                font-family:${element.font+",sans-serif"};
                                display:block;
                                text-align:center;
                                line-height:36px
                            }
                            .front .section .popup-promo .countdown .countdown-label{
                                font-size:9px;
                                color:${element.color};
                                text-transform:uppercase;
                                display:block;
                                text-align:center;
                            }`
                }).join('')
            )} 
            @media (max-width: 992px){
                .front .content{padding-left:${cardTextStyle.ml+'px'};padding-right:${cardTextStyle.mr+'px'}}.front .news-slider{padding:${pinkSubtitleStyle.mt+'px '+pinkSubtitleStyle.mr+'px '+pinkSubtitleStyle.mb+'px '+pinkSubtitleStyle.ml+'px'}}.front .news-slider .news-text{font-size:${menuStyle.sizeMobile+'px'}}
                .front .section .popup-promo{
                    max-width: ${texts[1].sizeMobile+'px'};
                }
                ${page=="poppins" &&(
                    texts.filter((element) => element.type === 'poppins-content').map((element) => {
                        if(element.elementType==="texte")
                            return `.front .section .popup-promo ${element.class ? "."+element.class : element.textType}{
                                font-size:${element.sizeMobile+'px'};
                            }`
                        if(element.elementType==="bouton")
                            return `.front .section .popup-promo ${element.class ? ".btn."+element.class : ".btn"}{
                                font-size:${element.sizeMobile+'px'};
                            }`
                        if(element.elementType==="compteur")
                            return `
                                .front .section .popup-promo .countdown .countdown-number{
                                    font-size:${element.sizeMobile+'px'};
                                    line-height:1
                                }`
                    }).join('')
                )} 
            } 
            @media (max-width: 767px){.front .section .popup-promo{max-width: calc(100% - 30px);margin:0 auto}}
            `}{mode ? 
            `.front{container-type:inline-size;}
            .front .section .popup-promo{
                display:flex;
                flex-direction:column;
                align-items:${texts[0].align == "left" ? "flex-start" : texts[0].align == "right" ? 'flex-end' : "center"}
            }
            @container (max-width: 992px){
                .front .content{padding-left:${cardTextStyle.ml+'px'};padding-right:${cardTextStyle.mr+'px'}}.front .news-slider{padding:${pinkSubtitleStyle.mt+'px '+pinkSubtitleStyle.mr+'px '+pinkSubtitleStyle.mb+'px '+pinkSubtitleStyle.ml+'px'}}.front .news-slider .news-text{font-size:${menuStyle.sizeMobile+'px'}}
                .front .section .popup-promo{
                    max-width: ${texts[1].sizeMobile+'px'};
                }  
                ${page=="poppins" &&(
                    texts.filter((element) => element.type === 'poppins-content').map((element) => {
                        if(element.elementType==="texte")
                            return `.front .section .popup-promo ${element.class ? "."+element.class : element.textType}{
                                font-size:${element.sizeMobile+'px'};
                            }`
                        if(element.elementType==="bouton")
                            return `.front .section .popup-promo ${element.class ? ".btn."+element.class : ".btn"}{
                                font-size:${element.sizeMobile+'px'};
                            }`
                        if(element.elementType==="compteur")
                            return `
                                .front .section .popup-promo .countdown .countdown-number{
                                    font-size:${element.sizeMobile+'px'};
                                    line-height:1
                                }`
                    }).join('')
                )} 
            }
            @container (max-width: 767px){.row > *{flex-shrink:0;width:100%;max-width:100%}.col-6{flex: 0 0 auto;width: 50%;}
            .front .section .popup-promo{
                max-width: calc(100% - 30px);
                margin:0 auto
            } 
            }`
            :null}</style>)}
            {!extract&&(
            mode || rest.slider ? 
            <div id="bandeau-noir">
                <Slider className="news-slider" {...settings}>
                    {banerSlides.map(objet=> objet.type == "baner-slide" ? (
                        <div className="slide" key={objet.id}>
                            <p className="news-text">{objet.text} <a href={objet.redirection}>{objet.link}</a></p>
                        </div>
                    ):null)}
                </Slider>
            </div>
            :
            <div className="news-slider">
                {banerSlides.map(objet=> objet.type == "baner-slide" ? (
                    <div className="slide" key={objet.id}>
                        <p className="news-text">{objet.text} <a href={objet.redirection}>{objet.link}</a></p>
                    </div>
                ):null)}
            </div>
            )}
            {!extract&&(
            <section className="section">
                <div className="jumbotron px-0">
                    <div className="content">
                        {page=="poppins" ? 
                        <div className="popup-promo">
                            {renderElements()}
                        </div>
                        :
                        <>
                        <h1 className="ttr">{titleBaner.text}<br/>
                            <span>{subtitleBaner.text}</span>
                        </h1>
                        <button className="btn">{banerBtnStyle.text}</button>
                        </>
                        }
                    </div>
                </div>
            </section>
            )}
            {page=="popup"&&(
                <div className={`popup-wrapper ${mode&&!extract?'is-open':''}`} data-close={texts[0].sizeMobile*1000}>
                    <style>
                    {`.popup-wrapper{position:fixed;top:0;left:0;width:100%;height:auto;min-height:100${mode?'cqh':'vh'};background-color:rgba(${hexToRgb(texts[0].color)},${texts[0].size});z-index:9999;display:none;align-items:center;justify-content:center;padding-top:50px;padding-bottom:50px;}
                    .popup-wrapper.is-open{display:flex}
                    .popup-content{background-color:${texts[0].background};width:${texts[1].size}${texts[1].unity ? texts[1].unity : 'px'};display:flex;align-items:center;position:relative;height:100%;max-width:900px}
                    .popup-content .image-block{width:40%;height:100%;position:absolute}
                    .popup-content .image-block img{width:100%;height:100%;object-fit:cover}
                    .popup-content .text-block{padding:${texts[0].mt+"px "+texts[0].mr+"px "+texts[0].mb+"px "+texts[0].ml+"px"};text-align:center;width:60%;margin-left:auto}
                    .popup-content #closePopup{position:absolute;right:0;top:-50px;border:none;background:transparent;width:32px;height:32px;opacity:.8}
                    .popup-content #closePopup:hover {
                        opacity: 1;
                    }
                    .popup-content #closePopup:before, .popup-content #closePopup:after {
                        position: absolute;
                        left: 15px;
                        content: "";
                        height: 33px;
                        width: 2px;
                        background-color: #fff;
                        top:0
                    }
                    .popup-content #closePopup:before {
                        transform: rotate(45deg);
                    }
                    .popup-content #closePopup:after {
                        transform: rotate(-45deg);
                    }
                    ${
                        texts.filter((element) => element.type === 'poppins-content').map((element) => {
                            if(element.elementType==="texte")
                                return `.popup-content .text-block ${element.class ? "."+element.class : element.textType}{
                                    font-size:${element.size+'px'};
                                    color:${element.color};
                                    font-weight:${element.weight};
                                    font-family:${element.font+",sans-serif"};
                                    margin:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'}
                                }`
                            if(element.elementType==="bouton")
                                return `.popup-content .text-block ${element.class ? ".btn."+element.class : ".btn"}{
                                    font-size:${element.size+'px'};
                                    color:${element.color};
                                    font-weight:${element.weight};
                                    font-family:${element.font+",sans-serif"};
                                    background-color:${element.background};
                                    text-transform:none;
                                    padding:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                                    border-radius:${element.btl+"px "+element.btr+"px "+element.bbr+"px "+element.bbl+"px"};
                                }`
                            if(element.elementType==="image")
                                return `.popup-content .text-block ${element.class ? '.'+element.class : "img"}{
                                    width:100%;
                                    max-width: ${element.size+'px'};
                                    margin:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                                    height:auto
                                }`
                            if(element.elementType==="compteur")
                                return `.popup-content .text-block .countdown{
                                        display:flex;
                                        justify-content:center;
                                        gap:16px;
                                        margin:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                                    }
                                    .popup-content .text-block .countdown .countdown-number{
                                        font-size:${element.size+'px'};
                                        color:${element.color};
                                        font-weight:${element.weight};
                                        font-family:${element.font+",sans-serif"};
                                        display:block;
                                        text-align:center;
                                        line-height:36px
                                    }
                                    .popup-content .text-block .countdown .countdown-label{
                                        font-size:9px;
                                        color:${element.color};
                                        text-transform:uppercase;
                                        display:block;
                                        text-align:center;
                                    }`
                            if(element.elementType==="champ")
                                return `.popup-content .text-block ${element.class ? "input."+element.class : "input"}{
                                    font-size:${element.size+'px'};
                                    color:${element.color};
                                    width:90%;
                                    font-weight:${element.weight};
                                    font-family:${element.font+",sans-serif"};
                                    background-color:${element.background};
                                    border:1px solid #ccc;
                                    margin-bottom:32px;
                                    margin-left:auto;
                                    margin-right:auto;
                                    text-transform:none;
                                    padding:${element.mt+'px '+element.mr+'px '+element.mb+'px '+element.ml+'px'};
                                    border-radius:${element.btl+"px "+element.btr+"px "+element.bbr+"px "+element.bbl+"px"};
                                }`
                        }).join('')
                    } 
                    ${mode?`
                    @container (max-width: 991px){
                        .popup-content{width:90%}
                    }
                    @container (max-width: 767px){
                        .popup-content{flex-direction:column;width:95%}
                        .popup-content .image-block,.popup-content .text-block{width:100%;position:relative}
                        ${
                            texts.filter((element) => element.type === 'poppins-content').map((element) => {
                                if(element.elementType==="texte")
                                    return `.popup-content .text-block ${element.class ? "."+element.class : element.textType}{
                                        font-size:${element.sizeMobile+'px'};
                                    }`
                                if(element.elementType==="bouton")
                                    return `.popup-content .text-block ${element.class ? ".btn."+element.class : ".btn"}{
                                        font-size:${element.sizeMobile+'px'};
                                    }`
                                if(element.elementType==="compteur")
                                    return `
                                        .popup-content .text-block .countdown .countdown-number{
                                            font-size:${element.sizeMobile+'px'};
                                            line-height:1
                                        }`
                            }).join('')
                        } 
                    `:''} 
                    @media (max-width: 991px){
                        .popup-content{width:90%}
                    }
                    @media (max-width: 767px){
                        .popup-content{flex-direction:column}
                        .popup-content .image-block,.popup-content .text-block{width:100%}
                        ${
                            texts.filter((element) => element.type === 'poppins-content').map((element) => {
                                if(element.elementType==="texte")
                                    return `.popup-content .text-block ${element.class ? "."+element.class : element.textType}{
                                        font-size:${element.sizeMobile+'px'};
                                    }`
                                if(element.elementType==="bouton")
                                    return `.popup-content .text-block ${element.class ? ".btn."+element.class : ".btn"}{
                                        font-size:${element.sizeMobile+'px'};
                                    }`
                                if(element.elementType==="compteur")
                                    return `
                                    .popup-content .text-block .countdown .countdown-number{
                                            font-size:${element.sizeMobile+'px'};
                                            line-height:1
                                        }`
                            }).join('')
                        } 
                    } 
                    `}
                    </style>
                    <div className="popup-content">
                        <button id="closePopup"></button>
                        <div className="image-block">
                            <img src={images[0].imagePreviewUrl} className="img-fluid"/>
                        </div>
                        <div className="text-block">
                            <form className="form" method="post" autoComplete="off">
                                <input name="NL_landingPage_context" type="hidden"/>
                                <input name="NL_landingPage_userAction" type="hidden"/>
                                <div style={{display: "none"}}><input id="origineForm" className="stacked" type="text" name="NL_input_1"/></div>
                                {renderElements()}
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
  }
export default Poppin;