import React, { useState } from 'react';
import Checkbox from "./Checkbox";
import {PrimaryInput} from "./Input.style";
function Redirection({id,action,value,text,name}) {
    const [isOpen, setIsOpen] = useState(value?true:false);
    return (
        <div>
            <Checkbox squareStyle={{backgroundColor:"#ef0909"}} text={text ? text : "Rediriger vers un lien ?"} labelStyle={{fontSize:13,color:"#fff",fontWeight:400}} id={"redirection-"+id} action={() => setIsOpen(!isOpen)} checked={isOpen}/>
            <div className={`${isOpen ? 'd-block' : 'd-none'}`}>
                <PrimaryInput value={value?value:""} type="text" id={"redirection-content-"+id} name={name} placeholder="le lien de la redirection" onChange={action}/>
            </div>
        </div>
    );
}
export default Redirection;