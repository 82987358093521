import React from 'react';
import AdminAccordion from "../AdminAccordion";
import ColorPicker from "../ColorPicker";
import ImgUpload from '../ImgUpload';
import InputNumber from "../InputNumber";
import Select from "../Select";
import SimpleWysiwygEditor from "../SimpleWysiwygEditor";
import DateTimePicker from 'react-datetime-picker';
import 'react-calendar/dist/Calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-clock/dist/Clock.css';
import {PrimaryButton} from "../Button.style";
import { PrimaryInput } from '../Input.style';
import Redirection from "../Redirection";
import DraggableList from "../DraggableList";
const AdminPoppin = (props) => {
    const textType = {
        "h1" : "h1",
        "h2" : "h2",
        "h3" : "h3",
        "h4" : "h4",
        "h5" : "h5",
        "h6" : "h6",
        "p" : "paragraphe",
    }
    const unity = {
        "px" : "px",
        "%" : "%"
    }
    const getAdminStyle = (type,id) => {
        if(type == "texte")
            return(
            <>
                <div className='row class'>
                    <div className='col-6'>
                        <p>Type du texte</p>
                        <Select type="textType" onChange={props.updateTextOption} id={props.texts[id].id} options={textType} selectedOption={props.texts[id].textType ? props.texts[id].textType : "p"}/>
                    </div>
                    <div className='col-6'>
                        <p>Class CSS <i className="fa fa-info-circle" aria-hidden="true" data-tooltip={`texte simple, sans éspace, ni caractère spéciaux, ni accentué (exemple: popin-title) pour "nommer" le texte, très utile pour eviter la repetition des styles pour les textes avec les mêmes styles; par exemple si un texte avec la classe "popup-paragraphe" à une couleur #000000, une taille de 16, une police "LFT Etica",il suffit de mettre une autre texte avec la même classe "popup-paragraphe" pour appliquer ce style, par contre il est aussi utile pour differencier deux éléménts de même type, par exemple si vous avez 2 "h2" dans votre poppins et que vous voulez appliquer des styles differents pour ces deux éléménts il faut differencier les deux avec une classe`}></i></p>
                        <PrimaryInput name="class" value={props.texts[id].class ? props.texts[id].class:""} placeholder="class CSS" id={props.texts[id].id} onChange={props.changeTitle}/>
                    </div>
                </div>
                <p className='mt-4'>Texte</p>
                {props.texts[id].textType=="p" ?
                <SimpleWysiwygEditor 
                    id={id}
                    onContentChange={props.changePara}
                    value={props.texts[id].text}
                />
                :
                <PrimaryInput value={props.texts[id].text} placeholder="votre texte" id={props.texts[id].id} onChange={props.changeTitle}/>
                }
                {props.getTextStyle(id)}
                <p className='mt-4'>Marge du texte</p>
                {props.getMarginSelector(id)}
            </>
            )
        else if (type == "bouton")
            return(
                <>
                    <Redirection id={props.texts[id].id} action={props.changeTitle} name="redirection" value={props.texts[id].redirection}/>
                    <div className='col-6'>
                        <p>Class CSS <i className="fa fa-info-circle" aria-hidden="true" data-tooltip={`texte simple, sans éspace, ni caractère spéciaux, ni accentué (exemple: popin-title) pour "nommer" le texte, très utile pour eviter la repetition des styles pour les textes avec les mêmes styles; par exemple si un texte avec la classe "popup-paragraphe" à une couleur #000000, une taille de 16, une police "LFT Etica",il suffit de mettre une autre texte avec la même classe "popup-paragraphe" pour appliquer ce style, par contre il est aussi utile pour differencier deux éléménts de même type, par exemple si vous avez 2 "h2" dans votre poppins et que vous voulez appliquer des styles differents pour ces deux éléménts il faut differencier les deux avec une classe`}></i></p>
                        <PrimaryInput name="class" value={props.texts[id].class ? props.texts[id].class:""} placeholder="class CSS" id={props.texts[id].id} onChange={props.changeTitle}/>
                    </div>
                    <p>Texte</p>
                    <PrimaryInput value={props.texts[id].text} placeholder="votre texte" id={props.texts[id].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(id)}
                    <p className='mt-4'>Couleur de fond du bouton</p>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[id]} type="background"/>
                    <p className='mt-4'>Marge du bouton</p>
                    {props.getMarginSelector(id)}
                    <p className='mt-4'>Rayon des bordures (pour des coins arrondis) <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="HG:En Haut à Gauche&#10;HD:En Haut à Droite&#10;BD:En Bas à Droite&#10;BG:En Bas à Gauche"></i></p>
                    {props.getMarginSelector(id,"border")}
                </>
                )
        else if(type == "image")
            return(
                <>
                    <Redirection text="Utiliser un URL pour l'image?" id={props.texts[id].id} action={props.changeTitle} name="redirection" value={props.texts[id].redirection}/>
                    <p>Télécharger votre image ici</p>
                    <div className='image-container'>
                        <ImgUpload onChange={(e) => props.photoUpload(e,props.texts[id].imgId)} src={props.images[props.texts[id].imgId].imagePreviewUrl} id={props.images[props.texts[id].imgId].id}/>
                    </div>
                    <div className='row mt-4'>
                        {props.windowSize!=="mobile-view"&&(
                            <div className='col-4'>
                                <p>{props.windowSize=="tablet-view" ? "Largeur sur tablette" : "Largeur"}</p>
                                <InputNumber changeNumber={(e) => props.changeNumber(e,props.texts[id].id, props.windowSize=="tablet-view"  ? "sizeMobile" : "size" )} incr={() => props.incrementSpeed(props.texts[id].id,props.windowSize=="tablet-view" ? "sizeMobile" : 'size',null,1000)} decr={() => props.decrementSpeed(props.texts[id].id,props.windowSize=="tablet-view" ? "sizeMobile" : 'size',null)} stop={props.stop} width={props.windowSize=="tablet-view" ? props.texts[id].sizeMobile : props.texts[id].size}/>
                            </div>
                        )}
                        <div className='col-8'>
                            <p>Class CSS <i className="fa fa-info-circle" aria-hidden="true" data-tooltip={`texte simple, sans éspace, ni caractère spéciaux, ni accentué (exemple: popin-title) pour "nommer" le texte, très utile pour eviter la repetition des styles pour les textes avec les mêmes styles; par exemple si un texte avec la classe "popup-paragraphe" à une couleur #000000, une taille de 16, une police "LFT Etica",il suffit de mettre une autre texte avec la même classe "popup-paragraphe" pour appliquer ce style, par contre il est aussi utile pour differencier deux éléménts de même type, par exemple si vous avez 2 "h2" dans votre poppins et que vous voulez appliquer des styles differents pour ces deux éléménts il faut differencier les deux avec une classe`}></i></p>
                            <PrimaryInput name="class" value={props.texts[id].class ? props.texts[id].class:""} placeholder="class CSS" id={props.texts[id].id} onChange={props.changeTitle}/>
                        </div>
                    </div>
                    <p className='mt-4'>Marge de l'image</p>
                    {props.getMarginSelector(id)}
                </>
            )
        else if(type == "compteur")
            return(
                <>
                    <p className='mt-4'>Seléctionnez la date et heure pour lancer le compteur</p>
                    <DateTimePicker onChange={newDate => props.changeDate(newDate, id)} value={props.texts[id].date}/>
                    <p className='mt-4'>Les styles de votre compteur</p>
                    {props.getTextStyle(id)}
                    <p className='mt-4'>Marge du compteur</p>
                    {props.getMarginSelector(id)}
                </>
            )
        else if(type == "champ")
            return(
                <>
                    <p>Placeholder (ex:inserez votre email, ...)</p>
                    <PrimaryInput value={props.texts[id].text} placeholder="votre texte" id={props.texts[id].id} onChange={props.changeTitle}/>
                    <div className='col-6'>
                        <p>Class CSS <i className="fa fa-info-circle" aria-hidden="true" data-tooltip={`texte simple, sans éspace, ni caractère spéciaux, ni accentué (exemple: popin-title) pour "nommer" le texte, très utile pour eviter la repetition des styles pour les textes avec les mêmes styles; par exemple si un texte avec la classe "popup-paragraphe" à une couleur #000000, une taille de 16, une police "LFT Etica",il suffit de mettre une autre texte avec la même classe "popup-paragraphe" pour appliquer ce style, par contre il est aussi utile pour differencier deux éléménts de même type, par exemple si vous avez 2 "h2" dans votre poppins et que vous voulez appliquer des styles differents pour ces deux éléménts il faut differencier les deux avec une classe`}></i></p>
                        <PrimaryInput name="class" value={props.texts[id].class ? props.texts[id].class:""} placeholder="class CSS" id={props.texts[id].id} onChange={props.changeTitle}/>
                    </div>
                    <p className='mt-4'>Les styles du texte du champ</p>
                    {props.getTextStyle(id)}
                    <p className='mt-4'>Marge du champ</p>
                    {props.getMarginSelector(id)}
                    <p className='mt-4'>Couleur de fond du champ</p>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[id]} type="background"/>
                    <p className='mt-4'>Rayon des bordures (pour des coins arrondis) <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="HG:En Haut à Gauche&#10;HD:En Haut à Droite&#10;BD:En Bas à Droite&#10;BG:En Bas à Gauche"></i></p>
                    {props.getMarginSelector(id,"border")}
                </>
        )
    }
    return (
        <div>
            <AdminAccordion
            title='Général'
            content={
                <div className="block">
                    <p className='mt-4'>Couleur de fond du {props.page}</p>
                    <ColorPicker action={(e) => props.handleChange(e,props.page=="popup" ? "color" : "background")} details={props.texts[0]} type={props.page=="popup" ? "color" : "background"}/>
                    {props.windowSize!=="mobile-view"&&(
                        <>
                            <p className='mt-4'>{props.windowSize=="tablet-view" ? "Largeur du "+props.page+" sur tablette" : "Largeur du "+props.page}</p>
                            <div className='row'>
                                <div className='col-4'>
                                    <InputNumber changeNumber={(e) => props.changeNumber(e,props.texts[1].id, props.windowSize=="tablet-view"  ? "sizeMobile" : "size" )} incr={() => props.incrementSpeed(props.texts[1].id,props.windowSize=="tablet-view" ? "sizeMobile" : 'size',null,1000)} decr={() => props.decrementSpeed(props.texts[1].id,props.windowSize=="tablet-view" ? "sizeMobile" : 'size',null)} stop={props.stop} width={props.windowSize=="tablet-view" ? props.texts[1].sizeMobile : props.texts[1].size}/>
                                </div>
                                <div className='col-8'>
                                    <Select type="unity" onChange={props.updateTextOption} id={props.texts[1].id} options={unity} selectedOption={props.texts[1].unity ? props.texts[1].unity : "px"}/>
                                </div>
                            </div>
                        </>
                    )}
                    <div className='range'>
                        <label htmlFor={"opacity-"+props.texts[0].id} style={{fontSize:13,textAlign:"left"}}>Opacité ({props.texts[0].size})</label>
                        <input className='w-100' type="range" id={"opacity-"+props.texts[0].id} min="0" max="1" step="0.01" value={props.texts[0].size} onChange={(e) => props.handleChange(e,"opacity")}/>
                    </div>
                    {props.page=="popup"&&(
                    <>
                        <p>Le popup se fermera après (en sec)</p>
                        <div className='col-4'>
                            <InputNumber changeNumber={(e) => props.changeNumber(e,props.texts[0].id, "sizeMobile")} incr={() => props.incrementSpeed(props.texts[0].id,"sizeMobile",null,10)} decr={() => props.decrementSpeed(props.texts[0].id,"sizeMobile",null)} stop={props.stop} width={props.texts[0].sizeMobile}/>
                        </div>
                        <h4>Image du popup</h4>
                        <div className='image-container'>
                            {props.images[0].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",0,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                            <ImgUpload onChange={(e) => props.photoUpload(e,0)} src={props.images[0].imagePreviewUrl} id={props.images[0].id}/>
                        </div>
                    </>
                    )}
                    {props.page=="poppins"&&
                        <>
                            <p className='mt-4'>Marge du poppins</p>
                            {props.getMarginSelector(0)}
                        </>
                    }
                    <p className='mt-4'>Alignement horizontal du contenu</p>
                    {props.getAlignSelector("baner",0)}
                    <p className='mt-4'>Rayon des bordures (pour des coins arrondis) <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="HG:En Haut à Gauche&#10;HD:En Haut à Droite&#10;BD:En Bas à Droite&#10;BG:En Bas à Gauche"></i></p>
                    {props.getMarginSelector(1,"border")}
                </div>
            }
            scroll={() => {}}
            />
            <AdminAccordion
                title='Contenu'
                content={
                    <div className="block py-4">
                        {props.page=="popup"&&
                        <div className='py-4'>
                            <p>Couleur de fond du contenu</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[0]} type="background"/>
                            <p className='mt-4'>Marge du contenu</p>
                            {props.getMarginSelector(0)}
                        </div>
                        }
                        <DraggableList
                            list={props.texts}
                            idPrefix="poppins-content-"
                            typeCard="poppins-content"
                            onDelete={props.handleDeleteElement}
                            updateCardOrder={props.updateCardOrder}
                            typeToggle="elements"
                            getStyle = {getAdminStyle}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-content",null,null)}>Ajouter un élément</PrimaryButton>
                    </div>
                }
                scroll={() => {}}
            />
        </div>
    );
}
export default AdminPoppin;