import React from 'react';
import AdminAccordion from "../AdminAccordion";
import ColorPicker from "../ColorPicker";
import ImgUpload from '../ImgUpload';
import InputNumber from "../InputNumber";
import Select from "../Select";
import SimpleWysiwygEditor from "../SimpleWysiwygEditor";
import DateTimePicker from 'react-datetime-picker';
import 'react-calendar/dist/Calendar.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-clock/dist/Clock.css';
import {PrimaryButton} from "../Button.style";
import { PrimaryInput } from '../Input.style';
import Redirection from "../Redirection";
import DraggableList from "../DraggableList";
const AdminNewHomepage = (props) => {
    const textType = {
        "h1" : "h1",
        "h2" : "h2",
        "h3" : "h3",
        "h4" : "h4",
        "h5" : "h5",
        "h6" : "h6",
        "p" : "paragraphe",
    }
    const unity = {
        "px" : "px",
        "vh" : "vh",
        "%" : "%"
    }
    const getAdvancedTextStyle = (id) => {
        return(
        <>
            <div className='row class'>
                <div className='col-6'>
                    <p>Type du texte</p>
                    <Select type="textType" onChange={props.updateTextOption} id={props.texts[id].id} options={textType} selectedOption={props.texts[id].textType ? props.texts[id].textType : "p"}/>
                </div>
                <div className='col-6'>
                    <p>Class CSS <i className="fa fa-info-circle" aria-hidden="true" data-tooltip={`texte simple, sans éspace, ni caractère spéciaux, ni accentué (exemple: popin-title) pour "nommer" le texte, très utile pour eviter la repetition des styles pour les textes avec les mêmes styles; par exemple si un texte avec la classe "popup-paragraphe" à une couleur #000000, une taille de 16, une police "LFT Etica",il suffit de mettre une autre texte avec la même classe "popup-paragraphe" pour appliquer ce style, par contre il est aussi utile pour differencier deux éléménts de même type, par exemple si vous avez 2 "h2" dans votre poppins et que vous voulez appliquer des styles differents pour ces deux éléménts il faut differencier les deux avec une classe`}></i></p>
                    <PrimaryInput name="class" value={props.texts[id].class ? props.texts[id].class:""} placeholder="class CSS" id={'class-'+props.texts[id].id} onChange={props.changeTitle}/>
                </div>
            </div>
            <p className='mt-4'>Texte</p>
            {props.texts[id].textType=="p" ?
            <SimpleWysiwygEditor 
                id={id}
                onContentChange={props.changePara}
                value={props.texts[id].text}
            />
            :
            <PrimaryInput value={props.texts[id].text} placeholder="votre texte" id={props.texts[id].id} onChange={props.changeTitle}/>
            }
            <div className='mt-4'>
                {props.getTextStyle(id)}
                <p className='mt-4'>Marge du texte</p>
                {props.getMarginSelector(id)}
                <p className='mt-4'>Alignement du texte</p>
                {props.getAlignSelector("text-align-"+id,id)}
            </div>
        </>
        )
    }
    const getAdminStyle = (type,id,typeElt) => {
        if(type == "texte")
            return(
            getAdvancedTextStyle(id)
            )
        else if (type == "bouton")
            return(
                <>
                    <Redirection id={props.texts[id].id} action={props.changeTitle} name="redirection" value={props.texts[id].redirection}/>
                    <div className='col-6'>
                        <p>Class CSS <i className="fa fa-info-circle" aria-hidden="true" data-tooltip={`texte simple, sans éspace, ni caractère spéciaux, ni accentué (exemple: popin-title) pour "nommer" le texte, très utile pour eviter la repetition des styles pour les textes avec les mêmes styles; par exemple si un texte avec la classe "popup-paragraphe" à une couleur #000000, une taille de 16, une police "LFT Etica",il suffit de mettre une autre texte avec la même classe "popup-paragraphe" pour appliquer ce style, par contre il est aussi utile pour differencier deux éléménts de même type, par exemple si vous avez 2 "h2" dans votre poppins et que vous voulez appliquer des styles differents pour ces deux éléménts il faut differencier les deux avec une classe`}></i></p>
                        <PrimaryInput name="class" value={props.texts[id].class ? props.texts[id].class:""} placeholder="class CSS" id={props.texts[id].id} onChange={props.changeTitle}/>
                    </div>
                    <p>Texte</p>
                    <PrimaryInput value={props.texts[id].text} placeholder="votre texte" id={props.texts[id].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(id)}
                    <p className='mt-4'>Couleur de fond du bouton</p>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[id]} type="background"/>
                    <p className='mt-4'>Marge du bouton</p>
                    {props.getMarginSelector(id)}
                    <p className='mt-4'>Rayon des bordures (pour des coins arrondis) <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="HG:En Haut à Gauche&#10;HD:En Haut à Droite&#10;BD:En Bas à Droite&#10;BG:En Bas à Gauche"></i></p>
                    {props.getMarginSelector(id,"border")}
                </>
                )
        else if(type == "image")
            return(
                <>
                    <Redirection text="Utiliser un URL pour l'image?" id={props.texts[id].id} action={props.changeTitle} name="redirection" value={props.texts[id].redirection}/>
                    <p>Télécharger votre image ici</p>
                    <div className='image-container'>
                        <ImgUpload onChange={(e) => props.photoUpload(e,props.texts[id].imgId)} src={props.images[props.texts[id].imgId].imagePreviewUrl} id={props.images[props.texts[id].imgId].id}/>
                    </div>
                    <div className='row my-4'>
                        {props.windowSize!=="mobile-view"&&(
                            <div className='col-4'>
                                <p>{props.windowSize=="tablet-view" ? "Largeur sur tablette" : "Largeur"}</p>
                                <InputNumber changeNumber={(e) => props.changeNumber(e,props.texts[id].id, props.windowSize=="tablet-view"  ? "sizeMobile" : "size" )} incr={() => props.incrementSpeed(props.texts[id].id,props.windowSize=="tablet-view" ? "sizeMobile" : 'size',null,1000)} decr={() => props.decrementSpeed(props.texts[id].id,props.windowSize=="tablet-view" ? "sizeMobile" : 'size',null)} stop={props.stop} width={props.windowSize=="tablet-view" ? props.texts[id].sizeMobile : props.texts[id].size}/>
                            </div>
                        )}
                        <div className='col-8'>
                            <p>Unité</p>
                            <Select type="unity" onChange={props.updateTextOption} id={props.texts[id].id} options={unity} selectedOption={props.texts[id].unity ? props.texts[id].unity : "px"}/>
                        </div>
                    </div>
                    <p>Class CSS <i className="fa fa-info-circle" aria-hidden="true" data-tooltip={`texte simple, sans éspace, ni caractère spéciaux, ni accentué (exemple: popin-title) pour "nommer" le texte, très utile pour eviter la repetition des styles pour les textes avec les mêmes styles; par exemple si un texte avec la classe "popup-paragraphe" à une couleur #000000, une taille de 16, une police "LFT Etica",il suffit de mettre une autre texte avec la même classe "popup-paragraphe" pour appliquer ce style, par contre il est aussi utile pour differencier deux éléménts de même type, par exemple si vous avez 2 "h2" dans votre poppins et que vous voulez appliquer des styles differents pour ces deux éléménts il faut differencier les deux avec une classe`}></i></p>
                    <PrimaryInput name="class" value={props.texts[id].class ? props.texts[id].class:""} placeholder="class CSS" id={props.texts[id].id} onChange={props.changeTitle}/>
                    <p className='mt-4'>Marge de l'image</p>
                    {props.getMarginSelector(id)}
                </>
            )
        else if(type == "compteur")
            return(
                <>
                    <p className='mt-4'>Seléctionnez la date et heure pour lancer le compteur</p>
                    <DateTimePicker onChange={newDate => props.changeDate(newDate, id)} value={props.texts[id].date}/>
                    <p className='mt-4'>Les styles de votre compteur</p>
                    {props.getTextStyle(id)}
                    <p className='mt-4'>Marge du compteur</p>
                    {props.getMarginSelector(id)}
                </>
            )   
    }
    const getAdminBanerStyle = (type,id) => {
        return(
            <>
                <h4>Alignement</h4>
                <p className='mb-2'>vertical</p>
                {props.getAlignSelector("baner-vertical-"+id,props.banerSlides[id].textId,"vertical")}
                <p className='mt-4 mb-2'>horizontal</p>
                {props.getAlignSelector("baner-"+id,props.banerSlides[id].textId)}
                <h4>Marge du slide</h4>
                {props.getMarginSelector(props.banerSlides[id].textId)}
                <h4>{props.windowSize == "desktop-view" ? "L'image du slide" : "L'image du slide sur mobile"}</h4>
                <div className='image-container'>
                    {props.windowSize == "desktop-view" ?
                    <ImgUpload onChange={(e) => props.photoUpload(e,props.banerSlides[id].imageId)} src={props.images[props.banerSlides[id].imageId].imagePreviewUrl} id={props.banerSlides[id].imageId}/>
                    :
                    <ImgUpload onChange={(e) => props.photoUpload(e,props.banerSlides[id].imageId,"baner-slide")} src={props.images[props.banerSlides[id].imageId].imagePreviewUrlMobile} id={props.banerSlides[id].imageId}/>
                    }
                </div>
                <h4>Contenu du slide</h4>
                <DraggableList
                    list={props.texts}
                    idPrefix="baner-slide-content-"
                    typeCard={"baner-slide-content-"+id}
                    onDelete={props.handleDeleteElement}
                    updateCardOrder={props.updateCardOrder}
                    typeToggle="elements"
                    getStyle = {getAdminStyle}
                />
                <PrimaryButton onClick={() => props.togglePopup("baner-slide-content-"+id,null,null)}>Ajouter un élément</PrimaryButton>
            </>
        )
    }
    return (
        <div>
            <AdminAccordion
                title='Banière'
                ref={props.accordionRefs[40]}
                content={
                    <div className="block">
                        <p className='mt-4'>{props.windowSize!=="desktop-view" ? "Hauteur en mobile" : "Hauteur"} <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="VH:ViewPort Height&#10;PX:PiXels"></i></p>
                        <div className='row'>
                            <div className='col-4'>
                                <InputNumber changeNumber={(e) => props.changeNumber(e,props.texts[0].id, props.windowSize!=="desktop-view"  ? "sizeMobile" : "size" )} incr={() => props.incrementSpeed(props.texts[0].id,props.windowSize!=="desktop-view" ? "sizeMobile" : 'size',null,1000)} decr={() => props.decrementSpeed(props.texts[0].id,props.windowSize!=="desktop-view" ? "sizeMobile" : 'size',null)} stop={props.stop} width={props.windowSize!=="desktop-view" ? props.texts[0].sizeMobile : props.texts[0].size}/>
                            </div>
                            <div className='col-8'>
                                <Select type="unity" onChange={props.updateTextOption} id={props.texts[0].id} options={unity} selectedOption={props.texts[0].unity ? props.texts[0].unity : "px"}/>
                            </div>
                        </div>
                        <h4 className='mt-4'>Les slides</h4>
                        <DraggableList
                            list={props.banerSlides}
                            idPrefix="slide-baner-"
                            typeCard="slide-baner"
                            onDelete={props.handleDelete}
                            updateCardOrder={props.updateCardOrder}
                            getStyle = {getAdminBanerStyle}
                        />
                        <PrimaryButton onClick={() => props.addSlide({text:"slide",type:"slide-baner"})}>Ajouter un slide</PrimaryButton>
                    </div>
                }
                scroll={() => props.handleClickScroll("baner")}
            />
            <AdminAccordion
                title='Découvrir'
                ref={props.accordionRefs[41]}
                content={
                    <div className="block">
                        <h4>Les carousels de texte</h4>
                        <h5>Marge du carousel</h5>
                        {props.getMarginSelector(3)}
                        <h5>Alignement du texte</h5>
                        {props.getAlignSelector("baner",3)}
                        <div className="pb-4 border-bottom-white">
                            <h5>Style du texte du bandeau</h5>
                            {props.getTextStyle(3)}
                            <h5>Les slides</h5>
                            <p className="mt-1">{props.getArraysize(props.banerSlides,'baner-slide')} slide(s)</p>
                            <DraggableList
                                list={props.banerSlides}
                                idPrefix="bandeau-"
                                typeCard="baner-slide"
                                typeToggle='edit-baner-slide'
                                onDelete={props.handleDelete}
                                updateCardOrder={props.updateCardOrder}
                                toggle={props.togglePopup}
                            />
                            <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"baner-slide")}>Ajouter un slide</PrimaryButton>
                        </div>
                        <h4>Les Cartes</h4>
                        <h5>Alignement du texte</h5>
                        <p className='mb-2'>vertical</p>
                        {props.getAlignSelector("card-vertical",4,"vertical")}
                        <p className='mt-4 mb-2'>horizontal</p>
                        {props.getAlignSelector("card-",4)}
                        <h5>Style du texte de la carte</h5>
                        {props.getTextStyle(4)}
                        <h5>Liste des cartes</h5>
                        <p className="mt-2">{props.getArraysize(props.cardSlides,'card-slide')} carte(s)</p>
                        <DraggableList
                            list={props.cardSlides}
                            idPrefix="card-"
                            typeCard="card-slide"
                            typeToggle='edit-card'
                            onDelete={props.handleDeleteCard}
                            updateCardOrder={props.updateCardOrder}
                            toggle={props.togglePopup}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-card",null,"card-slide")}>Ajouter une carte</PrimaryButton>
                    </div>
                }
                scroll={() => props.handleClickScroll("decouvrir")}
                />
            <AdminAccordion
                title='Bestsellers'
                ref={props.accordionRefs[42]}
                content={
                    <div className="block">
                        <h4>Le titre</h4>
                        {getAdvancedTextStyle(5)}
                        <h4>Les cartes</h4>
                        <p className='mt-4 mb-2'>Alignement horizontal</p>
                        {props.getAlignSelector("card-carousel-",6)}
                        <h5>Style du texte de la carte</h5>
                        {props.getTextStyle(6)}
                        <h5>Marge du text</h5>
                        {props.getMarginSelector(6)}
                        <h5>Liste des cartes</h5>
                        <p className="mt-2">{props.getArraysize(props.cardSlides,'card-carousel')} carte(s)</p>
                        <DraggableList
                            list={props.cardSlides}
                            idPrefix="card-carousel-"
                            typeCard="card-carousel"
                            typeToggle='edit-card'
                            onDelete={props.handleDeleteCard}
                            updateCardOrder={props.updateCardOrder}
                            toggle={props.togglePopup}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-card",null,"card-carousel")}>Ajouter une carte</PrimaryButton>
                    </div>
                    }
                scroll={() => props.handleClickScroll("bestseller")}
            />
            <AdminAccordion
                title='Deux images'
                ref={props.accordionRefs[43]}
                content={
                    <div className="block">
                        <div className='pb-4 border-bottom-white'>
                            <h4>Le titre</h4>
                            {getAdvancedTextStyle(18)}
                        </div>
                        <div className='pb-4 border-bottom-white'>
                            <h4>Style de la puce</h4>
                            <p className='mt-4'>Couleur de fond</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[7]} type="background"/>
                            {props.windowSize!=="mobile-view"&&(
                                <>
                                    <p className='mt-4'>Largeur</p>
                                    <InputNumber changeNumber={(e) => props.changeNumber(e,props.texts[7].id, "sizeMobile")} incr={() => props.incrementSpeed(props.texts[7].id,'sizeMobile',null,1000)} decr={() => props.decrementSpeed(props.texts[7].id,'sizeMobile',null)} stop={props.stop} width={props.texts[7].sizeMobile}/>
                                </>
                            )}
                            <div className='range'>
                                <label htmlFor={"opacity-"+props.texts[7].id} style={{fontSize:13,textAlign:"left"}}>Opacité ({props.texts[7].size})</label>
                                <input className='w-100' type="range" id={"opacity-"+props.texts[7].id} min="0" max="1" step="0.01" value={props.texts[7].size} onChange={(e) => props.handleChange(e,"opacity")}/>
                            </div>
                            <p className='mt-4'>Marge</p>
                            {props.getMarginSelector(7)}
                            <p className='mt-4'>Alignement horizontal du contenu</p>
                            {props.getAlignSelector("puce-",7)}
                            <p className='mt-4'>Rayon des bordures (pour des coins arrondis) <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="HG:En Haut à Gauche&#10;HD:En Haut à Droite&#10;BD:En Bas à Droite&#10;BG:En Bas à Gauche"></i></p>
                            {props.getMarginSelector(7,"border")}
                        </div>
                        <div className='pb-4 border-bottom-white'>
                            <h5 className='mt-4'>Style du titre de la puce</h5>
                            {props.getTextStyle(8)}
                            <p className='mt-4'>Marge du titre</p>
                            {props.getMarginSelector(8)}
                        </div>
                        <div className='pb-4 border-bottom-white'>
                            <h5 className='mt-4'>Style du sous-titre de la puce</h5>
                            {props.getTextStyle(9)}
                            <p className='mt-4'>Marge du sous-titre</p>
                            {props.getMarginSelector(9)}
                        </div>
                        <div className='pb-4 border-bottom-white'>
                            <h5 className='mt-4'>Style du prix de la puce</h5>
                            {props.getTextStyle(10)}
                            <p className='mt-4'>Marge du prix</p>
                            {props.getMarginSelector(10)}
                        </div>
                        <div className='pb-4 border-bottom-white'>
                            <h5 className='mt-4'>Style du bouton</h5>
                            <p className='mt-2'>Couleur de fond</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[11]} type="background"/>
                            <p className='mt-4'>texte du bouton</p>
                            {props.getTextStyle(11)}
                            <p className='mt-4'>Marge du bouton</p>
                            {props.getMarginSelector(11)}
                            <p className='mt-4'>Rayon des bordures (pour des coins arrondis) <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="HG:En Haut à Gauche&#10;HD:En Haut à Droite&#10;BD:En Bas à Droite&#10;BG:En Bas à Gauche"></i></p>
                            {props.getMarginSelector(11,"border")}
                        </div>
                        <h4>première colonne</h4>
                        <h5>L'image<br/><small>Taille recommandé: 702*1054</small></h5>
                        <div className='image-container'>
                            {props.images[2].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",2,'images')}><i className='fa fa-edit'></i></PrimaryButton>}
                            <ImgUpload onChange={(e) => props.photoUpload(e,props.images[2].id)} src={props.images[2].imagePreviewUrl} id={props.images[2].id}/>
                        </div>
                        <h5>Les puces</h5>
                        <p className="mt-1">{props.getArraysize(props.banerSlides,'puce-first')} puce(s)</p>
                        <DraggableList
                            list={props.banerSlides}
                            idPrefix="puce-first-"
                            typeCard="puce-first"
                            typeToggle='edit-baner-slide'
                            onDelete={props.handleDelete}
                            updateCardOrder={props.updateCardOrder}
                            toggle={props.togglePopup}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"puce-first")}>Ajouter une puce</PrimaryButton>
                        <h4>Deuxième colonne</h4>
                        <h5>L'image<br/><small>Taille recommandé: 702*1054</small></h5>
                        <div className='image-container'>
                            {props.images[3].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",3,'images')}><i className='fa fa-edit'></i></PrimaryButton>}
                            <ImgUpload onChange={(e) => props.photoUpload(e,props.images[3].id)} src={props.images[3].imagePreviewUrl} id={props.images[3].id}/>
                        </div>
                        <h5>Les puces</h5>
                        <p className="mt-1">{props.getArraysize(props.banerSlides,'puce-second')} puce(s)</p>
                        <DraggableList
                            list={props.banerSlides}
                            idPrefix="puce-second-"
                            typeCard="puce-second"
                            typeToggle='edit-baner-slide'
                            onDelete={props.handleDelete}
                            updateCardOrder={props.updateCardOrder}
                            toggle={props.togglePopup}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"puce-second")}>Ajouter une puce</PrimaryButton>
                    </div>
                }
                scroll={() => props.handleClickScroll("preview")}
            />
            <AdminAccordion
                title='Bandeau marron'
                ref={props.accordionRefs[44]}
                content={
                    <div className="block">
                        <div className='pb-4 border-bottom-white'>
                            <h4>Géneral</h4>
                            <h5 className='mt-4'>Style du bandeau</h5>
                            <p className='mt-2'>Couleur de fond</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[12]} type="background"/>
                            <p className='mt-4'>Marge</p>
                            {props.getMarginSelector(12)}
                        </div>
                        <h4>Le texte</h4>
                        {getAdvancedTextStyle(13)}
                    </div>
                }
                scroll={() => props.handleClickScroll("bandeau")}
            />
            <AdminAccordion
                title='Univers'
                ref={props.accordionRefs[45]}
                content={
                    <div className="block">
                        <h4>Le titre</h4>
                        {getAdvancedTextStyle(14)}
                        <h4>Les cartes</h4>
                        <h5>Alignement horizontal du contenu</h5>
                        {props.getAlignSelector("news-card-",17)}
                        <h5>Style des textes de la carte</h5>
                        <div className='pb-4 border-bottom-white'>
                            <h5 className='mt-4'>Style du titre</h5>
                            {props.getTextStyle(15)}
                            <p className='mt-4'>Marge du titre</p>
                            {props.getMarginSelector(15)}
                        </div>
                        <div className='pb-4 border-bottom-white'>
                            <h5 className='mt-4'>Style du texte</h5>
                            {props.getTextStyle(16)}
                            <p className='mt-4'>Marge du texte</p>
                            {props.getMarginSelector(16)}
                        </div>
                        <div className='pb-4 border-bottom-white'>
                            <h5 className='mt-4'>Style du bouton</h5>
                            <p className='mt-2'>Couleur de fond</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[17]} type="background"/>
                            <p className='mt-4'>texte du bouton</p>
                            {props.getTextStyle(17)}
                            <p className='mt-4'>Marge du bouton</p>
                            {props.getMarginSelector(17)}
                            <p className='mt-4'>Rayon des bordures (pour des coins arrondis) <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="HG:En Haut à Gauche&#10;HD:En Haut à Droite&#10;BD:En Bas à Droite&#10;BG:En Bas à Gauche"></i></p>
                            {props.getMarginSelector(17,"border")}
                        </div>
                        <p className="mt-2">{props.getArraysize(props.cardSlides,'card-news')} carte(s)</p>
                        <DraggableList
                            list={props.cardSlides}
                            idPrefix="bloc-"
                            typeCard="card-news"
                            typeToggle='edit-card'
                            onDelete={props.handleDeleteCard}
                            updateCardOrder={props.updateCardOrder}
                            toggle={props.togglePopup}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-card",null,"card-news")}>Ajouter une carte</PrimaryButton>
                    </div>
                    }
                scroll={() => props.handleClickScroll("news")}
            />
        </div>
    );
}
export default AdminNewHomepage;