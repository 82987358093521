import React from 'react';
import AdminAccordion from "../AdminAccordion";
import ImgUpload from '../ImgUpload';
import ColorPicker from "../ColorPicker";
import {PrimaryButton} from "../Button.style";
import { PrimaryInput,PrimaryTextarea } from '../Input.style';
import DraggableList from "../DraggableList";
function AdminBar(props) {
    return(
        <div>
            <AdminAccordion
            title='Entête'
            ref={props.accordionRefs[33]}
            content={
                <>
                    <div className="block border-bottom-white">
                        <h4>Alignement horizontal de l'entête</h4>
                        {props.getAlignSelector("content-header",0)}
                        <h4>Marge du contenu</h4>
                        {props.getMarginSelector(0)}
                    </div>
                    <div className="block">
                        <h4>Logo</h4>
                        <div className='image-container'>
                            <ImgUpload onChange={(e) => props.photoUpload(e,0)} src={props.images[0].imagePreviewUrl} id={props.images[0].id}/>
                        </div>
                    </div>
                    <div className='block'>
                        <div className="border-bottom-white pb-4">
                            <h4>Les textes de l'entête</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[0].text} placeholder="titre de l'entête" id={props.texts[0].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(0)}
                        </div>
                        <div className="border-bottom-white pb-4 mt-4">
                            <p>Sous-titre</p>
                            <PrimaryInput value={props.texts[1].text} placeholder="sous-titre de l'entête" id={props.texts[1].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(1)}
                            <p className='mt-4'>Couleur de fond du bouton</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[1]} type="background"/>
                            <p className='mt-4'>Marge du bouton</p>
                            {props.getMarginSelector(1)}
                        </div>
                        <div className="pb-4 mt-4">
                            <h4>Paragraphe</h4>
                            <p>Texte<br/>
                                <small>Pour mettre un texte en gras il suffit de le mettre entre deux guillemets droits"" . ex: Mon "Slim" est beau ...</small>
                            </p>
                            <PrimaryTextarea placeholder="Votre texte" value={props.texts[2].text} id={props.texts[2].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(2)}
                        </div>
                    </div>
                </>
            }
            scroll={() => props.handleClickScroll("header")}
            />
            <AdminAccordion
            title='Liste des cartes'
            ref={props.accordionRefs[34]}
            content={
                <div className="block mb-4">
                    <div className="border-bottom-white pb-4 mt-4">
                        <h4>Style des cartes</h4>
                        <p className='mt-4'>Couleur de fond des cartes</p>
                        <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[4]} type="background"/>
                        <p className='mt-4'>Alignement horizontal des textes de la carte</p>
                        {props.getAlignSelector("content-card",4)}
                    </div>
                    <div className="border-bottom-white pb-4 mt-4" id="title">
                        <p>Titre de la carte</p>
                        {props.getTextStyle(4)}
                    </div>
                    <div className="border-bottom-white pb-4 mt-4" id="subtitle">
                        <p>Sous-titre de la carte</p>
                        {props.getTextStyle(5)}
                    </div>
                    <div className="border-bottom-white pb-4 mt-4" id="text">
                        <p>Texte de la carte</p>
                        {props.getTextStyle(6)}
                    </div>
                    <div className="border-bottom-white pb-4 mt-4" id="list">
                        <p>Liste de la carte</p>
                        {props.getTextStyle(7)}
                    </div>
                    <div className="border-bottom-white pb-4 mt-4" id="num">
                        <p>Numero de la carte</p>
                        {props.getTextStyle(8)}
                        <p className='mt-4'>Couleur de fond du numero</p>
                        <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[8]} type="background"/>
                    </div>
                    <div className="border-bottom-white pb-4 mt-4" id="link">
                        <p>Lien de la carte</p>
                        {props.getTextStyle(9)}
                    </div>
                    <h4>Les cartes</h4>
                    <p className="mt-2">{props.getArraysize(props.cardSlides,'card-bar')} carte(s)</p>
                    <DraggableList
                        list={props.cardSlides}
                        idPrefix="bloc-bar"
                        typeCard="card-bar"
                        typeToggle='edit-card'
                        onDelete={props.handleDeleteCard}
                        updateCardOrder={props.updateCardOrder}
                        toggle={props.togglePopup}
                    />
                    <PrimaryButton onClick={() => props.togglePopup("add-card",null,"card-bar")}>Ajouter une carte</PrimaryButton>
                </div>
            }
            scroll={() => props.handleClickScroll("section")}
            />
            <AdminAccordion
            title='Footer'
            ref={props.accordionRefs[35]}
            content={
                <>
                    <div className="block border-bottom-white">
                        <h4>Alignement horizontal du footer</h4>
                        {props.getAlignSelector("content-footer",3)}
                        <h4>Marge du footer</h4>
                        {props.getMarginSelector(3)}
                    </div>
                    <div className="block">
                        <h4>Logo</h4>
                        <div className='image-container'>
                            <ImgUpload onChange={(e) => props.photoUpload(e,1)} src={props.images[1].imagePreviewUrl} id={props.images[1].id}/>
                        </div>
                        <div className="border-bottom-white pb-4">
                            <h4>Les textes de l'entête</h4>
                            <p>Texte</p>
                            <PrimaryInput value={props.texts[3].text} placeholder="texte ..." id={props.texts[3].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(3)}
                        </div>
                        <h4>Icone tortue</h4>
                        <div className='image-container'>
                            <ImgUpload onChange={(e) => props.photoUpload(e,2)} src={props.images[2].imagePreviewUrl} id={props.images[2].id}/>
                        </div>
                    </div>
                </>
            }
            scroll={() => props.handleClickScroll("footer")}
            />
        </div>
        );
    }
export default AdminBar;