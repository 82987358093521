import React from 'react';
import AdminAccordion from "../AdminAccordion";
import ColorPicker from "../ColorPicker";
import ImgUpload from '../ImgUpload';
import Objet from "../Objet";
import {PrimaryButton} from "../Button.style";
import { PrimaryInput,PrimaryTextarea } from '../Input.style';
import Redirection from "../Redirection";
import DraggableList from "../DraggableList";
function AdminEngagement(props) {
    return(
        <div>
            {props.windowSize == "desktop-view" ?
            <AdminAccordion
            title='Section image - texte -image'
            ref={props.accordionRefs[15]}
            content={
                <div className="block mb-4">
                <div> 
                    <h4>Image de gauche <br/><small>Taille recommandé: 1000*1090</small></h4>
                    <div className='image-container'>
                    {props.images[0].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",0,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,0)} src={props.images[0].imagePreviewUrl} id={props.images[0].id}/>
                    </div>
                    <h4 className='mt-4'>Image de droite <br/><small>Taille recommandé: 1000*1090</small></h4>
                    <div className='image-container'>
                    {props.images[1].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",1,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,1)} src={props.images[1].imagePreviewUrl} id={props.images[1].id}/>
                    </div>
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Titre</h4>
                    <p>Couleur de fond du bloc</p>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[0]} type="background"/>
                    <p className='mt-4'>Texte</p>
                    <PrimaryTextarea value={props.texts[0].text} id={props.texts[0].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(0,true)}
                    <p className='mt-4'>Alignement du texte</p>
                    {props.getAlignSelector("third-bloc-title",0)}
                </div>
                </div>
            }
            scroll={() => props.handleClickScroll("image-text-image")}
            />
            :
            null
            }
            <AdminAccordion
            title='Section Texte - texte'
            ref={props.accordionRefs[16]}
            content={
                <div className="block mb-4">
                <h4>Marge du contenu</h4>
                {props.getMarginSelector(1)}
                <h4>Premier bloc</h4>
                {props.windowSize == "mobile-view" || props.windowSize == "tablet-view" ? 
                    <div className='border-bottom-white pb-4'>
                    <h4>Image en version mobile <br/><small>Taille recommandé: 1000*887</small></h4>
                    <div className='image-container'>
                        {props.images[2].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",2,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,2)} src={props.images[2].imagePreviewUrl} id={props.images[2].id}/>
                    </div>
                    </div>
                : null 
                }
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Titre</h4>
                    <PrimaryInput value={props.texts[1].text} placeholder="Le titre en italic" id={props.texts[1].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(1,true)}
                    <p className='mt-4'>Alignement du texte</p>
                    {props.getAlignSelector("text-text-title",1)}
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Sous-titre</h4>
                    <PrimaryInput value={props.texts[2].text} placeholder="Le sous-titre" id={props.texts[2].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(2,true)}
                </div>
                <h4>Second bloc</h4>
                <h4 className='mt-4'>Paragraphe</h4>
                <p>Texte</p>
                <PrimaryTextarea value={props.texts[3].text} placeholder="paragraphe du second bloc" id={props.texts[3].id} onChange={props.changeTitle}/>
                {props.getTextStyle(3,true)}
                </div>
            }
            scroll={() => props.handleClickScroll("texte-texte")}
            />
            <AdminAccordion
                title='Carte Engagement'
                ref={props.accordionRefs[17]}
                content={
                <div className='block mb-4'>
                    <div className="border-bottom-white pb-4 mt-4">
                        <h4>Couleur de fond du contenu</h4>
                        <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[4]} type="background"/>
                        <h4>Marge du contenu</h4>
                        {props.getMarginSelector(4)}
                    </div>
                    <div className="border-bottom-white pb-4 mt-4">
                        <h4>Titre</h4>
                        <PrimaryInput value={props.texts[4].text} placeholder="Le sous-titre" id={props.texts[4].id} onChange={props.changeTitle}/>
                        {props.getTextStyle(4,true)}
                        <p className='mt-4'>Alignement du texte</p>
                        {props.getAlignSelector("carte-engagement",4)}
                    </div>
                    <>
                        <h4>Les cartes</h4>
                        <p className="mt-2">{props.getArraysize(props.cardSlides,'card-slide')} carte(s)</p>
                        <DraggableList
                            list={props.cardSlides}
                            idPrefix="card-"
                            typeCard="card-slide"
                            typeToggle='edit-card'
                            onDelete={props.handleDeleteCard}
                            updateCardOrder={props.updateCardOrder}
                            toggle={props.togglePopup}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-card",null,"card-slide")}>Ajouter une carte</PrimaryButton>
                    </>
                </div>}
                scroll={() => props.handleClickScroll("engagement-carte")}
            />
            <AdminAccordion
            title='Liste engagement'
            ref={props.accordionRefs[18]}
            content={
                <div className="block mb-4">
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Style des textes</h4>
                    <p>Le titre</p>
                    {props.getTextStyle(5,true)}
                    <p className='mt-4'>Alignement du texte</p>
                    {props.getAlignSelector("carte-engagement-title",5)}
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                    <p>Le Sous-titre</p>
                    {props.getTextStyle(6,true)}
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                    <p>Le paragraphe</p>
                    {props.getTextStyle(7,true)}
                </div>
                <h4>Les engagements</h4>
                <p className="mt-2">{props.getArraysize(props.cardSlides,'card-engagement')} carte(s)</p>
                <DraggableList
                    list={props.cardSlides}
                    idPrefix="bloc-"
                    typeCard="card-engagement"
                    typeToggle='edit-card'
                    onDelete={props.handleDeleteCard}
                    updateCardOrder={props.updateCardOrder}
                    toggle={props.togglePopup}
                />
                <PrimaryButton onClick={() => props.togglePopup("add-card",null,"card-engagement")}>Ajouter une carte</PrimaryButton>
                </div>
            }
            scroll={() => props.handleClickScroll("engagement-list")}
            />
            <AdminAccordion
            title='Plus loin'
            ref={props.accordionRefs[19]}
            content={
            <div className='block mb-4'>
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Couleur de fond du contenu</h4>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[8]} type="background"/>
                    <h4>Marge du contenu</h4>
                    {props.getMarginSelector(8)}
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Titre</h4>
                    <PrimaryTextarea value={props.texts[8].text} placeholder="Le sous-titre" id={props.texts[8].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(8,true)}
                    <p className='mt-4'>Alignement du texte</p>
                    {props.getAlignSelector("plus-loin-title",8)}
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Sous-titre</h4>
                    <PrimaryInput value={props.texts[9].text} placeholder="Le sous-titre" id={props.texts[9].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(9,true)}
                </div>
                <div>
                    <h4>Les cartes</h4>
                    <p className="mt-2">{props.getArraysize(props.cardSlides,'card-plus-loin')} carte(s)</p>
                    <DraggableList
                        list={props.cardSlides}
                        idPrefix="card-plus-loin-"
                        typeCard="card-plus-loin"
                        typeToggle='edit-card'
                        onDelete={props.handleDeleteCard}
                        updateCardOrder={props.updateCardOrder}
                        toggle={props.togglePopup}
                    />
                    <PrimaryButton onClick={() => props.togglePopup("add-card",null,'card-plus-loin')}>Ajouter une carte</PrimaryButton>
                </div>
            </div>}
            scroll={() => props.handleClickScroll("plus-loin")}
            />
            <AdminAccordion
            title='Bloc video'
            ref={props.accordionRefs[32]}
            content={
            <div className='block mb-4'>
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Titre</h4>
                    <PrimaryInput value={props.texts[14].text} placeholder="Le sous-titre" id={props.texts[14].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(14,true)}
                    <p className='mt-4'>Alignement du texte</p>
                    {props.getAlignSelector("video",14)}
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Sous-titre</h4>
                    <PrimaryInput value={props.texts[15].text} placeholder="Le sous-titre" id={props.texts[15].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(15,true)}
                </div>
                <div>
                    <p className='mt-4'>ID de la vidéo youtube</p>
                    <PrimaryInput value={props.texts[5].text} placeholder="Le sous-titre" id={props.texts[5].id} onChange={props.changeTitle}/>
                </div>
            </div>}
            scroll={() => props.handleClickScroll("association")}
            />
            <AdminAccordion
            title='FAQ'
            ref={props.accordionRefs[20]}
            content={
            <div className='block mb-4'>
                <div className="border-bottom-white pb-4 mt-4">
                <h4>Image <br/><small>Taille recommandé: 1920*793</small></h4>
                <div className='image-container'>
                    {props.images[3].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",3,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,3)} src={props.images[3].imagePreviewUrl} id={props.images[3].id}/>
                </div>
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                <h4>Titre</h4>
                <PrimaryInput value={props.texts[10].text} placeholder="Le titre" id={props.texts[10].id} onChange={props.changeTitle}/>
                {props.getTextStyle(10)}
                <p className='mt-4'>Alignement du texte</p>
                {props.getAlignSelector("faq-title",10)}
                </div>
                <div>
                <h4>Les questions</h4>
                <p className="mt-2">{props.getArraysize(props.cardSlides,'faq')} faq(s)</p>
                <DraggableList
                    list={props.cardSlides}
                    idPrefix="faq-"
                    typeCard="faq"
                    typeToggle='edit-card'
                    onDelete={props.handleDeleteCard}
                    updateCardOrder={props.updateCardOrder}
                    toggle={props.togglePopup}
                />
                <PrimaryButton onClick={() => props.togglePopup("add-card",null,'faq')}>Ajouter une carte</PrimaryButton>
                </div>
            </div>}
            scroll={() => props.handleClickScroll("faq")}
            />
            <AdminAccordion
            title='Posez vos questions'
            ref={props.accordionRefs[21]}
            content={
            <div className='block mb-4'>
                <div className="border-bottom-white pb-4 mt-4">
                <h4>Titre</h4>
                <PrimaryInput value={props.texts[11].text} placeholder="Le titre" id={props.texts[11].id} onChange={props.changeTitle}/>
                {props.getTextStyle(11,true)}
                <p className='mt-4'>Alignement du texte</p>
                {props.getAlignSelector("question-title",11)}
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                <h4>Sous-titre</h4>
                <PrimaryTextarea value={props.texts[12].text} placeholder="Le titre" id={props.texts[12].id} onChange={props.changeTitle}/>
                {props.getTextStyle(12,true)}
                <p className='mt-4'>Alignement du texte</p>
                {props.getAlignSelector("question-subtitle",12)}
                </div>
                <div>
                <h4>Bouton</h4>
                <p>Couleur de fond du bouton</p>
                <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[13]} type="background"/>
                <p className='mt-4'>Texte du bouton</p>
                <PrimaryInput value={props.texts[13].text} placeholder="Le titre" id={props.texts[13].id} onChange={props.changeTitle}/>
                {props.getTextStyle(13)}
                <div className='mt-4'>
                    <Redirection id={props.texts[13].id} action={props.changeTitle} name="redirection" value={props.texts[13].redirection}/>
                </div>
                </div>
            </div>
            }
            scroll={() => props.handleClickScroll("questions")}
            />
        </div>
    );
}
export default AdminEngagement;