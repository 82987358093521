import http from "../common/http-common";

class TutorialDataService {
  getAll() {
    return http.get("/tutorials");
  }
  get(id) {
    return http.get(`/tutorials/${id}`);
  }
  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }
  create(data) {
    return http.post("/tutorials", data);
  }
  delete(id) {
    return http.delete(`/tutorials/${id}`);
  }
  deleteImage(filename) {
    return http.delete(`/tutorials/images/delete/${filename}`);
  }
  copyImage(filename) {
    return http.post(`/tutorials/images/copy/${filename}`);
  }
}
export default new TutorialDataService();