import React from 'react';
import {PrimaryButton} from "./Button.style";
function Popup(props) {
    return (
        <div className={props.activePopup == "image-cropper" ? 'popup large' : 'popup'}>
            <div className={props.activePopup == "suppression"||props.activePopup == "duplication" ? 'popup_inner full' : 'popup_inner'}>
                <div className="popup-header">
                    <h4>{props.header}</h4>
                    <PrimaryButton onClick={() => props.toggle()}><i className='fa fa-times'></i></PrimaryButton>
                </div>
                <div className="popup-body">
                    {props.content}
                </div>
            </div>
        </div>
    );
}
export default Popup;