import React from 'react';
import AdminAccordion from "../AdminAccordion";
import ImgUpload from '../ImgUpload';
import Objet from "../Objet";
import {PrimaryButton} from "../Button.style";
import { PrimaryInput,PrimaryTextarea } from '../Input.style';
function AdminLookBook(props) {
    return(
        <div>
            <AdminAccordion
            title='Banière'
            ref={props.accordionRefs[7]}
            content={
                <div className="block">
                    <div className="border-bottom-white pb-4">
                        {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                        <>
                            <h4>Image <br/><small>Taille recommandé: 1920*689</small></h4>
                            <div className='image-container'>
                                {props.images[0].file ?
                                <PrimaryButton onClick={() => props.togglePopup("image-cropper",0,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                                :null
                                } 
                                <ImgUpload onChange={(e) => props.photoUpload(e,0)} src={props.images[0].imagePreviewUrl} id={props.images[0].id}/>
                            </div>
                        </> 
                        :
                        <>
                            <h4>Image en mobile <br/><small>Taille recommandé: 768*542</small></h4>
                            <div className='image-container'>
                                {props.images[1].file ?
                                <PrimaryButton onClick={() => props.togglePopup("image-cropper",1,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                                :null
                                } 
                                <ImgUpload onChange={(e) => props.photoUpload(e,1)} src={props.images[1].imagePreviewUrl} id={props.images[1].id}/>
                            </div>
                        </> 
                        }
                    </div>
                    <div className="pb-4">
                        <h4>Le Titre</h4>
                        <p>Texte</p>
                        <PrimaryInput value={props.texts[0].text} placeholder="titre de la banière" id={props.texts[0].id} onChange={props.changeTitle}/>
                        {props.getTextStyle(0)}
                        <p className='mt-4'>Alignement du texte</p>
                        {props.getAlignSelector("lookbook-title",0)}
                    </div>
                </div>
            }
            scroll={() => props.handleClickScroll("baner-lookbook")}
            />
            <AdminAccordion
            title='Section 2 images'
            ref={props.accordionRefs[8]}
            content={
                <div className="block">
                {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                <div>
                    <h4>Image de gauche <br/><small>Taille recommandé: 1280*1087</small></h4>
                    <div className='image-container'>
                    {props.images[2].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",2,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,2)} src={props.images[2].imagePreviewUrl} id={props.images[2].id}/>
                    </div>
                </div>
                :
                <div>
                    <h4>Image de gauche en mobile <br/><small>Taille recommandé: 900*1013</small></h4>
                    <div className='image-container'>
                    {props.images[3].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",3,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,3)} src={props.images[3].imagePreviewUrl} id={props.images[3].id}/>
                    </div>
                </div>
                }
                <h4>Les liens</h4>
                <p className="mt-2">{props.getArraysize(props.banerSlides,'block-2')} lien(s)</p>
                <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-2" ? (
                    <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                </ul>
                <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-2")}>Ajouter un lien</PrimaryButton>
                <h4>Image de droite <br/><small>Taille recommandé: 1280*1323</small></h4>
                <div className='image-container'>
                    {props.images[4].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",4,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,4)} src={props.images[4].imagePreviewUrl} id={props.images[4].id}/>
                </div>
                <h4>Les liens</h4>
                <p className="mt-2">{props.getArraysize(props.banerSlides,'block-3')} lien(s)</p>
                <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-3" ? (
                    <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                </ul>
                <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-3")}>Ajouter un lien</PrimaryButton>
                </div>
            }
            scroll={() => props.handleClickScroll("second-block-lookbook")}
            />
            <AdminAccordion
            title='Section image - texte'
            ref={props.accordionRefs[9]}
            content={
                <div className="block">
                <div className="border-bottom-white pb-4">
                    <h4>Image <br/><small>Taille recommandé: 1920*1280</small></h4>
                    <div className='image-container'>
                    {props.images[5].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",5,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,5)} src={props.images[5].imagePreviewUrl} id={props.images[5].id}/>
                    </div>
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-4')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-4" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-4")}>Ajouter un lien</PrimaryButton>
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Titre</h4>
                    <p>Texte</p>
                    <PrimaryInput value={props.texts[1].text} id={props.texts[1].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(1,true)}
                </div>
                <div className="mt-4">
                    <h4>Paragraphe</h4>
                    <p>Texte</p>
                    <PrimaryTextarea value={props.texts[2].text} id={props.texts[2].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(2,true)}
                </div>
                </div>
            }
            scroll={() => props.handleClickScroll("image-texte-lookbook")}
            />
            <AdminAccordion
            title='Section 3 images'
            ref={props.accordionRefs[10]}
            content={
                <div className="block">
                <div>
                    {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                    <div>
                    <h4>Image de gauche <br/><small>Taille recommandé: 1280*1920</small></h4>
                    <div className='image-container'>
                        {props.images[6].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",6,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,6)} src={props.images[6].imagePreviewUrl} id={props.images[6].id}/>
                    </div>
                    </div>
                    :
                    <div>
                    <h4>Image de gauche en mobile <br/><small>Taille recommandé: 900*1260</small></h4>
                    <div className='image-container'>
                        {props.images[7].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",7,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,7)} src={props.images[7].imagePreviewUrl} id={props.images[7].id}/>
                    </div>
                    </div>
                    }
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-5')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-5" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-5")}>Ajouter un lien</PrimaryButton>
                    {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                    <div>
                    <h4>Image du milieu <br/><small>Taille recommandé: 1280*1920</small></h4>
                    <div className='image-container'>
                        {props.images[8].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",8,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,8)} src={props.images[8].imagePreviewUrl} id={props.images[8].id}/>
                    </div>
                    </div> : 
                    <div>
                    <h4>Image du milieu en mobile <br/><small>Taille recommandé: 900*1260</small></h4>
                    <div className='image-container'>
                        {props.images[2].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",9,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,9)} src={props.images[9].imagePreviewUrl} id={props.images[9].id}/>
                    </div>
                    </div>
                    }
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-6')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-6" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-6")}>Ajouter un lien</PrimaryButton>
                    {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                    <div>
                    <h4>Image de droite <br/><small>Taille recommandé: 1280*1920</small></h4>
                    <div className='image-container'>
                        {props.images[2].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",10,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,10)} src={props.images[10].imagePreviewUrl} id={props.images[10].id}/>
                    </div>
                    </div>
                    :
                    <div>
                    <h4>Image de droite en mobile <br/><small>Taille recommandé: 900*1260</small></h4>
                    <div className='image-container'>
                        {props.images[11].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",11,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,11)} src={props.images[11].imagePreviewUrl} id={props.images[11].id}/>
                    </div>
                    </div>
                    }
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-7')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-7" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-7")}>Ajouter un lien</PrimaryButton>
                </div>
                </div>
            }
            scroll={() => props.handleClickScroll("fourth-block-lookbook")}
            />
            <AdminAccordion
            title='Section 2 images'
            ref={props.accordionRefs[11]}
            content={
                <div className="block">
                <h4>Image de gauche <br/><small>Taille recommandé: 1280*1747</small></h4>
                <div className='image-container'>
                    {props.images[12].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",12,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,12)} src={props.images[12].imagePreviewUrl} id={props.images[12].id}/>
                </div>
                <h4>Les liens</h4>
                <p className="mt-2">{props.getArraysize(props.banerSlides,'block-8')} lien(s)</p>
                <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-8" ? (
                    <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                </ul>
                <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-8")}>Ajouter un lien</PrimaryButton>
                {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                <div>
                    <h4>Image de droite <br/><small>Taille recommandé: 1280*658</small></h4>
                    <div className='image-container'>
                    {props.images[13].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",13,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,13)} src={props.images[13].imagePreviewUrl} id={props.images[13].id}/>
                    </div>
                </div>
                :
                <div>
                    <h4>Image de droite en mobile <br/><small>Taille recommandé: 903*612</small></h4>
                    <div className='image-container'>
                    {props.images[14].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",14,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,14)} src={props.images[14].imagePreviewUrl} id={props.images[14].id}/>
                    </div>
                </div>
                }
                <h4>Les liens</h4>
                <p className="mt-2">{props.getArraysize(props.banerSlides,'block-9')} lien(s)</p>
                <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-9" ? (
                    <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                </ul>
                <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-9")}>Ajouter un lien</PrimaryButton>
                </div>
            }
            scroll={() => props.handleClickScroll("fifth-block-lookbook")}
            />
            <AdminAccordion
            title='Section 3 images'
            ref={props.accordionRefs[12]}
            content={
                <div className="block">
                {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                    <div>
                    <h4>Image de gauche <br/><small>Taille recommandé: 1280*1000</small></h4>
                    <div className='image-container'>
                        {props.images[15].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",15,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,15)} src={props.images[15].imagePreviewUrl} id={props.images[15].id}/>
                    </div>
                    </div>
                    :
                    <div>
                    <h4>Image de gauche en mobile <br/><small>Taille recommandé: 900*1080</small></h4>
                    <div className='image-container'>
                        {props.images[16].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",16,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,16)} src={props.images[16].imagePreviewUrl} id={props.images[16].id}/>
                    </div>
                    </div>
                    }
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-10')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-10" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-10")}>Ajouter un lien</PrimaryButton>
                    {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                    <div>
                    <h4>Image du milieu <br/><small>Taille recommandé: 1280*1000</small></h4>
                    <div className='image-container'>
                        {props.images[17].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",17,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,17)} src={props.images[17].imagePreviewUrl} id={props.images[17].id}/>
                    </div>
                    </div> : 
                    <div>
                    <h4>Image du milieu en mobile <br/><small>Taille recommandé: 900*1080</small></h4>
                    <div className='image-container'>
                        {props.images[18].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",18,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,18)} src={props.images[18].imagePreviewUrl} id={props.images[18].id}/>
                    </div>
                    </div>
                    }
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-11')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-11" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-11")}>Ajouter un lien</PrimaryButton>
                    {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                    <div>
                    <h4>Image de droite <br/><small>Taille recommandé: 1280*1000</small></h4>
                    <div className='image-container'>
                        {props.images[19].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",19,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,19)} src={props.images[19].imagePreviewUrl} id={props.images[19].id}/>
                    </div>
                    </div>
                    :
                    <div>
                    <h4>Image de droite en mobile <br/><small>Taille recommandé: 900*1080</small></h4>
                    <div className='image-container'>
                        {props.images[20].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",20,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,20)} src={props.images[20].imagePreviewUrl} id={props.images[20].id}/>
                    </div>
                    </div>
                    }
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-12')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-12" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-12")}>Ajouter un lien</PrimaryButton>
                </div>
            }
            scroll={() => props.handleClickScroll("sixth-block-lookbook")}
            />
            <AdminAccordion
            title='Section texte - image'
            ref={props.accordionRefs[13]}
            content={
                <div className="block">
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Titre</h4>
                    <p>Texte</p>
                    <PrimaryInput value={props.texts[3].text} id={props.texts[3].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(3,true)}
                </div>
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Paragraphe</h4>
                    <p>Texte</p>
                    <PrimaryTextarea value={props.texts[4].text} id={props.texts[4].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(4,true)}
                </div>
                <div className="pb-4">
                    <h4>Image <br/><small>Taille recommandé: 1280*854</small></h4>
                    <div className='image-container'>
                    {props.images[21].file ?
                    <PrimaryButton onClick={() => props.togglePopup("image-cropper",21,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                    :null
                    } 
                    <ImgUpload onChange={(e) => props.photoUpload(e,21)} src={props.images[21].imagePreviewUrl} id={props.images[21].id}/>
                    </div>
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-13')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-13" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-13")}>Ajouter un lien</PrimaryButton>
                </div>
                </div>
            }
            scroll={() => props.handleClickScroll("texte-image-lookbook")}
            />
            <AdminAccordion
            title='Section 3 images'
            ref={props.accordionRefs[14]}
            content={
                <div className="block">
                {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                    <div>
                    <h4>Image de gauche <br/><small>Taille recommandé: 1280*1920</small></h4>
                    <div className='image-container'>
                        {props.images[22].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",22,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,22)} src={props.images[22].imagePreviewUrl} id={props.images[22].id}/>
                    </div>
                    </div>
                    :
                    <div>
                    <h4>Image de gauche en mobile <br/><small>Taille recommandé: 920*1280</small></h4>
                    <div className='image-container'>
                        {props.images[23].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",23,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,23)} src={props.images[23].imagePreviewUrl} id={props.images[23].id}/>
                    </div>
                    </div>
                    }
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-14')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-14" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-14")}>Ajouter un lien</PrimaryButton>
                    {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                    <div>
                    <h4>Image du milieu <br/><small>Taille recommandé: 1280*1920</small></h4>
                    <div className='image-container'>
                        {props.images[24].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",24,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,24)} src={props.images[24].imagePreviewUrl} id={props.images[24].id}/>
                    </div>
                    </div> : 
                    <div>
                    <h4>Image du milieu en mobile <br/><small>Taille recommandé: 920*1280</small></h4>
                    <div className='image-container'>
                        {props.images[25].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",25,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,25)} src={props.images[25].imagePreviewUrl} id={props.images[25].id}/>
                    </div>
                    </div>
                    }
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-15')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-15" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-15")}>Ajouter un lien</PrimaryButton>
                    {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                    <div>
                    <h4>Image de droite <br/><small>Taille recommandé: 1280*1000</small></h4>
                    <div className='image-container'>
                        {props.images[26].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",26,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,26)} src={props.images[26].imagePreviewUrl} id={props.images[26].id}/>
                    </div>
                    </div>
                    :
                    <div>
                    <h4>Image de droite en mobile <br/><small>Taille recommandé: 900*1280</small></h4>
                    <div className='image-container'>
                        {props.images[27].file ?
                        <PrimaryButton onClick={() => props.togglePopup("image-cropper",27,'images')}><i className='fa fa-edit'></i></PrimaryButton>
                        :null
                        } 
                        <ImgUpload onChange={(e) => props.photoUpload(e,27)} src={props.images[27].imagePreviewUrl} id={props.images[27].id}/>
                    </div>
                    </div>
                    }
                    <h4>Les liens</h4>
                    <p className="mt-2">{props.getArraysize(props.banerSlides,'block-16')} lien(s)</p>
                    <ul>
                    {props.banerSlides.map(objet=> objet.type == "block-16" ? (
                        <Objet details={objet} id={"link-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ):null)}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"block-16")}>Ajouter un lien</PrimaryButton>
                </div>
            }
            scroll={() => props.handleClickScroll("eighth-block-lookbook")}
            />
        </div>
    );
}
export default AdminLookBook;