import React ,{Component} from "react";
class Select extends Component{
    constructor(props) {
        super(props)
        this.state = {
          selectedOption: props.selectedOption || props.defaultOption,
          showDropdown: false
        }
    }
    toggleDropdown = () => {
        this.setState(prevState => {
          return { showDropdown: !prevState.showDropdown }
        })
    }
    setSelectedOption = event => {
        const selectedOption = event.target.getAttribute('value');
        this.setState({ selectedOption, showDropdown: false });
        const { onChange,id,type } = this.props;
        if (onChange)
          onChange(selectedOption,id,type)
    }
    render(){
        const { showDropdown, selectedOption } = this.state
        const { options } = this.props
        const optionKeys = Object.keys(options)
        let styles = {
            icon: {
              color: '#fff',
              fontSize: 15,
              transform: 'none',
              transition: 'all 0.1s ease-in'
            },
            listItem: {
              background: '#FFFFFF',
              alignItems: 'center',
              borderBottom: '2px solid #EEEEEE',
              cursor: 'pointer',
              fontSize: '14px',
              paddingBottom: 8,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 8,
              icon: {
                color: '#31D0EC',
                fontSize: 14
              }
            },
            selectedOption: {
              alignItems: 'center',
              background: '#01b9ff',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: "0.55rem",
              paddingLeft: "0.6rem",
              paddingRight: "0.6rem",
              paddingTop: "0.55rem",
              marginBottom: -3
            },
            selectedOptionLink: {
              fontSize: 14
            }
        }
        return(
        <div className="select-dropdown" tabIndex={1}>
            <div style={styles.selectedOption} onClick={this.toggleDropdown}>
                <div style={styles.selectedOptionLink}>{options[selectedOption]}</div>
                <i className={`fa fa-chevron-${showDropdown ? 'up' : 'down'}`} style={styles.icon} />
            </div>
            <ul className={`${showDropdown ? 'show' : ''}`}>
                {
                optionKeys.map((optionKey, index) => (
                    <li style={styles.listItem} key={index} value={optionKey} onClick={this.setSelectedOption}>
                    {options[optionKey]}
                    </li>
                ))
                }
            </ul>
        </div>
        )
    }
}
export default Select