import React from 'react';
import ColorPicker from "../ColorPicker";
import AdminAccordion from "../AdminAccordion";
import InputNumber from "../InputNumber";
import Redirection from "../Redirection";
import ImgUpload from '../ImgUpload';
import {PrimaryButton} from "../Button.style";
import { PrimaryInput,PrimaryTextarea } from '../Input.style';
function AdminHistoire(props) {
    return(
        <div>
            <AdminAccordion
            title='Banière'
            ref={props.accordionRefs[36]}
            content={
                <>
                    <div className="block border-bottom-white">
                        <h4>Banière <i className="fa fa-info-circle" aria-hidden="true" data-tooltip={`unité de mesure en "em" basé sur la taille de l'élément parent, 1em correspond à 14px, 2em à 28px etc ...`}></i></h4>
                        <p>{props.windowSize=="mobile-view" ?"Hauteur de la banière en mobile" : "Hauteur de la banière"}</p>
                        <InputNumber changeNumber={(e) => props.changeNumber(e,props.texts[0].id, props.windowSize=="mobile-view" ? "sizeMobile" : "size" )} incr={() => props.incrementSpeed(props.texts[0].id,props.windowSize=="mobile-view" ? "sizeMobile" : 'size',null,200)} decr={() => props.decrementSpeed(props.texts[0].id,props.windowSize=="mobile-view" ? "sizeMobile" : 'size',null)} stop={props.stop} width={props.windowSize=="mobile-view" ? props.texts[0].sizeMobile : props.texts[0].size}/>
                        <p className='mt-4'>Marge</p>
                        {props.windowSize=="mobile-view" ? props.getMarginSelector(1) : props.getMarginSelector(0)}
                    </div>
                    <div className="block">
                        <h4>Logo</h4>
                        <div className='image-container'>
                            {props.images[0].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",0,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                            <ImgUpload onChange={(e) => props.photoUpload(e,0)} src={props.images[0].imagePreviewUrl} id={props.images[0].id}/>
                        </div>
                    </div>
                    <div className='block'>
                        <div className="pb-4">
                            <h4>Les textes de l'entête</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[1].text} placeholder="titre de l'entête" id={props.texts[1].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(1)}
                            <p className="pt-4">Alignement horizontal du texte</p>
                            {props.getAlignSelector("story-baner",1)}
                        </div>
                    </div>
                </>
            }
            scroll={() => props.handleClickScroll("banner")}
            />
            <AdminAccordion
            title='Section Image-Texte'
            ref={props.accordionRefs[37]}
            content={
                <>
                    <div className="block border-bottom-white">
                        <h4>Première colonne</h4>
                        <p><strong>Image</strong></p>
                        <div className='image-container'>
                            {props.images[1].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",1,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                            <ImgUpload onChange={(e) => props.photoUpload(e,1)} src={props.images[1].imagePreviewUrl} id={props.images[1].id}/>
                        </div>
                    </div>
                    <div className='block'>
                        <div className="border-bottom-white pb-4">
                            <h4>Deuxieme colonne</h4>
                            <p>Image</p>
                            <div className='image-container mb-4'>
                                <ImgUpload onChange={(e) => props.photoUpload(e,4)} src={props.images[4].imagePreviewUrl} id={props.images[4].id}/>
                            </div>
                            <p>Texte</p>
                            <PrimaryInput value={props.texts[2].text} placeholder="titre de la section" id={props.texts[2].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(2)}
                            <p className="pt-4">Alignement horizontal du texte</p>
                            {props.getAlignSelector("first-row-title",2)}
                        </div>
                        <div className="border-bottom-white pb-4">
                            <p className='mt-4'>Texte</p>
                            <PrimaryTextarea value={props.texts[3].text} placeholder="texte de la section" id={props.texts[3].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(3)}
                            <p className="pt-4">Alignement horizontal du texte</p>
                            {props.getAlignSelector("first-row-text",3)}
                        </div>
                        <div className="border-bottom-white py-4">
                            <Redirection id={props.texts[4].id} action={props.changeTitle} name="redirection" value={props.texts[4].redirection}/>
                            <p className='mt-4'>Bouton</p>
                            <PrimaryInput value={props.texts[4].text} placeholder="texte de la section" id={props.texts[4].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(4)}
                            <p className="pt-4">Alignement horizontal du bouton</p>
                            {props.getAlignSelector("first-row-btn",4)}
                            <p className='mt-4'>Couleur de fond du bouton</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[4]} type="background"/>
                            <p className='mt-4'>Marge du bouton</p>
                            {props.getMarginSelector(4)}
                        </div>
                    </div>
                </>
            }
            scroll={() => props.handleClickScroll("header")}
            />
            <AdminAccordion
            title='Section Texte-Image'
            ref={props.accordionRefs[38]}
            content={
                <>
                    <div className="block border-bottom-white">
                        <div className="border-bottom-white pb-4">
                            <h4>Première colonne</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[5].text} placeholder="titre de la section" id={props.texts[5].id} onChange={props.changeTitle}/>
                        </div>
                        <div className="border-bottom-white pb-4">
                            <p className='mt-4'>Texte</p>
                            <PrimaryTextarea value={props.texts[6].text} placeholder="texte de la section" id={props.texts[6].id} onChange={props.changeTitle}/>
                        </div>
                    </div>
                    <div className='block'>
                        <h4>Deuxieme colonne</h4>
                        <h4>Image</h4>
                        <div className='image-container'>
                            {props.images[2].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",2,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                            <ImgUpload onChange={(e) => props.photoUpload(e,2)} src={props.images[2].imagePreviewUrl} id={props.images[2].id}/>
                        </div>
                    </div>
                </>
            }
            scroll={() => props.handleClickScroll("header")}
            />
            <AdminAccordion
            title='Section Image-Texte'
            ref={props.accordionRefs[39]}
            content={
                <>
                    <div className="block border-bottom-white">
                        <h4>Première colonne</h4>
                        <h4>Image</h4>
                        <div className='image-container'>
                            {props.images[3].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",3,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                            <ImgUpload onChange={(e) => props.photoUpload(e,3)} src={props.images[3].imagePreviewUrl} id={props.images[3].id}/>
                        </div>
                    </div>
                    <div className='block'>
                        <div className="border-bottom-white pb-4">
                            <h4>Deuxieme colonne</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[7].text} placeholder="titre de la section" id={props.texts[7].id} onChange={props.changeTitle}/>
                        </div>
                        <div className="border-bottom-white pb-4">
                            <p className='mt-4'>Texte<br/>
                                <small>Pour mettre un texte en gras il suffit de le mettre entre deux guillemets droits"" . ex: Mon "Slim" est beau ...</small>
                            </p>
                            <PrimaryTextarea value={props.texts[8].text} placeholder="texte de la section" id={props.texts[8].id} onChange={props.changeTitle}/>
                        </div>
                        <div>
                            <p className='mt-4'>Bouton 1</p>
                            <Redirection id={props.texts[9].id} action={props.changeTitle} name="redirection" value={props.texts[9].redirection}/>
                            <p className='mt-4'>Texte du bouton 1</p>
                            <PrimaryInput value={props.texts[9].text} placeholder="texte de la section" id={props.texts[9].id} onChange={props.changeTitle}/>
                            <p className='mt-4'>Bouton 2</p>
                            <Redirection id={props.texts[10].id} action={props.changeTitle} name="redirection" value={props.texts[10].redirection}/>
                            <p className='mt-4'>Texte du bouton 2</p>
                            <PrimaryInput value={props.texts[10].text} placeholder="texte de la section" id={props.texts[10].id} onChange={props.changeTitle}/>
                        </div>
                    </div>
                </>
            }
            scroll={() => props.handleClickScroll("header")}
            />
        </div>
        );
    }
export default AdminHistoire;