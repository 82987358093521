import React, { useState} from 'react';
import lookbook from "../assets/img/lookbook.jpg";
import {useNavigate} from 'react-router-dom';
import TutorialDataService from "../services/tutorial.service";
import {PrimaryButton} from "../Components/Button.style";
import { format } from 'date-fns';
import Popup from "../Components/Popup";
import Spinner from "../Components/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
function Template(props){
    const navigate = useNavigate();
    const   [size, setSize] = useState(2),
            [isOpen, setIsOpen] = useState(Array(props.allPage.length).fill(false)),
            [duplicateOpen, setDuplicateOpen] = useState(false),
            [updateStatus, setUpdateStatus] = useState(null),
            [percentageUpload, setPercentageUpload] = useState(0);
    const setOpen = (index, value) => {
        const updatedOpen = [...isOpen];
        updatedOpen[index] = value;
        setIsOpen(updatedOpen);
    };
    const navigateTo = (id) =>{
        props.resetState();
        props.setId(id);
        navigate('/edit/'+id);
    }
    function getFilename(file){
        const linkParts = file.split("/");
        const filename = linkParts[linkParts.length - 1];
        return filename;
    }
    const duplicate = async (title, content, contentCard, contentImage, contentText, illustration) => {
        setDuplicateOpen(true);
        //faire une copie des images
        setPercentageUpload(0);
        const lengthToCopy = contentImage.length;
        const copiedContentImage = await Promise.all(contentImage.map(async (image) => {
            if (image.imagePreviewUrl.includes("/app/public/")) {
                try {
                    const filename = getFilename(image.imagePreviewUrl);
                    const response = await TutorialDataService.copyImage(filename);
                    setUpdateStatus('copie de l\'image: '+filename);
                    console.log(response.data.message);
                    const copiedImage = { ...image };
                    copiedImage.imagePreviewUrl = copiedImage.imagePreviewUrl.replace(new RegExp(filename, 'g'), "copy-" + filename);
                    setPercentageUpload(percentageUpload + (100 / lengthToCopy));
                    return copiedImage;
                } catch (error) {
                    console.log("Erreur lors de la copie de l'image :", error);
                }
            }
            return image;
        }));
        // Faire une copie de la capture d'écran
        if (illustration.includes("/app/public/")) {
            try{
                setPercentageUpload(0);
                setUpdateStatus('copie de la capture d\'écran');
                const filename = getFilename(illustration);
                illustration = illustration.replace(new RegExp(filename, 'g'), "copy-" + filename);
                await TutorialDataService.copyImage(filename);
                setPercentageUpload(100);
            } catch{
                console.log("Erreur lors de la copie de la capture d'écran!");
            }
        }
        // Dupliquer
        const data = {
            title: title,
            content: content,
            contentCard: contentCard,
            contentImage: copiedContentImage,
            contentText: contentText,
            illustration: illustration
        };
        try{
            setPercentageUpload(0);
            setUpdateStatus('Création de la nouvelle page ...');
            const response = await TutorialDataService.create(data);
            setSize(props.allPage.length + 1);
            setIsOpen(Array(props.allPage.length + 1).fill(false));
            setPercentageUpload(100);
            setUpdateStatus(null);
            setDuplicateOpen(false);
            props.duplicatePage(response.data.data)
        } catch{
            alert('Erreur lors de la duplication! Veuillez recommencer plus tard')
        }
    };
    const deleteTuto = async (id, images, illustration) => {
        try {
            // Supprimer les images da la page du serveur
            setPercentageUpload(0);
            const lengthToDelete = images.length;
            for (const image of images) {
                if (image.imagePreviewUrl.includes("/app/public/")) {
                    const filename = getFilename(image.imagePreviewUrl);
                    setUpdateStatus('suppression de l\'image: '+filename);
                    await TutorialDataService.deleteImage(filename);
                    setPercentageUpload(percentageUpload + (100 / lengthToDelete));
                }
            }
            // Supprimer la capture d'écran
            if (illustration.includes("/app/public/")) {
                const filename = getFilename(illustration);
                setPercentageUpload(0);
                setUpdateStatus('suppression de capture d\'ecran: '+filename);
                await TutorialDataService.deleteImage(filename);
                setPercentageUpload(100);
            }
        }catch{
            console.log("erreur de la suppression des images")
        }
        try{
            // Supprimer l'objet
            setPercentageUpload(0);
            setUpdateStatus('suppression de la page');
            await TutorialDataService.delete(id);
            setSize(props.allPage.length - 1);
            setPercentageUpload(100);
            setUpdateStatus(null);
            setIsOpen(Array(props.allPage.length - 1).fill(false));
            props.deletePage(id);
        } catch (error) {
            console.log('Erreur lors de la suppression! Veuillez recommencer plus tard')
        }
    };
    return (
        <div className='templates'>
            <div className="container">
                <h1 className='ttr'>Choisir {props.poppinsView ? 'le pop-in' : 'la page'} à modifier</h1>
                {props.allPage.length <= 0 ?
                    <Spinner/>
                    :
                    <div className='row'>
                        {props.allPage.sort((a, b) => {
                        if (!a.createdAt && b.createdAt) {
                            return -1; // a vient en premier
                        } else if (a.createdAt && !b.createdAt) {
                            return 1; // b vient en premier
                        } else {
                            return 0; // l'ordre n'importe pas
                        }
                        }).filter(page =>{
                            if(props.poppinsView) {
                                return page.title == 'poppins'||page.title == 'popup';
                            } else {
                                return page.title !== 'poppins'&&page.title !== 'popup';
                            }
                        }).map((objet, index) => 
                        <div className='col-lg-3 col-md-4 col-sm-6' key={objet.id}>
                            <div className="card">
                                <div className='card-background'>
                                    <LazyLoadImage 
                                        width={'100%'} 
                                        height={'auto'} 
                                        src={objet.illustration ? objet.illustration : lookbook}
                                        placeholderSrc={objet.illustration&&objet.illustration.includes('/app/public/') ? objet.illustration.replace('/app/public/', '/app/public/placeholder/') : null}
                                    />
                                </div>
                                <div className="card-content">
                                    <h3 className="card-heading">{objet.createdAt ? "Copie de la page " + objet.title + " du " + format(new Date(objet.createdAt), 'dd-MM-yyyy à hh:mm') : "page " + objet.title}</h3>
                                </div>
                                <div className="button-action">
                                    <PrimaryButton  onClick={() => navigateTo(objet.id)}><i className='fa fa-edit'></i> Modifier</PrimaryButton>
                                    <PrimaryButton  onClick={() => duplicate(objet.title, objet.content, objet.contentCard, objet.contentImage, objet.contentText, objet.illustration)}><i className='fa fa-clone'></i> Dupliquer</PrimaryButton>
                                    {objet.createdAt&&(
                                    <PrimaryButton onClick={() => setOpen(index, !isOpen[index])}><i className='fa fa-trash'></i></PrimaryButton>
                                    )}
                                </div>
                                {isOpen[index] && (
                                <Popup
                                    activePopup="suppression"
                                    header="Voulez vous supprimer cette page?"
                                    toggle={() => setOpen(index, !isOpen[index])}
                                    content={
                                        <div>
                                            {updateStatus ? 
                                            <div className='text-center'>
                                                <p className="text-white"><small>{updateStatus} ({percentageUpload+'%'})</small></p>
                                                <div className='progress-content'>
                                                    <div className='progress-bar' style={{width: `${percentageUpload}%`}}></div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <p className='text-white'>La suppression est définitive, vous ne pouvez plus recupérér les images et les textes, êtes-vous sur de continuer?</p>
                                                <div className='d-flex justify-content-between mt-5'>
                                                    <PrimaryButton onClick={() => deleteTuto(objet.id,objet.contentImage,objet.illustration)}><i className='fa fa-check'></i> Confirmer</PrimaryButton>
                                                    <PrimaryButton onClick={() => setOpen(index, !isOpen[index])}><i className='fa fa-times'></i> Annuler</PrimaryButton>
                                                </div>   
                                            </div>
                                            }
                                        </div>
                                    }
                                />)}
                            </div>
                        </div>
                        )}
                    </div>
                }
            </div>
            {duplicateOpen && (
            <Popup
                activePopup="duplication"
                header="Duplication de la page"
                toggle={() => setDuplicateOpen(false)}
                content={
                    <div className='text-center'>
                        <p className="text-white"><small>{updateStatus} ({percentageUpload+'%'})</small></p>
                        <div className='progress-content'>
                            <div className='progress-bar' style={{width: `${percentageUpload}%`}}></div>
                        </div>
                    </div>
                }
            />)}
        </div>
    )
}
export default Template;