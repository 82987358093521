import React from 'react';
import AdminAccordion from "../AdminAccordion";
import ImgUpload from '../ImgUpload';
import ColorPicker from "../ColorPicker";
import Objet from "../Objet";
import {PrimaryButton} from "../Button.style";
import { PrimaryInput,PrimaryTextarea } from '../Input.style';
import Redirection from "../Redirection";
import DraggableList from "../DraggableList";
function AdminRecrutement(props) {
    return(
        <div>
            <AdminAccordion
                title='Général'
                ref={props.accordionRefs[30]}
                content={
                    <div className="block">
                        <h4>Bouton</h4>
                        <Redirection id={props.texts[20].id} action={props.changeTitle} name="redirection" value={props.texts[20].redirection}/>
                        <p>Texte du bouton</p>
                        <PrimaryInput placeholder="ex:je découvre, je m'inscris,..." value={props.texts[20].text} id={props.texts[20].id} onChange={props.changeTitle}/>
                        {props.getTextStyle(20)}
                        <p className='mt-4'>Couleur de fond du bouton</p>
                        <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[20]} type="background"/>
                        <p className='mt-4'>Marge du bouton</p>
                        {props.getMarginSelector(20)}
                    </div>
                }
                scroll={()=>{}}
            />
            <AdminAccordion
                title='Banière'
                ref={props.accordionRefs[22]}
                content={
                    <div className="block">
                        <div className="border-bottom-white pb-4">
                            <h4>Votre logo</h4>
                            <div className='image-container'>
                                <ImgUpload onChange={(e) => props.photoUpload(e,0)} src={props.images[0].imagePreviewUrl} id={props.images[0].id}/>
                            </div>
                        </div>
                        <div className="border-bottom-white pb-4">
                            <h4>L'image de fond<br/><small>Taille recommandé: 1280*590</small></h4>
                            <div className='image-container'>
                                {props.images[1].file&&(<PrimaryButton onClick={() => props.togglePopup("image-cropper",1,'images')}><i className='fa fa-edit'></i></PrimaryButton>)} 
                                <ImgUpload onChange={(e) => props.photoUpload(e,1)} src={props.images[1].imagePreviewUrl} id={props.images[1].id}/>
                            </div>
                        </div>
                        <div className="border-bottom-white pb-4">
                            <h4>Les textes</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[0].text} placeholder="titre de la banière" id={props.texts[0].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(0)}
                            <p className="mt-4">Alignement du texte</p>
                            {props.getAlignSelector("baner-title",0)}
                            <p className="mt-4">Sous-titre</p>
                            <PrimaryTextarea value={props.texts[1].text} placeholder="sous-titre de la banière" id={props.texts[1].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(1)}
                            <p className='mt-4'>Couleur de fond du sous-titre</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[1]} type="background"/>
                        </div>
                        <div className="border-bottom-white pb-4">
                            <h4 className='mt-4'>Le formulaire</h4>
                            <p className='mt-4'>Couleur de fond du formulaire</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[2]} type="background"/>
                            <p className='mt-4'>Marge du formulaire</p>
                            {props.getMarginSelector(2)}
                        </div>
                        <div className="border-bottom-white pb-4">
                            <h4 className='mt-4'>Style des champs de saisie</h4>
                            <p className='mt-4'>Couleur de fond</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[4]} type="background"/>
                            <p className='mt-4'>Marge</p>
                            {props.getMarginSelector(4)}
                        </div>
                        <div className="border-bottom-white pb-4">
                            <p className='mt-4'>Titre</p>
                            <PrimaryInput value={props.texts[2].text} placeholder="titre du formulaire" id={props.texts[2].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(2)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("form-title",2)}
                        </div>
                        <div className="border-bottom-white pb-4 mt-4">
                            <p>Sous-titre</p>
                            <PrimaryInput value={props.texts[3].text} placeholder="sous-titre du formulaire" id={props.texts[3].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(3)}
                            <p className='mt-4'>Couleur de fond du sous-titre</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[3]} type="background"/>
                        </div>
                        <div className="border-bottom-white pb-4 mt-4">
                            <p>Le paragraphe</p>
                            <PrimaryTextarea value={props.texts[4].text} placeholder="paragraphe" id={props.texts[4].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(4)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("form-para",4)}
                        </div>
                        <div className="pb-4 mt-4">
                            <p>Mentions légales</p>
                            <PrimaryTextarea value={props.texts[5].text} placeholder="mentions légales" id={props.texts[5].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(5)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("mentions-para",5)}
                            <div className='mt-4'>
                                <Redirection id={props.texts[6].id} action={props.changeTitle} name="redirection" value={props.texts[6].redirection}/>
                                <p>texte de l'url mentions légales</p>
                                <PrimaryInput value={props.texts[6].text} placeholder="URL mentions légales" id={props.texts[6].id} onChange={props.changeTitle}/>
                                {props.getTextStyle(6)}
                            </div>
                        </div>
                    </div>
                }
                scroll={() => props.handleClickScroll("top")}
            />
            <AdminAccordion
                title="Conseillère pour le fun"
                ref={props.accordionRefs[23]}
                content={
                    <div className="block mt-4">
                        <div className="border-bottom-white pb-4">
                            <h4>Première colonne</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[7].text} placeholder="titre" id={props.texts[7].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(7)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("conseillere-",7)}
                            <p className="mt-4">Sous-titre</p>
                            <PrimaryInput value={props.texts[8].text} placeholder="sous-titre" id={props.texts[8].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(8)}
                            <p className="mt-4">Sous-titre rose</p>
                            <PrimaryInput value={props.texts[9].text} placeholder="sous-titre" id={props.texts[9].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(9)}
                            <p className="mt-4">Le lien de la vidéo</p>
                            <PrimaryInput value={props.texts[10].text} placeholder="sous-titre" id={props.texts[10].id} onChange={props.changeTitle}/>
                        </div>
                        <div className="mt-4">
                            <h4>Deuxième colonne</h4>
                            <p>Titre</p>
                            <PrimaryTextarea value={props.texts[11].text} placeholder="titre" id={props.texts[11].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(11)}
                            <h4 className='mt-4'>Les avantages</h4>
                            <p className="mt-1">{props.getArraysize(props.banerSlides,'fun')} textes(s)</p>
                            <DraggableList
                                list={props.banerSlides}
                                idPrefix="fun-"
                                typeCard="fun"
                                typeToggle='edit-baner-slide'
                                onDelete={props.handleDeleteCard}
                                updateCardOrder={props.updateCardOrder}
                                toggle={props.togglePopup}
                            />
                            <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"fun")}>Ajouter un texte</PrimaryButton>
                        </div>
                    </div>
                }
                scroll={() => props.handleClickScroll("bloc-video")}
            />
            <AdminAccordion
                title="Sur les reseaux"
                ref={props.accordionRefs[24]}
                content={
                    <div className="block mt-4">
                        <p>Couleur de fond du bloc</p>
                        <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[13]} type="background"/>
                        <p className='mt-4'>Marge du contenu</p>
                        {props.getMarginSelector(13)}
                        <p className='mt-4'>Titre</p>
                        <PrimaryInput value={props.texts[13].text} placeholder="titre de la banière" id={props.texts[13].id} onChange={props.changeTitle}/>
                        {props.getTextStyle(13)}
                        <p className='mt-4'>Alignement du texte</p>
                        {props.getAlignSelector("reseaux",13)}
                        <h4 className='mt-4'>Les images</h4>
                        <p className="mt-2">{props.getArraysize(props.cardSlides,'reseaux')} carte(s)</p>
                        <DraggableList
                            list={props.cardSlides}
                            idPrefix="card-"
                            typeCard="reseaux"
                            typeToggle='edit-card'
                            onDelete={props.handleDeleteCard}
                            updateCardOrder={props.updateCardOrder}
                            toggle={props.togglePopup}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-card",null,"reseaux")}>Ajouter une image</PrimaryButton>
                    </div>
                }
                scroll={() => props.handleClickScroll("bloc-logos")}
            />
            <AdminAccordion
                title="Découvrir nos conseillères"
                ref={props.accordionRefs[25]}
                content={
                    <div className="block mt-4">
                        <div className="border-bottom-white pb-4">
                            <h4>Première colonne</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[14].text} placeholder="titre" id={props.texts[14].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(14)}
                            <p className="mt-4">Sous-titre</p>
                            <PrimaryInput value={props.texts[15].text} placeholder="sous-titre" id={props.texts[15].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(15)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("conseillere-sub",14)}
                        </div>
                        <div className="border-bottom-white pb-4">
                            <h4>Les slides</h4>
                            <p className="mt-1">{props.getArraysize(props.banerSlides,'iframe-slide')} slide(s)</p>
                            <ul>
                            {props.banerSlides.filter((objet) => objet.type === 'iframe-slide').map(objet => (
                                <Objet details={objet} id={"bandeau-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                            ))}
                            </ul>
                            <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"iframe-slide")}>Ajouter un slide</PrimaryButton>
                        </div>
                        <div className="pb-4">
                            <h4>Deuxième colonne</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[16].text} placeholder="titre" id={props.texts[16].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(16)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("conseillere-title",16)}
                            <p className="mt-4">Le texte <small>{'(utilisez "->" pour ajouter un élémént dans la liste)'}</small></p>
                            <PrimaryTextarea value={props.texts[17].text} placeholder="le texte" id={props.texts[17].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(17)}
                        </div>
                    </div>
                }
                scroll={() => props.handleClickScroll("bloc-videoSlider")}
            />
            <AdminAccordion
                title="Carousel d'images"
                ref={props.accordionRefs[26]}
                content={
                    <div className="block pt-4">
                        <p>Couleur de fond du bloc</p>
                        <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[12]} type="background"/>
                        <h4>Les carousels</h4>
                        <p className="mt-2">{props.getArraysize(props.cardSlides,'carousel')} carte(s)</p>
                        <DraggableList
                            list={props.cardSlides}
                            idPrefix="card-"
                            typeCard="carousel"
                            typeToggle='edit-card'
                            onDelete={props.handleDeleteCard}
                            updateCardOrder={props.updateCardOrder}
                            toggle={props.togglePopup}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-card",null,"carousel")}>Ajouter un carousel</PrimaryButton>
                    </div>
                }
                scroll={() => props.handleClickScroll("bloc-carrousel")}
            />
            <AdminAccordion
                title='Imagine le plaisir'
                ref={props.accordionRefs[27]}
                content={
                    <div className='block'>
                        <div className="border-bottom-white pb-4">
                            <h4>Les textes</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[18].text} placeholder="titre de la banière" id={props.texts[18].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(18)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("plaisir-title",18)}
                        </div>
                        <div className="border-bottom-white pb-4 mt-4">
                            <p>Sous-titre</p>
                            <PrimaryTextarea value={props.texts[19].text} placeholder="sous-titre de la banière" id={props.texts[19].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(19)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("plaisir-sub",19)}
                        </div>
                        <div className="border-bottom-white pb-4 mt-4">
                            <h4>Les cartes</h4>
                            <p>Style du titre des cartes</p>
                            {props.getTextStyle(12)}
                            <p className="mt-4">{props.getArraysize(props.cardSlides,'card-recrutement')} carte(s)</p>
                            <DraggableList
                                list={props.cardSlides}
                                idPrefix="card-"
                                typeCard="card-recrutement"
                                typeToggle='edit-card'
                                onDelete={props.handleDeleteCard}
                                updateCardOrder={props.updateCardOrder}
                                toggle={props.togglePopup}
                            />
                            <PrimaryButton onClick={() => props.togglePopup("add-card",null,"card-recrutement")}>Ajouter une carte</PrimaryButton>
                        </div>
                    </div>
                }
                scroll={() => props.handleClickScroll("bloc-illus")}
            />
            <AdminAccordion
                title='En savoir plus'
                ref={props.accordionRefs[28]}
                content={
                    <div className='block'>
                        <div className="border-bottom-white pb-4">
                        <h4>Les textes</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[21].text} placeholder="titre de la banière" id={props.texts[21].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(21)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("savoir-plus-title",21)}
                            <p className='mt-4'>Marge du titre(en %)</p>
                            {props.getMarginSelector(21)}
                        </div>
                        <div className="pb-4 mt-4">
                            <p>Sous-titre</p>
                            <PrimaryInput value={props.texts[22].text} placeholder="sous-titre de la banière" id={props.texts[22].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(22)}
                            <p className='mt-4'>Sous-titre en rose</p>
                            <PrimaryInput value={props.texts[23].text} placeholder="sous-titre de la banière" id={props.texts[23].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(23)}
                            <p className='mt-4'>Marge du texte(en %)</p>
                            {props.getMarginSelector(22)}
                        </div>
                        <div className="border-bottom-white pb-4 mt-4">
                            <h4>Les cartes</h4>
                            <p>couleur de fond du bloc texte</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[24]} type="background"/>
                            <p className='mt-4'>Marge du bloc texte</p>
                            {props.windowSize == "desktop-view" ? props.getMarginSelector(24) : props.getMarginSelector(25)}
                            <p className='mt-4'>style du titre des cartes</p>
                            {props.getTextStyle(24)}
                            <p className='mt-4'>style du texte des cartes</p>
                            {props.getTextStyle(25)}
                            <p className="mt-4">{props.getArraysize(props.cardSlides,'savoir-plus')} carte(s)</p>
                            <DraggableList
                                list={props.cardSlides}
                                idPrefix="card-"
                                typeCard="savoir-plus"
                                typeToggle='edit-card'
                                onDelete={props.handleDeleteCard}
                                updateCardOrder={props.updateCardOrder}
                                toggle={props.togglePopup}
                            />
                            <PrimaryButton onClick={() => props.togglePopup("add-card",null,"savoir-plus")}>Ajouter une carte</PrimaryButton>
                        </div>
                    </div>
                }
                scroll={() => props.handleClickScroll("bloc-2column")}
            />
            <AdminAccordion
                title='La presse'
                ref={props.accordionRefs[29]}
                content={
                    <div className='block'>
                        <div className="border-bottom-white pb-4">
                            <h4>Styles de la section</h4>
                            <p>couleur de fond</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[26]} type="background"/>
                            <p className='mt-4'>Marge du contenu</p>
                            {props.getMarginSelector(26)}
                        </div>
                        <div className="border-bottom-white pb-4">
                            <h4>Les textes</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[26].text} placeholder="titre de la section" id={props.texts[26].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(26)}
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("presse-title",26)}
                        </div>
                        <div className="pb-4">
                            <h4>Les cartes de presses</h4>
                            <p className="mt-4">{props.getArraysize(props.cardSlides,'card-slide')} carte(s)</p>
                            <DraggableList
                                list={props.cardSlides}
                                idPrefix="card-"
                                typeCard="card-slide"
                                typeToggle='edit-card'
                                onDelete={props.handleDeleteCard}
                                updateCardOrder={props.updateCardOrder}
                                toggle={props.togglePopup}
                            />
                            <PrimaryButton onClick={() => props.togglePopup("add-card",null,"card-slide")}>Ajouter une carte</PrimaryButton>
                        </div>
                    </div>
                }
                scroll={() => props.handleClickScroll("bloc-presse")}
            />
            <AdminAccordion
                title='Footer'
                ref={props.accordionRefs[31]}
                content={
                    <div className="block">
                        <div className="border-bottom-white pb-4">
                            <h4>Style général du footer</h4>
                            <p className='mt-4'>Couleur de fond du footer</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[27]} type="background"/>
                            <p className='mt-4'>Marge du footer</p>
                            {props.getMarginSelector(27)}
                        </div>
                        <div className="border-bottom-white pb-4">
                            <h4>Votre logo</h4>
                            <div className='image-container'>
                                <ImgUpload onChange={(e) => props.photoUpload(e,2)} src={props.images[2].imagePreviewUrl} id={props.images[2].id}/>
                            </div>
                        </div>
                        <div className="pb-4">
                            <h4>Les textes</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[27].text} placeholder="titre du footer" id={props.texts[27].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(27)}
                            <p className="mt-4">Alignement du titre</p>
                            {props.getAlignSelector("footer-title",27)}
                            <p className="mt-4">Le texte</p>
                            <PrimaryTextarea value={props.texts[28].text} placeholder="Texte du footer" id={props.texts[28].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(28)}
                            <p className="mt-4">Alignement du texte</p>
                            {props.getAlignSelector("footer-text",28)}
                        </div>
                    </div>
                }
                scroll={() => props.handleClickScroll("footer")}
            />
        </div>
    );
}
export default AdminRecrutement;