import React, { useState } from 'react';
function Accordion({ question, answer, mode, click}) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <li onClick={() => click()}>
            <div className={`${mode ? 'input-edit' : ''} faq-title ${isOpen ? '' : 'collapsed'}`} onClick={() => setIsOpen(!isOpen)}>{question}  <i>{'>'}</i></div>
            <div className={`collapse ${isOpen ? 'show' : ''}`}>
                <p>
                    {answer}
                </p>
            </div>
        </li>
    );
}
export default Accordion;