import React from "react";
import AutoHeightTextarea from "../Components/AutoHeightTextarea";
import AutoWidthInput from "../Components/AutoWidthInput";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Histoire = (props) => {
    const{mode,banerStyle,banerTextStyle,baner,op1,op2,op3,firstRowText,firstRowTitle,firstRowButton,firstRowSign,secondRowTitle,secondRowText,thirdRowTitle,thirdRowText,thirdRowFirstBtn,thirdRowSecondBtn,...rest} = props;
    return (
        <div className="front histoire">
            <style>
                {`
                .front.histoire{
                    font-size:14px
                }
                .front.histoire .text-right{
                    text-align:right
                }
                .front.histoire .banner {
                    width: 100%; 
                    height: ${banerStyle.size+'em'};
                    background-image: url(${baner});
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .front.histoire .banner-title {
                    margin-top:0;
                    padding:${banerStyle.mt+'em '+banerStyle.mr+'em '+banerStyle.mb+'em '+banerStyle.ml+'em'};
                    font-size:${banerTextStyle.size+'px'};
                    color:${banerTextStyle.color};
                    font-weight:${banerTextStyle.weight};
                    font-family:${banerTextStyle.font+',sans-serif'};
                    text-align:${banerTextStyle.align}
                }
                .front.histoire .mystory-button {
                    padding:${firstRowButton.mt+'px '+firstRowButton.mr+'px '+firstRowButton.mb+'px '+firstRowButton.ml+'px'};
                    font-size:${firstRowButton.size+'px'};
                    color:${firstRowButton.color};
                    font-weight:${firstRowButton.weight};
                    font-family:${firstRowButton.font+',sans-serif'};
                    background-color:${firstRowButton.background};
                    border-radius: 25px;
                    border: none;
                    text-align: center;
                    -webkit-appearance: button;
                    -moz-appearance: button;
                    appearance: button;
                    text-decoration: none;
                    margin-top:50px;
                }
                .front.histoire .mystory-button:hover {
                    color:white;
                    background-color: #1f1f23;
                    text-decoration:none; 
                    cursor:pointer;  
                }
                .front.histoire .text-content{
                    padding:0 16px;
                    padding-top:20px;
                }
                .front.histoire .text-content h2{
                    font-size:${firstRowTitle.size+'px'};
                    color:${firstRowTitle.color};
                    font-weight:${firstRowTitle.weight};
                    font-family:${firstRowTitle.font+',sans-serif'};
                    text-align:${firstRowTitle.align};
                    font-style: italic;
                    margin-bottom:3rem;
                }
                .front.histoire .text-content p{
                    font-size:${firstRowText.size+'px'};
                    color:${firstRowText.color};
                    font-weight:${firstRowText.weight};
                    font-family:${firstRowText.font+',sans-serif'};
                    text-align:${firstRowText.align};
                    white-space:break-spaces
                }
                .front.histoire .text-content p strong{font-weight:700;font-style:italic}
                .front.histoire .row{
                    margin-top:50px
                }
                @media(max-width:767px){
                    .front.histoire .banner {
                        height: ${banerStyle.sizeMobile+'em'};
                    }
                    .front.histoire .banner-title {
                        padding:${banerTextStyle.mt+'em '+banerTextStyle.mr+'em '+banerTextStyle.mb+'em '+banerTextStyle.ml+'em'};
                    }
                    .reverse{
                        flex-direction:column-reverse
                    }
                    .front.histoire .row:last-child .mystory-button{
                        display:block!important;
                        width:100%!important;
                    }
                    .front.histoire .row:last-child .mystory-button:last-child{
                        margin-top:16px
                    }
                }
                `}
                {mode&&`
                    .front{container-type:inline-size;}
                    .front.histoire .banner-title .input-edit{
                        font-size:${banerTextStyle.size+'px'};
                        color:${banerTextStyle.color};
                        font-weight:${banerTextStyle.weight};
                        font-family:${banerTextStyle.font+',sans-serif'};
                        text-align:${banerTextStyle.align}
                    }
                    .front.histoire .text-content h2 .input-edit{
                        font-size:${firstRowTitle.size+'px'};
                        color:${firstRowTitle.color};
                        font-weight:${firstRowTitle.weight};
                        font-family:${firstRowTitle.font+',sans-serif'};
                        text-align:${firstRowTitle.align};
                        font-style: italic;
                    }
                    .front.histoire .text-content p .input-edit{
                        font-size:${firstRowText.size+'px'};
                        color:${firstRowText.color};
                        font-weight:${firstRowText.weight};
                        font-family:${firstRowText.font+',sans-serif'};
                        text-align:${firstRowText.align};
                        white-space:break-spaces
                    }
                    .front.histoire .mystory-button .input-edit{
                        font-size:${firstRowButton.size+'px'};
                        color:${firstRowButton.color};
                        font-weight:${firstRowButton.weight};
                        font-family:${firstRowButton.font+',sans-serif'};
                        text-align:center;
                    }
                    @container(max-width:767px){
                        .front.histoire .banner {
                            height: ${banerStyle.sizeMobile+'em'};
                        }
                        .front.histoire .banner-title {
                            padding:${banerTextStyle.mt+'em '+banerTextStyle.mr+'em '+banerTextStyle.mb+'em '+banerTextStyle.ml+'em'};
                        }
                        .row > *{
                            width:100%
                        }
                        .reverse{
                            flex-direction:column-reverse
                        }
                        .front.histoire .row:last-child .mystory-button{
                            display:block!important;
                            width:100%!important;
                        }
                        .front.histoire .row:last-child .mystory-button:last-child{
                            margin-top:16px
                        }
                    }
                `}
            </style>
            {mode ? 
            <label htmlFor='photo-upload-0' className="label-edit banner" onClick={() => rest.focusAccordion(36,`label[for="photo-upload-0"]`)}>
                <h1 className="banner-title">{rest.windowSize&&rest.windowSize=="desktop-view" ? <AutoWidthInput id={banerTextStyle.id} className="input-edit" value={banerTextStyle.text} change={rest.changeTitle} onFocus={(event) => {event.stopPropagation();rest.focusAccordion(36,`#${CSS.escape('1')}`)}}/>:<AutoHeightTextarea id={banerTextStyle.id} className="input-edit" value={banerTextStyle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(36,`#${CSS.escape('1')}`)}/>}</h1>
            </label>
            :
            <div className="banner">
                <h1 className="banner-title">{banerTextStyle.text}</h1>
            </div>
            }
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center">
                        {mode ? <label htmlFor='photo-upload-1' className="label-edit" onClick={() => rest.focusAccordion(37,`label[for="photo-upload-1"]`)}><LazyLoadImage className="img-fluid" src={op1}/></label> : <img className="img-fluid" src={op1}/>}
                    </div>
                    <div className="col-md-6">
                        <div className="text-content">  
                            {mode ?<label htmlFor='photo-upload-4' className="label-edit d-inline-block mx-auto" onClick={() => rest.focusAccordion(37,`label[for="photo-upload-4"]`)}><img className="img-fluid" src={firstRowSign}/></label>:<img className="img-fluid" src={firstRowSign}/>}
                            <h2>{mode ? <AutoHeightTextarea id={firstRowTitle.id} className="input-edit" value={firstRowTitle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(37,`#${CSS.escape('2')}`)}/> :firstRowTitle.text}</h2>
                            <p>
                                {mode ? <AutoHeightTextarea id={firstRowText.id} className="input-edit" value={firstRowText.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(37,`#${CSS.escape('3')}`)}/> : firstRowText.text}
                            </p>
                            <div className={"text-"+firstRowButton.align}>
                                <a href={mode ? null : firstRowButton.redirection ? firstRowButton.redirection : "#"} className="mystory-button">{mode ? <AutoWidthInput id={firstRowButton.id} value={firstRowButton.text} change={rest.changeTitle} onClick={(event) => rest.focusAccordion(37,`#${CSS.escape('4')}`)}/>:firstRowButton.text}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row reverse">
                    <div className="col-md-6">
                        <div className="text-content">  
                            <h2>{mode ? <AutoHeightTextarea id={secondRowTitle.id} className="input-edit" value={secondRowTitle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(38,`#${CSS.escape('5')}`)}/> : secondRowTitle.text}</h2>
                            <p>
                                {mode ? <AutoHeightTextarea id={secondRowText.id} className="input-edit" value={secondRowText.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(38,`#${CSS.escape('6')}`)}/>:secondRowText.text}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        {mode ? <label htmlFor='photo-upload-2' className="label-edit" onClick={() => rest.focusAccordion(38,`label[for="photo-upload-2"]`)}><LazyLoadImage className="img-fluid" src={op2}/></label> : <img className="img-fluid" src={op2}/>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 text-center">
                        {mode ? <label htmlFor='photo-upload-3' className="label-edit" onClick={() => rest.focusAccordion(39,`label[for="photo-upload-3"]`)}><LazyLoadImage className="img-fluid" src={op3}/></label> : <img className="img-fluid" src={op3}/>}
                    </div>
                    <div className="col-md-6">
                        <div className="text-content">  
                            <h2>{mode ? <AutoHeightTextarea id={thirdRowTitle.id} className="input-edit" value={thirdRowTitle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(39,`#${CSS.escape('7')}`)}/>:thirdRowTitle.text}</h2>
                            <p className={mode?"label-edit":null}dangerouslySetInnerHTML={{ __html: thirdRowText.text.replace(/"([^"]+)"/g, '<strong>$1</strong>') }} onClick={() => rest.focusAccordion(39,`#${CSS.escape('8')}`)}></p>
                            <a href={mode ? null : thirdRowFirstBtn.redirection ? thirdRowFirstBtn.redirection : "#"} className="mystory-button" style={{width:"49%",marginRight:"2%"}}>{mode ? <AutoWidthInput id={thirdRowFirstBtn.id} value={thirdRowFirstBtn.text} change={rest.changeTitle} onClick={() => rest.focusAccordion(39,`#${CSS.escape('9')}`)}/> : thirdRowFirstBtn.text}</a>
                            <a href={mode ? null : thirdRowSecondBtn.redirection ? thirdRowSecondBtn.redirection : "#"} className="mystory-button" style={{width:"49%"}}>{mode ? <AutoWidthInput id={thirdRowSecondBtn.id} value={thirdRowSecondBtn.text} change={rest.changeTitle} onClick={() => rest.focusAccordion(39,`#${CSS.escape('10')}`)}/> : thirdRowSecondBtn.text}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Histoire;