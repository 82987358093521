import React from 'react';
import AdminAccordion from "../AdminAccordion";
import {useNavigate} from 'react-router-dom';
import ColorPicker from "../ColorPicker";
import ImgUpload from '../ImgUpload';
import Objet from "../Objet";
import Checkbox from "../Checkbox";
import {PrimaryButton} from "../Button.style";
import { PrimaryInput,PrimaryTextarea } from '../Input.style';
import Redirection from "../Redirection";
import DraggableList from "../DraggableList";
function AdminHomepage(props) {
    const navigate = useNavigate();
    const navigateTo = (id) =>{
        props.resetState();
        props.setId(id);
        navigate('/edit/'+id);
    }
    return (
        <div>
            <AdminAccordion
            title='Général'
            content={
                <div className="block">
                    <h4>Couleur de fond du site en général</h4>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[2]} type="background"/>
                    <h4>Marge du contenu général</h4>
                    {props.windowSize == "desktop-view" ? props.getMarginSelector(2):props.getMarginSelector(4)}
                </div>}
            scroll={() => props.handleClickScroll()}
            />
            <AdminAccordion
            title='Bandeau noir'
            ref={props.accordionRefs[0]}
            content={
                <div>
                <div className="block">
                    <h4>Choisissez la couleur du bandeau</h4>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[0]} type="background"/>
                    <h4>Marge du bandeau</h4>
                    {props.windowSize == "desktop-view" ? props.getMarginSelector(0) : props.getMarginSelector(7)}
                </div>
                <div className='block'>
                    <h4>Alignement du texte</h4>
                    {props.getAlignSelector("baner",0)}
                </div>
                <div className="block border-bottom-white">
                    <h4>Style du texte du bandeau</h4>
                    {props.getTextStyle(0)}
                    <h4>Les slides</h4>
                    <p className="mt-1">{props.getArraysize(props.banerSlides,'baner-slide')} slide(s)</p>
                    <ul>
                    
                    {props.banerSlides.filter((objet) => objet.type === 'baner-slide').map(objet => (
                        <Objet details={objet} id={"bandeau-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                    ))}
                    </ul>
                    <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"baner-slide")}>Ajouter un slide</PrimaryButton>
                </div>
                </div>
            }
            scroll={() => props.handleClickScroll("bandeau-noir")}
            />
            <AdminAccordion
            title='Bannière'
            ref={props.accordionRefs[1]}
            content={
                <>
                    <div className="block">
                        <Redirection id={props.texts[1].id} action={props.changeTitle} name="redirection" value={props.texts[1].redirection}/>
                        <h4>Fond de la banière <br/><small>Taille recommandé: 1280*658</small></h4>
                        <div className='image-container'>
                            {props.images[0].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",0,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                            <ImgUpload onChange={(e) => props.photoUpload(e,0)} src={props.images[0].imagePreviewUrl} id={props.images[0].id}/>
                        </div>
                    </div>
                    <div className="block border-bottom-white">
                        <h4>Alignement horizontal du contenu</h4>
                        {props.windowSize == "desktop-view" ? 
                        props.getAlignSelector("content",1) : props.getAlignSelector("content-mobile",6)}
                        <h4>Marge du contenu</h4>
                        {props.getMarginSelector(1)}
                    </div>
                    <div className='block'>
                        <Checkbox squareStyle={{backgroundColor:"#ef0909"}} text={"Mettre un poppins à la place"} labelStyle={{fontSize:13,color:"#fff",fontWeight:400}} id={"add-poppins-"+props.texts[1].id} action={() => props.activePoppin(props.texts[1].id)} checked={props.texts[1].usePoppins!==undefined?props.texts[1].usePoppins:false}/>
                        {!props.texts[1].usePoppins?<>
                        <div className="border-bottom-white pb-4">
                            <h4>Les textes de la banière</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[1].text} placeholder="titre de la banière" id={props.texts[1].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(1)}
                        </div>
                        <div className="border-bottom-white pb-4 mt-4">
                            <p>Sous-titre</p>
                            <PrimaryInput value={props.texts[2].text} placeholder="sous-titre de la banière" id={props.texts[2].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(2)}
                        </div>
                        <div className="pb-4 mt-4">
                            <h4>Bouton</h4>
                            <p>Texte du bouton</p>
                            <PrimaryInput placeholder="ex:je découvre, je m'inscris,..." value={props.texts[3].text} id={props.texts[3].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(3)}
                            <p className='mt-4'>Couleur de fond du bouton</p>
                            <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[3]} type="background"/>
                            <p className='mt-4'>Marge du bouton</p>
                            {props.windowSize == "desktop-view" ? props.getMarginSelector(3) : props.getMarginSelector(8)}
                        </div>
                        </>
                        :
                        <>
                            {props.texts[1].poppinsId&&(
                            <li className="object">
                                <span>Poppins</span>
                                <div className='d-flex'>
                                    <PrimaryButton style={{ marginRight: 2 }} onClick={() => navigateTo(props.texts[1].poppinsId)}>
                                        <i className='fa fa-edit'></i>
                                    </PrimaryButton>
                                    <PrimaryButton onClick={() => props.removePoppin()}>
                                        <i className='fa fa-times'></i>
                                    </PrimaryButton>
                                </div>
                            </li>
                            )}
                            <PrimaryButton onClick={() => props.togglePopup("select-poppins",null,null)}>Selectionner un poppin</PrimaryButton>
                        </>
                        }
                    </div>
                </>
            }
            scroll={() => props.handleClickScroll("baner")}
            />
            <AdminAccordion
            title='Carousel de carte'
            ref={props.accordionRefs[2]}
            content={
                <>
                    <div className="block border-bottom-white pb-4 mb-4">
                        <h4>Style du bouton</h4>
                        {props.getTextStyle(17)}
                        <p className='mt-4'>Marge du bouton</p>
                        {props.getMarginSelector(17)}
                    </div>
                    <div className="block">
                        <h4>Les boutons</h4>
                        <p className="mt-2">{props.getArraysize(props.banerSlides,'button')} boutons(s)</p>
                        <ul>
                        {props.banerSlides.map(objet=> objet.type == "button" ? (
                            <Objet details={objet} id={"bouton-"+objet.id} key={objet.id} onDelete={props.handleDelete} toggle={props.togglePopup} type='edit-baner-slide' cardType={objet.type}/>
                        ):null)}
                        </ul>
                        <PrimaryButton onClick={() => props.togglePopup("add-baner-slide",null,"button")}>Ajouter un bouton</PrimaryButton>
                    </div>
                    <div className="block border-bottom-white pb-4 mb-4">
                        <h4>Style du texte des cartes</h4>
                        {props.getTextStyle(4)}
                        <p className='mt-4'>Alignement du texte</p>
                        {props.getAlignSelector("card",4)}
                    </div>
                    <div className="block">
                        <h4>Les cartes</h4>
                        <p className="mt-2">{props.getArraysize(props.cardSlides,'card-slide')} carte(s)</p>
                        <DraggableList
                            list={props.cardSlides}
                            idPrefix="card-"
                            typeCard="card-slide"
                            typeToggle='edit-card'
                            onDelete={props.handleDeleteCard}
                            updateCardOrder={props.updateCardOrder}
                            toggle={props.togglePopup}
                        />
                        <PrimaryButton onClick={() => props.togglePopup("add-card",null,"card-slide")}>Ajouter une carte</PrimaryButton>
                    </div>
                </>
            }
            scroll={() => props.handleClickScroll("card-carousel")}
            />
            <AdminAccordion
                title='Bandeau rose'
                ref={props.accordionRefs[3]}
                content={
                    <>
                        <div className="block mt-4">
                            <Redirection id={props.texts[5].id} action={props.changeTitle} name="redirection" value={props.texts[5].redirection}/>
                            <h4>Choisissez la couleur du bandeau</h4>
                            <ColorPicker action={(e) => props.handleChange(e,'background')} details={props.texts[5]}/>
                            <h4>Marge du bandeau</h4>
                            {props.getMarginSelector(5)}
                        </div>
                        <div className='block'>
                            <h4>Alignement du texte</h4>
                            {props.getAlignSelector("pink",5)}
                        </div>
                        <div className="block border-bottom-white pb-4">
                            <h4>Les textes de la banière</h4>
                            <p>Titre</p>
                            <PrimaryInput value={props.texts[6].text} placeholder="titre de la banière" id={props.texts[6].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(6)}
                        </div>
                        <div className="block border-bottom-white pb-4 mt-4">
                            <p>Sous-titre</p>
                            <PrimaryInput value={props.texts[7].text} placeholder="sous-titre de la banière" id={props.texts[7].id} onChange={props.changeTitle}/>
                            {props.getTextStyle(7)}
                        </div>
                    </>
                }
                scroll={() => props.handleClickScroll("pink-baner")}
            />
            <AdminAccordion
                title='Section avec deux blocs'
                ref={props.accordionRefs[4]}
                content={
                    <div>
                        <div className="block border-bottom-white pb-4 mb-4">
                            <h4>Style du titre des bloc</h4>
                            {props.getTextStyle(8)}
                        </div>
                        <div className="block border-bottom-white pb-4 mb-4">
                            <h4>Style des sous-titre des bloc</h4>
                            {props.getTextStyle(9)}
                            <h4>Marge des blocs</h4>
                            {props.windowSize == "desktop-view" ? props.getMarginSelector(6) : props.getMarginSelector(9) }
                        </div>
                        <div className="block border-bottom-white pb-4 mb-4">
                            <h3>Premier bloc</h3>
                            <Redirection id={props.texts[18].id} action={props.changeTitle} name="redirection" value={props.texts[18].redirection}/>
                            {props.windowSize == "desktop-view" ? 
                            <div>
                                <h4>Image en version desktop <br/><small>Taille recommandé: 720*688</small></h4>
                                <div className='image-container'>
                                {props.images[1].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",1,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                                <ImgUpload onChange={(e) => props.photoUpload(e,1)} src={props.images[1].imagePreviewUrl} id={props.images[1].id}/>
                                </div>
                            </div>
                            :
                            <div>
                                <h4 className='mt-4'>Image en version mobile <br/><small>Taille recommandé: 600*470</small></h4>
                                <div className='image-container'>
                                {props.images[2].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",2,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                                <ImgUpload onChange={(e) => props.photoUpload(e,2)} src={props.images[2].imagePreviewUrl} id={props.images[2].id}/>
                                </div>
                            </div>
                            }
                            <h4>Titre</h4>
                            <p>Le texte</p>
                            <PrimaryInput value={props.texts[18].text} id={props.texts[18].id} onChange={props.changeTitle}/>
                            <p>Le texte italic</p>
                            <PrimaryInput value={props.texts[19].text} id={props.texts[19].id} onChange={props.changeTitle}/>
                            <p>Couleur de fond du titre</p>
                            <ColorPicker action={(e) => props.handleChange(e,'background')} details={props.texts[18]}/>
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("first-bloc",18)}
                            <h4 className='mt-4'>Sous-titre du bloc</h4>
                            <p>Texte</p>
                            <PrimaryTextarea value={props.texts[9].text} placeholder="titre de la banière" id={props.texts[9].id} onChange={props.changeTitle}/>
                            <p>Alignement du texte</p>
                            {props.getAlignSelector("subtitle-first-bloc",9)}
                        </div>
                        <div className="block mb-4">
                            <h3>Deuxième bloc</h3>
                            <Redirection id={props.texts[10].id} action={props.changeTitle} name="redirection" value={props.texts[10].redirection}/>
                            {props.windowSize == "desktop-view" ? 
                            <>
                                <h4>Image en version desktop <br/><small>Taille recommandé: 720*688</small></h4>
                                <div className='image-container'>
                                    {props.images[3].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",3,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                                    <ImgUpload onChange={(e) => props.photoUpload(e,3)} src={props.images[3].imagePreviewUrl} id={props.images[3].id}/>
                                </div>
                            </>
                            :
                            <>
                                <h4 className='mt-4'>Image en version mobile <br/><small>Taille recommandé: 600*470</small></h4>
                                <div className='image-container'>
                                {props.images[4].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",4,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                                    <ImgUpload onChange={(e) => props.photoUpload(e,3)} src={props.images[4].imagePreviewUrl} id={props.images[4].id}/>
                                </div>
                            </>
                            }
                            <h4 className='mt-4'>Sous-titre du bloc</h4>
                            <p>texte</p>
                            <PrimaryTextarea value={props.texts[11].text} placeholder="sous-titre" id={props.texts[11].id} onChange={props.changeTitle}/>
                            <p>Alignement du texte</p>
                            {props.getAlignSelector("subtitle-second-bloc",11)}
                            <h4>Titre du bloc</h4>
                            <p>texte</p>
                            <PrimaryTextarea value={props.texts[10].text} placeholder="titre" id={props.texts[10].id} onChange={props.changeTitle}/>
                            <p>Couleur de fond du titre</p>
                            <ColorPicker action={(e) => props.handleChange(e,'background')} details={props.texts[10]}/>
                            <p className='mt-4'>Alignement du texte</p>
                            {props.getAlignSelector("title-second-bloc",10)}
                        </div>
                    </div>
                }
                scroll={() => props.handleClickScroll("section-two-block")}
            />
            <AdminAccordion
            title='Section image - texte -image'
            ref={props.accordionRefs[5]}
            content={
                <div className="block mb-4">
                {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ?
                <div> 
                    <h4>Image de gauche <br/><small>Taille recommandé: 1000*1090</small></h4>
                    <div className='image-container'>
                    {props.images[5].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",5,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                    <ImgUpload onChange={(e) => props.photoUpload(e,5)} src={props.images[5].imagePreviewUrl} id={props.images[5].id}/>
                    </div>
                    <h4 className='mt-4'>Image de droite <br/><small>Taille recommandé: 1000*1090</small></h4>
                    <div className='image-container'>
                    {props.images[6].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",6,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                    <ImgUpload onChange={(e) => props.photoUpload(e,6)} src={props.images[6].imagePreviewUrl} id={props.images[6].id}/>
                    </div>
                </div>
                :
                <div>
                    <h4>Image en mobile <br/><small>Taille recommandé: 700*638</small></h4>
                    <div className='image-container'>
                    {props.images[7].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",7,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                    <ImgUpload onChange={(e) => props.photoUpload(e,7)} src={props.images[7].imagePreviewUrl} id={props.images[7].id}/>
                    </div>
                </div>
                }
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Titre</h4>
                    <p>Couleur de fond du bloc</p>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[12]} type="background"/>
                    <p className='mt-4'>Texte</p>
                    <PrimaryTextarea value={props.texts[13].text} id={props.texts[13].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(13)}
                    <p className='mt-4'>Couleur de fond du bouton</p>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[13]} type="background"/>
                    <p className='mt-4'>Alignement du texte</p>
                    {props.getAlignSelector("third-bloc-title",13)}
                </div>
                <div className="mt-4">
                    <h4>Bouton</h4>
                    <Redirection id={props.texts[14].id} action={props.changeTitle} name="redirection" value={props.texts[14].redirection}/>
                    <p>Texte du bouton</p>
                    <PrimaryTextarea placeholder="ex:je découvre, je m'inscris,..." value={props.texts[14].text} id={props.texts[14].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(14)}
                    <p className='mt-4'>Marge du bouton</p>
                    {props.getMarginSelector(14)}
                    <p className='mt-4'>Alignement du texte</p>
                    {props.getAlignSelector("third-bloc-button",14)}
                </div>
                </div>
            }
            scroll={() => props.handleClickScroll("image-text-image")}
            />
            <AdminAccordion
            title='Collections'
            ref={props.accordionRefs[6]}
            content={
                <div className="block mb-4">
                {props.windowSize == "desktop-view"||props.windowSize == "tablet-view" ? 
                <div>
                    <h4>Image <br/><small>Taille recommandé: 1280*420</small></h4>
                    <div className='image-container'>
                    {props.images[8].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",8,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                    <ImgUpload onChange={(e) => props.photoUpload(e,8)} src={props.images[8].imagePreviewUrl} id={props.images[8].id}/>
                    </div>
                </div>:
                <div>
                    <h4>Image en version mobile <br/><small>Taille recommandé: 1005*600</small></h4>
                    <div className='image-container'>
                    {props.images[9].file&&<PrimaryButton onClick={() => props.togglePopup("image-cropper",9,'images')}><i className='fa fa-edit'></i></PrimaryButton>} 
                    <ImgUpload onChange={(e) => props.photoUpload(e,9)} src={props.images[9].imagePreviewUrl} id={props.images[9].id}/>
                    </div>
                </div>
                }
                <div className="border-bottom-white pb-4 mt-4">
                    <h4>Titre</h4>
                    <p>Texte</p>
                    <PrimaryInput value={props.texts[15].text} id={props.texts[15].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(15)}
                    <p className='mt-4'>Alignement du texte</p>
                    {props.getAlignSelector("collection-title",15)}
                </div>
                <div className="mt-4">
                    <h4>Bouton</h4>
                    <Redirection id={props.texts[16].id} action={props.changeTitle} name="redirection" value={props.texts[16].redirection}/>
                    <p>Texte du bouton</p>
                    <PrimaryInput placeholder="ex:je découvre, je m'inscris,..." value={props.texts[16].text} id={props.texts[16].id} onChange={props.changeTitle}/>
                    {props.getTextStyle(16)}
                    <p className='mt-4'>Marge du bouton</p>
                    {props.windowSize == "desktop-view" ? props.getMarginSelector(16) : props.getMarginSelector(10)}
                    <p className='mt-4'>Couleur de fond du bouton</p>
                    <ColorPicker action={(e) => props.handleChange(e,"background")} details={props.texts[16]} type="background"/>
                    <p className='mt-4'>Alignement du texte</p>
                    {props.getAlignSelector("collection-button",16)}
                </div>
                </div>
            }
            scroll={() => props.handleClickScroll("collection")}
            />
        </div>
    );
}
export default AdminHomepage;