import React from "react";
import imageUpload from "../assets/img/upload.png";
const ImgUpload = (props) => {
    const {id,src,onChange} = props;
    return(
        <label htmlFor={'photo-upload-'+id} className="custom-file-upload fas">
            <div className="img-wrap img-upload" >
                <img htmlFor="photo-upload" src={src?src:imageUpload} alt=""/>
            </div>
            <input id={'photo-upload-'+id} type="file" onChange={onChange} accept="image/png, image/gif, image/jpeg"/> 
        </label>
    )
}
export default ImgUpload