import React ,{useState} from "react";
import {PrimaryButton} from "./Button.style";
import Redirection from "./Redirection";
import {PrimaryInput, PrimaryTextarea} from "./Input.style";
import imageUpload from "../assets/img/upload.png";

const Form = (props) => {
    var id, text, link, redirection, imageCard, imagePreview,imageCardJean,imagePreviewJean, type;
    if(props.objet!=null){
        id = props.objet.id;
        text = props.objet.text;
        link= props.objet.link;
        redirection = props.objet.redirection;
        imageCard = props.objet.file;
        imagePreview = props.objet.imagePreviewUrl;
        imageCardJean = props.objet.fileJean;
        imagePreviewJean = props.objet.imagePreviewUrlJean;
        type = props.objet.type;
    }else{
        id = "";
        text = "";
        link= ""
        redirection = "";
        imageCard = "";
        imagePreview = imageUpload;
        imageCardJean = "";
        imagePreviewJean = imageUpload;
        type = props.cardType;
    }
    const [newObjet, setNewObjet] = useState({id:id, text:text, link:link, redirection:redirection, type:type});
    const [image, setImage] = useState({file:imageCard,imagePreviewUrl:imagePreview});
    const [imageJean, setImageJean] = useState({file:imageCardJean,imagePreviewUrl:imagePreviewJean});

    const handleChange = (e) =>{
        const {name,value} = e.target;
        setNewObjet((prevState => ({
            ...prevState,
                [name]:value
            })
        ));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const id = newObjet.id;
        const text = newObjet.text;
        const link = newObjet.link;
        const type = newObjet.type;
        const redirection = newObjet.redirection;
        const file = image.file;
        const imagePreviewUrl = image.imagePreviewUrl;
        const fileJean = imageJean.file;
        const imagePreviewUrlJean = imageJean.imagePreviewUrl;
        var data = {id,text, link, redirection, type};
        var dataCard = type == "card-engagement"|| type == "card-news" || type== "card-carousel" ? {id, link, redirection, text, file, imagePreviewUrl, type} : type == "faq" ? {id, link, text, type} : type=="card-slide" ? {id,text,redirection, file, imagePreviewUrl, type} : type=="card-recrutement"||type=="savoir-plus" ? {id, link, text, file, imagePreviewUrl, type} : type=="carousel"||type=="reseaux" ? {id, file, imagePreviewUrl, type} : type=="card-bar" ? {id, link, redirection, text, file, imagePreviewUrl, fileJean, imagePreviewUrlJean, type} : {id,text, file, imagePreviewUrl, type}; 
        if(props.type == 'add'){
            props.onAdd(data);
            setNewObjet((prevState => ({
                ...prevState,
                    text:"",
                    link:"",
                    redirection:"",
                })
            ));
        }else if(props.type == 'edit'){
            props.onEdit(data);
        }
        else if(props.type == 'add-card'){
            props.onAdd(dataCard);
            setNewObjet((prevState => ({
                ...prevState,
                    text:"",
                })
            ));
            setImage((prevState => ({
                ...prevState,
                    file:"",
                    imgPreview:imageUpload
                })
            ));
            setImageJean((prevState => ({
                ...prevState,
                    file:"",
                    imgPreview:imageUpload
                })
            ));
        }
        else if(props.type == 'edit-card'){
            props.onEdit(dataCard);
        }
    }
    const photoUpload = (e) =>{
        e.preventDefault();
        const file = e.target.files[0],
            id = e.target.id;
        if(file&&(file.size/1024) < 1024){
            const reader = new FileReader();
            reader.onloadend = () => {
                if(id!=="photo-upload-jean")
                    setImage((prevState => ({
                        ...prevState,
                        file: file,
                        imagePreviewUrl : reader.result
                        })
                    ))
                else
                    setImageJean((prevState => ({
                        ...prevState,
                        file: file,
                        imagePreviewUrl : reader.result
                        })
                    ))
            }
            reader.readAsDataURL(file);
        }
        else{
          alert("La taille de l'image ne doit pas depasser les 1Mo! vous risquez de ralentir drastiquement le site avec des images lourdes")
        }
    }
    const ImgUpload =({onChange,src,...rest})=>
    <label htmlFor={rest.id ? rest.id :'photo-upload'} className="custom-file-upload fas mb-4">
        <div className="img-wrap img-upload" >
            <img htmlFor={rest.id ? rest.id :'photo-upload'} src={src} alt=""/>
        </div>
        <input id={rest.id ? rest.id :'photo-upload'} type="file" onChange={onChange} accept="image/png, image/gif, image/jpeg"/> 
    </label>
    return(
        <form onSubmit={handleSubmit}>
        {props.type=="add"||props.type=="edit" ?
        <div>
            <p>{props.cardType == "fun" ? "Le texte en gras" : props.cardType=="puce-first"||props.cardType=="puce-second" ? 
                <>Le contenu <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="Cette carte est traité differement des autres cartes. Veuillez suivre attentivement les instructions.&#10;Une carte est composé:&#10;-d'un titre&#10;-d'un sous-titre&#10;-d'un prix&#10;-d'un bouton&#10;-Pour ajouter un titre il faut mettre le texte entre deux asterisques puis commencer le texte par titre: (exemple: *titre:Charlie*)&#10;-Pour ajouter un sous-titre il suffit de mettre le texte entre deux asterisques et commencer le texte par sous-titre: ex(*soutitre: Veste Noir*)&#10;-Même chose pour un prix (*prix:120€*)&#10;Même chose pour ajouter un bouton mais il faut separer le texte et le lien de redirection par un anti-slash ou \ (exemple: *bouton:découvrir\https://facebook.com)"></i></> : props.cardType != "button" ? "Le texte avant le lien" : "Le texte du bouton"}</p>
            {props.cardType=="puce-first"||props.cardType=="puce-second" ?
            <PrimaryTextarea value={newObjet.text} type="text" onChange={handleChange} name="text"/>
            :
            <PrimaryInput value={newObjet.text} type="text" onChange={handleChange} name="text"/>}
            {props.cardType != "button"&& props.cardType != "iframe-slide"&& props.cardType != "fun" && props.cardType.toLowerCase().indexOf("block") === -1 && props.cardType != "puce-first"&& props.cardType != "puce-second" ? 
            <div>
                <p>Le texte dans le lien</p>
                <PrimaryInput value={newObjet.link} type="text" onChange={handleChange} name="link"/>
            </div> : null}
            {(props.cardType!=="puce-first"&&props.cardType!=="puce-second")&&(
                <>
                    <p>{props.cardType == "iframe-slide" ? "Le lien de la video": props.cardType != "button" ? props.cardType == "fun" ? "Le texte" : "Le lien redirige où?" : "Le bouton redirige où?"}</p>
                    <PrimaryInput value={newObjet.redirection} type="text" onChange={handleChange} name="redirection"/>
                </>
            )}
        </div>
        :props.type=="add-card"||props.type=="edit-card" ?
        <div>
            {type=="card-engagement"||type=="card-recrutement"||type=="savoir-plus"||type=="card-bar"||type=="card-news" ? 
            <div>
                <p>Le titre de la carte</p>
                <PrimaryInput value={newObjet.link} type="text" onChange={handleChange} name="link"/>
                {(type=="card-engagement"||type=="card-bar"||type=="card-news")&&<>
                    <p>{type !== "card-news" ? 'Le titre en italic' : 'Le bouton'}</p>
                    {type == "card-news"&&(<small className="text-white mb-4 d-block" style={{fontSize:11,lineHeight:1.2}}>-Pour ajouter un bouton, ajouter le texte et le lien separé d'un anti slash "\" (exemple: *** voir plus\https://monline.com)"</small>)}
                    <PrimaryInput value={newObjet.redirection} type="text" onChange={handleChange} name="redirection"/>
                </>}
            </div>
            :type=="faq" ? 
            <div>
                <p>La question</p>
                <PrimaryInput value={newObjet.link} type="text" onChange={handleChange} name="link"/>
            </div>
            :null}
            {type!=="carousel"&&type!=="reseaux"&&(
            <p className={type=="card-engagement" ? "mb-0" : null}>
                {type=="card-engagement"||type=="card-news" ? 
                "Le texte" : 
                type=="faq" ? 
                "La reponse" : 
                type=="savoir-plus" ? 
                <>le texte <small>{'(commencez le texte avec "->" pour ajouter un élément dans la liste)'}</small></>: 
                type=="card-bar" ? 
                <>Le contenu <i className="fa fa-info-circle" aria-hidden="true" data-tooltip="Cette carte est traité differement des autres cartes. Veuillez suivre attentivement les instructions.&#10;Une carte est composé:&#10;-d'une description&#10;-d'une liste de details&#10;-d'une compostion&#10;-d'un numero de serie&#10;-d'un bouton&#10;-Pour ajouter une description il faut mettre le texte entre deux asterisques puis commencer le texte par description: (exemple: *description:La description de ma carte*)&#10;-Pour ajouter une liste des details il suffit de commencer le texte par -> par exemple(->Liste 1 ->Liste2)&#10;-Pour ajouter une composition il suffit de mettre le texte entre deux asterisques et commencer le texte par composition: ex(*composition: la composition de ma carte*)&#10;-Même chose pour un numero de serie exemple (*numero:0001*)&#10;Même chose pour ajouter le lien mais il faut separer le texte et le lien de redirection par un anti-slash ou \ (exemple: *lien:voir plus\https://facebook.com)"></i></> 
                : "Le titre"}
            </p>
            )}
            {type=="card-engagement"&&<small className="text-white mb-4 d-block" style={{fontSize:11,lineHeight:1.2}}>
                Pour ajouter un lien dans le texte, veuillez suivre la logique suivante:<br/>
                -mettre le lien entre trois *** (exemple : *** mon lien ***)<br/>
                -mettre le texte du lien en premier (exemple: *** voir plus ***)<br/>
                -ajouter ensuite le lien de redirection après le texte separé d'un anti slash "\" (exemple: *** voir plus\https://monline.com)"
            </small>}
            {type == 'card-slide'&&props.currentPage=="homepage" ?
            <PrimaryInput value={newObjet.text} onChange={handleChange} name="text" required={true}/>
            :
            type!=="carousel"&&type!=="reseaux"&&(<PrimaryTextarea value={newObjet.text} onChange={handleChange} name="text" required={true}/>)}
            {(type == "card-slide"||type == "card-carousel")&&(
            <Redirection id={"card-"+id} action={handleChange} name="redirection" value={newObjet.redirection}/>)}
            <div className="row">
            {type != "faq"&&(
            <div className={type == "card-bar" ? "col-md-6" : "col-md-12"}>
                <p>Image de la carte</p>
                <div className="image-container">
                    <ImgUpload onChange={photoUpload} src={image.imagePreviewUrl}/>
                </div>
            </div>
            )}
            {type == "card-bar"&&(
            <div className="col-md-6">
                <p>Image du jean</p>
                <div className="image-container">
                    <ImgUpload onChange={photoUpload} src={imageJean.imagePreviewUrl} id="photo-upload-jean"/>
                </div>
            </div>
            )}
            </div>
        </div>
        :null}
            <PrimaryButton>{props.type=="edit"||props.type=="edit-card"?"Enregistrer":"Ajouter"}</PrimaryButton>
        </form>
    )
}
export default Form