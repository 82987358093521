import React, { useRef, useEffect } from 'react';

function AutoWidthInput({ id, change, value, className, ...restProps }) {
  const inputRef = useRef(null);
  const measurementRef = useRef(null);

  useEffect(() => {
    const inputText = inputRef.current.value;
    measurementRef.current.innerText = inputText;
    const inputWidth = measurementRef.current.scrollWidth;
    if (inputWidth > 10) {
      inputRef.current.style.width = inputWidth + 'px';
    } else {
      inputRef.current.style.width = '10px';
    }
  }, [value]);
  return (
    <>
      <div 
        style={{ position: 'absolute', left: '-9999px' }} 
        ref={measurementRef}
        className={restProps.classValue?restProps.classValue:''}
      >
        {/* This div is used for text measurement */}
      </div>
      <input
        type='text'
        id={id}
        style={{width:0}}
        ref={inputRef}
        value={value}
        className={restProps.classValue ?'input-edit '+restProps.classValue :'input-edit'}
        onChange={change}
      />
    </>
  );
}

export default AutoWidthInput;