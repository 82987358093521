import React, { useState, useRef, useEffect } from 'react';
import { PrimaryButton } from "./Button.style";
import { PrimaryInput } from './Input.style';

function SimpleWysiwygEditor(props) {
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [content, setContent] = useState(props.value);
  const editorRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkText, setLinkText] = useState('');
  const [linkURL, setLinkURL] = useState('');
  const [linkColor, setLinkColor] = useState('#232323');
  const [selectionRange, setSelectionRange] = useState(null);
  const [selectedLinkNode, setSelectedLinkNode] = useState(null);

  const updateFormatting = () => {
    const isTextBold = document.queryCommandState('bold');
    const isTextItalic = document.queryCommandState('italic');
    setIsBold(isTextBold);
    setIsItalic(isTextItalic);
  };

  useEffect(() => {
    const editorElement = editorRef.current;

    const handleMouseUp = () => {
      updateFormatting();
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const br = document.createElement('br');
        range.deleteContents();
        range.insertNode(br);
        range.setStartAfter(br);
        range.setEndAfter(br);
        selection.removeAllRanges();
        selection.addRange(range);
        handleEditorChange();
      }
      updateFormatting();
    };

    const handleLinkEdit = (e) => {
      const target = e.target;
      if (target.tagName === 'A') {
        const linkNode = target;
        const range = document.createRange();
        range.selectNode(linkNode);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        setSelectionRange(range);
        setSelectedLinkNode(linkNode);
        setLinkText(linkNode.textContent);
        setLinkURL(linkNode.getAttribute('href'));
        setLinkColor(linkNode.style.color || '#232323');
        setIsModalOpen(true);
      }
    };

    if (editorElement) {
      editorElement.addEventListener('mouseup', handleMouseUp);
      editorElement.addEventListener('keydown', handleKeyDown);
      editorElement.addEventListener('click', handleLinkEdit);
    }

    return () => {
      if (editorElement) {
        editorElement.removeEventListener('mouseup', handleMouseUp);
        editorElement.removeEventListener('keydown', handleKeyDown);
        editorElement.removeEventListener('click', handleLinkEdit);
      }
    };
  }, []);

  const handleBoldClick = () => {
    document.execCommand('bold', false, null);
    updateFormatting();
    editorRef.current.focus();
  };

  const handleItalicClick = () => {
    document.execCommand('italic', false, null);
    updateFormatting();
    editorRef.current.focus();
  };

  const handleEditorChange = () => {
    const newContent = editorRef.current.innerHTML;
    setContent(newContent);
    props.onContentChange(props.id, newContent);
  };

  const handleLinkClick = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      setSelectionRange(range);
    }
    setIsModalOpen(true);
  };

  const handleSubmit = () => {
    if (linkText && linkURL) {
      const linkHTML = `<a href="${linkURL}" style="color:${linkColor}">${linkText}</a>`;
      if (selectedLinkNode) {
        selectedLinkNode.outerHTML = linkHTML;
      } else {
        const selection = selectionRange;
        if (selection) {
          selection.deleteContents();
          const linkNode = document.createElement('span');
          linkNode.innerHTML = linkHTML;
          selection.insertNode(linkNode);
        } else {
          document.execCommand('insertHTML', false, linkHTML);
        }
      }
    }
    setIsModalOpen(false);
    setLinkText('');
    setLinkURL('');
    setLinkColor('#232323');
    setSelectionRange(null);
    setSelectedLinkNode(null);
    handleEditorChange();
  };

  useEffect(() => {
    const editorElement = editorRef.current;
    const currentContent = editorElement.innerHTML;
    if (currentContent !== content) {
      editorElement.innerHTML = content;
    }
  }, [content]);

  return (
    <div className='lite-wysiwyg'>
      <PrimaryButton
        onClick={handleBoldClick}
        className={isBold ? 'active' : ''}
        key="bold-button"
      >
        <i className='fa fa-bold'></i>
      </PrimaryButton>
      <PrimaryButton
        onClick={handleItalicClick}
        className={isItalic ? 'active' : ''}
        key="italic-button"
      >
        <i className='fa fa-italic'></i>
      </PrimaryButton>
      <PrimaryButton onClick={handleLinkClick}>
        <i className='fa fa-link'></i>
      </PrimaryButton>
      <div
        ref={editorRef}
        contentEditable="true"
        className="editor"
        onInput={handleEditorChange}
        id={"content-editable-"+props.id}
      >
      </div>
      {isModalOpen && (
        <div className='link-about'>
          <p>Texte</p>
          <PrimaryInput 
            placeholder="Entrez le texte du lien"
            value={linkText}
            onChange={(e) => setLinkText(e.target.value)}
          />
          <p>Url</p>
          <PrimaryInput 
            placeholder="Entrez l'URL du lien"
            value={linkURL}
            onChange={(e) => setLinkURL(e.target.value)}
          />
          <p>Couleur</p>
          <label className="color-selector mb-4">
            <span className="circle" style={{background:linkColor}} />
            <span>{linkColor}</span>
            <input
                type="color"
                value={linkColor}
                onChange={(e) => setLinkColor(e.target.value)}
                className="hidden"
            />
          </label>
          <PrimaryButton onClick={handleSubmit}>Valider</PrimaryButton>
        </div>
      )}
    </div>
  );
}

export default SimpleWysiwygEditor;