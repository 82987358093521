import React, { useEffect, useState } from 'react';

function CountDown({ targetDate }) {
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    function calculateTimeRemaining() {
        const now = new Date().getTime();
        const difference = new Date(targetDate).getTime() - now;
        if (difference <= 0) {
        return { days: '00', hours: '00', minutes: '00', seconds: '00' };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24)).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
        });
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
        });
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
        });
        const seconds = Math.floor((difference % (1000 * 60)) / 1000).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
        });

        return { days, hours, minutes, seconds };
    }

    // Utilisez un effet secondaire pour mettre à jour le compte à rebours lorsque la date cible change.
    useEffect(() => {
        const intervalId = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(intervalId);
    }, [targetDate]); // Surveillez les changements de targetDate

    return (
        <div className="countdown">
        <div className="countdown-item">
            <span className="countdown-number">{timeRemaining.days}</span>
            <span className="countdown-label">jour{timeRemaining.days !== 1 ? 's' : ''}</span>
        </div>
        <div className="countdown-item">
            <span className="countdown-number">{timeRemaining.hours}</span>
            <span className="countdown-label">heure{timeRemaining.hours !== 1 ? 's' : ''}</span>
        </div>
        <div className="countdown-item">
            <span className="countdown-number">{timeRemaining.minutes}</span>
            <span className="countdown-label">minute{timeRemaining.minutes !== 1 ? 's' : ''}</span>
        </div>
        <div className="countdown-item">
            <span className="countdown-number">{timeRemaining.seconds}</span>
            <span className="countdown-label">seconde{timeRemaining.seconds !== 1 ? 's' : ''}</span>
        </div>
        </div>
    );
}

export default CountDown;