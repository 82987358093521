import React, {useState} from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Objet from "./Objet";

const DraggableList = (props) => {
    const [buttonClickedMap, setButtonClickedMap] = useState({});
    const handleButtonClick = (id) => {
        setButtonClickedMap((prev) => ({
            ...prev,
            [id]: !prev[id] // Inverse the current state
        }));
    };
    const onDragEnd = result => {
        if (!result.destination) return;
        const reorderedItems = [...props.list];
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, reorderedItem);
        //met à jour l'id aussi pour eviter que tout ce casse
        reorderedItems.forEach((item, index) => {
            item.id = index;
        });
        props.updateCardOrder(reorderedItems,props.typeToggle);
    };
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {props.list.map((item, index) => item.type == props.typeCard&&(
                    <Draggable key={item.id} draggableId={`draggable-${item.id}`} index={index}>
                        {(provided) => (
                            <>
                                <Objet 
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    details={item} 
                                    id={props.idPrefix+item.id} 
                                    onDelete={props.onDelete} 
                                    toggle={props.toggle} 
                                    type={props.typeToggle} 
                                    cardType={item.type}
                                    handleButtonClick={() => handleButtonClick(item.id)}
                                    isButtonClicked={buttonClickedMap[item.id] || false}
                                />
                                {buttonClickedMap[item.id]&&(<div className="edit-content">
                                    {props.getStyle(item.elementType,item.id,item.type)}
                                </div>)}
                            </>
                        )}
                    </Draggable>
                    ))}
                    {provided.placeholder}
                </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableList;