import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import {PrimaryButton} from "./Button.style";

const ImageCropper = (props) => {
  const [imageSrc] = useState(props.image);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [width] = useState(props.dimensions.width);
  const [height] = useState(props.dimensions.height);
  const editorRef = useRef(null);

  const handleScaleChange = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const handlePositionChange = (position) => {
    setPosition(position);
  };

  const handleEditorLoad = (editor) => {
    setEditor(editor);
  };

  const handlePreview = () => {
    if (editor) {
      const canvas = editorRef.current.getImage();
      const dataURL = canvas.toDataURL('image/jpeg', 0.9);
      fetch(dataURL)
      .then(res => res.blob())
      .then(blob => {
        const newfile = new File([blob], 'cropped-' + props.name, { type: 'image/jpeg' });
        props.preview(props.imageId, newfile, URL.createObjectURL(newfile));
      });
    }
  };
  return (
    <div>
      {imageSrc && (
        <div>
          <AvatarEditor
            ref={editorRef}
            image={imageSrc}
            width={width}
            height={height}
            border={15}
            color={[255, 255, 255, 0.6]} // optionnel
            scale={scale}
            position={position}
            onPositionChange={handlePositionChange}
            onLoadSuccess={handleEditorLoad}
          />
          <div className='range'>
            <label htmlFor="range">Zoom</label>
            <input type="range" id="scale" min="1" max="2" step="0.01" value={scale} onChange={handleScaleChange} />
          </div>
          <div className='text-center'>
            <PrimaryButton onClick={handlePreview}><i className="fa fa-eye"></i> Prévisualiser le resultat</PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageCropper;