import React, { useState } from "react";
import { PrimaryButton } from "../Components/Button.style";
import {PrimaryInput} from "../Components/Input.style";
import Checkbox from "../Components/Checkbox";
import logo from "../assets/img/logo-captain-tortue.jpg";


async function loginUser(credentials) {
    return fetch('https://editeur.itislive.com/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
}
function Login(props){
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState();
    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            username,
            password
        });
        if(username==="admin"&&password==="aCxE1Ajq2C"){
            props.setToken(token);
        }
        else{
            setUserName('');
            setPassword('');
            setError('Données invalides')
        }
    }
    return(
        <div className="login-page">
            <div className="login-box">
                <div className="illustration-wrapper">
                    <img src={logo} alt="Login"/>
                </div>
                <form id="login-form" onSubmit={handleSubmit}>
                    <p className="form-title mb-4">Editeur Captain tortue</p>
                    <p>Connectez-vous à l'éditeur</p>
                    <PrimaryInput type="text" placeholder="Nom d'utilisateur" id="username" onChange={e => setUserName(e.target.value)} value={username} required={true}/>
                    <PrimaryInput type="password" placeholder="Mot de passe" id="password" onChange={e => setPassword(e.target.value)} value={password} required={true}/>
                    <Checkbox squareStyle={{backgroundColor:"#ef0909"}} text="Se souvenir de moi" labelStyle={{fontSize:14,color:"rgb(33, 64, 154)"}} id="confirmation"/>
                    <PrimaryButton htmlType="submit" className="login-form-button">Se connecter au manager</PrimaryButton>
                    <p className="error mt-4">{error}</p>
                </form>
            </div>
        </div>
    )
}
export default Login;