import React,{useEffect} from "react";
import '../assets/styles/Bar.scss';
import arrow from "../assets/img/bar/arrow.svg";
import AutoWidthInput from "../Components/AutoWidthInput";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Bar = (props) => {
    useEffect(() => {
        const mobileButtons = document.querySelectorAll(".btn.mobile");
        mobileButtons.forEach(function (button) {
          button.addEventListener("click", function () {
            const content = this.closest(".content");
            if (content) {
                content.classList.toggle("open");
                const buttonText = content.classList.contains("open") ? "Voir moins" : "Voir plus";
                button.querySelector(".btn-text").textContent = buttonText;
            }
          });
        });
    }, []);
    const{mode,logo,headerTitle,headerSubtitle,headerPara,logoBlack,footerText,tortue,cardTitleStyle,cardSubtitleStyle,cardTextStyle,cardListStyle,cardNumStyle,cardLinkStyle,...rest} = props;
    return (
        <div className="front bar">
            <style>
                {`
                .front.bar header{
                    padding:${headerTitle.mt+'px '+headerTitle.mr+'px '+headerTitle.mb+'px '+headerTitle.ml+'px'};
                    text-align:${headerTitle.align}
                }
                .front.bar header .ttr{
                    font-size:${headerTitle.size+'px'};
                    color:${headerTitle.color};
                    font-weight:${headerTitle.weight};
                    font-family:${headerTitle.font+',sans-serif'}
                }
                .front.bar header .ttr span{
                    font-size:${headerSubtitle.size+'px'};
                    color:${headerSubtitle.color};
                    font-weight:${headerSubtitle.weight};
                    font-family:${headerSubtitle.font+',sans-serif'};
                    background-color:${headerSubtitle.background};
                    padding:${headerSubtitle.mt+'px '+headerSubtitle.mr+'px '+headerSubtitle.mb+'px '+headerSubtitle.ml+'px'};
                    display:inline-block
                }
                .front.bar header .para{
                    font-size:${headerPara.size+'px'};
                    color:${headerPara.color};
                    font-weight:${headerPara.weight};
                    font-family:${headerPara.font+',sans-serif'};
                }
                .front.bar header .para strong{font-weight:700}
                .front.bar section .bloc-bar::before{background-color:${cardTitleStyle.background}}
                .front.bar section .bloc-bar .jean-details .details{text-align:${cardTitleStyle.align}}
                .front.bar section .bloc-bar .jean-details .details .ttr{
                    font-size:${cardTitleStyle.size+'px'};
                    color:${cardTitleStyle.color};
                    font-weight:${cardTitleStyle.weight};
                    font-family:${cardTitleStyle.font+',sans-serif'}
                }
                .front.bar section .bloc-bar .jean-details .details .ttr span{
                    font-size:${cardSubtitleStyle.size+'px'};
                    color:${cardSubtitleStyle.color};
                    font-weight:${cardSubtitleStyle.weight};
                    font-family:${cardSubtitleStyle.font+',sans-serif'};
                }
                .front.bar section .bloc-bar .jean-details .details .content p{
                    font-size:${cardTextStyle.size+'px'};
                    color:${cardTextStyle.color};
                    font-weight:${cardTextStyle.weight};
                    font-family:${cardTextStyle.font+',sans-serif'}
                }
                .front.bar section .bloc-bar .jean-details .details .content ul li{
                    font-size:${cardListStyle.size+'px'};
                    color:${cardListStyle.color};
                    font-weight:${cardListStyle.weight};
                    font-family:${cardListStyle.font+',sans-serif'}
                }
                .front.bar section .bloc-bar .jean-details .details .content p.ref{
                    font-size:${cardNumStyle.size+'px'};
                    color:${cardNumStyle.color};
                    font-weight:${cardNumStyle.weight};
                    font-family:${cardNumStyle.font+',sans-serif'};
                    background-color:${cardNumStyle.background};
                    margin:${cardTitleStyle.align=="left"?"0 0 1rem":cardTitleStyle.align=="center"?"0 auto 1.5rem":"0 0 1.5rem auto"}
                }
                .front.bar section .bloc-bar .jean-details .details .content .button{
                    font-size:${cardLinkStyle.size+'px'};
                    color:${cardLinkStyle.color};
                    font-weight:${cardLinkStyle.weight};
                    font-family:${cardLinkStyle.font+',sans-serif'};
                    margin:${cardTitleStyle.align=="left"?"0":cardTitleStyle.align=="center"?"0 auto":"0 0 0 auto"}
                }
                .front.bar section .bloc-bar .jean-details .details .content .button:after{background-color:${cardLinkStyle.color}}
                .front.bar footer{
                    justify-content:${footerText.align=="center" ? "center" : footerText.align=="left" ? "flex-start" : "flex-end"}
                }
                .front.bar footer p{
                    font-size:${footerText.size+'px'};
                    color:${footerText.color};
                    font-weight:${footerText.weight};
                    font-family:${footerText.font+',sans-serif'};
                }
                .front.bar section .bloc-bar .jean-details .details .content .numero-bloc.many{
                    display:flex;
                    flex-wrap:wrap;
                    justify-content:space-between
                }
                .front.bar section .bloc-bar .jean-details .details .content .numero-bloc.many p.ref{
                    min-width:unset;
                    width:49%;
                }
                @media(max-width:991px){
                    .front.bar footer{padding:1.5rem 0 1rem}
                    .front.bar section .bloc-bar{padding:1.5rem 0}
                    .front.bar section .bloc-bar .jean-details .details .ttr{font-size:${cardTitleStyle.sizeMobile+'px'}}
                    .front.bar section .bloc-bar .jean-details .details .ttr span{font-size:${cardSubtitleStyle.sizeMobile+'px'}}
                    .front.bar section .bloc-bar .jean-details .details .content p{font-size:${cardTextStyle.sizeMobile+'px'}}
                    .front.bar section .bloc-bar .jean-details .details .content ul li{font-size:${cardListStyle.sizeMobile+'px'}}
                    .front.bar section .bloc-bar .jean-details .details .content .button{font-size:${cardLinkStyle.sizeMobile+'px'}}
                }
                @media(max-width:767px){
                    .front.bar header .ttr{font-size:${headerTitle.sizeMobile+'px'}}
                    .front.bar header .ttr span{font-size:${headerSubtitle.sizeMobile+'px'}}
                    .front.bar header .para{font-size:${headerPara.sizeMobile+'px'}}
                    .front.bar footer p{font-size:${footerText.sizeMobile+'px'}}
                }
                `}
                {mode&&`
                .front{container-type:inline-size;}
                .front.bar header .ttr .input-edit{
                    line-height:normal;
                    letter-spacing:2px;
                    font-size:${headerTitle.size+'px'};
                    color:${headerTitle.color};
                    font-weight:${headerTitle.weight};
                    font-family:${headerTitle.font+',sans-serif'};
                    display:inline-block
                }
                .front.bar header .ttr span{
                    line-height:1
                }
                .front.bar header .ttr span .input-edit{
                    line-height:1;
                    font-size:${headerSubtitle.size+'px'};
                    color:${headerSubtitle.color};
                    font-weight:${headerSubtitle.weight};
                    font-family:${headerSubtitle.font+',sans-serif'}
                }
                .front.bar footer p .input-edit{
                    font-size:${footerText.size+'px'};
                    font-style:italic;
                    color:${footerText.color};
                    font-weight:${footerText.weight};
                    font-family:${footerText.font+',sans-serif'};
                }
                @container (max-width: 991px){
                    .front.bar section .bloc-bar:before{height:480px}
                    .front.bar section .bloc-bar .jean-details .details .ttr{font-size:${cardTitleStyle.sizeMobile+'px'}}
                    .front.bar section .bloc-bar .jean-details .details .ttr span{font-size:${cardSubtitleStyle.sizeMobile+'px'}}
                    .front.bar section .bloc-bar .jean-details .details .content p{font-size:${cardTextStyle.sizeMobile+'px'}}
                    .front.bar section .bloc-bar .jean-details .details .content ul li{font-size:${cardListStyle.sizeMobile+'px'}}
                    .front.bar section .bloc-bar .jean-details .details .content .button{font-size:${cardLinkStyle.sizeMobile+'px'}}
                    .front.bar section .bloc-bar .jean-details .details .content{min-height:400px;margin-bottom:85px}
                    .front.bar section .bloc-bar .jean-details .details .content ul{margin:1rem 0}
                    .front.bar section .bloc-bar{padding:1.5rem 0}
                    .front.bar footer{padding:1.5rem 0 1rem}
                    .front.bar section .bloc-bar .jean-details .details .content p.composition,.front.bar section .bloc-bar .jean-details .details .content p.ref{margin-bottom:1rem}
                }
                @container (max-width: 767px){
                    .front.bar header .ttr span,.front.bar header .ttr span .input-edit{font-size:${headerSubtitle.sizeMobile+'px'}}
                    .front.bar header .ttr .input-edit{font-size:${headerTitle.sizeMobile+'px'};letter-spacing:1px;text-align:${headerTitle.align}}
                    .front.bar .mobile{display:block}
                    .front.bar .desktop{display:none}
                    .front.bar .reverse{flex-direction: column-reverse;}
                    .front.bar header{padding-bottom:0}
                    .front.bar header .ttr{font-size: ${headerTitle.sizeMobile+'px'};line-height: 24px;letter-spacing: 1px;}
                    .front.bar header .ttr span{display: block;width: fit-content;margin: auto;}
                    .front.bar header .para{font-size:${headerPara.sizeMobile+'px'}}
                    .front.bar section .bloc-bar{padding: 1.5rem 0}
                    .front.bar section .bloc-bar:before{height: 210px;top: 80px}
                    .front.bar section .bloc-bar .col-md-5,.front .bloc-bar .col-md-7{width:100%}
                    .front.bar section .bloc-bar .col-md-5{padding:0}
                    .front.bar section .bloc-bar .col-md-5 img{display:inline-block}
                    .front.bar section .bloc-bar .col-md-5 img:first-child{width:60%}
                    .front.bar section .bloc-bar .col-md-5 img:last-child{width:40%;padding:0 15px}
                    .front.bar section .bloc-bar .jean-details .details .ttr{margin:2rem 0 1rem}
                    .front.bar section .bloc-bar .jean-details .details .content{min-height:unset;margin-bottom: 0;}
                    .front.bar section .bloc-bar .jean-details .details .content p.composition, .front.bar section .bloc-bar .jean-details .details .content p.ref, .front.bar section .bloc-bar .jean-details .details .content ul, .front.bar section .bloc-bar .jean-details .details .content p.short-description{display:none}
                    .front.bar section .bloc-bar .jean-details .details .content p{order:2}
                    .front.bar section .bloc-bar .jean-details .details .content ul{order:3}
                    .front.bar section .bloc-bar .jean-details .details .content p.composition{order:4}
                    .front.bar section .bloc-bar .jean-details .details .content p.ref{order:5}
                    .front.bar section .bloc-bar .jean-details .details .content .button{order:1;margin-bottom:1rem}
                    .front.bar footer{flex-direction:column;align-items:center;padding: 2rem 15px 1rem}
                    .front.bar footer .tortue{display:block;margin:1rem auto 0}
                    .front.bar footer p{font-size:${footerText.sizeMobile+'px'};text-align:${footerText.align}}
                }
                `}
            </style>
            <header id={mode?"header":null}>
                <div className="container">
                    {mode ? <label htmlFor='photo-upload-0' className="label-edit d-inline-block w-auto" onClick={() => rest.focusAccordion(33,`label[for="photo-upload-0"]`)}><LazyLoadImage src={logo} alt="" width="250"/></label> : <img src={logo} alt="" />}
                    <h1 className="ttr my-4">{mode ? <AutoWidthInput id={headerTitle.id} className="input-edit" value={headerTitle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(33,`#${CSS.escape('0')}`)}/> : headerTitle.text} <span>{mode ? <AutoWidthInput id={headerSubtitle.id} className="input-edit" value={headerSubtitle.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(33,`#${CSS.escape('1')}`)}/> : headerSubtitle.text}</span></h1>
                    <h6 onClick={mode ? () => rest.focusAccordion(33,`#${CSS.escape('2')}`) : () => {}} className={mode ? "label-edit para mt-4" :"para mt-4"} dangerouslySetInnerHTML={{ __html: headerPara.text.replace(/"([^"]+)"/g, '<strong>$1</strong>') }}></h6>
                </div>
            </header>
            <section id={mode?"section":null}>
                <div className="container">
                    {props.cards.filter((objet) => objet.type === 'card-bar').map((objet,index) => (
                    index % 2 === 0 ? 
                    <div className="row align-items-center bloc-bar" key={index}>
                        <div className="col-md-5">
                            <img src={objet.imagePreviewUrl} alt="" className={mode ? "label-edit img-fluid" : "img-fluid"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#bloc-bar${objet.id}`)} : ()=> {} }/>
                            <img src={objet.imagePreviewUrlJean} alt="" className="img-fluid mobile"/>
                        </div>
                        <div className="col-md-7">
                            <div className="jean-details">
                                <div className="pic desktop">
                                    <img src={objet.imagePreviewUrlJean} alt="" className={mode ? "label-edit img-fluid" : "img-fluid"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#bloc-bar${objet.id}`)} : ()=> {} }/>
                                </div>
                                <div className="details">
                                    <h2 className={mode?"ttr label-edit":"ttr"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#title`)} : ()=> {}}>{objet.link} <span>{objet.redirection}</span></h2>
                                    <div className="content">
                                        {objet.text.match(/\*description:(.*?)\*/)&&(
                                            <p className={mode ? "short-description label-edit" : "short-description"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#text`)} : ()=> {}}>{objet.text.match(/\*description:(.*?)\*/)[1]}</p>
                                        )}
                                        {objet.text.includes("->")&&
                                            <ul className={mode ? "label-edit" : ""} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#list`)} : ()=> {}}>
                                            {objet.text.split(/^(->.*)$/gm).filter(Boolean).map((item, index) => {
                                                if(item.startsWith("->"))
                                                    return <li key={index}>{item.slice(2)}</li>;
                                            })}
                                            </ul>
                                        }
                                        {objet.text.match(/\*composition:(.*?)\*/)&&(
                                        <p className={mode ? "composition label-edit" : "composition"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#text`)} : ()=> {} }>{objet.text.match(/\*composition:(.*?)\*/)[1]}</p>
                                        )}
                                        {objet.text.match(/\*numero:(.*?)\*/)&&(
                                        <div className={objet.text.match(/\*numero:(.*?)\*/g).length > 1 ? "numero-bloc many" : "numero-bloc"}>
                                            {objet.text.match(/\*numero:(.*?)\*/g)?.map((match, index) => (
                                            <p className={mode ? "ref label-edit" : "ref"} key={index} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#num`)} : ()=> {} }>{match.replace(/\*numero:(.*?)\*/, "$1")}</p>
                                            ))}
                                        </div>
                                        )}
                                        {objet.text.match(/\*lien:(.*?)\*/)&&(
                                            <a href={mode ? null : objet.text.match(/\*lien:(.*?)\*/)[1].split("\\")[1]} className={mode ?"button label-edit" :"button"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#link`)} : ()=> {} }>{objet.text.match(/\*lien:(.*?)\*/)[1].split("\\")[0]}</a>
                                        )}
                                        <button type="button" className="btn mobile">
                                            <span className="btn-text">Voir plus</span>
                                            <img src={arrow} alt=""/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row align-items-center bloc-bar reverse" key={index}>
                        <div className="col-md-7">
                            <div className="jean-details">
                                <div className="details">
                                    <h2 className="ttr">{objet.link} <span>{objet.redirection}</span></h2>
                                    <div className="content">
                                        {objet.text.match(/\*description:(.*?)\*/)&&(
                                            <p className={mode ? "short-description label-edit" : "short-description"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#text`)} : ()=> {} }>{objet.text.match(/\*description:(.*?)\*/)[1]}</p>
                                        )}
                                        {objet.text.includes("->")&&
                                            <ul className={mode ? "label-edit" : ""}>
                                            {objet.text.split(/^(->.*)$/gm).filter(Boolean).map((item, index) => {
                                                if(item.startsWith("->"))
                                                    return <li key={index}>{item.slice(2)}</li>;
                                            })}
                                            </ul>
                                        }
                                        {objet.text.match(/\*composition:(.*?)\*/)&&(
                                        <p className={mode ? "composition label-edit" : "composition"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#text`)} : ()=> {} }>{objet.text.match(/\*composition:(.*?)\*/)[1]}</p>
                                        )}
                                        {objet.text.match(/\*numero:(.*?)\*/)&&(
                                        <div className={objet.text.match(/\*numero:(.*?)\*/g).length > 1 ? "numero-bloc many" : "numero-bloc"}>
                                            {objet.text.match(/\*numero:(.*?)\*/g)?.map((match, index) => (
                                            <p className={mode ? "ref label-edit" : "ref"} key={index} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#num`)} : ()=> {} }>{match.replace(/\*numero:(.*?)\*/, "$1")}</p>
                                            ))}
                                        </div>
                                        )}
                                        {objet.text.match(/\*lien:(.*?)\*/)&&(
                                            <a href={mode ? null : objet.text.match(/\*lien:(.*?)\*/)[1].split("\\")[1]} className={mode ?"button label-edit" :"button"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#link`)} : ()=> {} }>{objet.text.match(/\*lien:(.*?)\*/)[1].split("\\")[0]}</a>
                                        )}
                                        <button type="button" className="btn mobile">
                                            <span className="btn-text">Voir plus</span>
                                            <img src={arrow} alt=""/>
                                        </button>
                                    </div>
                                </div>
                                <div className="pic desktop">
                                    <img src={objet.imagePreviewUrlJean} alt="" className={mode ? "label-edit img-fluid" : "img-fluid"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#bloc-bar${objet.id}`)} : ()=> {} }/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src={objet.imagePreviewUrl} alt="" className={mode ? "label-edit img-fluid" : "img-fluid"} onClick={mode ? () => {rest.togglePopup("edit-card",objet.id,objet.type);rest.focusAccordion(34,`#bloc-bar${objet.id}`)} : ()=> {} }/>
                            <img src={objet.imagePreviewUrlJean} alt="" className="img-fluid mobile"/>
                        </div>
                    </div>
                    ))}
                </div>
            </section>
            <footer id={mode?"footer":null}>
                {mode ? <label htmlFor='photo-upload-1' className="label-edit d-inline-block w-auto" onClick={() => rest.focusAccordion(35,`label[for="photo-upload-1"]`)}><img src={logoBlack} className="logo" alt="" width="176"/></label>: <img src={logoBlack} className="logo" alt="" width="176"/>}
                <p>
                    {mode ? <AutoWidthInput id={footerText.id} className="input-edit" value={footerText.text} onChange={rest.changeTitle} onFocus={() => rest.focusAccordion(35,`#${CSS.escape('3')}`)}/> : footerText.text}
                    {mode ? <label htmlFor='photo-upload-2' className="label-edit d-inline-block w-auto" onClick={() => rest.focusAccordion(35,`label[for="photo-upload-2"]`)}><img src={tortue} className="tortue" alt=""/></label> : <img src={tortue} alt="" className="tortue"/>}
                </p>
            </footer>
        </div>
    );
}
export default Bar;