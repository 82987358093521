import React from 'react';
import axios from "axios";
import { Routes, Route, Link } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import './assets/styles/App.scss';
import AdminHomepage from "./Components/Admin/AdminHomepage";
import AdminLookbook from "./Components/Admin/AdminLookbook";
import AdminEngagement from './Components/Admin/AdminEngagement';
import AdminRecrutement from './Components/Admin/AdminRecrutement';
import AdminBar from './Components/Admin/AdminBar';
import AdminNewHomepage from './Components/Admin/AdminNewHomepage';
import AdminHistoire from './Components/Admin/AdminHistoire';
import AdminPoppin from './Components/Admin/AdminPoppin';
import {PrimaryButton} from "./Components/Button.style";
import ColorPicker from "./Components/ColorPicker";
import Form from "./Components/Form";
import ImageCropper from './Components/ImageCropper';
import InputNumber from "./Components/InputNumber";
import {PrimaryTextarea} from "./Components/Input.style";
import Popup from "./Components/Popup";
import Select from "./Components/Select";
import Spinner from "./Components/Spinner";
import Home from "./Page/Home";
import LookBook from "./Page/LookBook";
import Recrutement from "./Page/Recrutement";
import Bar from "./Page/Bar";
import Histoire from "./Page/Histoire";
import Template from "./Page/Template";
import Poppin from "./Components/Poppin";
import Login from "./Page/Login";
import Engagement from './Page/Engagement';
import TutorialDataService from "./services/tutorial.service";
import html2canvas from "html2canvas";
import "./assets/img/banner.jpg";
import "./assets/img/slide/slide-1.jpg";
import "./assets/img/slide/slide-2.jpg";
import "./assets/img/slide/slide-3.jpg";
import "./assets/img/slide/slide-4.jpg";
import "./assets/img/bloc/bloc-1.jpg";
import "./assets/img/bloc/bloc-1-mobile.jpg";
import "./assets/img/bloc/bloc-2.jpg";
import "./assets/img/bloc/bloc-2-mobile.png";
import "./assets/img/bloc/bloc-3-left.jpg";
import "./assets/img/bloc/bloc-3-left-mobile.jpg";
import "./assets/img/bloc/bloc-4.jpg";
import "./assets/img/bloc/bloc-4-mobile.png";
import "./assets/img/bloc/bloc-3-right.jpg";
import "./assets/img/icones/about-1.svg";
import "./assets/img/icones/about-2.svg";
import "./assets/img/icones/about-3.svg";
import "./assets/img/icones/about-4.svg";
import "./assets/img/grid/grid-1.jpg";
import "./assets/img/grid/grid-1-mobile.jpg";
import "./assets/img/grid/grid-2.jpg";
import "./assets/img/grid/grid-2-mobile.jpg";
import "./assets/img/grid/grid-3.jpg";
import "./assets/img/grid/grid-4.jpg";
import "./assets/img/grid/grid-5.jpg";
import "./assets/img/grid/grid-5-mobile.jpg";
import "./assets/img/grid/grid-6.jpg";
import "./assets/img/grid/grid-6-mobile.jpg";
import "./assets/img/grid/grid-7.jpg";
import "./assets/img/grid/grid-7-mobile.jpg";
import "./assets/img/grid/grid-8.jpg";
import "./assets/img/grid/grid-9.jpg";
import "./assets/img/grid/grid-9-mobile.jpg";
import "./assets/img/grid/grid-10.jpg";
import "./assets/img/grid/grid-10-mobile.jpg";
import "./assets/img/grid/grid-11.jpg";
import "./assets/img/grid/grid-11-mobile.jpg";
import "./assets/img/grid/grid-12.jpg";
import "./assets/img/grid/grid-12-mobile.jpg";
import "./assets/img/grid/grid-13.jpg";
import "./assets/img/grid/grid-14.jpg";
import "./assets/img/grid/grid-14-mobile.jpg";
import "./assets/img/grid/grid-15.jpg";
import "./assets/img/grid/grid-15-mobile.jpg";
import "./assets/img/grid/grid-16.jpg";
import "./assets/img/grid/grid-16-mobile.jpg";
import "./assets/img/faq.jpg";
import "./assets/img/bloc/engagement-1.jpg";
import "./assets/img/bloc/engagement-2.jpg";
import "./assets/img/bloc/engagement-3.jpg";
import "./assets/img/bloc/engagement-4.jpg";
import "./assets/img/bloc/engagement-5.jpg";
import "./assets/img/bloc/engagement-6.jpg";
import path1 from "./assets/img/icones/path-01.svg";
import path2 from "./assets/img/icones/path-02.svg";
import path3 from "./assets/img/icones/path-03.svg";
import path4 from "./assets/img/icones/path-04.svg";
import path5 from "./assets/img/icones/path-05.svg";
import path6 from "./assets/img/icones/path-06.svg";
import path7 from "./assets/img/icones/path-07.svg";
import logo from "./assets/img/recrutement/logo-noir.svg";
import logoBlanc from "./assets/img/recrutement/logo-blanc.svg";
import banner from "./assets/img/recrutement/header-1.jpg";
import illus1 from "./assets/img/recrutement/illus-1.svg";
import illus2 from "./assets/img/recrutement/illus-2.svg";
import illus3 from "./assets/img/recrutement/illus-3.svg";
import illus4 from "./assets/img/recrutement/illus-4.svg";
import rs1 from "./assets/img/recrutement/rs-v2-1.jpg";
import rs2 from "./assets/img/recrutement/rs-v2-2.jpg";
import rs3 from "./assets/img/recrutement/rs-v2-3.jpg";
import rs4 from "./assets/img/recrutement/rs-v2-4.jpg";
import carousel1 from "./assets/img/recrutement/visu-carrousel-1.jpg";
import carousel2 from "./assets/img/recrutement/visu-carrousel-2.jpg";
import carousel3 from "./assets/img/recrutement/visu-carrousel-3.jpg";
import carousel4 from "./assets/img/recrutement/visu-carrousel-4.jpg";
import carousel5 from "./assets/img/recrutement/visu-carrousel-5.jpg";
import savoirPlus1 from "./assets/img/recrutement/visu-3.jpg";
import savoirPlus2 from "./assets/img/recrutement/visu-4.jpg";
import savoirPlus3 from "./assets/img/recrutement/visu-5.jpg";
import savoirPlus4 from "./assets/img/recrutement/visu-6.jpg";
import presse1 from "./assets/img/recrutement/logo-lepoint.jpg";
import presse2 from "./assets/img/recrutement/logo-lesechos.jpg";
import presse3 from "./assets/img/recrutement/logo-nicematin.jpg";
import presse4 from "./assets/img/recrutement/logo-laprovence.jpg";
import logoBar from "./assets/img/bar/automne-hiver-2023.png";
import logoBlack from "./assets/img/bar/logo-black.svg";
import tortue from "./assets/img/bar/tortue.svg";
import "./assets/img/icones/path-08.png";
import "./assets/img/engagement-mobile.jpg";
import kate from "./assets/img/bar/kate.jpg";
import romy from "./assets/img/bar/romy.jpg";
import charlie from "./assets/img/bar/charlie.jpg";
import alice from "./assets/img/bar/alice.jpg";
import juliette from "./assets/img/bar/juliette.jpg";
import kateJean from "./assets/img/bar/jean-kate.png";
import romyJean from "./assets/img/bar/jean-romy.png";
import charlieJean from "./assets/img/bar/jean-charlie.png";
import aliceJean from "./assets/img/bar/jean-alice.png";
import julietteJean from "./assets/img/bar/jean-juliette.png";
import baner from "./assets/img/histoire/mystory_banner.jpg";
import op1 from "./assets/img/histoire/op1nh.jpg";
import op2 from "./assets/img/histoire/op2nh.jpg";
import op3 from "./assets/img/histoire/op3nh.jpg";
import sign from "./assets/img/histoire/captain_sign.png";
import imageUpload from "./assets/img/upload.png";
import NewHomepage from './Page/NewHomepage';
import sliderIcon from "./assets/img/icones/slider-icon.png";
import "./assets/img/newsletter-bg.jpg";
let interval;
const css = require('css');
const dimensionImage = [{width:433 , height:300},{width:314 , height:300},{width:382 , height:300},{width:314 , height:300},{width:382 , height:300},{width:275 , height:300},{width:329 , height:300},{width:275 , height:300},{width:840 , height:275},{width:552 , height:300},];
const dimensionImageLookBook = [{width:840 , height:300},{width:426 , height:300},{width:354 , height:300},{width:267 , height:300},{width:291 , height:300},{width:450 , height:300},{width:200 , height:300},{width:214 , height:300},{width:200 , height:300},{width:214 , height:300},{width:200 , height:300},{width:214 , height:300},{width:220 , height:300},{width:583 , height:300},{width:442 , height:300},{width:384 , height:300},{width:254 , height:300},{width:384 , height:300},{width:254 , height:300},{width:384 , height:300},{width:254 , height:300},{width:449 , height:300},{width:200 , height:300},{width:216 , height:300},{width:200 , height:300},{width:216 , height:300},{width:200 , height:300},{width:216 , height:300}];
const dimensionImageEngagement = [{width:305 , height:330},{width:305 , height:330},{width:372 , height:330},{width:726 , height:300}]
const dimensionImageRecrutement = [{},{width:683 , height:315},{}];
const dimensionImageHistoire = [{width:500 , height:300},{width:200 , height:300},{width:200 , height:300},{width:200 , height:300},{}];
const dimensionImageNew = [{width:199 , height:300}];
class App extends React.Component {
  constructor(props) {
    super(props);
    this.updatePage = this.updatePage.bind(this);
    this.captureRef = React.createRef();
    this.accordionRefs = Array.from({ length:51}, () => React.createRef());
    this.codeRef = React.createRef();
    this.state = {
      banerSlides:[],
      cardSlides:[],
      texts:[],
      images:[],
      cardType:"",
      html:'',
      htmlToImport:'',
      showPopup:false,
      activePopup:"",
      editObject:null,
      windowSize:"desktop-view",
      currentPage: "",
      currentId:'',
      token:'',
      percentageUpload:0,
      importStatut:'',
      expand:true,
      updateStatus:'',
      currentScreenShot:null,
      currentImage:null,
      currentImageName:null,
      currentImageId:null,
      capturedImage: [{file:'', imagePreviewUrl:''}],
      allPage:[],
      poppinsView:false,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData = async () => {
    try {
      const response = await TutorialDataService.getAll();
      const data = response.data;
      this.setState({ allPage: data });
      var id = window.location.href.split('/')[5];
      if(id){
        this.setPageToEdit(id);
      }
    } catch (error) {
      console.error(error);
    }
  }
  handleElementFocus = (index,id) => {
    // Obtenez la référence (ref) du composant Accordion et définissez isOpen sur true
    if (this.accordionRefs[index].current) {
      this.accordionRefs[index].current.setIsOpen(true);
      setTimeout(() => {
        this.accordionRefs[index].current.scrollIntoView(id);
      }, 0);
    }
  }
  handleUpdateCardSlidesOrder = (orderedCards,type) => {
    if(type=="edit-card") 
      this.setState({cardSlides:orderedCards})
    else if (type=="elements")
      this.setState({texts:orderedCards})
    else
      this.setState({banerSlides:orderedCards})
  }
  setEditedImagePreview = (id,file,preview) => {
    const prevImage = this.state.images.slice();
    prevImage[id].file = file;
    prevImage[id].imagePreviewUrl = preview;
    this.setState({images:prevImage});
  }
  handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section<P
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  logout = () => {
    this.setState({token:null});
    sessionStorage.removeItem("token");
  }
  setTokenOnLocalStorage = (token) => {
    sessionStorage.setItem('token', JSON.stringify(token));
  }
  getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }
  setToken = (token) => {
    this.setState({token:token});
    this.setTokenOnLocalStorage(this.state.token);
  }
  activePoppin = (id) => {
    const texts = this.state.texts.slice();
    if(texts[id].usePoppins!==undefined){
      texts[id].usePoppins = !texts[id].usePoppins;
    }
    else{
      texts[id].usePoppins = true;
    }
    this.setState({texts:texts});
  }
  removePoppin = () => {
    const texts = this.state.texts.slice();
    texts[1].poppinsId = null;
    this.setState({texts:texts});
  }
  setActivePoppins = (id) => {
    const texts = this.state.texts.slice();
    texts[1].poppinsId = id;
    this.togglePopup();
    this.setState({texts:texts});
  }
  resetState = () => {
    this.setState({
      banerSlides:[],
      cardSlides:[],
      texts:[],
      images:[],
      currentScreenShot:null,
      currentImage:null,
      currentImageName:null,
      currentImageId:null,
      capturedImage: [{file:'', imagePreviewUrl:''}]
    })
  }
  setPageToEdit = async (id) => {
    this.setState({ currentId: id });
    try {
      let page = this.state.allPage.find(function(objet) {
        return objet.id === id;
      });
      const { content, contentText, title, contentImage, contentCard, illustration } = page;
      this.setState({
        banerSlides: (title !== "bar à jean"&&title !=="histoire") ? content : null,
        texts: contentText,
        currentPage: title,
        images: contentImage,
        cardSlides: (title !== "lookbook"&&title !=="histoire") ? contentCard : null,
        currentScreenShot: illustration
      });
    } catch (error) {
      console.log(error);
    }
  }
  updatePage = async () => {
    const id = this.state.currentId;
    const title = this.state.currentPage;
    const content = this.state.banerSlides;
    const contentText = this.state.texts;
    const contentImage = this.state.images;
    const contentCard = this.state.cardSlides;
    const illustration = this.state.capturedImage[0].imagePreviewUrl;
    const data = { id, title, content, contentText, contentImage, contentCard, illustration };
    // Supprimer l'ancienne capture d'écran si elle existe
    if (this.state.currentScreenShot && this.state.currentScreenShot.includes("/app/public/")) {
      try {
        const linkParts = this.state.currentScreenShot.split("/");
        const filename = linkParts[linkParts.length - 1];
        await TutorialDataService.deleteImage(filename);
        this.setState({
          currentScreenShot: null
        });
      } catch (error) {
        console.log(error);
      }
    }
    try {
      await TutorialDataService.update(id, data);
      this.togglePopup();
      //mettre à jou le tableau allPage et recupère le createdAt pour s'assurer que c'est une page dupliquée
      const oldAllPage = this.state.allPage.slice();
      const index = oldAllPage.findIndex(objet=>objet.id === id);
      const createdAt = oldAllPage[index].createdAt;
      oldAllPage[index] = data;
      if(createdAt){
        oldAllPage[index].createdAt = createdAt;
      }
      this.setState({allPage:oldAllPage})
    } catch (error) {
      console.log(error);
    }
  }
  uploadAllImage = () => {
    this.togglePopup("save",null,null);
    //telecharger les images et les images de carte si la page est different de lookbook qui n'a pas de cartes avec des images
    this.uploadImage("images");
    if(this.state.currentPage!=="lookbook"&&this.state.currentPage!=="histoire"){
      this.uploadImage("cardSlides");
    }
    //générer la capture d'ecran
    this.setState({updateStatus:"Création de la capture d'écran ..."});
    html2canvas(this.captureRef.current, {
      scale: 0.25, // réduire la taille de l'image capturée
      quality: 0.45, // réduire la qualité de l'image capturée
    }).then((canvas) => {
      this.setState({updateStatus:"Conversion de l'image..."});
      canvas.toBlob((blob) => {
        const file = new File([blob], 'screenshot.jpg', { type: 'image/jpg' });
        const prevCapturedImage = this.state.capturedImage.slice();
        prevCapturedImage[0].file = file;
        this.setState({ capturedImage: prevCapturedImage });
        this.uploadImage("capturedImage");
      }, 'image/jpg', .7)
    });
  }
  uploadImage = (type) => {
    const contentImage = [...this.state[type]];
    contentImage.forEach((image) => {
      if(image.file||image.fileJean||image.fileMobile){
        this.setState({percentageUpload:0});
        const formData = new FormData();
        if (image.file) {
          formData.append('profileImg', image.file);
        }
        else if (image.fileJean) {
          formData.append('profileImg', image.fileJean);
        }
        else if (image.fileMobile){
          formData.append('profileImg', image.fileMobile);
        }
        axios.post("https://editeur.itislive.com/api/tutorials/images/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: data => {
            var filename = image.file ? image.file.name : image.fileJean ? image.fileJean.name : image.fileMobile ? image.fileMobile.name : 'image sans nom';
            this.setState({
              updateStatus:type!='capturedImage' ? 'téléchargement de :'+ filename: "Téléchargement de la capture d'écran",
              percentageUpload: Math.round((100 * data.loaded) / data.total)
            })
          }
          },).then(res => {
            if (image.file) {
              image.file = null;
              image.imagePreviewUrl = res.data.url;
            }
            if (image.fileJean) {
              image.fileJean = null;
              image.imagePreviewUrlJean = res.data.url;
            }
            if (image.fileMobile){
              image.fileMobile = null;
              image.imagePreviewUrlMobile = res.data.url;
            }
            this.setState({
              [type]:contentImage
            })
        })
      }
    });
  }
  handleTextareaImportHtmlChange(event){
    this.setState({ htmlToImport: event.target.value , importStatut: '' });
  }
  importHtml = (e) => {
    e.preventDefault();
    const tempElement = document.createElement('div');
    tempElement.innerHTML = this.state.htmlToImport;
    this.setState({ percentageUpload : 25 , updateStatus: 'Extractions des slides ...'});
    const banerSlides = this.extractBanerSlides(tempElement);
    this.setState({ percentageUpload : 50 , updateStatus: 'Extractions des cartes ...'});
    const cardSlides = this.extractCardSlides(tempElement);
    this.setState({ percentageUpload : 75 , updateStatus: 'Extractions des images ...'});
    const images = this.extractImages(tempElement, this.state.htmlToImport);
    this.setState({ percentageUpload : 100 , updateStatus: 'Extractions des textes ...'});
    const texts = this.extractTexts(tempElement);
    const styles = this.extractStyles(tempElement);
    if(images.length > 0 && texts.length > 0){
      //maj des texts et images suivant le tableau récupéré
      const tempImages = [...this.state.images];
      const tempTexts = [...this.state.texts];
      tempImages.forEach((tempImage, index) => {
        tempImage.imagePreviewUrl = images[index];
      })
      //eto ny modif manaraka
      tempTexts.forEach((tempText, index) => {
        tempText.text = texts[index];
        if (index < styles.length) {
          const styleKeys = Object.keys(styles[index]);
          // Parcourir les clés et mettre à jour les valeurs correspondantes dans "tempTexts[index]"
          for (const key of styleKeys) {
            if (tempText[key] !== undefined && styles[index][key] !== undefined) {
              tempText[key] = styles[index][key];
            }
          }
        }
      })
      this.setState({
        importStatut: 'success',
        banerSlides: banerSlides,
        cardSlides: cardSlides,
        percentageUpload : 0 ,
        updateStatus: '',
        htmlToImport: '',
      })
    }
    else{
      this.setState({
        importStatut: 'error',
        percentageUpload : 0 ,
        updateStatus: '',
        htmlToImport: '',
      })
    }
  };
  extractBanerSlides = (tempElement) => {
    const banerSlides = [];
    if(this.state.currentPage=="homepage"){
      const slider = tempElement.querySelector('.news-slider');
      if (slider) {
        const slideElements = slider.querySelectorAll('.slide');
        slideElements.forEach((slideElement, index) => {
          const newsTextElement = slideElement.querySelector('.news-text');
          const linkElement = newsTextElement.querySelector('a');
          let collectionColors = '';
          let linkText = '';
          let linkHref = '';
          if (linkElement) {
            const textNodes = Array.from(newsTextElement.childNodes).filter(node => node.nodeType === Node.TEXT_NODE);
            collectionColors = textNodes.map(node => node.textContent.trim()).join(' ');
            linkText = linkElement.textContent.trim();
            linkHref = linkElement.getAttribute('href');
          } else {
            collectionColors = newsTextElement.textContent.trim();
          }
          const dataObject = {
            id: index,
            text: collectionColors,
            link: linkText,
            redirection: linkHref,
            type: 'baner-slide'
          };
          banerSlides.push(dataObject);
        });
      }
      const btnElements = tempElement.querySelectorAll('.button-content .btn.with-border');
      let length = banerSlides.length;
      btnElements.forEach((btnElement, index) => {
        const btnText = btnElement.textContent.trim();
        const btnHref = btnElement.getAttribute('href');
        const dataObject = {
          id: length + index,
          text: btnText,
          redirection: btnHref,
          type: 'button'
        };
        banerSlides.push(dataObject);
      });
    }else if(this.state.currentPage=="lookbook"){
      const links = tempElement.querySelectorAll('.bloc-link .bloc-subtitle a');
      links.forEach((link, index) => {
        var type = null;
        if(link.closest('.bloc').classList.contains('second')){
          if (link.parentElement.parentElement.parentElement.classList.contains('first-content')) {
            type = 'block-2';
          }
          else if(link.parentElement.parentElement.parentElement.classList.contains('second-content')) {
            type = 'block-3';
          }
        }
        else if(link.closest('.bloc').classList.contains('third')){
          type='block-4'
        }
        else if(link.closest('.bloc').classList.contains('fourth')){
          if (link.parentElement.parentElement.parentElement.classList.contains('first-content')) {
            type = 'block-5';
          }
          else if(link.parentElement.parentElement.parentElement.classList.contains('second-content')) {
            type = 'block-6';
          }
          else if(link.parentElement.parentElement.parentElement.classList.contains('third-content')) {
            type = 'block-7';
          }
        }
        else if(link.closest('.bloc').classList.contains('fifth')){
          if (link.parentElement.parentElement.parentElement.classList.contains('first-content')) {
            type = 'block-8';
          }
          else if(link.parentElement.parentElement.parentElement.classList.contains('second-content')) {
            type = 'block-9';
          }
        }
        else if(link.closest('.bloc').classList.contains('sixth')){
          if (link.parentElement.parentElement.parentElement.classList.contains('first-content')) {
            type = 'block-10';
          }
          else if(link.parentElement.parentElement.parentElement.classList.contains('second-content')) {
            type = 'block-11';
          }
          else if(link.parentElement.parentElement.parentElement.classList.contains('third-content')) {
            type = 'block-12';
          }
        }
        else if(link.closest('.bloc').classList.contains('seventh')){
          type= 'block-13'
        }
        else if(link.closest('.bloc').classList.contains('eighth')){
          if (link.parentElement.parentElement.parentElement.classList.contains('first-content')) {
            type = 'block-14';
          }
          else if(link.parentElement.parentElement.parentElement.classList.contains('second-content')) {
            type = 'block-15';
          }
          else if(link.parentElement.parentElement.parentElement.classList.contains('third-content')) {
            type = 'block-16';
          }
        }
        const btnText = link.textContent.trim();
        const btnHref = link.getAttribute('href');
        const dataObject = {
          id: index,
          text: btnText,
          redirection: btnHref,
          type: type
        };
        banerSlides.push(dataObject);
      });
    }else if(this.state.currentPage==="recrutement"){
      const btnElementSlider = tempElement.querySelectorAll('.bloc-videoslider .video-slider .carousel-indicators button');
      const linksIframe = tempElement.querySelectorAll('.bloc-videoslider .video-slider .carousel-inner .carousel-item .video-container iframe');
      let length = banerSlides.length;
      btnElementSlider.forEach((btnElement, index) => {
        const btnText = btnElement.textContent.trim();
        const btnHref = linksIframe[index].getAttribute('src');
        const dataObject = {
          id: length + index,
          text: btnText,
          redirection: btnHref,
          type: 'iframe-slide'
        };
        banerSlides.push(dataObject);
      });
      const elementSuitBanner = tempElement.querySelectorAll('.bloc-video .container .col-md-5 p');
      elementSuitBanner.forEach((paragraph, index) => {
        // Récupérez le texte contenu dans l'élément <p> (sans les balises <strong>)
        const textWithoutStrong = Array.from(paragraph.childNodes)
        .filter(node => node.nodeType === Node.TEXT_NODE)
        .map(node => node.textContent)
        .join('')
        .trim();
        // Récupérez le texte contenu dans la balise <strong> au sein de l'élément <p>
        const strongText = paragraph.querySelector('strong').textContent;
        const dataObject = {
          id: btnElementSlider.length + index,
          text: strongText,
          redirection: textWithoutStrong,
          type: 'fun'
        };
        banerSlides.push(dataObject);
      });
    }
    return banerSlides;
  };
  extractCardSlides = (tempElement) => {
    const cardSlides = [];
    if(this.state.currentPage=="homepage"){
      const sliders = tempElement.querySelector('.card-slider');
      if (sliders) {
        const cardElements = sliders.querySelectorAll('.slide');
        cardElements.forEach((cardElement, index) => {
          const cardText = cardElement.querySelector('.card-title').textContent.trim();
          const cardImage = cardElement.querySelector('img').getAttribute('src');
          const dataObject = {
            id: index,
            text: cardText,
            file: '',
            imagePreviewUrl: cardImage,
            type: 'card-slide'
          };
          cardSlides.push(dataObject);
        });
      }
    }else if(this.state.currentPage=="engagement"){
      //obtenir les cartes de engagements dans "Nos engagements responsables"
      const row = tempElement.querySelector('.front .engagement .section.responsable .row');
      if (row) {
        const cardElements = row.querySelectorAll('.col-lg-2');
        cardElements.forEach((cardElement, index) => {
          const cardText = cardElement.querySelector('.card-text').textContent.trim();
          const cardImage = cardElement.querySelector('img').getAttribute('src');
          const dataObject = {
            id: index,
            text: cardText,
            file: '',
            imagePreviewUrl: cardImage,
            type: 'card-slide'
          };
          cardSlides.push(dataObject);
        });
      }
      //obtenir la liste des engagement
      const engagementList = tempElement.querySelector('.front .engagement .section.engagement-list');
      const length = cardSlides.length;
      if (engagementList) {
        const cardElements = engagementList.querySelectorAll('.row');
        cardElements.forEach((cardElement, index) => {
          const cardText = cardElement.querySelector('.bloc-text .para').textContent.trim();
          const cardImage = cardElement.querySelector('.col-lg-6 .img-fluid').getAttribute('src');
          const cardLink = cardElement.querySelector('.bloc-text .ttr').innerHTML.split('<span class="italic-font">')[0];
          const cardRedirection = cardElement.querySelector('.bloc-text .ttr span').textContent.trim();
          const dataObject = {
            id: length + index,
            link: cardLink,
            redirection: cardRedirection,
            text: cardText,
            file: '',
            imagePreviewUrl: cardImage,
            type: 'card-engagement'
          };
          cardSlides.push(dataObject);
        });
      }
      //obtenir les cartes dans "plus loin"
      const horizontalCards = tempElement.querySelector('.front .engagement .section.plus-loin .horizontal-cards');
      if (horizontalCards) {
        const cardElements = horizontalCards.querySelectorAll('.d-flex.align-items-center');
        cardElements.forEach((cardElement, index) => {
          const cardText = cardElement.querySelector('.para').textContent.trim();
          const cardImage = cardElement.querySelector('.pic img').getAttribute('src');
          const dataObject = {
            id: length + index,
            text: cardText,
            file: '',
            imagePreviewUrl: cardImage,
            type: 'card-plus-loin'
          };
          cardSlides.push(dataObject);
        });
      }
      //obtenir les faqs
      const faqs = tempElement.querySelector('.front .engagement .section.faq-content .faq-list');
      if (faqs) {
        const cardElements = faqs.querySelectorAll('li');
        cardElements.forEach((cardElement, index) => {
          const cardQuestion = cardElement.querySelector('.faq-title').innerHTML.split('<!-- -->')[0].trim();
          const cardText = cardElement.querySelector('div p').textContent.trim();
          const dataObject = {
            id: length + index,
            link: cardQuestion,
            text: cardText,
            type: 'faq'
          };
          cardSlides.push(dataObject);
        });
      }
    }else if(this.state.currentPage=="recrutement"){
      const elementBloc = tempElement.querySelectorAll('.bloc-illus .row .col-md-6');
      const elementBlocReseau = tempElement.querySelectorAll('.bloc-logos .col-md-3 img');
      const elementCarousel = tempElement.querySelectorAll('.bloc-carrousel .carousel .carousel-item .soon');
      const elementLearnMore = tempElement.querySelectorAll('.bloc-2column .wrap');
      const elementPresse = tempElement.querySelectorAll('.bloc-presse .container .py-4 .row');
      let length = cardSlides.length;
      elementBloc.forEach((element, index) => {
      const title = element.querySelector('.col-8 h4').textContent.trim();
      const paragraphe = element.querySelector('.col-8 p').textContent.trim();
      const img = element.querySelector('.col-4 img').getAttribute('src');
        const dataObject = {
          id: length + index,
          link: title,
          text: paragraphe,
          file: '',
          imagePreviewUrl: img,
          type: 'card-recrutement'
        };
        cardSlides.push(dataObject);
      });
      elementBlocReseau.forEach((element, index) => {
        const dataObject = {
          id: elementBloc.length + index,
          file: '',
          imagePreviewUrl: element.getAttribute('src'),
          type: 'reseaux'
        };
        cardSlides.push(dataObject);
      });
      elementCarousel.forEach((element, index) => {
        const img = element.querySelector('.pc-visu img').getAttribute('src');
        const dataObject = {
          id: elementBloc.length + elementBlocReseau.length + index,
          file: '',
          imagePreviewUrl: img,
          type: 'carousel'
        };
        cardSlides.push(dataObject);
      });
      elementLearnMore.forEach((element, index) => {
        const divElement = element.querySelector('.item');
        const h2Element = divElement.querySelector('h2');
        const paragraphElements = divElement.querySelectorAll('p');
        const listItems = divElement.querySelectorAll('ul li');
        const extractedText = [];
        paragraphElements.forEach(paragraph => {
          const text = paragraph.textContent.trim();
          if (text !== '')
            extractedText.push(text);
        });
        listItems.forEach(item => {
          const text = item.textContent.trim();
          if (text !== '')
          extractedText.push(`->${text}`);
        });
        const combinedParagraphs = extractedText.join('\n');
        const img = element.querySelector('.row img').getAttribute('src');
        const dataObject = {
          id: elementBloc.length + elementBlocReseau.length + elementCarousel.length + index,
          link:h2Element.textContent.trim(),
          text:combinedParagraphs,
          file: '',
          imagePreviewUrl: img,
          type: 'savoir-plus'
        };
        cardSlides.push(dataObject);
      });
      elementPresse.forEach((element, index) => {
        const paragraphe = element.querySelector('p').textContent.trim();
        const img = element.querySelector('img').getAttribute('src');
        const dataObject = {
          id: elementBloc.length + elementBlocReseau.length + elementCarousel.length + elementLearnMore.length + index,
          text: paragraphe,
          file: '',
          imagePreviewUrl: img,
          type: 'card-slide'
        };
        cardSlides.push(dataObject);
      });
    }
    return cardSlides;
  };
  extractImages = (tempElement, htmlToImport) => {
    const images = [];
    const extractImageUrl = (regex) => {
      const matches = regex.exec(htmlToImport);
      return matches && matches.length > 1 ? matches[1] : '';
    };
    const extractImage = (selector) => {
      const element = tempElement.querySelector(selector);
      return element ? element.getAttribute('src') : '';
    };
    if(this.state.currentPage=="homepage"){
      images[0] = extractImageUrl(/\.section \.jumbotron{background-image:url\((.*?)\);/);
      images[1] = extractImage('a.bloc.first img.bloc-bg');
      images[2] = extractImage('a.bloc.first img.bloc-bg-mobile');
      images[3] = extractImage('a.bloc.second img.bloc-bg');
      images[4] = extractImage('a.bloc.second img.bloc-bg-mobile');
      images[5] = extractImage('.large-bloc .horizontal-bloc img.bloc-bg');
      images[6] = extractImage('.large-bloc .horizontal-bloc.second img.img-fluid');
      images[7] = extractImage('.large-bloc .horizontal-bloc img.bloc-bg-mobile');
      images[8] = extractImage('.bloc.fourth img.bloc-bg');
      images[9] = extractImage('.bloc.fourth img.bloc-bg-mobile');
    }
    else if(this.state.currentPage=="lookbook"){
      images[0] = extractImage('.bloc.first .bloc-link img.bloc-bg');
      images[1] = extractImage('.bloc.first .bloc-link img.bloc-bg-mobile');
      images[2] = extractImage('.bloc.second .first-content .bloc-link img.bloc-bg');
      images[3] = extractImage('.bloc.second .first-content .bloc-link img.bloc-bg-mobile');
      images[4] = extractImage('.bloc.second .second-content .bloc-link img');
      images[5] = extractImage('.bloc.third .first-content .bloc-link img');
      images[6] = extractImage('.bloc.fourth .first-content .bloc-link img.bloc-bg');
      images[7] = extractImage('.bloc.fourth .first-content .bloc-link img.bloc-bg-mobile');
      images[8] = extractImage('.bloc.fourth .second-content .bloc-link img.bloc-bg');
      images[9] = extractImage('.bloc.fourth .second-content .bloc-link img.bloc-bg-mobile');
      images[10] = extractImage('.bloc.fourth .third-content .bloc-link img.bloc-bg');
      images[11] = extractImage('.bloc.fourth .third-content .bloc-link img.bloc-bg-mobile');
      images[12] = extractImage('.bloc.fifth .first-content .bloc-link img.bloc-bg');
      images[13] = extractImage('.bloc.fifth .second-content .bloc-link img.bloc-bg');
      images[14] = extractImage('.bloc.fifth .second-content .bloc-link img.bloc-bg-mobile');
      images[15] = extractImage('.bloc.sixth .first-content .bloc-link img.bloc-bg');
      images[16] = extractImage('.bloc.sixth .first-content .bloc-link img.bloc-bg-mobile');
      images[17] = extractImage('.bloc.sixth .second-content .bloc-link img.bloc-bg');
      images[18] = extractImage('.bloc.sixth .second-content .bloc-link img.bloc-bg-mobile');
      images[19] = extractImage('.bloc.sixth .third-content .bloc-link img.bloc-bg');
      images[20] = extractImage('.bloc.sixth .third-content .bloc-link img.bloc-bg-mobile');
      images[21] = extractImage('.bloc.seventh .second-content .bloc-link img.bloc-bg');
      images[22] = extractImage('.bloc.eighth .first-content .bloc-link img.bloc-bg');
      images[23] = extractImage('.bloc.eighth .first-content .bloc-link img.bloc-bg-mobile');
      images[24] = extractImage('.bloc.eighth .second-content .bloc-link img.bloc-bg');
      images[25] = extractImage('.bloc.eighth .second-content .bloc-link img.bloc-bg-mobile');
      images[26] = extractImage('.bloc.eighth .third-content .bloc-link img.bloc-bg');
      images[27] = extractImage('.bloc.eighth .third-content .bloc-link img.bloc-bg-mobile');
    }
    else if(this.state.currentPage=="engagement"){
      images[0] = extractImage('.front .section.preview .large-bloc .col-md-4:first-child img.bloc-bg');
      images[1] = extractImage('.front .section.preview .large-bloc .col-md-4:last-child img');
      images[2] = extractImage('.front .engagement .section.captain-engagement .text-content article img.d-lg-none');
      images[3] = extractImage('.front .engagement .section.faq img');
    }else if(this.state.currentPage=="recrutement"){
      images[0] = extractImage('header a img');
      images[1] = extractImageUrl(/\.bloc-hero:before{background-image:url\((.*?)\)}/);
      images[2] = extractImage('footer .col-md-2 img');
    }
    return images;
  };
  extractTexts = (tempElement) => {
    const extractText = (selector) => {
      const element = tempElement.querySelector(selector);
      //bien regarder les conditions
      return element ? (selector==".jumbotron .ttr"||selector==".front.engagement .bloc-hero h1"||selector==".front.engagement .bloc-video h2"||selector==".front.engagement .bloc-videoslider h2" ? element.innerHTML.split('<br>')[0] : selector==".front .engagement .section.captain-engagement .text-content article .ttr" ? element.innerHTML.split('</span>')[1] : selector==".front .engagement .section.plus-loin .ttr"||selector==".front.engagement .formulaire h2"||selector==".front.engagement .bloc-2column .container h2 div:last-child" ? element.innerHTML.split('<span>')[0] : selector==".front.engagement .bloc-hero h1 span" ? element.innerHTML.replace(/<br\s*\/?>/g, '\n').trim() : selector==".front.engagement .formulaire .hs-richtext" ? element.innerHTML.split('<a')[0].trim() : selector==".front.engagement .bloc-video h2 .txtweight-300" ? element.innerHTML.split('<div>')[0].trim() : selector==".front.engagement .video-container iframe" ? element.getAttribute('src') : selector==".front.engagement .bloc-videoslider .col-md-5 ul" ? Array.from(element.querySelectorAll('li')).map(li => `->${li.textContent}`).join('\n') : element.textContent.trim()) : '';
    };
    const texts = [];
    if(this.state.currentPage=="homepage"){
      texts[1] = extractText('.jumbotron .ttr');
      texts[2] = extractText('.jumbotron .ttr span');
      texts[3] = extractText('.jumbotron .btn');
      texts[6] = extractText('.bandeau-remise .remise-title');
      texts[7] = extractText('.bandeau-remise .remise-text');
      texts[9] = extractText('.bloc.first .bloc-text-content .bloc-text');
      texts[10] = extractText('.bloc.second .bloc-text-content .ttr span');
      texts[11] = extractText('.bloc.second .bloc-text-content .bloc-text');
      texts[13] = extractText('.large-bloc .special .bloc.third .ttr');
      texts[14] = extractText('.large-bloc .special .bloc.third .btn');
      texts[15] = extractText('.bloc.fourth .ttr');
      texts[16] = extractText('.bloc.fourth .btn');
      texts[18] = extractText('.bloc.first .bloc-text-content .ttr span');
      texts[19] = extractText('.bloc.first .bloc-text-content .ttr span.italic-font');
    }
    else if(this.state.currentPage=="lookbook"){
      texts[0] = extractText('.bloc-wrapper .bloc.first .bloc-link .bloc-title');
      texts[1] = extractText('.bloc-wrapper .bloc.third .second-content .ttr');
      texts[2] = extractText('.bloc-wrapper .bloc.third .second-content .para');
      texts[3] = extractText('.bloc-wrapper .bloc.seventh .first-content .ttr');
      texts[4] = extractText('.bloc-wrapper .bloc.seventh .first-content .para');
    }
    else if(this.state.currentPage=="engagement"){
      texts[0] = extractText('.front .engagement .section.preview .large-bloc .bloc.third .ttr');
      texts[1] = extractText('.front .engagement .section.captain-engagement .text-content article .ttr span');
      texts[2] = extractText('.front .engagement .section.captain-engagement .text-content article .ttr');
      texts[3] = extractText('.front .engagement .section.captain-engagement .text-content article .para');
      texts[4] = extractText('.front .engagement .section.responsable .ttr');
      texts[8] = extractText('.front .engagement .section.plus-loin .ttr');
      texts[9] = extractText('.front .engagement .section.plus-loin .ttr span');
      texts[10] = extractText('.front .engagement .section.faq .text-content .ttr');
      texts[11] = extractText('.front .engagement .section.question .ttr');
      texts[12] = extractText('.front .engagement .section.question .para');
      texts[13] = extractText('.front .engagement .section.question .btn');
    }
    else if(this.state.currentPage=="recrutement"){
      texts[0] = extractText('.front.engagement .bloc-hero h1');
      texts[1] = extractText('.front.engagement .bloc-hero h1 span');
      texts[2] = extractText('.front.engagement .formulaire h2');
      texts[3] = extractText('.front.engagement .formulaire h2 span');
      texts[4] = extractText('.front.engagement .formulaire p');
      texts[5] = extractText('.front.engagement .formulaire .hs-richtext');
      texts[6] = extractText('.front.engagement .formulaire .hs-richtext a');
      texts[7] = extractText('.front.engagement .bloc-video h2');
      texts[8] = extractText('.front.engagement .bloc-video h2 .txtweight-300');
      texts[9] = extractText('.front.engagement .bloc-video h2 .txtweight-300 div');
      texts[10] = extractText('.front.engagement .video-container iframe');
      texts[11] = extractText('.front.engagement .bloc-video .col-md-5 h3');
      texts[13] = extractText('.front.engagement .bloc-logos h2');
      texts[14] = extractText('.front.engagement .bloc-videoslider h2');
      texts[15] = extractText('.front.engagement .bloc-videoslider h2 span');
      texts[16] = extractText('.front.engagement .bloc-videoslider .col-md-5 h4');
      texts[17] = extractText('.front.engagement .bloc-videoslider .col-md-5 ul');
      texts[18] = extractText('.front.engagement .bloc-illus h2');
      texts[19] = extractText('.front.engagement .bloc-illus h3');
      texts[20] = extractText('.front.engagement .btn-bottom .btn');
      texts[21] = extractText('.front.engagement .bloc-2column .container h2 .txtweight-300');
      texts[22] = extractText('.front.engagement .bloc-2column .container h2 div:last-child');
      texts[23] = extractText('.front.engagement .bloc-2column .container h2 div span');
      texts[26] = extractText('.front.engagement .bloc-presse h2');
      texts[27] = extractText('.front.engagement footer .title');
      texts[28] = extractText('.front.engagement footer .text');
    }
    return texts;
  };
  extractStyles = (tempElement) => {
    //cette fonction sert à recuperer les valeurs dans le <style> du html
    const styleTag = tempElement.querySelector('style');
    const texts = [];
    if (styleTag) {
      const cssContent = styleTag.textContent;
      const parsedCSS = css.parse(cssContent);
      const findRule = (selector) => {
        return parsedCSS.stylesheet.rules.find((rule) => rule.selectors && rule.selectors.includes(selector));
      };
      const findMediaRule = (selector,media) => {
        const mediaRule = parsedCSS.stylesheet.rules.find((rule) => rule.type === 'media' && rule.media === media);
        return mediaRule.rules.find((rule) => rule.selectors && rule.selectors.includes(selector));
      };
      const findDeclaration = (rule, property) => {
        return rule ? rule.declarations.find((declaration) => declaration.property === property) : null;
      };
      if(this.state.currentPage=="homepage"){
        texts[0] = {
          background: findDeclaration(findRule('.front .news-slider'), 'background').value,
          color: findDeclaration(findRule('.front .news-slider .news-text'), 'color').value,
          size: parseInt(findDeclaration(findRule('.front .news-slider .news-text'), 'font-size').value),
          weight: findDeclaration(findRule('.front .news-slider .news-text'), 'font-weight').value,
          font: findDeclaration(findRule('.front .news-slider .news-text'), 'font-family').value.replace(',sans-serif', ''),
          align: findDeclaration(findRule('.front .news-slider .news-text'), 'text-align').value,
          mt: parseInt(findDeclaration(findRule('.front .news-slider'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front .news-slider'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front .news-slider'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front .news-slider'), 'padding').value?.replace('px', '').split(' ')[3]),
          sizeMobile:parseInt(findDeclaration(findMediaRule('.front .news-slider .news-text','(max-width: 992px)'), 'font-size').value)
        };
        texts[1] = {
          align: findDeclaration(findRule('.front .section .jumbotron .content'), 'text-align').value,
          mt: parseInt(findDeclaration(findRule('.front .section .jumbotron'), 'padding-top').value?.replace('px', '')),
          mb: parseInt(findDeclaration(findRule('.front .section .jumbotron'), 'padding-bottom').value?.replace('px', '')),
          size: parseInt(findDeclaration(findRule('.front .section .jumbotron .ttr'), 'font-size').value),
          weight: findDeclaration(findRule('.front .section .jumbotron .ttr'), 'font-weight').value,
          font: findDeclaration(findRule('.front .section .jumbotron .ttr'), 'font-family').value.replace(',sans-serif', ''),
          color: findDeclaration(findRule('.front .section .jumbotron .ttr'), 'color').value,
          sizeMobile:parseInt(findDeclaration(findMediaRule('.front .section .jumbotron .ttr','(max-width: 992px)'), 'font-size').value)
        }
        texts[2] = {
          background: findDeclaration(findRule('.front'), 'background-color').value,
          ml: parseInt(findDeclaration(findRule('.front .content'), 'padding-left').value?.replace('px', '')),
          mr: parseInt(findDeclaration(findRule('.front .content'), 'padding-right').value?.replace('px', '')),
          size: parseInt(findDeclaration(findRule('.front .section .jumbotron .ttr span'), 'font-size').value),
          weight: findDeclaration(findRule('.front .section .jumbotron .ttr span'), 'font-weight').value,
          font: findDeclaration(findRule('.front .section .jumbotron .ttr span'), 'font-family').value.replace(',sans-serif', ''),
          color: findDeclaration(findRule('.front .section .jumbotron .ttr span'), 'color').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section .jumbotron .ttr span','(max-width: 992px)'), 'font-size').value)
        }
        texts[3] = {
          background:findDeclaration(findRule('.front .section .jumbotron .btn'), 'background-color').value,
          color: findDeclaration(findRule('.front .section .jumbotron .btn'), 'color').value,
          size: parseInt(findDeclaration(findRule('.front .section .jumbotron .btn'), 'font-size').value),
          weight: findDeclaration(findRule('.front .section .jumbotron .btn'), 'font-weight').value,
          font: findDeclaration(findRule('.front .section .jumbotron .btn'), 'font-family').value.replace(',sans-serif', ''),
          mt: parseInt(findDeclaration(findRule('.front .section .jumbotron .btn'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front .section .jumbotron .btn'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front .section .jumbotron .btn'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front .section .jumbotron .btn'), 'padding').value?.replace('px', '').split(' ')[3]),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section .jumbotron .btn','(max-width: 992px)'), 'font-size').value)
        }
        texts[4] = {
          align: findDeclaration(findRule('.front .section.preview .card-content .card-title'), 'text-align').value,
          color: findDeclaration(findRule('.front .section.preview .card-content .card-title'), 'color').value,
          size: parseInt(findDeclaration(findRule('.front .section.preview .card-content .card-title'), 'font-size').value),
          font: findDeclaration(findRule('.front .section.preview .card-content .card-title'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .section.preview .card-content .card-title'), 'font-weight').value,
          ml: parseInt(findDeclaration(findMediaRule('.front .content','(max-width: 992px)'), 'padding-left').value?.replace('px', '')),
          mr: parseInt(findDeclaration(findMediaRule('.front .content','(max-width: 992px)'), 'padding-right').value?.replace('px', '')),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .card-content .card-title','(max-width: 992px)'), 'font-size').value)
        }
        texts[5] = {
          background:findDeclaration(findRule('.front .section.preview .bandeau-remise'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front .section.preview .bandeau-remise'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front .section.preview .bandeau-remise'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front .section.preview .bandeau-remise'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front .section.preview .bandeau-remise'), 'padding').value?.replace('px', '').split(' ')[3]),
          align: findDeclaration(findRule('.front .section.preview .bandeau-remise'), 'text-align').value,
        }
        texts[6] = {
          size: parseInt(findDeclaration(findRule('.front .section.preview .bandeau-remise .remise-title'), 'font-size').value),
          color: findDeclaration(findRule('.front .section.preview .bandeau-remise .remise-title'), 'color').value,
          font: findDeclaration(findRule('.front .section.preview .bandeau-remise .remise-title'), 'font-family').value.replace(', sans-serif', ''),
          weight: findDeclaration(findRule('.front .section.preview .bandeau-remise .remise-title'), 'font-weight').value,
          mt: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc .bloc-text-content','(max-width: 1280px)'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc .bloc-text-content','(max-width: 1280px)'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc .bloc-text-content','(max-width: 1280px)'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc .bloc-text-content','(max-width: 1280px)'), 'padding').value?.replace('px', '').split(' ')[3]),
          align: findDeclaration(findMediaRule('.front .section .jumbotron .ttr','(max-width: 992px)'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .bandeau-remise .remise-title','(max-width: 992px)'), 'font-size').value)
        }
        texts[7] = {
          color: findDeclaration(findRule('.front .section.preview .bandeau-remise .remise-text'), 'color').value,
          size: parseInt(findDeclaration(findRule('.front .section.preview .bandeau-remise .remise-text'), 'font-size').value),
          font: findDeclaration(findRule('.front .section.preview .bandeau-remise .remise-text'), 'font-family').value.replace(', sans-serif', ''),
          weight: findDeclaration(findRule('.front .section.preview .bandeau-remise .remise-text'), 'font-weight').value,
          mt: parseInt(findDeclaration(findMediaRule('.front .news-slider','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findMediaRule('.front .news-slider','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findMediaRule('.front .news-slider','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findMediaRule('.front .news-slider','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[3]),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .bandeau-remise .remise-text','(max-width: 992px)'), 'font-size').value)
        }
        texts[8] = {
          color: findDeclaration(findRule('.front .section.preview .bloc .ttr'), 'color').value,
          weight: findDeclaration(findRule('.front .section.preview .bloc .ttr'), 'font-weight').value,
          font: findDeclaration(findRule('.front .section.preview .bloc .ttr'), 'font-family').value.replace(',sans-serif', ''),
          size: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.third .ttr span','(max-width: 1280px)'), 'font-size').value),
          mt: parseInt(findDeclaration(findMediaRule('.front .section .jumbotron .btn','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findMediaRule('.front .section .jumbotron .btn','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findMediaRule('.front .section .jumbotron .btn','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findMediaRule('.front .section .jumbotron .btn','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[3]),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.third .ttr span','(max-width: 992px)'), 'font-size').value)
        }
        texts[9] = {
          color: findDeclaration(findRule('.front .section.preview .bloc .bloc-text'), 'color').value,
          weight: findDeclaration(findRule('.front .section.preview .bloc .bloc-text'), 'font-weight').value,
          font: findDeclaration(findRule('.front .section.preview .bloc .bloc-text'), 'font-family').value.replace(',sans-serif', ''),
          align: findDeclaration(findRule('.front .section.preview .bloc.first .bloc-text'), 'text-align').value,
          size: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc .bloc-text','(max-width: 1280px)'), 'font-size').value),
          mt: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.second .bloc-text-content','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.second .bloc-text-content','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.second .bloc-text-content','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.second .bloc-text-content','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[3]),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc .bloc-text','(max-width: 992px)'), 'font-size').value)
        }
        texts[10] = {
          background:findDeclaration(findRule('.front .section.preview .bloc.second .ttr span'), 'background-color').value,
          align: findDeclaration(findRule('.front .section.preview .bloc.second .bloc-text-content .ttr'), 'text-align').value,
          mt: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.fourth .btn','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.fourth .btn','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.fourth .btn','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.fourth .btn','(max-width: 992px)'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[11] = {
          align: findDeclaration(findRule('.front .section.preview .bloc.second .bloc-text-content .bloc-text'), 'text-align').value,
        }
        texts[12] = {
          align: findDeclaration(findRule('.front .section.about .col-md-3 .about-title'), 'text-align').value,
          size: parseInt(findDeclaration(findRule('.front .section.about .col-md-3 .about-title'), 'font-size').value),
          weight: findDeclaration(findRule('.front .section.about .col-md-3 .about-title'), 'font-weight').value,
          color: findDeclaration(findRule('.front .section.about .col-md-3 .about-title'), 'color').value,
          font: findDeclaration(findRule('.front .section.about .col-md-3 .about-title'), 'font-family').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.about .col-md-3 .about-title','(max-width: 992px)'), 'font-size').value)
        }
        texts[13] = {
          align: findDeclaration(findRule('.front .section.preview .bloc.third .ttr span'), 'text-align').value,
          background:findDeclaration(findRule('.front .section.preview .bloc.third .ttr span'), 'background-color').value,
          color: findDeclaration(findRule('.front .section.preview .bloc.third .ttr span'), 'color').value,
          weight: findDeclaration(findRule('.front .section.preview .bloc.third .ttr span'), 'font-weight').value,
          font: findDeclaration(findRule('.front .section.preview .bloc.third .ttr span'), 'font-family').value,
          size: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.third .ttr span','(max-width: 1280px)'), 'font-size').value),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.third .ttr span','(max-width: 992px)'), 'font-size').value),
        }
        texts[14] = {
          color: findDeclaration(findRule('.front .section.preview .large-bloc .bloc .btn'), 'color').value,
          size: parseInt(findDeclaration(findRule('.front .section.preview .large-bloc .bloc .btn'), 'font-size').value),
          font: findDeclaration(findRule('.front .section.preview .large-bloc .bloc .btn'), 'font-family').value,
          weight: findDeclaration(findRule('.front .section.preview .large-bloc .bloc .btn'), 'font-weight').value,
          mt: parseInt(findDeclaration(findRule('.front .section.preview .large-bloc .bloc .btn'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front .section.preview .large-bloc .bloc .btn'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front .section.preview .large-bloc .bloc .btn'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front .section.preview .large-bloc .bloc .btn'), 'padding').value?.replace('px', '').split(' ')[3]),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .large-bloc .bloc .btn','(max-width: 992px)'), 'font-size').value),
        }
        texts[15] = {
          align: findDeclaration(findRule('.front .section.preview .bloc.fourth .ttr'), 'text-align').value,
          color: findDeclaration(findRule('.front .section.preview .bloc.fourth .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .section.preview .bloc.fourth .ttr'), 'font-family').value,
          weight: findDeclaration(findRule('.front .section.preview .bloc.fourth .ttr'), 'font-weight').value,
          size: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.fourth .ttr','(max-width: 1280px)'), 'font-size').value),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.fourth .ttr','(max-width: 992px)'), 'font-size').value),
        }
        texts[16] = {
          background:findDeclaration(findRule('.front .section.preview .bloc.fourth .btn'), 'background-color').value,
          size: parseInt(findDeclaration(findRule('.front .section.preview .bloc.fourth .btn'), 'font-size').value),
          color: findDeclaration(findRule('.front .section.preview .bloc.fourth .btn'), 'color').value,
          font: findDeclaration(findRule('.front .section.preview .bloc.fourth .btn'), 'font-family').value,
          weight: findDeclaration(findRule('.front .section.preview .bloc.fourth .btn'), 'font-weight').value,
          mt: parseInt(findDeclaration(findRule('.front .section.preview .bloc.fourth .btn'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front .section.preview .bloc.fourth .btn'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front .section.preview .bloc.fourth .btn'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front .section.preview .bloc.fourth .btn'), 'padding').value?.replace('px', '').split(' ')[3]),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .bloc.fourth .btn','(max-width: 992px)'), 'font-size').value),
        }
        texts[17] = {
          color: findDeclaration(findRule('.front .section.preview .button-content .btn'), 'color').value,
          font: findDeclaration(findRule('.front .section.preview .button-content .btn'), 'font-family').value,
          weight: findDeclaration(findRule('.front .section.preview .button-content .btn'), 'font-weight').value,
          size: parseInt(findDeclaration(findRule('.front .section.preview .button-content .btn'), 'font-size').value),
          mt: parseInt(findDeclaration(findRule('.front .section.preview .button-content .btn'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front .section.preview .button-content .btn'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front .section.preview .button-content .btn'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front .section.preview .button-content .btn'), 'padding').value?.replace('px', '').split(' ')[3]),
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .section.preview .button-content .btn','(max-width: 992px)'), 'font-size').value),
        }
        texts[18] = {
          background: findDeclaration(findRule('.front .section.preview .bloc.first .ttr span'), 'background-color').value,
          align: findDeclaration(findRule('.front .section.preview .bloc.first .ttr'), 'text-align').value,
        }
        texts[19] = {}
      }else if(this.state.currentPage=="lookbook"){
        texts[0] = {
          size: parseInt(findDeclaration(findRule('.front .bloc-wrapper .bloc .bloc-link .bloc-title'), 'font-size').value),
          color: findDeclaration(findRule('.front .bloc-wrapper .bloc .bloc-link .bloc-title'), 'color').value,
          font: findDeclaration(findRule('.front .bloc-wrapper .bloc .bloc-link .bloc-title'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .bloc-wrapper .bloc .bloc-link .bloc-title'), 'font-weight').value,
          align: findDeclaration(findRule('.front .bloc-wrapper .bloc .bloc-link .bloc-title'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .bloc-wrapper .bloc .bloc-link .bloc-title','(max-width: 991px)'), 'font-size').value),
        }
        texts[1] = {
          size: parseFloat(findDeclaration(findRule('.front .bloc-wrapper .bloc.third .second-content .ttr'), 'font-size').value),
          color: findDeclaration(findRule('.front .bloc-wrapper .bloc.third .second-content .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .bloc-wrapper .bloc.third .second-content .ttr'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .bloc-wrapper .bloc.third .second-content .ttr'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .bloc-wrapper .bloc.third .second-content .ttr','(max-width: 767px)'), 'font-size').value),
        }
        texts[2] = {
          size: parseFloat(findDeclaration(findRule('.front .bloc-wrapper .bloc.third .second-content .para'), 'font-size').value),
          color: findDeclaration(findRule('.front .bloc-wrapper .bloc.third .second-content .para'), 'color').value,
          font: findDeclaration(findRule('.front .bloc-wrapper .bloc.third .second-content .para'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .bloc-wrapper .bloc.third .second-content .para'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .bloc-wrapper .bloc.third .second-content .para','(max-width: 767px)'), 'font-size').value),
        }
        texts[3] = {
          size: parseFloat(findDeclaration(findRule('.front .bloc-wrapper .bloc.seventh .ttr'), 'font-size').value),
          color: findDeclaration(findRule('.front .bloc-wrapper .bloc.seventh .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .bloc-wrapper .bloc.seventh .ttr'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .bloc-wrapper .bloc.seventh .ttr'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .bloc-wrapper .bloc.seventh .ttr','(max-width: 767px)'), 'font-size').value),
        }
        texts[4] = {
          size: parseFloat(findDeclaration(findRule('.front .bloc-wrapper .bloc.seventh .first-content .para'), 'font-size').value),
          color: findDeclaration(findRule('.front .bloc-wrapper .bloc.seventh .first-content .para'), 'color').value,
          font: findDeclaration(findRule('.front .bloc-wrapper .bloc.seventh .first-content .para'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .bloc-wrapper .bloc.seventh .first-content .para'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .bloc-wrapper .bloc.seventh .first-content .para','(max-width: 767px)'), 'font-size').value),
        }
      }else if(this.state.currentPage=="engagement"){
        texts[0] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.preview .large-bloc .bloc.third .ttr'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.preview .large-bloc .bloc.third .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.preview .large-bloc .bloc.third .ttr'), 'font-family').value,
          weight: findDeclaration(findRule('.front .engagement .section.preview .large-bloc .bloc.third .ttr'), 'font-weight').value,
          align: findDeclaration(findRule('.front .engagement .section.preview .large-bloc .bloc.third .ttr'), 'text-align').value,
        }
        texts[1] = {
          mt: parseInt(findDeclaration(findRule('.front .engagement .section.captain-engagement'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front .engagement .section.captain-engagement'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front .engagement .section.captain-engagement'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front .engagement .section.captain-engagement'), 'padding').value?.replace('px', '').split(' ')[3]),
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .ttr span'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .ttr span'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .ttr span'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .ttr span'), 'font-weight').value,
          align: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .ttr'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.captain-engagement .text-content article .ttr span','(max-width: 991px)'), 'font-size').value),
        }
        texts[2] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .ttr'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .ttr'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .ttr'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.captain-engagement .text-content article .ttr','(max-width: 991px)'), 'font-size').value),
        }
        texts[3] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .para'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .para'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .para'), 'font-family')?.value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.captain-engagement .text-content article .para'), 'font-weight')?.value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.captain-engagement .text-content article .para','(max-width: 991px)'), 'font-size').value),
        }
        texts[4] = {
          background:findDeclaration(findRule('.front .engagement .section.responsable'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front .engagement .section.responsable'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front .engagement .section.responsable'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front .engagement .section.responsable'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front .engagement .section.responsable'), 'padding').value?.replace('px', '').split(' ')[3]),
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.responsable .ttr'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.responsable .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.responsable .ttr'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.responsable .ttr'), 'font-weight').value,
          align: findDeclaration(findRule('.front .engagement .section.responsable .ttr'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.responsable .ttr','(max-width: 991px)'), 'font-size').value),
        }
        texts[5] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .ttr'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .ttr'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .ttr'), 'font-weight').value,
          align: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .ttr'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.engagement-list .row .bloc-text .ttr','(max-width: 991px)'), 'font-size').value),
        }
        texts[6] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .ttr span'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .ttr span'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .ttr span'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .ttr span'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.engagement-list .row .bloc-text .ttr span','(max-width: 991px)'), 'font-size').value),
        }
        texts[7] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .para'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .para'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .para'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.engagement-list .row .bloc-text .para'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.engagement-list .row .bloc-text .para','(max-width: 991px)'), 'font-size').value),
        }
        texts[8] = {
          background:findDeclaration(findRule('.front .engagement .section.plus-loin'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front .engagement .section.plus-loin'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front .engagement .section.plus-loin'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front .engagement .section.plus-loin'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front .engagement .section.plus-loin'), 'padding').value?.replace('px', '').split(' ')[3]),
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.plus-loin .ttr'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.plus-loin .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.plus-loin .ttr'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.plus-loin .ttr'), 'font-weight').value,
          align: findDeclaration(findRule('.front .engagement .section.plus-loin .ttr'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.plus-loin .ttr','(max-width: 991px)'), 'font-size').value),
        }
        texts[9] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.plus-loin .ttr span'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.plus-loin .ttr span'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.plus-loin .ttr span'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.plus-loin .ttr span'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.plus-loin .ttr span','(max-width: 991px)'), 'font-size').value),
        }
        texts[10] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.faq .text-content .ttr'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.faq .text-content .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.faq .text-content .ttr'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.faq .text-content .ttr'), 'font-weight').value,
          align: findDeclaration(findRule('.front .engagement .section.faq .text-content .ttr'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.faq .text-content .ttr','(max-width: 991px)'), 'font-size').value),
        }
        texts[11] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.question .ttr'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.question .ttr'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.question .ttr'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.question .ttr'), 'font-weight').value,
          align: findDeclaration(findRule('.front .engagement .section.question .ttr'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.question .ttr','(max-width: 991px)'), 'font-size').value),
        }
        texts[12] = {
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.question .para'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.question .para'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.question .para'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.question .para'), 'font-weight').value,
          align: findDeclaration(findRule('.front .engagement .section.question .para'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.question .para','(max-width: 991px)'), 'font-size').value),
        }
        texts[13] = {
          background:findDeclaration(findRule('.front .engagement .section.question .btn'), 'background-color').value,
          size: parseFloat(findDeclaration(findRule('.front .engagement .section.question .btn'), 'font-size').value),
          color: findDeclaration(findRule('.front .engagement .section.question .btn'), 'color').value,
          font: findDeclaration(findRule('.front .engagement .section.question .btn'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front .engagement .section.question .btn'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front .engagement .section.question .btn','(max-width: 991px)'), 'font-size').value),
        }
      }else if(this.state.currentPage=="recrutement"){
        const getColorFromGradient = (value) => {
          var extractedColor= "";
          if(value){
            const start = value.indexOf("#"); // Trouver la première occurrence du caractère '#'
            const end = value.indexOf(",", start); // Trouver l'indice de la virgule après le code couleur
            extractedColor = value.slice(start, end).trim();
          }
          return extractedColor;
        }
        texts[0] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-hero h1'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-hero h1'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-hero h1'), 'font-family').value,
          weight: findDeclaration(findRule('.front.engagement .bloc-hero h1'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .bloc-hero h1'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-hero h1','(max-width: 767px)'), 'font-size').value),
        }
        //pour recuperer la couleur dans le linear-gradient du css
        texts[1] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-hero h1 span'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-hero h1 span'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-hero h1 span'), 'font-family').value,
          weight: findDeclaration(findRule('.front.engagement .bloc-hero h1 span'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-hero h1 span','(max-width: 767px)'), 'font-size').value),
          background:getColorFromGradient(findDeclaration(findRule('.front.engagement .bloc-hero h1 span'), 'background-image').value),
        }
        texts[2] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .formulaire h2'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .formulaire h2'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .formulaire h2'), 'font-family').value,
          weight: findDeclaration(findRule('.front.engagement .formulaire h2'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .formulaire h2'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .formulaire h2','(max-width: 767px)'), 'font-size').value),
          background:findDeclaration(findRule('.front.engagement .formulaire'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front.engagement .formulaire'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front.engagement .formulaire'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front.engagement .formulaire'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front.engagement .formulaire'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[3] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .formulaire h2 span'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .formulaire h2 span'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .formulaire h2 span'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .formulaire h2 span'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .formulaire h2 span','(max-width: 767px)'), 'font-size').value),
          background:getColorFromGradient(findDeclaration(findRule('.front.engagement .formulaire h2 span'), 'background-image').value),
        }
        texts[4] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .formulaire p'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .formulaire p'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .formulaire p'), 'font-family').value,
          weight: findDeclaration(findRule('.front.engagement .formulaire p'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .formulaire p'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .formulaire p','(max-width: 767px)'), 'font-size').value),
          background:findDeclaration(findRule('.front.engagement .formulaire .hs-input:not([type=file],.hs-fieldtype-intl-phone)'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front.engagement .formulaire .hs-input:not([type=file],.hs-fieldtype-intl-phone)'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front.engagement .formulaire .hs-input:not([type=file],.hs-fieldtype-intl-phone)'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front.engagement .formulaire .hs-input:not([type=file],.hs-fieldtype-intl-phone)'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front.engagement .formulaire .hs-input:not([type=file],.hs-fieldtype-intl-phone)'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[5] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .formulaire p.hs-richtext'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .formulaire p.hs-richtext'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .formulaire p.hs-richtext'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .formulaire p.hs-richtext'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .formulaire p.hs-richtext'), 'text-align').value,
        }
        texts[6] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .formulaire p.hs-richtext a'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .formulaire p.hs-richtext a'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .formulaire p.hs-richtext a'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .formulaire p.hs-richtext a'), 'font-weight').value,
          redirection: tempElement.querySelector('.front.engagement .formulaire p.hs-richtext a').getAttribute('href')
        }
        texts[7] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-video h2'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-video h2'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-video h2'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-video h2'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .bloc-video h2'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-video h2','(max-width: 767px)'), 'font-size').value),
        }
        texts[8] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-video h2 .txtweight-300'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-video h2 .txtweight-300'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-video h2 .txtweight-300'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-video h2 .txtweight-300'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-video h2 .txtweight-300','(max-width: 767px)'), 'font-size').value),
        }
        texts[9] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-video h2 .txtweight-300 div'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-video h2 .txtweight-300 div'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-video h2 .txtweight-300 div'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-video h2 .txtweight-300 div'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-video h2 .txtweight-300 div','(max-width: 767px)'), 'font-size').value),
        }
        texts[10]={}
        texts[11] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-video .col-md-5 h3'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-video .col-md-5 h3'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-video .col-md-5 h3'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-video .col-md-5 h3'), 'font-weight').value,
          sizeMobile: 20,
        }
        texts[12] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-illus .col-8 h4'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-illus .col-8 h4'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-illus .col-8 h4'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-illus .col-8 h4'), 'font-weight').value,
          background:findDeclaration(findRule('.front.engagement .bloc-illus'), 'background-color').value,
        }
        texts[13] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-logos h2'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-logos h2'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-logos h2'), 'font-family').value,
          weight: findDeclaration(findRule('.front.engagement .bloc-logos h2'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .bloc-logos h2'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-logos h2','(max-width: 767px)'), 'font-size').value),
          background:findDeclaration(findRule('.front.engagement .bloc-logos'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front.engagement .bloc-logos'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front.engagement .bloc-logos'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front.engagement .bloc-logos'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front.engagement .bloc-logos'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[14] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-videoslider h2'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-videoslider h2'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-videoslider h2'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-videoslider h2'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .bloc-videoslider h2'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-videoslider h2','(max-width: 767px)'), 'font-size').value),
        }
        texts[15] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-videoslider h2 span'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-videoslider h2 span'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-videoslider h2 span'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-videoslider h2 span'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-videoslider h2 span','(max-width: 767px)'), 'font-size').value),
        }
        texts[16] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-videoslider .col-md-5 h4'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-videoslider .col-md-5 h4'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-videoslider .col-md-5 h4'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-videoslider .col-md-5 h4'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .bloc-videoslider .col-md-5 h4'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-videoslider .col-md-5 h4','(max-width: 767px)'), 'font-size').value),
        }
        texts[17] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-videoslider .col-md-5 ul li'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-videoslider .col-md-5 ul li'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-videoslider .col-md-5 ul li'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-videoslider .col-md-5 ul li'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-videoslider .col-md-5 ul li','(max-width: 767px)'), 'font-size').value),
        }
        texts[18] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-illus h2'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-illus h2'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-illus h2'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-illus h2'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .bloc-illus h2'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-illus h2','(max-width: 767px)'), 'font-size').value),
        }
        texts[19] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-illus h3 span'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-illus h3 span'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-illus h3 span'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-illus h3 span'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .bloc-illus h3'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-illus h3 span','(max-width: 767px)'), 'font-size').value),
        }
        texts[20] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .btn-bottom .btn'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .btn-bottom .btn'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .btn-bottom .btn'), 'font-family').value,
          weight: findDeclaration(findRule('.front.engagement .btn-bottom .btn'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .btn-bottom .btn','(max-width: 767px)'), 'font-size').value),
          background:findDeclaration(findRule('.front.engagement .btn-bottom .btn'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front.engagement .btn-bottom .btn'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front.engagement .btn-bottom .btn'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front.engagement .btn-bottom .btn'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front.engagement .btn-bottom .btn'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[21] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 .txtweight-300'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-2column .container h2 .txtweight-300'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-2column .container h2 .txtweight-300'), 'font-family').value,
          weight: findDeclaration(findRule('.front.engagement .bloc-2column .container h2 .txtweight-300'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .bloc-2column .container h2'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-2column .container h2 .txtweight-300','(max-width: 767px)'), 'font-size').value),
          mt: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 .txtweight-300'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 .txtweight-300'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 .txtweight-300'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 .txtweight-300'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[22] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2)'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2)'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2)'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2)'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2)','(max-width: 767px)'), 'font-size').value),
          mt: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2)'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2)'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2)'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2)'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[23] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2) span'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2) span'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2) span'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2) span'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-2column .container h2 div:nth-of-type(2) span','(max-width: 767px)'), 'font-size').value),
        }
        texts[24] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-2column .wrap h2'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-2column .wrap h2'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-2column .wrap h2'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-2column .wrap h2'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-2column .wrap h2','(max-width: 767px)'), 'font-size').value),
          background:findDeclaration(findRule('.front.engagement .bloc-2column .row .txt .item'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .row .txt .item'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .row .txt .item'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .row .txt .item'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front.engagement .bloc-2column .row .txt .item'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[25] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-2column .wrap ul li'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-2column .wrap ul li'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-2column .wrap ul li'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-2column .wrap ul li'), 'font-weight').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-2column .wrap ul li','(max-width: 767px)'), 'font-size').value),
          mt: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-2column .row .txt .item','(max-width: 767px)'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-2column .row .txt .item','(max-width: 767px)'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-2column .row .txt .item','(max-width: 767px)'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-2column .row .txt .item','(max-width: 767px)'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[26] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement .bloc-presse h2'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement .bloc-presse h2'), 'color').value,
          font: findDeclaration(findRule('.front.engagement .bloc-presse h2'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement .bloc-presse h2'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement .bloc-presse h2'), 'text-align').value,
          sizeMobile: parseInt(findDeclaration(findMediaRule('.front.engagement .bloc-presse h2','(max-width: 767px)'), 'font-size').value),
          background:findDeclaration(findRule('.front.engagement .bloc-presse'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front.engagement .bloc-presse'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front.engagement .bloc-presse'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front.engagement .bloc-presse'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front.engagement .bloc-presse'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[27] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement footer .title'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement footer .title'), 'color').value,
          font: findDeclaration(findRule('.front.engagement footer .title'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement footer .title'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement footer .title'), 'text-align').value,
          background:findDeclaration(findRule('.front.engagement footer'), 'background-color').value,
          mt: parseInt(findDeclaration(findRule('.front.engagement footer'), 'padding').value?.replace('px', '').split(' ')[0]),
          mr: parseInt(findDeclaration(findRule('.front.engagement footer'), 'padding').value?.replace('px', '').split(' ')[1]),
          mb: parseInt(findDeclaration(findRule('.front.engagement footer'), 'padding').value?.replace('px', '').split(' ')[2]),
          ml: parseInt(findDeclaration(findRule('.front.engagement footer'), 'padding').value?.replace('px', '').split(' ')[3]),
        }
        texts[28] = {
          size: parseFloat(findDeclaration(findRule('.front.engagement footer .text'), 'font-size').value),
          color: findDeclaration(findRule('.front.engagement footer .text'), 'color').value,
          font: findDeclaration(findRule('.front.engagement footer .text'), 'font-family').value.replace(',sans-serif', ''),
          weight: findDeclaration(findRule('.front.engagement footer .text'), 'font-weight').value,
          align: findDeclaration(findRule('.front.engagement footer .text'), 'text-align').value
        }
      }
    }
    return texts;
  };
  handleCopyCode() {
    const text = this.codeRef.current.textContent;
    navigator.clipboard.writeText(text);
    alert("code copié dans le presse papier")
  }
  changeWindowsSize = (type) => {
    this.setState({
      windowSize: type
    });
  }
  restore = () => {
    //compliqué de nommer un fichier "bar à jean"
    var filename = this.state.currentPage!=="bar à jean"?this.state.currentPage:"bar";
    const jsonData = require('./assets/data/'+filename+'.json');
    this.setState({
      banerSlides: jsonData.content,
      texts: jsonData.contentText,
      images: jsonData.contentImage,
      cardSlides:jsonData.contentCard
    })
    this.togglePopup();
  }
  togglePopup = (type,id,cardType) => {
    this.setState({
      showPopup: !this.state.showPopup
    });
    switch(type){
      case "image-cropper":
        var previewUrl = this.state[cardType][id].imagePreviewUrl;
        this.setState({
          currentImageName:this.state[cardType][id].file.name,
          currentImage:previewUrl,
          currentImageId: id,
          })
        ;
        break;
      case "add-card":
        this.setState({cardType:cardType});
        break;
      case "add-baner-slide":
        this.setState({cardType:cardType});
        break;
      case "edit-baner-slide":
        var index = this.state.banerSlides.findIndex(banerSlide => banerSlide.id === id);
        this.setState({
          editObject: this.state.banerSlides[index],
          cardType:cardType
        });
        break;
      case "edit-card":
        var index = this.state.cardSlides.findIndex(cardSlide => cardSlide.id === id);
        this.setState({
          editObject: this.state.cardSlides[index]
        });
        break;
      case "get-html":
        var htmlContent = ReactDOMServer.renderToString(
          this.state.currentPage == "homepage" ? 
          <Home 
            menuStyle={this.state.texts[0]}
            menuTextStyle={this.state.texts[1]}
            banerSlides = {this.state.banerSlides}
            fondBaner = {this.state.images[0].imagePreviewUrl}
            titleBaner={this.state.texts[1]}
            subtitleBaner={this.state.texts[2]}
            banerBtnStyle={this.state.texts[3]}
            cardTextStyle={this.state.texts[4]}
            cardSlides = {this.state.cardSlides}
            pinkStyle={this.state.texts[5]}
            pinkTitleStyle = {this.state.texts[6]}
            pinkSubtitleStyle = {this.state.texts[7]}
            imgBloc1 = {this.state.images[1].imagePreviewUrl}
            imgBloc1Mobile = {this.state.images[2].imagePreviewUrl}
            imgBloc2 = {this.state.images[3].imagePreviewUrl}
            imgBloc2Mobile = {this.state.images[4].imagePreviewUrl}
            imgBloc3Left = {this.state.images[5].imagePreviewUrl}
            imgBloc3Right = {this.state.images[6].imagePreviewUrl}
            imgBloc3LeftMobile = {this.state.images[7].imagePreviewUrl}
            imgBloc4 = {this.state.images[8].imagePreviewUrl}
            imgBloc4Mobile = {this.state.images[9].imagePreviewUrl}
            titleBloc1Style = {this.state.texts[8]}
            subtitleBloc1Style = {this.state.texts[9]}
            titleBloc2Style = {this.state.texts[10]}
            subtitleBloc2Style = {this.state.texts[11]}
            cardFooterTextStyle={this.state.texts[12]}
            mode = {false}
            windowsSize = {this.state.windowSize}
            twoImageTitleStyle = {this.state.texts[13]}
            twoImageBtnStyle = {this.state.texts[14]}
            collectionTitleStyle = {this.state.texts[15]}
            collectionBtnStyle = {this.state.texts[16]}
            btnListStyle = {this.state.texts[17]}
            titleFirstBloc = {this.state.texts[18]}
            titleItalicFirstBloc = {this.state.texts[19]}
            poppins = {this.state.texts[1].usePoppins&&this.state.texts[1].poppinsId ? this.state.allPage.find(objet => objet.id === this.state.texts[1].poppinsId) : null}
          />
          :
          this.state.currentPage == "lookbook" ?
          <LookBook
            grid1 = {this.state.images[0].imagePreviewUrl}
            grid1Mobile = {this.state.images[1].imagePreviewUrl}
            grid2 = {this.state.images[2].imagePreviewUrl}
            grid2Mobile = {this.state.images[3].imagePreviewUrl}
            grid3 = {this.state.images[4].imagePreviewUrl}
            grid4 = {this.state.images[5].imagePreviewUrl}
            grid5 = {this.state.images[6].imagePreviewUrl}
            grid5Mobile = {this.state.images[7].imagePreviewUrl}
            grid6 = {this.state.images[8].imagePreviewUrl}
            grid6Mobile = {this.state.images[9].imagePreviewUrl}
            grid7 = {this.state.images[10].imagePreviewUrl}
            grid7Mobile = {this.state.images[11].imagePreviewUrl}
            grid8 = {this.state.images[12].imagePreviewUrl}
            grid9 = {this.state.images[13].imagePreviewUrl}
            grid9Mobile = {this.state.images[14].imagePreviewUrl}
            grid10 = {this.state.images[15].imagePreviewUrl}
            grid10Mobile = {this.state.images[16].imagePreviewUrl}
            grid11 = {this.state.images[17].imagePreviewUrl}
            grid11Mobile = {this.state.images[18].imagePreviewUrl}
            grid12 = {this.state.images[19].imagePreviewUrl}
            grid12Mobile = {this.state.images[20].imagePreviewUrl}
            grid13 = {this.state.images[21].imagePreviewUrl}
            grid14 = {this.state.images[22].imagePreviewUrl}
            grid14Mobile = {this.state.images[23].imagePreviewUrl}
            grid15 = {this.state.images[24].imagePreviewUrl}
            grid15Mobile = {this.state.images[25].imagePreviewUrl}
            grid16 = {this.state.images[26].imagePreviewUrl}
            grid16Mobile = {this.state.images[27].imagePreviewUrl}
            banerTitleStyle = {this.state.texts[0]}
            block4ImageTextTitleStyle = {this.state.texts[1]}
            block4ImageTextParaStyle = {this.state.texts[2]}
            block13ImageTextTitleStyle = {this.state.texts[3]}
            block13ImageTextParaStyle = {this.state.texts[4]}
            links = {this.state.banerSlides}
            mode = {false}
          />
          :this.state.currentPage == "engagement" ?
          <Engagement
            imgBloc3Left = {this.state.images[0].imagePreviewUrl}
            bloc3Text = {this.state.texts[0]}
            imgBloc3Right = {this.state.images[1].imagePreviewUrl}
            bloc4FirstTitle = {this.state.texts[1]}
            bloc4FirstSubtitle = {this.state.texts[2]}
            bloc4SecondPara = {this.state.texts[3]}
            imgBloc4 = {this.state.images[2].imagePreviewUrl}
            engagementStyle={this.state.texts[4]}
            engagementSlides = {this.state.cardSlides}
            engagementTitleStyle={this.state.texts[5]}
            engagementSubtitleStyle={this.state.texts[6]}
            engagementParaStyle={this.state.texts[7]}
            plusLoinTitleStyle={this.state.texts[8]}
            plusLoinSubtitleStyle={this.state.texts[9]}
            faqStyle={this.state.texts[10]}
            imgFaq = {this.state.images[3].imagePreviewUrl}
            questionTitleStyle={this.state.texts[11]}
            questionSubtitleStyle={this.state.texts[12]}
            questionBtnStyle={this.state.texts[13]}
            videoTitle={this.state.texts[14]}
            videoSubtitle={this.state.texts[15]}
            mode = {false}
          />
          :
          this.state.currentPage == "recrutement" ?
          <Recrutement
            logo = {this.state.images[0].imagePreviewUrl}
            fondBaner = {this.state.images[1].imagePreviewUrl}
            logoBlanc = {this.state.images[2].imagePreviewUrl}
            banerSlides = {this.state.banerSlides}
            cards = {this.state.cardSlides}
            banerTitleStyle={this.state.texts[0]}
            banerSubtitleStyle={this.state.texts[1]}
            formStyle={this.state.texts[2]}
            formSubtitleStyle={this.state.texts[3]}
            formParaStyle={this.state.texts[4]}
            formMentionStyle={this.state.texts[5]}
            formMentionLinkStyle={this.state.texts[6]}
            conseillereTitleStyle={this.state.texts[7]}
            conseillereSubtitleStyle={this.state.texts[8]}
            conseillereSubtitlePinkStyle={this.state.texts[9]}
            conseillereIframe={this.state.texts[10]}
            conseillereSecondColumnTitle={this.state.texts[11]}
            bgColor={this.state.texts[12]}
            reseauxStyle={this.state.texts[13]}
            decouvrirTitleStyle={this.state.texts[14]}
            decouvrirSubtitleStyle={this.state.texts[15]}
            decouvrirSecondColumnTitle={this.state.texts[16]}
            decouvrirSecondColumnPara={this.state.texts[17]}
            imagineTitleStyle={this.state.texts[18]}
            imagineSubtitleStyle={this.state.texts[19]}
            generalBtnStyle={this.state.texts[20]}
            savoiPlusTitleStyle={this.state.texts[21]}
            savoiPlusSubtitleStyle={this.state.texts[22]}
            savoiPlusSubtitlePinkStyle={this.state.texts[23]}
            savoiPlusCardTitleStyle={this.state.texts[24]}
            savoiPlusCardTextStyle={this.state.texts[25]}
            presseStyle={this.state.texts[26]}
            footerStyle={this.state.texts[27]}
            footerTextStyle={this.state.texts[28]}
            mode = {false}
            windowSize = {this.state.windowSize}
          />
          :
          this.state.currentPage == "bar à jean" ?
            <Bar
              logo = {this.state.images[0].imagePreviewUrl}
              cards = {this.state.cardSlides}
              headerTitle={this.state.texts[0]}
              headerSubtitle={this.state.texts[1]}
              headerPara={this.state.texts[2]}
              cardTitleStyle={this.state.texts[4]}
              cardSubtitleStyle={this.state.texts[5]}
              cardTextStyle={this.state.texts[6]}
              cardListStyle={this.state.texts[7]}
              cardNumStyle={this.state.texts[8]}
              cardLinkStyle={this.state.texts[9]}
              logoBlack = {this.state.images[1].imagePreviewUrl}
              footerText = {this.state.texts[3]}
              tortue = {this.state.images[2].imagePreviewUrl}
              mode = {false}
            />
          :
          this.state.currentPage == "histoire" ?
          <Histoire
            baner={this.state.images[0].imagePreviewUrl}
            banerStyle={this.state.texts[0]}
            banerTextStyle={this.state.texts[1]}
            firstRowTitle={this.state.texts[2]}
            firstRowText={this.state.texts[3]}
            firstRowButton={this.state.texts[4]}
            op1={this.state.images[1].imagePreviewUrl}
            op2={this.state.images[2].imagePreviewUrl}
            op3={this.state.images[3].imagePreviewUrl}
            firstRowSign={this.state.images[4].imagePreviewUrl}
            secondRowTitle={this.state.texts[5]}
            secondRowText={this.state.texts[6]}
            thirdRowTitle={this.state.texts[7]}
            thirdRowText={this.state.texts[8]}
            thirdRowFirstBtn={this.state.texts[9]}
            thirdRowSecondBtn={this.state.texts[10]}
            mode = {false}
          />
          :
          this.state.currentPage == "new homepage" ?
          <NewHomepage
            texts = {this.state.texts}
            images = {this.state.images}
            windowSize = {this.state.windowSize}
            mode = {false}
            banerSlides = {this.state.banerSlides}
            cards = {this.state.cardSlides}
          />
          :
          this.state.currentPage == "popup" ?
            <Poppin
              page={this.state.currentPage}
              menuStyle={this.state.allPage[0].contentText[0]}
              menuTextStyle={this.state.allPage[0].contentText[1]}
              banerSlides = {this.state.allPage[0].content}
              fondBaner = {this.state.allPage[0].contentImage[0].imagePreviewUrl}
              titleBaner={this.state.allPage[0].contentText[1]}
              subtitleBaner={this.state.allPage[0].contentText[2]}
              banerBtnStyle={this.state.allPage[0].contentText[3]}
              cardTextStyle={this.state.allPage[0].contentText[4]}
              pinkSubtitleStyle = {this.state.allPage[0].contentText[7]}
              pinkStyle={this.state.allPage[0].contentText[5]}
              pinkTitleStyle = {this.state.allPage[0].contentText[6]}
              titleBloc1Style = {this.state.allPage[0].contentText[8]}
              texts={this.state.texts}
              extract={true}
              focusAccordion = {this.handleElementFocus}
              changeTitle = {this.changeTitle}
              images={this.state.images}
              mode = {false}
            />
          :
          null
        )
        var style=`
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
          ${this.state.currentPage=="recrutement" ? 
          '<script charset="utf-8" src="https://js-eu1.hsforms.net/forms/embed/v2.js" type="text/javascript"></script>':'' }
          ${this.state.currentPage!=="popup"?
          '<meta content="IE=edge" http-equiv="X-UA-Compatible" /><link href="https://editeur.itislive.com/static/css/font.css" rel="stylesheet" /><link crossorigin="anonymous" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" rel="stylesheet" />':''}
          ${this.state.currentPage=="homepage"||this.state.currentPage=="new homepage" ? 
          '<link href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" rel="stylesheet" />' : ''}
          ${this.state.currentPage=="new homepage"&&'<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">'}
          ${this.state.currentPage !== "recrutement" ? 
            this.state.currentPage == "bar à jean" ? 
              '<link href="https://editeur.itislive.com/static/css/bar.css" rel="stylesheet" />'
            :this.state.currentPage !== "histoire"&&this.state.currentPage !== "new homepage"&&this.state.currentPage !== "popup"?
              '<link href="https://editeur.itislive.com/static/css/home.css?ver=1.7" rel="stylesheet" /><meta content="width=device-width, initial-scale=1.0" name="viewport" />'
            :''
          :'<link href="https://editeur.itislive.com/static/css/recrutement.css" rel="stylesheet" />'}
          ${this.state.currentPage!="recrutement"&&this.state.currentPage!="histoire"&&this.state.currentPage!="popup"?'<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>':''}`;
        var script=this.state.currentPage == "homepage" ? `<script src="https://editeur.itislive.com/static/js/home.js"></script>`: this.state.currentPage == "engagement" ? `<script>$(".faq-list li").on("click", function(){$(this).children('.faq-title').toggleClass("collapsed");$(this).children(".collapse").toggleClass("show");})</script>` : this.state.currentPage == "recrutement" ? `<div><script crossorigin="anonymous" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script></div><script src="https://editeur.itislive.com/static/css/recrutement.js"></script>` : this.state.currentPage == "bar à jean" ? '<script src="https://editeur.itislive.com/static/js/bar.js"></script>' : this.state.currentPage == "new homepage" ? '<script src="https://editeur.itislive.com/static/js/new.js"></script>' : this.state.currentPage == "popup" ? '<script src="https://editeur.itislive.com/static/js/popup.js"></script>' : '';
        var hubspotScript=`<script>hbspt.forms.create({region: "eu1",portalId: "26694758",formId: "ea7d0ab2-2840-4377-a3f2-f3ae16ebd928"});</script>`;
        this.setState({
          html: 
            this.state.currentPage !== "recrutement" ? 
              style+htmlContent.replace(/\/static\/media/g, "https://editeur.itislive.com/static/media").replace(/&quot;&quot;/g,'""').replace(/id="b0abc783-c1ce-4439-b077-d0440dc67ba5"/,'id="b0abc783-c1ce-4439-b077-d0440dc67ba5" onclick="validation(\'popup\')"')+script : 
              style+htmlContent.replace(/\/static\/media/g, "https://editeur.itislive.com/static/media").replace(/<div><div id="reactHubspotForm\d+" style="display:none"><\/div><\/div>/,hubspotScript).replace(/<!--([\s\S]*?)-->/g, '')+script
        });
        break;
    }
    this.setState({activePopup: type});
  }
  updateTextOption = (selectedOption, id, optionName) => {
    this.setState((prevState) => {
      const texts = [...prevState.texts];
      texts[id][optionName] = selectedOption;
      return { texts };
    });
  }
  //crud pour les éléments du poppins
  handleAddElement = (type) =>{
    var idImage = null;
    if(type == "image"){
      //créer l'element image correspondant
      const images = this.state.images.slice();
      const imageObject = {file:null,imagePreviewUrl:imageUpload};
      images.push(imageObject);
      const index = images.findIndex(image=>image.id === imageObject.id);
      images[index].id = index;
      idImage=index;
      this.setState({images:images});
    }
    const typeCard = this.state.activePopup.includes("baner-slide-content-") ? this.state.activePopup : this.state.activePopup.includes("add-element-") ? this.state.activePopup.split("add-element-")[1] : "poppins-content";
    const objet = type=="texte"?{elementType:type,textType:"p",mt:0,mr:0,mb:16,ml:0,text:type,size:16,sizeMobile:14,font:"LFT Etica",color:"#232323",weight:"700",type:typeCard} : type=="bouton"?{elementType:type,mt:6,mr:16,mb:6,ml:16,btl:5,btr:5,bbr:5,bbl:5,text:type,size:16,background:"#232323",font:"LFT Etica",color:"#fff",weight:"500",type:typeCard}:type=="image" ? {elementType:type,type:typeCard,text:type,imgId:idImage,size:100,sizeMobile:80,mt:0,mr:0,mb:0,ml:0,unity:"px"} : type=="compteur" ? {elementType:type,text:type,mt:0,mr:0,mb:16,ml:0,size:40,sizeMobile:32,color:"#232323",font:"LFT Etica",weight:500,type:typeCard,date:new Date()} : type=="champ" ? {elementType:type,text:type,mt:10,mr:15,mb:10,ml:15,btl:5,btr:5,bbr:5,bbl:5,type:typeCard,background:'#fff',size:80,sizeMobile:100,color:'#000',font:"LFT Etica",weight:500,class:""} : {elementType:type,text:type,mt:16,mr:0,mb:16,ml:0,background:"#fff",align:"center",type:typeCard,class:""};
    const texts = this.state.texts.slice();
    texts.push(objet);
    const index = texts.findIndex(text=>text.id === objet.id);
    texts[index].id = index;
    this.setState({texts:texts});
  }
  handleDeleteElement = id =>{
    const texts = this.state.texts.slice();
    const index = texts.findIndex(function(objet){
      return objet.id === id
    });
    texts.splice(index, 1);
    texts.forEach((item, index) => {
      item.id = index;
    });
    this.setState({texts:texts});
  }

  //crud pour les slide du banner et les boutons dans le carousel de cartes
  handleDelete = id =>{
    const banerSlides = this.state.banerSlides.slice();
    const index = banerSlides.findIndex(function(objet){
      return objet.id === id
    });
    banerSlides.splice(index, 1);
    banerSlides.forEach((item, index) => {
      item.id = index;
    });
    this.setState({banerSlides:banerSlides});
  }
  handleAdd = (objet) => {
    const { banerSlides, images, texts } = this.state;
    const addObjectToArr = (arr, obj) => {
      const clonedArr = [...arr];
      clonedArr.push(obj);
      const index = clonedArr.findIndex((item) => item.id === obj.id);
      clonedArr[index].id = index;
      return { clonedArr, index };
    };
    const { clonedArr: updatedBanerSlides, index: banerIndex } = addObjectToArr(
      banerSlides,
      objet
    );
    if (objet.type === "slide-baner") {
      const objetImage = { file: null, imagePreviewUrl: imageUpload , imagePreviewUrlMobile: imageUpload, fileMobile: null };
      const objetText = { mt: 0, ml: 0, mb:0 ,mr: 0,align: "left",verticalAlign: "flex-start"};
      const { clonedArr: updatedImages, index: imageIndex } = addObjectToArr( images, objetImage);
      const { clonedArr: updatedTexts, index: textIndex } = addObjectToArr(texts, objetText);
      updatedBanerSlides[banerIndex].imageId = imageIndex;
      updatedBanerSlides[banerIndex].textId = textIndex;
      this.setState({
        images: updatedImages,
        texts: updatedTexts,
      });
    }
    this.setState({ banerSlides: updatedBanerSlides });
  };
  handleEdit = updatedBanerSlider => {
    const previousBanerSlides = this.state.banerSlides.slice();
    const index = previousBanerSlides.findIndex(slide=>slide.id === updatedBanerSlider.id);
    previousBanerSlides[index] = updatedBanerSlider;
    this.setState({banerSlides:previousBanerSlides})
  }
  //crud pour les cartes
  handleDeleteCard = id =>{
    const cardSlides = this.state.cardSlides.slice();
    const index = cardSlides.findIndex(function(objet){
      return objet.id === id
    });
    cardSlides.splice(index, 1);
    this.setState({cardSlides:cardSlides});
  }
  handleAddCard = objet =>{
    const cardSlides = this.state.cardSlides.slice();
    cardSlides.push(objet);
    const index = cardSlides.findIndex(slide=>slide.id === objet.id);
    cardSlides[index].id = index;
    this.setState({cardSlides:cardSlides});
  }
  handleEditCard = updatedCardSlider => {
    const previousCardSlides = this.state.cardSlides.slice();
    const index = previousCardSlides.findIndex(slide=>slide.id === updatedCardSlider.id);
    previousCardSlides[index] = updatedCardSlider;
    this.setState({cardSlides:previousCardSlides})
  }
  //crud pour toutes les pages
  handleDeletePage = id =>{
    const oldAllPage = this.state.allPage.slice();
    const index = oldAllPage.findIndex(function(objet){
      return objet.id === id
    });
    oldAllPage.splice(index, 1);
    this.setState({allPage:oldAllPage});
  }
  handleDuplicatePage = objet =>{
    const oldAllPage = this.state.allPage.slice();
    oldAllPage.push(objet);
    this.setState({allPage:oldAllPage});
  }
  handleChange = (e,type) => {
    const texts = this.state.texts.slice();
    switch(type){
      case 'color':
        texts[e.target.id.replace(/^color-picker-/, '')].color = e.target.value;
        break;
      case 'background' :
        texts[e.target.id.replace(/^color-picker-/, '')].background = e.target.value;
        break;
      case 'opacity':
        texts[e.target.id.replace(/^opacity-/, '')].size = e.target.value;
        break;
    }
    this.setState({
      texts : texts,
    });
  };
  photoUpload = (e,id,responsive) =>{
    e.preventDefault();
    const images = this.state.images.slice();
    const file = e.target.files[0];
      if(file&&(file.size/1024) < 1024){
        const reader = new FileReader();
        reader.onloadend = () => {
          if(responsive&&responsive == "baner-slide"){
            console.log('version mobile');
            images[id].fileMobile = file;
            images[id].imagePreviewUrlMobile = reader.result;
          }else{
            images[id].file = file;
            images[id].imagePreviewUrl = reader.result;
          }
          this.setState({
            images: images 
          });
        }
        reader.readAsDataURL(file);
      }
      else{
        alert("La taille de l'image ne doit pas depasser les 1Mo! vous risquez de ralentir drastiquement le site avec des images lourdes")
      }   
  }
  changeNumber = (e,id,attr,responsive) => {
    const texts = this.state.texts.slice();
    texts[id][attr] = responsive ? parseFloat(e.target.value) : parseInt(e.target.value);
    this.setState({texts : texts});
  }
  incrementSpeed = (id,attr,responsive,max) => {
    const texts = this.state.texts.slice();
    interval = setInterval(() => {
      if(this.state.texts[id][attr] >= max)
        return;
      else
        texts[id][attr] = responsive ?  parseFloat((this.state.texts[id][attr] + 0.1).toFixed(1)) : this.state.texts[id][attr] + 1;
      this.setState({texts : texts});
    }, 50);
  };
  decrementSpeed = (id,attr,responsive) => {
    const texts = this.state.texts.slice();
    interval = setInterval(() => {
    let min  = 0;
    if(this.state.texts[id][attr] <= min)
      return;
    else
      texts[id][attr] = responsive ?  parseFloat((this.state.texts[id][attr] - 0.1).toFixed(1)) : this.state.texts[id][attr] - 1;
    this.setState({texts : texts });
    }, 50);
  };
  stopCounter = () => {
    clearInterval(interval);
  };
  changeDate = (value,id) => {
    const texts = this.state.texts.slice();
    texts[id].date = value;
    this.setState({texts : texts})
  }
  changePara = (id,content) =>{
    const texts = this.state.texts.slice();
    texts[id].text = content;
    this.setState({texts : texts})
  }
  changeTitle = (e) =>{
    const { name, value, id } = e.target;
    const texts = this.state.texts.slice();
    const index = name === "redirection" ? id.replace(/^redirection-content-/, '') : name == "class" ? id.replace(/^class-/, '') : id;
    if(name)
      texts[index][name] = value
    else
      texts[index].text = value;
    this.setState({texts : texts})
  }
  radiochange = (e,id,attr) =>{
    const texts = this.state.texts.slice();
    texts[id][attr] = e.target.value;
    this.setState({texts : texts});
  }
  render() {
    const options = {
      'LFT Etica': 'LFT Etica',
      'Amalfi Coast': 'Amalfi Coast',
      'Poppins': 'Poppins',
      'Baskerville': 'Baskerville',
      'Avenir Next': 'Avenir Next',
      'BebasNeue': 'BebasNeue',
      'Baihgale': 'Baihgale',
      'BigCaslonFB': 'BigCaslonFB',
      'CradleyTitle-Regular2': 'CradleyTitle-Regular2'
    }
    const weights = {
      300 : 300,
      400 : 400,
      500 : 500,
      700 : 700,
      800 : 800,
      900 : 900
    }
    const getArraysize = (array,type) =>{
      var count = 0;
      for(let i = 0; i < array.length; i++){
        if(array[i].type == type){
          count = count + 1;
        }
      }
      return count;
    }
    const getMarginSelector = (id,type) =>{
      return(
        <div className="marge">
          <div className="column">
            <p>{type ? 'HG' :'Haut'}</p>
            <InputNumber changeNumber={(e) => this.changeNumber(e,this.state.texts[id].id, type ? "btl" : "mt")} incr={() => this.incrementSpeed(this.state.texts[id].id,type ? "btl" : "mt",null,200)} decr={() => this.decrementSpeed(this.state.texts[id].id,type ? "btl" : "mt",null)} stop={this.stopCounter} width={type ? this.state.texts[id].btl : this.state.texts[id].mt}/>
          </div>
          <div className="column">
            <p>{type ? 'HD' :'Gauche'}</p>
            <InputNumber changeNumber={(e) => this.changeNumber(e,this.state.texts[id].id, type ? "btr" : "ml")} incr={() => this.incrementSpeed(this.state.texts[id].id, type ? "btr" : "ml",null,200)} decr={() => this.decrementSpeed(this.state.texts[id].id, type ? "btr" : "ml",null)} stop={this.stopCounter} width={type ? this.state.texts[id].btr : this.state.texts[id].ml}/>
          </div>
          <div className="column">
            <p>{type ? 'BD' :'Bas'}</p>
            <InputNumber changeNumber={(e) => this.changeNumber(e,this.state.texts[id].id, type ? "bbr" : "mb")} incr={() => this.incrementSpeed(this.state.texts[id].id, type ? "bbr" : "mb",null,200)} decr={() => this.decrementSpeed(this.state.texts[id].id, type ? "bbr" : "mb",null)} stop={this.stopCounter} width={type ? this.state.texts[id].bbr : this.state.texts[id].mb}/>
          </div>
          <div className="column">
            <p>{type ? 'BG' :'Droite'}</p>
            <InputNumber changeNumber={(e) => this.changeNumber(e,this.state.texts[id].id, type ? "bbl" : "mr")} incr={() => this.incrementSpeed(this.state.texts[id].id, type ? "bbl" : "mr",null,200)} decr={() => this.decrementSpeed(this.state.texts[id].id, type ? "bbl" : "mr",null)} stop={this.stopCounter} width={type ? this.state.texts[id].bbl : this.state.texts[id].mr}/>
          </div>
        </div>
      )
    }
    const getAlignSelector = (name,id,vertical) => {
      const align = vertical ? this.state.texts[id].verticalAlign : this.state.texts[id].align;
      const alignments = [
        { value: vertical ? "flex-start" : "left", label: vertical ? "Haut" : "Gauche" },
        { value: "center", label: "Centre" },
        { value: vertical ? "flex-end" : "right", label: vertical ? "Bas" : "Droite" },
      ];
      return (
        <div className="style">
            {alignments.map((alignment) => (
              <div className="align" key={`${name}-${alignment.value}`}>
                  <React.Fragment>
                      <input
                          type="radio"
                          value={alignment.value}
                          id={`${name}-${alignment.value}`}
                          name={name}
                          onChange={(e) => this.radiochange(e, id, vertical ? "verticalAlign" : "align")}
                          checked={align === alignment.value}
                      />
                      <label htmlFor={`${name}-${alignment.value}`}>{alignment.label}</label>
                  </React.Fragment>
              </div>
          ))}
        </div>
      );
    }
    const getTextStyle = (id,responsive) =>{
      //responsive pour verifier si il ne s'agit pas d'un texte à taille responsive en vw
      return(
        <div>
          <div className="style mb-4">
            <div className="color">
              <p>Couleur</p>
              <ColorPicker action={(e) => this.handleChange(e,"color")} details={this.state.texts[id]} type="color"/>
            </div>
            <div className="size">
              <p>taille {responsive&&<i className="fa fa-info-circle" aria-hidden="true" data-tooltip="taille responsive (en vw)  1 correspond à 1% de la largeur de l'écran"></i>}</p>
              {this.state.windowSize == "desktop-view"||this.state.texts[id].sizeMobile===undefined ? 
              <InputNumber changeNumber={(e) => this.changeNumber(e,this.state.texts[id].id, "size",responsive)} incr={() => this.incrementSpeed(this.state.texts[id].id,"size",responsive,200)} decr={() => this.decrementSpeed(this.state.texts[id].id,"size",responsive)} stop={this.stopCounter} width={this.state.texts[id].size}/>
              :
              <InputNumber changeNumber={(e) => this.changeNumber(e,this.state.texts[id].id, "sizeMobile",responsive)} incr={() => this.incrementSpeed(this.state.texts[id].id,"sizeMobile",responsive,200)} decr={() => this.decrementSpeed(this.state.texts[id].id,"sizeMobile",responsive)} stop={this.stopCounter} width={this.state.texts[id].sizeMobile}/>
              }
            </div>
            <div className="weight">
              <p>Epaisseur</p>
              <Select type="weight" onChange={this.updateTextOption} id={this.state.texts[id].id} options={weights} selectedOption={this.state.texts[id].weight}/>
            </div>
          </div>
          <p>Police</p>
          <Select type="font" onChange={this.updateTextOption} id={this.state.texts[id].id} options={options} selectedOption={this.state.texts[id].font}/>
        </div>
      )
    }
    const globalToken = this.getToken();

    if(!this.state.token&&!globalToken){
      return <div className="App"><Login setToken={this.setToken}/></div>
    }
    return (
      <div className="App">
        <Routes>
          <Route path='/edit/:id' element={
            <div className={this.state.expand ? 'SideBar expand' : 'SideBar'}>
              <button className='expand' onClick={() => {this.setState({expand:!this.state.expand})}}><i className={this.state.expand ? 'fa fa-bars' : 'fa fa-times'}></i></button>
              <h2>Editeur</h2>
              <div className="scroll">
                {this.state.currentPage == "homepage" ?
                //check if the stats are fully loaded
                (this.state.banerSlides.length > 0 && this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0) ?
                  <AdminHomepage
                    handleChange={this.handleChange}
                    texts = {this.state.texts}
                    windowSize = {this.state.windowSize}
                    handleClickScroll = {this.handleClickScroll}
                    accordionRefs={this.accordionRefs}
                    getMarginSelector={getMarginSelector}
                    getAlignSelector={getAlignSelector}
                    getTextStyle={getTextStyle}
                    getArraysize={getArraysize}
                    banerSlides={this.state.banerSlides}
                    handleDelete={this.handleDelete}
                    togglePopup={this.togglePopup}
                    changeTitle={this.changeTitle}
                    images={this.state.images}
                    photoUpload={this.photoUpload}
                    cardSlides={this.state.cardSlides}
                    updateCardOrder={this.handleUpdateCardSlidesOrder}
                    activePoppin={this.activePoppin}
                    removePoppin={this.removePoppin}
                    setId = {this.setPageToEdit}
                    resetState={this.resetState}
                  />
                : 
                <Spinner/>
                :
                this.state.currentPage == "lookbook" ?
                (this.state.images.length > 0 && this.state.texts.length > 0 && this.state.banerSlides.length > 0)?
                <AdminLookbook
                  handleChange={this.handleChange}
                  texts = {this.state.texts}
                  windowSize = {this.state.windowSize}
                  handleClickScroll = {this.handleClickScroll}
                  accordionRefs={this.accordionRefs}
                  getMarginSelector={getMarginSelector}
                  getAlignSelector={getAlignSelector}
                  getTextStyle={getTextStyle}
                  getArraysize={getArraysize}
                  banerSlides={this.state.banerSlides}
                  handleDelete={this.handleDelete}
                  togglePopup={this.togglePopup}
                  changeTitle={this.changeTitle}
                  images={this.state.images}
                  photoUpload={this.photoUpload}
                />
                :
                <Spinner/>
                :
                this.state.currentPage == "engagement" ?
                (this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0) ?
                <AdminEngagement
                  handleChange={this.handleChange}
                  texts = {this.state.texts}
                  windowSize = {this.state.windowSize}
                  handleClickScroll = {this.handleClickScroll}
                  accordionRefs={this.accordionRefs}
                  getMarginSelector={getMarginSelector}
                  getAlignSelector={getAlignSelector}
                  getTextStyle={getTextStyle}
                  getArraysize={getArraysize}
                  banerSlides={this.state.banerSlides}
                  handleDeleteCard={this.handleDeleteCard}
                  togglePopup={this.togglePopup}
                  changeTitle={this.changeTitle}
                  images={this.state.images}
                  photoUpload={this.photoUpload}
                  cardSlides={this.state.cardSlides}
                  updateCardOrder={this.handleUpdateCardSlidesOrder}
                />
                :
                <Spinner/>
                :
                this.state.currentPage == "recrutement" ?
                (this.state.banerSlides.length > 0 && this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0)?
                  <AdminRecrutement
                    handleChange={this.handleChange}
                    texts = {this.state.texts}
                    windowSize = {this.state.windowSize}
                    handleClickScroll = {this.handleClickScroll}
                    accordionRefs={this.accordionRefs}
                    getMarginSelector={getMarginSelector}
                    getAlignSelector={getAlignSelector}
                    getTextStyle={getTextStyle}
                    getArraysize={getArraysize}
                    banerSlides={this.state.banerSlides}
                    handleDelete={this.handleDelete}
                    handleDeleteCard={this.handleDeleteCard}
                    togglePopup={this.togglePopup}
                    changeTitle={this.changeTitle}
                    images={this.state.images}
                    photoUpload={this.photoUpload}
                    cardSlides={this.state.cardSlides}
                    updateCardOrder={this.handleUpdateCardSlidesOrder}
                  />
                :
                <Spinner/>
                :
                this.state.currentPage == "bar à jean" ?
                (this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0)?
                <AdminBar
                  handleChange={this.handleChange}
                  texts = {this.state.texts}
                  windowSize = {this.state.windowSize}
                  handleClickScroll = {this.handleClickScroll}
                  accordionRefs={this.accordionRefs}
                  getMarginSelector={getMarginSelector}
                  getAlignSelector={getAlignSelector}
                  getTextStyle={getTextStyle}
                  getArraysize={getArraysize}
                  handleDelete={this.handleDelete}
                  handleDeleteCard={this.handleDeleteCard}
                  togglePopup={this.togglePopup}
                  changeTitle={this.changeTitle}
                  images={this.state.images}
                  photoUpload={this.photoUpload}
                  cardSlides={this.state.cardSlides}
                  updateCardOrder={this.handleUpdateCardSlidesOrder}
                />
                :
                <Spinner/>
                :
                this.state.currentPage == "histoire" ?
                <AdminHistoire
                  handleChange={this.handleChange}
                  texts = {this.state.texts}
                  windowSize = {this.state.windowSize}
                  handleClickScroll = {this.handleClickScroll}
                  accordionRefs={this.accordionRefs}
                  getMarginSelector={getMarginSelector}
                  getAlignSelector={getAlignSelector}
                  getTextStyle={getTextStyle}
                  getArraysize={getArraysize}
                  togglePopup={this.togglePopup}
                  changeTitle={this.changeTitle}
                  images={this.state.images}
                  photoUpload={this.photoUpload}
                  incrementSpeed={this.incrementSpeed}
                  decrementSpeed={this.decrementSpeed}
                  changeNumber={this.changeNumber}
                  stop={this.stopCounter}
                />
                :
                this.state.currentPage == "poppins"||this.state.currentPage == "popup" ?
                <AdminPoppin
                  handleChange={this.handleChange}
                  page={this.state.currentPage}
                  handleDeleteElement={this.handleDeleteElement}
                  texts = {this.state.texts}
                  windowSize = {this.state.windowSize}
                  handleClickScroll = {this.handleClickScroll}
                  accordionRefs={this.accordionRefs}
                  getMarginSelector={getMarginSelector}
                  getAlignSelector={getAlignSelector}
                  getTextStyle={getTextStyle}
                  getArraysize={getArraysize}
                  togglePopup={this.togglePopup}
                  changeTitle={this.changeTitle}
                  changePara={this.changePara}
                  changeDate={this.changeDate}
                  images={this.state.images}
                  photoUpload={this.photoUpload}
                  incrementSpeed={this.incrementSpeed}
                  decrementSpeed={this.decrementSpeed}
                  updateCardOrder={this.handleUpdateCardSlidesOrder}
                  updateTextOption={this.updateTextOption}
                  changeNumber={this.changeNumber}
                  stop={this.stopCounter}
                />
                :this.state.currentPage == "new homepage" ?
                <AdminNewHomepage
                  handleChange={this.handleChange}
                  handleDeleteElement={this.handleDeleteElement}
                  texts = {this.state.texts}
                  windowSize = {this.state.windowSize}
                  handleClickScroll = {this.handleClickScroll}
                  accordionRefs={this.accordionRefs}
                  getMarginSelector={getMarginSelector}
                  getAlignSelector={getAlignSelector}
                  getTextStyle={getTextStyle}
                  getArraysize={getArraysize}
                  banerSlides={this.state.banerSlides}
                  addSlide={this.handleAdd}
                  handleDelete={this.handleDelete}
                  handleDeleteCard={this.handleDeleteCard}
                  togglePopup={this.togglePopup}
                  changeTitle={this.changeTitle}
                  images={this.state.images}
                  changePara={this.changePara}
                  photoUpload={this.photoUpload}
                  cardSlides={this.state.cardSlides}
                  updateCardOrder={this.handleUpdateCardSlidesOrder}
                  incrementSpeed={this.incrementSpeed}
                  decrementSpeed={this.decrementSpeed}
                  changeDate={this.changeDate}
                  stop={this.stopCounter}
                  changeNumber={this.changeNumber}
                  updateTextOption={this.updateTextOption}
                />
                :null
                }
                {this.state.currentPage!=="poppins"&&<PrimaryButton onClick={() => this.togglePopup("get-html", null, null)}>Obtenir le html de la page</PrimaryButton>}
              </div>
            </div>}/>
        </Routes>
        <Routes>
          <Route path='/edit/:id' element={
          <div className="Content" id={this.state.windowSize} ref={this.captureRef}>
            {this.state.currentPage == "homepage" ?
            (this.state.banerSlides.length > 0 && this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0)?
            <Home
              menuStyle={this.state.texts[0]}
              menuTextStyle={this.state.texts[1]}
              banerSlides = {this.state.banerSlides}
              fondBaner = {this.state.images[0].imagePreviewUrl}
              titleBaner={this.state.texts[1]}
              subtitleBaner={this.state.texts[2]}
              banerBtnStyle={this.state.texts[3]}
              cardTextStyle={this.state.texts[4]}
              cardSlides = {this.state.cardSlides}
              pinkStyle={this.state.texts[5]}
              pinkTitleStyle = {this.state.texts[6]}
              pinkSubtitleStyle = {this.state.texts[7]}
              imgBloc1 = {this.state.images[1].imagePreviewUrl}
              imgBloc1Mobile = {this.state.images[2].imagePreviewUrl}
              imgBloc2 = {this.state.images[3].imagePreviewUrl}
              imgBloc2Mobile = {this.state.images[4].imagePreviewUrl}
              imgBloc3Left = {this.state.images[5].imagePreviewUrl}
              imgBloc3Right = {this.state.images[6].imagePreviewUrl}
              imgBloc3LeftMobile = {this.state.images[7].imagePreviewUrl}
              imgBloc4 = {this.state.images[8].imagePreviewUrl}
              imgBloc4Mobile = {this.state.images[9].imagePreviewUrl}
              titleBloc1Style = {this.state.texts[8]}
              subtitleBloc1Style = {this.state.texts[9]}
              titleBloc2Style = {this.state.texts[10]}
              subtitleBloc2Style = {this.state.texts[11]}
              cardFooterTextStyle={this.state.texts[12]}
              mode = {true}
              windowSize = {this.state.windowSize}
              twoImageTitleStyle = {this.state.texts[13]}
              twoImageBtnStyle = {this.state.texts[14]}
              collectionTitleStyle = {this.state.texts[15]}
              collectionBtnStyle = {this.state.texts[16]}
              btnListStyle = {this.state.texts[17]}
              titleFirstBloc = {this.state.texts[18]}
              titleItalicFirstBloc = {this.state.texts[19]}
              changeTitle = {this.changeTitle}
              togglePopup = {this.togglePopup}
              focusAccordion = {this.handleElementFocus}
              poppins = {this.state.texts[1].usePoppins&&this.state.texts[1].poppinsId ? this.state.allPage.find(objet => objet.id === this.state.texts[1].poppinsId) : null}
            />
            :
            <Spinner/>
            :
            this.state.currentPage == "lookbook" ?
            (this.state.images.length > 0 && this.state.texts.length > 0 && this.state.banerSlides.length > 0)?
            <LookBook
              grid1 = {this.state.images[0].imagePreviewUrl}
              grid1Mobile = {this.state.images[1].imagePreviewUrl}
              grid2 = {this.state.images[2].imagePreviewUrl}
              grid2Mobile = {this.state.images[3].imagePreviewUrl}
              grid3 = {this.state.images[4].imagePreviewUrl}
              grid4 = {this.state.images[5].imagePreviewUrl}
              grid5 = {this.state.images[6].imagePreviewUrl}
              grid5Mobile = {this.state.images[7].imagePreviewUrl}
              grid6 = {this.state.images[8].imagePreviewUrl}
              grid6Mobile = {this.state.images[9].imagePreviewUrl}
              grid7 = {this.state.images[10].imagePreviewUrl}
              grid7Mobile = {this.state.images[11].imagePreviewUrl}
              grid8 = {this.state.images[12].imagePreviewUrl}
              grid9 = {this.state.images[13].imagePreviewUrl}
              grid9Mobile = {this.state.images[14].imagePreviewUrl}
              grid10 = {this.state.images[15].imagePreviewUrl}
              grid10Mobile = {this.state.images[16].imagePreviewUrl}
              grid11 = {this.state.images[17].imagePreviewUrl}
              grid11Mobile = {this.state.images[18].imagePreviewUrl}
              grid12 = {this.state.images[19].imagePreviewUrl}
              grid12Mobile = {this.state.images[20].imagePreviewUrl}
              grid13 = {this.state.images[21].imagePreviewUrl}
              grid14 = {this.state.images[22].imagePreviewUrl}
              grid14Mobile = {this.state.images[23].imagePreviewUrl}
              grid15 = {this.state.images[24].imagePreviewUrl}
              grid15Mobile = {this.state.images[25].imagePreviewUrl}
              grid16 = {this.state.images[26].imagePreviewUrl}
              grid16Mobile = {this.state.images[27].imagePreviewUrl}
              banerTitleStyle = {this.state.texts[0]}
              block4ImageTextTitleStyle = {this.state.texts[1]}
              block4ImageTextParaStyle = {this.state.texts[2]}
              block13ImageTextTitleStyle = {this.state.texts[3]}
              block13ImageTextParaStyle = {this.state.texts[4]}
              links = {this.state.banerSlides}
              mode = {true}
              windowsSize = {this.state.windowSize}
              changeTitle={this.changeTitle}
              togglePopup = {this.togglePopup}
              focusAccordion = {this.handleElementFocus}
            />
            :
            <Spinner/>
            :
            this.state.currentPage == "engagement" ?
            (this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0) ?
            <Engagement
              imgBloc3Left = {this.state.images[0].imagePreviewUrl}
              bloc3Text = {this.state.texts[0]}
              imgBloc3Right = {this.state.images[1].imagePreviewUrl}
              bloc4FirstTitle = {this.state.texts[1]}
              bloc4FirstSubtitle = {this.state.texts[2]}
              bloc4SecondPara = {this.state.texts[3]}
              imgBloc4 = {this.state.images[2].imagePreviewUrl}
              engagementStyle={this.state.texts[4]}
              engagementSlides = {this.state.cardSlides}
              engagementTitleStyle={this.state.texts[5]}
              engagementSubtitleStyle={this.state.texts[6]}
              engagementParaStyle={this.state.texts[7]}
              plusLoinTitleStyle={this.state.texts[8]}
              plusLoinSubtitleStyle={this.state.texts[9]}
              faqStyle={this.state.texts[10]}
              imgFaq = {this.state.images[3].imagePreviewUrl}
              questionTitleStyle={this.state.texts[11]}
              questionSubtitleStyle={this.state.texts[12]}
              questionBtnStyle={this.state.texts[13]}
              videoTitle={this.state.texts[14]}
              videoSubtitle={this.state.texts[15]}
              mode = {true}
              changeTitle = {this.changeTitle}
              togglePopup = {this.togglePopup}
              focusAccordion = {this.handleElementFocus}
            />
            :
            <Spinner/>
            :
            this.state.currentPage == "recrutement" ?
            (this.state.banerSlides.length > 0 && this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0)?
            <Recrutement
              logo = {this.state.images[0].imagePreviewUrl}
              fondBaner = {this.state.images[1].imagePreviewUrl}
              logoBlanc = {this.state.images[2].imagePreviewUrl}
              banerSlides = {this.state.banerSlides}
              cards = {this.state.cardSlides}
              banerTitleStyle={this.state.texts[0]}
              banerSubtitleStyle={this.state.texts[1]}
              formStyle={this.state.texts[2]}
              formSubtitleStyle={this.state.texts[3]}
              formParaStyle={this.state.texts[4]}
              formMentionStyle={this.state.texts[5]}
              formMentionLinkStyle={this.state.texts[6]}
              conseillereTitleStyle={this.state.texts[7]}
              conseillereSubtitleStyle={this.state.texts[8]}
              conseillereSubtitlePinkStyle={this.state.texts[9]}
              conseillereIframe={this.state.texts[10]}
              conseillereSecondColumnTitle={this.state.texts[11]}
              bgColor={this.state.texts[12]}
              reseauxStyle={this.state.texts[13]}
              decouvrirTitleStyle={this.state.texts[14]}
              decouvrirSubtitleStyle={this.state.texts[15]}
              decouvrirSecondColumnTitle={this.state.texts[16]}
              decouvrirSecondColumnPara={this.state.texts[17]}
              imagineTitleStyle={this.state.texts[18]}
              imagineSubtitleStyle={this.state.texts[19]}
              generalBtnStyle={this.state.texts[20]}
              savoiPlusTitleStyle={this.state.texts[21]}
              savoiPlusSubtitleStyle={this.state.texts[22]}
              savoiPlusSubtitlePinkStyle={this.state.texts[23]}
              savoiPlusCardTitleStyle={this.state.texts[24]}
              savoiPlusCardTextStyle={this.state.texts[25]}
              presseStyle={this.state.texts[26]}
              footerStyle={this.state.texts[27]}
              footerTextStyle={this.state.texts[28]}
              mode = {true}
              windowSize = {this.state.windowSize}
              changeTitle = {this.changeTitle}
              togglePopup = {this.togglePopup}
              focusAccordion = {this.handleElementFocus}
            />
            :
            <Spinner/>
            :
            this.state.currentPage == "bar à jean" ?
            (this.state.images.length > 0 && this.state.texts.length > 0 && this.state.cardSlides.length > 0)?
            <Bar
              logo = {this.state.images[0].imagePreviewUrl}
              cards = {this.state.cardSlides}
              headerTitle={this.state.texts[0]}
              headerSubtitle={this.state.texts[1]}
              headerPara={this.state.texts[2]}
              cardTitleStyle={this.state.texts[4]}
              cardSubtitleStyle={this.state.texts[5]}
              cardTextStyle={this.state.texts[6]}
              cardListStyle={this.state.texts[7]}
              cardNumStyle={this.state.texts[8]}
              cardLinkStyle={this.state.texts[9]}
              logoBlack = {this.state.images[1].imagePreviewUrl}
              footerText = {this.state.texts[3]}
              tortue = {this.state.images[2].imagePreviewUrl}
              focusAccordion = {this.handleElementFocus}
              changeTitle = {this.changeTitle}
              togglePopup = {this.togglePopup}
              mode = {true}
            />
            :
            <Spinner/>
            :
            this.state.currentPage == "histoire" ?
            <Histoire
              baner={this.state.images[0].imagePreviewUrl}
              banerStyle={this.state.texts[0]}
              banerTextStyle={this.state.texts[1]}
              firstRowTitle={this.state.texts[2]}
              firstRowText={this.state.texts[3]}
              firstRowButton={this.state.texts[4]}
              op1={this.state.images[1].imagePreviewUrl}
              op2={this.state.images[2].imagePreviewUrl}
              op3={this.state.images[3].imagePreviewUrl}
              firstRowSign={this.state.images[4].imagePreviewUrl}
              secondRowTitle={this.state.texts[5]}
              secondRowText={this.state.texts[6]}
              thirdRowTitle={this.state.texts[7]}
              thirdRowText={this.state.texts[8]}
              thirdRowFirstBtn={this.state.texts[9]}
              thirdRowSecondBtn={this.state.texts[10]}
              focusAccordion = {this.handleElementFocus}
              changeTitle = {this.changeTitle}
              windowSize = {this.state.windowSize}
              mode = {true}
            />
            :
            this.state.currentPage == "poppins"||this.state.currentPage == "popup" ?
            <Poppin
              page={this.state.currentPage}
              menuStyle={this.state.allPage[0].contentText[0]}
              menuTextStyle={this.state.allPage[0].contentText[1]}
              banerSlides = {this.state.allPage[0].content}
              fondBaner = {this.state.allPage[0].contentImage[0].imagePreviewUrl}
              titleBaner={this.state.allPage[0].contentText[1]}
              subtitleBaner={this.state.allPage[0].contentText[2]}
              banerBtnStyle={this.state.allPage[0].contentText[3]}
              cardTextStyle={this.state.allPage[0].contentText[4]}
              pinkSubtitleStyle = {this.state.allPage[0].contentText[7]}
              pinkStyle={this.state.allPage[0].contentText[5]}
              pinkTitleStyle = {this.state.allPage[0].contentText[6]}
              titleBloc1Style = {this.state.allPage[0].contentText[8]}
              texts={this.state.texts}
              focusAccordion = {this.handleElementFocus}
              changeTitle = {this.changeTitle}
              images={this.state.images}
              mode = {true}
              extract={false}
            />
            :
            this.state.currentPage == "new homepage" ?
            <NewHomepage
              mode = {true}
              texts = {this.state.texts}
              images = {this.state.images}
              banerSlides = {this.state.banerSlides}
              cards = {this.state.cardSlides}
              windowSize = {this.state.windowSize}
              changeTitle = {this.changeTitle}
              togglePopup = {this.togglePopup}
              focusAccordion = {this.handleElementFocus}
              handleEdit={this.handleEdit}
            />
            :null
            }
            {this.state.showPopup ?
              <Popup
                activePopup={this.state.activePopup}
                header={this.state.activePopup == "add-baner-slide" ? ( this.state.cardType == "baner-slide" ? "Ajouter un slide" : "Ajouter un bouton") : this.state.activePopup == "edit-baner-slide" ? ( this.state.cardType == "baner-slide" ? "Modifier un slide" : "Modifier le bouton") : this.state.activePopup == "add-card" ? "Ajouter une carte" : this.state.activePopup == "edit-card" ? "Modifier la carte" : this.state.activePopup == "get-html" ? "Le contenu html de la page": this.state.activePopup == "save" ? "Confirmation" : this.state.activePopup == "image-cropper" ? "éditer l'image": this.state.activePopup == "import" ? 'Importer votre html' : this.state.activePopup.includes("-content") ? 'Choix des éléménts' : 'choisir le poppins à ajouter'}
                toggle={this.togglePopup}
                content={
                  this.state.activePopup == "add-baner-slide" ?
                  <Form onAdd={this.handleAdd} type="add" cardType={this.state.cardType}/>
                  :this.state.activePopup == "edit-baner-slide" ?
                  <Form onEdit={this.handleEdit} type="edit" objet={this.state.editObject} cardType={this.state.cardType}/>
                  :this.state.activePopup == "add-card" ?
                  <Form onAdd={this.handleAddCard} type="add-card" cardType={this.state.cardType} currentPage={this.state.currentPage}/>
                  :this.state.activePopup == "edit-card" ?
                  <Form onEdit={this.handleEditCard} type="edit-card" objet={this.state.editObject} cardType={this.state.cardType} currentPage={this.state.currentPage}/>
                  :this.state.activePopup == "get-html" ?
                  <div className='text-center'>
                    <div className='html-content' ref={this.codeRef}>{this.state.html}</div>
                    <PrimaryButton className='mt-2' onClick={() => this.handleCopyCode()}><i className='fa fa-copy'></i> Copier le code</PrimaryButton>
                  </div>
                  :this.state.activePopup == "save" ?
                    <div>
                      { this.state.updateStatus=="Téléchargement de la capture d'écran"&&this.state.percentageUpload==100
                      ?
                      <div>
                        <p className="text-white">Voulez-vous sauvegarder les modifications effectuée?</p>
                        <div className='d-flex justify-content-between mt-5'>
                          <PrimaryButton onClick={this.updatePage}><i className='fa fa-check'></i> Confirmer</PrimaryButton>
                          <PrimaryButton onClick={() => this.togglePopup()}><i className='fa fa-times'></i> Annuler</PrimaryButton>
                        </div>
                      </div>
                      :
                      <div className='text-center'>
                        <p className="text-white"><small>{this.state.updateStatus} ({this.state.percentageUpload+'%'})</small></p>
                        <div className='progress-content'>
                          <div className='progress-bar' style={{width: `${this.state.percentageUpload}%`}}></div>
                        </div>
                      </div>
                      }
                    </div>
                  : this.state.activePopup == "image-cropper" ?
                  <div>
                    <p>Le champ s'adapte automatiquement à la taille de l'image recommandée, il vous suffit de rogner l'images, vous pouvez egalement le zoomer</p>
                    <ImageCropper image={this.state.currentImage} preview={this.setEditedImagePreview} name={this.state.currentImageName} imageId={this.state.currentImageId} dimensions={this.state.currentPage == "homepage" ? dimensionImage[this.state.currentImageId] : this.state.currentPage == "lookbook" ? dimensionImageLookBook[this.state.currentImageId] : this.state.currentPage == "engagement" ? dimensionImageEngagement[this.state.currentImageId] : this.state.currentPage == "histoire" ? dimensionImageHistoire[this.state.currentImageId] : this.state.currentPage == "new homepage" ? dimensionImageNew[0] : dimensionImageRecrutement[this.state.currentImageId]}/>
                  </div>
                  :this.state.activePopup == "import" ?
                    (this.state.updateStatus == "" && this.state.percentageUpload == 0 ?
                    <form onSubmit={(e) => this.importHtml(e)}>
                      <p>Votre html à importer</p>
                      <PrimaryTextarea style={{minHeight:300}} value={this.state.htmlToImport} onChange={(e) => this.handleTextareaImportHtmlChange(e)} placeholder="copier votre html ici"/>
                      {this.state.importStatut == "success" ? <p className='success'>Votre html a été importé avec succès</p> : this.state.importStatut == "error" ? <p className='error'>Votre html n'est pas valide</p> : null}
                      <div className='text-center'>
                        <PrimaryButton className='mt-2 text-center'><i className='fa fa-download'></i> Importer le code</PrimaryButton>
                      </div>
                    </form>
                    :
                    <div className='text-center'>
                      <p className="text-white"><small>{this.state.updateStatus} ({this.state.percentageUpload+'%'})</small></p>
                      <div className='progress-content'>
                        <div className='progress-bar' style={{width: `${this.state.percentageUpload}%`}}></div>
                      </div>
                    </div>
                    )
                  :this.state.activePopup == "restore" ?
                    <div>
                      <p className="text-white">Voulez-vous annuler les modifications apportées?</p>
                      <div className='d-flex justify-content-between mt-5'>
                        <PrimaryButton onClick={this.restore}><i className='fa fa-check'></i> Confirmer</PrimaryButton>
                        <PrimaryButton onClick={() => this.togglePopup()}><i className='fa fa-times'></i> Annuler</PrimaryButton>
                      </div>
                    </div>
                  :this.state.activePopup.includes("-content")||this.state.activePopup.includes("add-element-") ?
                    <div className='element-choice'>
                      <p className="text-white">Sélectionnez le type d'élément que vous voulez ajouter</p>
                      <div className='row'>
                        <div className='col-4'>
                          <PrimaryButton onClick={()=>this.handleAddElement('texte')} className='w-100 mb-4'><i className='fa fa-font'></i><br/> Texte</PrimaryButton>
                        </div>
                        <div className='col-4'>
                          <PrimaryButton onClick={()=>this.handleAddElement('image')} className='w-100 mb-4'><i className='fa fa-photo'></i><br/> Une image</PrimaryButton>
                        </div>
                        <div className='col-4'>
                          <PrimaryButton onClick={()=>this.handleAddElement('bouton')} className='w-100 mb-4'><i className='fa fa-mouse-pointer'></i><br/> Un bouton</PrimaryButton>
                        </div>
                        <div className='col-4'>
                          <PrimaryButton onClick={()=>this.handleAddElement('compteur')} className='w-100 mb-4'><i className='fa fa-tachometer'></i><br/> Un compteur</PrimaryButton>
                        </div>
                        <div className='col-4'>
                          <PrimaryButton onClick={()=>this.handleAddElement('champ')} className='w-100 mb-4 px-0'><i className='fa fa-keyboard-o'></i><br/> Champ de saisie</PrimaryButton>
                        </div>
                      </div>
                      {/*
                      <p className="text-white">Sélectionnez le type de conteneur que vous voulez ajouter</p>
                        <div className='row'>
                          <div className='col-4'>
                            <PrimaryButton onClick={()=>this.handleAddElement('carousel')} className='w-100 mb-4'><i className='fa fa-sliders'></i><br/> Un carousel</PrimaryButton>
                          </div>
                        </div>
                      */}
                    </div>
                  : this.state.activePopup=="select-poppins" ?
                    <div>
                      <div className='row'>
                      {this.state.allPage.filter(page => page.title == "poppins").map((page,index) =>
                        <div className='col-md-6 mb-2' key={index}>
                          <img src={page.illustration} className='img-fluid' onClick={() => this.setActivePoppins(page.id)}/>
                        </div>
                      )}
                      </div>
                    </div>
                  :null
                }
              />
              :null
            }
          </div>}/>
          <Route path='/' element={
            <Template
              setId = {this.setPageToEdit}
              resetState={this.resetState}
              allPage={this.state.allPage}
              deletePage={this.handleDeletePage}
              duplicatePage={this.handleDuplicatePage}
              poppinsView = {this.state.poppinsView}
            />
          }
          />
          <Route path='/voir/:id' element={
            this.state.currentPage == "homepage" ?
            (this.state.banerSlides.length > 0 && this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0)?
            <Home 
              menuStyle={this.state.texts[0]}
              menuTextStyle={this.state.texts[1]}
              banerSlides = {this.state.banerSlides}
              fondBaner = {this.state.images[0].imagePreviewUrl}
              titleBaner={this.state.texts[1]}
              subtitleBaner={this.state.texts[2]}
              banerBtnStyle={this.state.texts[3]}
              cardTextStyle={this.state.texts[4]}
              cardSlides = {this.state.cardSlides}
              pinkStyle={this.state.texts[5]}
              pinkTitleStyle = {this.state.texts[6]}
              pinkSubtitleStyle = {this.state.texts[7]}
              imgBloc1 = {this.state.images[1].imagePreviewUrl}
              imgBloc1Mobile = {this.state.images[2].imagePreviewUrl}
              imgBloc2 = {this.state.images[3].imagePreviewUrl}
              imgBloc2Mobile = {this.state.images[4].imagePreviewUrl}
              imgBloc3Left = {this.state.images[5].imagePreviewUrl}
              imgBloc3Right = {this.state.images[6].imagePreviewUrl}
              imgBloc3LeftMobile = {this.state.images[7].imagePreviewUrl}
              imgBloc4 = {this.state.images[8].imagePreviewUrl}
              imgBloc4Mobile = {this.state.images[9].imagePreviewUrl}
              titleBloc1Style = {this.state.texts[8]}
              subtitleBloc1Style = {this.state.texts[9]}
              titleBloc2Style = {this.state.texts[10]}
              subtitleBloc2Style = {this.state.texts[11]}
              cardFooterTextStyle={this.state.texts[12]}
              mode = {false}
              windowsSize = 'desktop-view'
              twoImageTitleStyle = {this.state.texts[13]}
              twoImageBtnStyle = {this.state.texts[14]}
              collectionTitleStyle = {this.state.texts[15]}
              collectionBtnStyle = {this.state.texts[16]}
              btnListStyle = {this.state.texts[17]}
              titleFirstBloc = {this.state.texts[18]}
              titleItalicFirstBloc = {this.state.texts[19]}
              slider = {true}
              poppins = {this.state.texts[1].usePoppins&&this.state.texts[1].poppinsId ? this.state.allPage.find(objet => objet.id === this.state.texts[1].poppinsId) : null}
            />
            : 
            <Spinner/>
            :
            this.state.currentPage == "lookbook" ? 
            (this.state.images.length > 0 && this.state.texts.length > 0 && this.state.banerSlides.length > 0)?
            <LookBook
              grid1 = {this.state.images[0].imagePreviewUrl}
              grid1Mobile = {this.state.images[1].imagePreviewUrl}
              grid2 = {this.state.images[2].imagePreviewUrl}
              grid2Mobile = {this.state.images[3].imagePreviewUrl}
              grid3 = {this.state.images[4].imagePreviewUrl}
              grid4 = {this.state.images[5].imagePreviewUrl}
              grid5 = {this.state.images[6].imagePreviewUrl}
              grid5Mobile = {this.state.images[7].imagePreviewUrl}
              grid6 = {this.state.images[8].imagePreviewUrl}
              grid6Mobile = {this.state.images[9].imagePreviewUrl}
              grid7 = {this.state.images[10].imagePreviewUrl}
              grid7Mobile = {this.state.images[11].imagePreviewUrl}
              grid8 = {this.state.images[12].imagePreviewUrl}
              grid9 = {this.state.images[13].imagePreviewUrl}
              grid9Mobile = {this.state.images[14].imagePreviewUrl}
              grid10 = {this.state.images[15].imagePreviewUrl}
              grid10Mobile = {this.state.images[16].imagePreviewUrl}
              grid11 = {this.state.images[17].imagePreviewUrl}
              grid11Mobile = {this.state.images[18].imagePreviewUrl}
              grid12 = {this.state.images[19].imagePreviewUrl}
              grid12Mobile = {this.state.images[20].imagePreviewUrl}
              grid13 = {this.state.images[21].imagePreviewUrl}
              grid14 = {this.state.images[22].imagePreviewUrl}
              grid14Mobile = {this.state.images[23].imagePreviewUrl}
              grid15 = {this.state.images[24].imagePreviewUrl}
              grid15Mobile = {this.state.images[25].imagePreviewUrl}
              grid16 = {this.state.images[26].imagePreviewUrl}
              grid16Mobile = {this.state.images[27].imagePreviewUrl}
              banerTitleStyle = {this.state.texts[0]}
              block4ImageTextTitleStyle = {this.state.texts[1]}
              block4ImageTextParaStyle = {this.state.texts[2]}
              block13ImageTextTitleStyle = {this.state.texts[3]}
              block13ImageTextParaStyle = {this.state.texts[4]}
              links = {this.state.banerSlides}
              mode = {false}
              windowsSize = 'desktop-view'
            />
            : 
            <Spinner/>
            : this.state.currentPage == "engagement" ? 
            (this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0) ?
            <Engagement
              imgBloc3Left = {this.state.images[0].imagePreviewUrl}
              bloc3Text = {this.state.texts[0]}
              imgBloc3Right = {this.state.images[1].imagePreviewUrl}
              bloc4FirstTitle = {this.state.texts[1]}
              bloc4FirstSubtitle = {this.state.texts[2]}
              bloc4SecondPara = {this.state.texts[3]}
              imgBloc4 = {this.state.images[2].imagePreviewUrl}
              engagementStyle={this.state.texts[4]}
              engagementSlides = {this.state.cardSlides}
              engagementTitleStyle={this.state.texts[5]}
              engagementSubtitleStyle={this.state.texts[6]}
              engagementParaStyle={this.state.texts[7]}
              plusLoinTitleStyle={this.state.texts[8]}
              plusLoinSubtitleStyle={this.state.texts[9]}
              faqStyle={this.state.texts[10]}
              imgFaq = {this.state.images[3].imagePreviewUrl}
              questionTitleStyle={this.state.texts[11]}
              questionSubtitleStyle={this.state.texts[12]}
              questionBtnStyle={this.state.texts[13]}
              videoTitle={this.state.texts[14]}
              videoSubtitle={this.state.texts[15]}
              mode = {false}
            />
            :
            <Spinner/>
            :
            this.state.currentPage == "recrutement" ?
            (this.state.banerSlides.length > 0 && this.state.cardSlides.length > 0 && this.state.texts.length > 0 && this.state.images.length > 0)?
            <Recrutement
              logo = {this.state.images[0].imagePreviewUrl}
              fondBaner = {this.state.images[1].imagePreviewUrl}
              logoBlanc = {this.state.images[2].imagePreviewUrl}
              cards = {this.state.cardSlides}
              banerSlides = {this.state.banerSlides}
              banerTitleStyle={this.state.texts[0]}
              banerSubtitleStyle={this.state.texts[1]}
              formStyle={this.state.texts[2]}
              formSubtitleStyle={this.state.texts[3]}
              formParaStyle={this.state.texts[4]}
              formMentionStyle={this.state.texts[5]}
              formMentionLinkStyle={this.state.texts[6]}
              conseillereTitleStyle={this.state.texts[7]}
              conseillereSubtitleStyle={this.state.texts[8]}
              conseillereSubtitlePinkStyle={this.state.texts[9]}
              conseillereIframe={this.state.texts[10]}
              conseillereSecondColumnTitle={this.state.texts[11]}
              bgColor={this.state.texts[12]}
              reseauxStyle={this.state.texts[13]}
              decouvrirTitleStyle={this.state.texts[14]}
              decouvrirSubtitleStyle={this.state.texts[15]}
              decouvrirSecondColumnTitle={this.state.texts[16]}
              decouvrirSecondColumnPara={this.state.texts[17]}
              imagineTitleStyle={this.state.texts[18]}
              imagineSubtitleStyle={this.state.texts[19]}
              generalBtnStyle={this.state.texts[20]}
              savoiPlusTitleStyle={this.state.texts[21]}
              savoiPlusSubtitleStyle={this.state.texts[22]}
              savoiPlusSubtitlePinkStyle={this.state.texts[23]}
              savoiPlusCardTitleStyle={this.state.texts[24]}
              savoiPlusCardTextStyle={this.state.texts[25]}
              presseStyle={this.state.texts[26]}
              footerStyle={this.state.texts[27]}
              footerTextStyle={this.state.texts[28]}
              mode = {false}
              slider = {true}
              windowSize = {this.state.windowSize}
            />
            :
            <Spinner/>
            :
            this.state.currentPage == "bar à jean" ?
            (this.state.images.length > 0 && this.state.texts.length > 0 && this.state.cardSlides.length > 0)?
            <Bar
              logo = {this.state.images[0].imagePreviewUrl}
              cards = {this.state.cardSlides}
              headerTitle={this.state.texts[0]}
              headerSubtitle={this.state.texts[1]}
              headerPara={this.state.texts[2]}
              cardTitleStyle={this.state.texts[4]}
              cardSubtitleStyle={this.state.texts[5]}
              cardTextStyle={this.state.texts[6]}
              cardListStyle={this.state.texts[7]}
              cardNumStyle={this.state.texts[8]}
              cardLinkStyle={this.state.texts[9]}
              logoBlack = {this.state.images[1].imagePreviewUrl}
              footerText = {this.state.texts[3]}
              tortue = {this.state.images[2].imagePreviewUrl}
              mode = {false}
            />
            :
            <Spinner/>
            :
            this.state.currentPage == "histoire" ?
            <Histoire
              baner={this.state.images[0].imagePreviewUrl}
              banerStyle={this.state.texts[0]}
              banerTextStyle={this.state.texts[1]}
              firstRowTitle={this.state.texts[2]}
              firstRowText={this.state.texts[3]}
              firstRowButton={this.state.texts[4]}
              op1={this.state.images[1].imagePreviewUrl}
              op2={this.state.images[2].imagePreviewUrl}
              op3={this.state.images[3].imagePreviewUrl}
              firstRowSign={this.state.images[4].imagePreviewUrl}
              secondRowTitle={this.state.texts[5]}
              secondRowText={this.state.texts[6]}
              thirdRowTitle={this.state.texts[7]}
              thirdRowText={this.state.texts[8]}
              thirdRowFirstBtn={this.state.texts[9]}
              thirdRowSecondBtn={this.state.texts[10]}
              mode = {false}
            />
            :
            this.state.currentPage == "poppins"||this.state.currentPage == "popup" ?
            <Poppin
              page={this.state.currentPage}
              menuStyle={this.state.allPage[0].contentText[0]}
              menuTextStyle={this.state.allPage[0].contentText[1]}
              banerSlides = {this.state.allPage[0].content}
              fondBaner = {this.state.allPage[0].contentImage[0].imagePreviewUrl}
              titleBaner={this.state.allPage[0].contentText[1]}
              subtitleBaner={this.state.allPage[0].contentText[2]}
              banerBtnStyle={this.state.allPage[0].contentText[3]}
              cardTextStyle={this.state.allPage[0].contentText[4]}
              pinkSubtitleStyle = {this.state.allPage[0].contentText[7]}
              pinkStyle={this.state.allPage[0].contentText[5]}
              pinkTitleStyle = {this.state.allPage[0].contentText[6]}
              titleBloc1Style = {this.state.allPage[0].contentText[8]}
              texts={this.state.texts}
              images={this.state.images}
              mode = {false}
              extract={false}
              slider = {true}
            />
            :
            this.state.currentPage == "new homepage" ?
            <NewHomepage
              mode = {false}
              texts = {this.state.texts}
              images = {this.state.images}
              banerSlides = {this.state.banerSlides}
              cards = {this.state.cardSlides}
              slider = {true}
            />
            :
            null
          }
          />
        </Routes>
        <Routes>
          <Route path='/edit/*' element={
            <div id="mySidenav">
              <Link to={'/'} id="back"> <i className="fa fa-arrow-left"></i> Retour</Link>
              <Link to={'/voir/'+this.state.currentId} id="voir"> <i className="fa fa-eye"></i> Aperçu</Link>
              <button id="cancel"  onClick={() => this.uploadAllImage()}><i className="fa fa-save"></i> Sauvegarde</button>
              <button id="import"  onClick={() => this.togglePopup("import",null,null)}><i className="fa fa-download"></i> Import</button>
              <button id="refresh"  onClick={() => this.togglePopup("restore",null,null)}><i className="fa fa-refresh"></i> Annuler</button>
              {this.state.windowSize == "mobile-view" ? 
              <div>
                <button id="desktop"  onClick={() => this.changeWindowsSize("desktop-view")}><i className="fa fa-desktop"></i> Desktop</button>
                <button id="tablet"  onClick={() => this.changeWindowsSize("tablet-view")}><i className="fa fa-tablet"></i> Tablette</button>
              </div>
              :
              this.state.windowSize == "tablet-view" ?
              <div>
                <button id="desktop"  onClick={() => this.changeWindowsSize("desktop-view")}><i className="fa fa-desktop"></i> Desktop</button>
                <button id="mobile" className='special' onClick={() => this.changeWindowsSize("mobile-view")}><i className="fa fa-mobile"></i> Mobile</button>
              </div>
              :
              <div>
                <button id="tablet"  onClick={() => this.changeWindowsSize("tablet-view")}><i className="fa fa-tablet"></i> Tablette</button>
                <button id="mobile" onClick={() => this.changeWindowsSize("mobile-view")}><i className="fa fa-mobile"></i> Mobile</button>
              </div>
              }
            </div>
          }/>
          <Route path='/voir/*' element={
            <div id="mySidenav">
              <Link to={'/edit/'+this.state.currentId} id="voir"><i className="fa fa-edit"></i> Editer</Link>
            </div>
          }/>
          <Route path='/' element={
            <div id="mySidenav">
              <button onClick={() => this.logout()} id="voir"><i className="fa fa-sign-out"></i> Déconnexion</button>
              <button onClick={() => this.setState({poppinsView:!this.state.poppinsView})} id="back"><i className="fa fa-refresh"></i>{this.state.poppinsView ? 'les pages' : 'les pop-in'}</button>
            </div>
          }/>
        </Routes>
      </div>
    )
  }
}
export default App;