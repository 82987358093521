import React, {useState, forwardRef } from "react";
import { PrimaryButton } from "./Button.style";

const Objet = forwardRef((props, ref) => {
    const { details, onDelete, toggle, type, cardType, id,element,handleButtonClick,isButtonClicked, ...dragProps } = props;
    const truncatedText = (text, maxLength) => {
        return text.length <= maxLength ? text : text.slice(0, maxLength) + '...';
    };
    return (
        <li className="object" id={id} {...dragProps} ref={ref}>
            <span>{cardType === "card-engagement" || cardType === "card-bar" || cardType === "faq" || cardType === "card-recrutement" || cardType === "savoir-plus"
                ? truncatedText(details.link, 16)
                : cardType === "reseaux" || cardType === "carousel" ? 
                    truncatedText(details.imagePreviewUrl, 16)
                : cardType === "slide-baner" ?
                    details.text+' '+details.id
                :
                details.text ? truncatedText(details.text, 15) : truncatedText(details.link, 15)
                }</span>
            <div className='d-flex'>
                <PrimaryButton style={{ marginRight: 2 }} onClick={cardType !== "slide-baner"&&!cardType.includes("-content") ? () => toggle(type, details.id, cardType) : handleButtonClick}>
                    {cardType !== "poppins-content"&&cardType !== "slide-baner"&&!cardType.includes("-content") ?
                    <i className='fa fa-edit'></i>
                    :
                    (isButtonClicked ? <i className='fa fa-chevron-up'></i> : <i className='fa fa-chevron-down'></i>)
                    }
                </PrimaryButton>
                <PrimaryButton onClick={() => onDelete(details.id)}>
                    <i className='fa fa-times'></i>
                </PrimaryButton>
            </div>
        </li>
    );
});

export default Objet;