import React from "react";
const ColorPicker = (props) => {
    const {details,action,type} = props;
    return(
    <label className="color-selector">
        <span className="circle" style={{background:type == 'color' ? details.color : details.background===undefined ? '#b4d6ca' : details.background}} />
        <span>{type == 'color' ? details.color : details.background===undefined ? '#b4d6ca' : details.background}</span>
        <input
            type="color"
            value={type == 'color' ? details.color : details.background===undefined ? '#b4d6ca' : details.background}
            onChange={action}
            className="hidden"
            id={'color-picker-'+details.id}
        />
    </label>
    )
}
export default ColorPicker