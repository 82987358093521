import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
const AdminAccordion = forwardRef((props, ref) => {
    const { title,content, scroll } = props;
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);
    function handleClick() {
        setIsOpen(!isOpen);
        scroll();
    }
    useImperativeHandle(ref, () => ({
        setIsOpen: (value) => setIsOpen(value),
        scrollIntoView: (id) => {
            const element = contentRef.current.querySelector(id);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }));
    return (
        <div className='dropdown' ref={ref}>
            <div className='dropdown-header' onClick={() => handleClick()}>
                {title}
                <i className={`fa fa-chevron-right icon ${isOpen ? 'open' : ''}`}></i>
            </div>
            <div ref={contentRef} className={!isOpen? 'dropdown-body' : 'dropdown-body open'}>
                {content}
            </div>
        </div>
    );
})
export default AdminAccordion;