import React, { useRef,useEffect } from 'react';

function AutoHeightTextarea({id, change, value, ...restProps }) {
    const textareaRef = useRef(null);
    useEffect(() => {
        autosize();
    }, [value]);
    useEffect(() => {
        autosize();
    }, [restProps.view]);
    const autosize = () => {
        const textarea = textareaRef.current;
        textarea.style.cssText = 'height:0; padding:0';
        textarea.style.cssText = 'height:' + textarea.scrollHeight + 'px';
    };
    return (
        <textarea
            id={id}
            ref={textareaRef}
            value={value}
            className={restProps.absolute ? (restProps.classvalue ? 'textarea-edit absolute-edit '+restProps.classvalue : 'textarea-edit absolute-edit'): restProps.classvalue ? 'textarea-edit '+restProps.classvalue : 'textarea-edit'}
            onChange={change}
            onKeyDown={autosize}
            {...restProps}
        />
    );
}
export default AutoHeightTextarea;