import styled from "styled-components";
export const PrimaryInput = styled.input`
    border:none;
    background-color:#01b9ff;
    padding:.5rem .75rem;
    border-radius:0;
    color:#fff;
    font-size:14px;
    width:100%;
    margin-bottom:16px;
    display:block;
    font-family:inherit
`;
export const PrimaryTextarea = styled.textarea`
border:none;
background-color:#01b9ff;
padding:.5rem .75rem;
border-radius:0;
color:#fff;
font-size:14px;
width:100%;
margin-bottom:16px;
min-height:100px;
font-family:inherit
`;