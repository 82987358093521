import React from "react";
const InputNumber = (props) => {
  return(
    <div className="input-number">
      <input className="number" name={props.name} id={props.id} onChange={(e)=>props.changeNumber(e)} type="text" value={props.width ? props.width : 0}/>
      <div className="action">
        <button type="button" onMouseDown={props.incr} onMouseUp={props.stop}>&#43;</button>  
        <button type="button" onMouseDown={props.decr} onMouseUp={props.stop}>&minus;</button>
      </div> 
    </div>
  )
}
export default InputNumber